<template>
  <div>
    <div class="d-flex align-items-center flex-row justify-content-between">
      <div>
        <span class="select-label">{{ $t(label) }}</span>
        <div class="select-description">{{ $t(description) }}</div>
        <div class="select-sub-description">{{ $t(subDescription) }}</div>
      </div>
      <base-select-wrapper
        class="selector"
        :options="enrichedOptions"
        :icon="true"
        :value="value"
        :placeholder="$t(placeholder)"
        @select="handleSelect"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'select-setting',
  data() {
    return {
      value: '',
      isOpen: false,
    };
  },
  props: {
    label: {
      type: String,
      default: () => '',
    },
    companyPreference: {
      type: String,
      default: () => '',
    },
    getter: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.value = this[this.getter] && !!this[this.getter].type ? 'AND' : 'OR';
  },
  computed: {
    description() {
      const currentOption = this.options.find((op) => op.value === this.value);
      return currentOption.description;
    },
    subDescription() {
      const currentOption = this.options.find((op) => op.value === this.value);
      return currentOption.subDescription;
    },
    enrichedOptions() {
      return this.options.map((op) => {
        return { ...op, key: op.value, label: this.$t(op.label) };
      });
    },
    ...mapGetters(['companyApplyExclusiveSearchPreference']),
  },
  methods: {
    async handleChange(val) {
      this.$notify.info({
        title: this.$t(
          'settings-layout.menu-data.exclusive-search.toast-title',
        ),
        message: this.$t(
          'settings-layout.menu-data.exclusive-search.toast-description',
        ),
        showClose: true,
        duration: 5000,
        customClass: 'custom-notification custom-notification-info',
      });
      return val === 'AND'
        ? this.addCompanyPreferences({
            preferenceType: this.companyPreference,
            preferenceValue: '',
          })
        : this.deleteCompanyPreferences({
            preferenceIds: [this[this.getter].id],
          });
    },
    handleSelect(e) {
      this.value = e;
    },
    ...mapActions(['addCompanyPreferences', 'deleteCompanyPreferences']),
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal && oldVal && newVal !== oldVal) this.handleChange(newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-label {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}
.select-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
.select-sub-description {
  font-size: 12px;
  color: $grey-7-mayday;
  font-style: italic;
}
.selector {
  width: 80px;
}
</style>
