<template>
  <div class="brain-taskt-toast">
    <BrainTaskToastHeader
      :icon="['fal', 'bolt']"
      :title="$t('brain-file-import.loading-toast.title')"
      :closable="closable"
      @close="$emit('close')"
    />

    <BrainTaskToastBody
      :status="status"
      :status-configs="statusConfigs"
      @click:cta="handleCTA"
    />
  </div>
</template>

<script>
import BrainTaskToastHeader from './BrainTaskToastHeader.vue';
import BrainTaskToastBody from './BrainTaskToastBody.vue';

export default {
  name: 'brain-import-file-task-toast',
  components: {
    BrainTaskToastHeader,
    BrainTaskToastBody,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      statusConfigs: {
        loading: {
          loading: true,
          description: this.$t('brain-file-import.loading-toast.loading'),
          closable: true,
        },
        pending: {
          loading: true,
          description: this.$t('brain-file-import.loading-toast.pending'),
          closable: true,
        },
        done: {
          description: this.$t('brain-file-import.loading-toast.done'),
          cta: this.$t('brain-file-import.loading-toast.done-button'),
          closable: true,
        },
        failed: {
          title: this.$t('brain-file-import.loading-toast.failed-title'),
          description: this.$t(
            'brain-file-import.loading-toast.failed-description',
          ),
          cta: this.$t('brain-file-import.loading-toast.failed-button'),
          closable: true,
        },
      },
    };
  },
  methods: {
    handleCTA(status) {
      switch (status) {
        case 'done':
          return this.$emit('click:refresh');
        case 'failed':
          return this.$emit('click:retry');
        default:
          return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.brain-taskt-toast {
  width: 380px;
  font-size: 14px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  z-index: 999;
  box-shadow: $shadow-mayday;
  color: $purple-5-mayday;
}
</style>
