<template>
  <!-- RELATED CONTENT -->
  <div class="settings__public-item">
    <div class="label">
      {{
        $t(
          'knowledge.drawers.content-view-setting.settings.public.related-contents',
        )
      }}
      <el-tooltip
        :content="
          $t(
            'knowledge.drawers.content-view-setting.settings.public.related-contents-tooltip',
          )
        "
        placement="top"
      >
        <font-awesome-icon
          :icon="['fad', 'info-circle']"
          class="info-icon mx-2"
        />
      </el-tooltip>
      <div class="toggle">
        <button class="reveal" @click="displayRelated = !displayRelated">
          <div :class="{ 'open-reveal': displayRelated }">
            <font-awesome-icon :icon="['fal', 'chevron-right']" />
          </div>
        </button>
      </div>
    </div>

    <not-allowed-tooltip
      :user-permissions="userPermissions"
      permission-key="canUpdateContent"
      placement="top"
    >
      <template v-slot:main-content="{ hasPermissions }">
        <div class="related-container" v-if="displayRelated">
          <!-- RELATED CONTENT SEARCH -->
          <input
            v-model="query"
            @input="debounceSearch"
            :disabled="!hasPermissions"
            :placeholder="
              $t(
                'knowledge.drawers.content-view-setting.settings.public.related-contents-search-placeholder',
              )
            "
          />
          <div v-if="query" class="search-wrapper">
            <div v-if="searchLoading" class="flex justify-content-center w-100">
              <font-awesome-icon :icon="['fal', 'spinner-third']" spin />
            </div>
            <div v-else>
              <div
                v-for="(content, index) in results"
                :key="content.id"
                class="search-item pointer"
                :class="[index === hovered ? 'search-item-hovered' : '']"
                @mouseenter="onHover(index)"
                @mouseleave="hovered = -1"
                @click="addRelatedContent(content)"
              >
                <div class="mx-1 content-icon">
                  <img
                    v-if="content.type === 'Article'"
                    src="~assets/article-icon-v2.svg"
                    class="content-search-img"
                  />
                  <img
                    v-else
                    src="~assets/diag-icon-v2.svg"
                    class="content-search-img"
                  />
                </div>
                <TextEllipsis :text="content.label" font-size="14px" />
              </div>
              <div v-if="!results.length && query.length">
                {{
                  $t(
                    'knowledge.drawers.content-view-setting.settings.public.related-contents-search-empty',
                  )
                }}
              </div>
            </div>
          </div>
          <!-- RELATED CONTENT DRAGGABLE LIST -->
          <div v-else>
            <draggable
              v-if="relatedList.length"
              class="related-list"
              v-model="relatedList"
              handle=".draggable"
              group="related-contents"
              :animation="200"
              :disabled="!hasPermissions"
              @start="handleStart"
              @end="drag = false"
            >
              <div
                v-for="content in relatedList"
                :key="content.id"
                class="draggable flex justify-content-between w-100"
              >
                <div class="search-item">
                  <div class="mx-1 content-icon">
                    <font-awesome-icon
                      class="drag-icon"
                      :icon="['fas', 'grip-vertical']"
                    />
                    <img
                      v-if="content.type === 'Article'"
                      class="content-img"
                      src="~assets/article-icon-v2.svg"
                    />
                    <img
                      v-else
                      class="content-img"
                      src="~assets/diag-icon-v2.svg"
                    />
                  </div>
                  <TextEllipsis
                    :text="content.label"
                    :stop="drag"
                    font-size="14px"
                  />
                  <el-tooltip
                    v-if="!content.published"
                    :content="
                      $t(
                        'knowledge.drawers.content-view-setting.settings.public.related-contents-published-tooltip',
                      )
                    "
                    placement="top"
                    class="published-tooltip"
                  >
                    <font-awesome-icon
                      :icon="['fad', 'info-circle']"
                      class="info-icon mx-2"
                    />
                  </el-tooltip>
                </div>

                <div
                  class="remove-related mr-2"
                  @click="
                    hasPermissions ? removeRelatedContent(content.id) : ''
                  "
                >
                  <font-awesome-icon :icon="['fal', 'trash']" />
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </template>
    </not-allowed-tooltip>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters, mapActions } from 'vuex';
import TextEllipsis from '@/components/TextEllipsis.vue';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  name: 'content-view-settings-public-related',
  components: {
    TextEllipsis,
    NotAllowedTooltip,
  },
  props: {
    relatedContents: {
      type: Array,
      required: false,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      displayRelated: true,
      query: '',
      relatedList: this.relatedContents,
      results: [],
      searchLoading: false,
      drag: false,
      dragId: null,
      hovered: -1,
    };
  },
  computed: {
    relatedContentIds() {
      return this.relatedList.map((content) => content.id);
    },
    ...mapGetters('knowledgeModule', ['focusKnowledgeId', 'focusContent']),
  },
  watch: {
    relatedList() {
      this.$emit('update-related-contents', this.relatedContentIds);
    },
    query() {
      if (this.query === '') this.hovered = -1;
    },
  },
  mounted() {
    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  methods: {
    _keyListener(e) {
      if (
        e.key === 'Enter' &&
        this.hovered >= 0 &&
        this.hovered <= this.results.length
      ) {
        e.preventDefault();
        this.addRelatedContent(this.results[this.hovered]);
      } else if (e.key === 'ArrowUp') {
        this.hovered =
          this.hovered > 0 ? this.hovered - 1 : this.results.length - 1;
      } else if (e.key === 'ArrowDown') {
        this.hovered =
          this.hovered < this.results.length - 1 ? this.hovered + 1 : 0;
      }
    },
    onHover(index) {
      this.hovered = index;
    },
    addRelatedContent(content) {
      const { label, id, type } = content;
      this.relatedList.push({ label, id, type, published: true });
      this.query = '';
    },
    removeRelatedContent(id) {
      this.relatedList = this.relatedList.filter(
        (content) => content.id !== id,
      );
    },
    debounceSearch: debounce(function () {
      return this.handleSearch();
    }, 200),
    async handleSearch() {
      if (this.query === '') return;
      this.searchLoading = true;
      const res = await this.handleSearchRelatedContent(this.query);
      //HIDE ALREADY ADDED CONTENT
      this.results = res.filter(
        ({ id }) =>
          !this.relatedContentIds.includes(id) && id !== this.focusContent.id,
      );
      this.searchLoading = false;
    },
    handleStart() {},
    ...mapActions('knowledgeModule', ['handleSearchRelatedContent']),
  },
};
</script>

<style lang="scss" scoped>
:deep() .el-input__inner {
  padding: 4px 8px;
  height: 28px;
  background-color: $grey-1-mayday;
  border-color: $grey-2-mayday;
  color: $grey-7-mayday;
}

.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
}

.draggable {
  overflow: hidden;
  cursor: grab;
  border-radius: 4px;
  &:hover {
    background-color: $grey-1-mayday;
  }
  &:hover .remove-related {
    display: flex;
  }
  &:hover .drag-icon {
    color: $grey-6-mayday;
    display: flex;
  }

  &:not(:hover) .content-img {
    display: flex !important;
  }
}

.drag-icon {
  display: none;
  font-size: 14px;
  margin: 0 4px 0 2px;
}

.remove-related {
  cursor: pointer !important;
  background: $grey-1-mayday;
  border-radius: 4px;
  height: 18px;
  width: 18px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  margin-left: 4px;
  color: $grey-9-mayday;
  * {
    margin: 2px;
    height: 12px;
    width: 12px;
    font-size: 12px;
  }
  &:hover {
    background: $grey-3-mayday;
  }
}

.related-container {
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
  font-size: 14px;
  input {
    width: 100%;
    outline: none;
    border: none;
    padding: 4px 8px;
  }

  .related-list {
    padding: 4px 8px;
    max-height: 200px;
    overflow: auto;
    border-top: 1px solid $grey-3-mayday;
  }

  .related-placeholder {
    padding: 4px 8px;
    max-height: 200px;
    color: $grey-5-mayday;
  }
}

.search-wrapper {
  border-top: 1px solid $grey-3-mayday;
  overflow: auto;
  max-height: 200px;
  padding: 4px 8px;
}

.search-item {
  max-width: 100%;
  min-width: 0;
  font-size: 14px;
  display: flex;
  padding: 2px 0 2px 0;
  border-radius: 4px;

  .content-icon {
    margin-top: 4px;
    width: 14px;
    height: 14px;
    align-items: center;
    justify-content: center;
    img {
      width: 14px;
      height: 14px;
    }
    .content-img {
      display: none;
    }
    .content-search-img {
      display: flex;
    }
  }
}

.search-item-hovered {
  background-color: $grey-1-mayday !important;
}

.pointer {
  cursor: pointer;
}

.published-tooltip {
  cursor: default;
  margin-top: 6px;
}

.settings__public-item {
  margin-bottom: 12px;
}

.settings__public-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

:deep() .el-button {
  padding: 4px 8px;
  font-size: 12px;
}

.reveal {
  width: 18px;
  height: 18px;
  transform-origin: center;

  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: $grey-1-mayday;
  &:hover {
    color: $blue-mayday;
    background: rgba($blue-mayday, 0.1);
  }

  * {
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 200ms ease;
  }
  .open-reveal {
    transform: rotate(90deg);
  }
}
</style>
