<template>
  <modal
    class="search-admin-modal"
    :show.sync="localDisplay"
    :close-icon="false"
    body-classes="p-0"
  >
    <div class="search-admin-wrapper" @click="handleClickInside">
      <div class="search-main" ref="search-main">
        <SearchInput
          ref="search-input"
          class="search-input"
          :tags="selectedTags"
          :placeholder="displayAsk ? $t('ask.search.placeholder') : ''"
          :display-ask="!!displayAsk"
          v-model="searchInputFilter"
          :autocomplete="searchAutocompleteContent"
          @focus="showDropdown = true"
          @delete-tag="deleteTag"
          @delete-all="deleteAllFilters"
        />
        <LoaderLine :loading="loading" />

        <div v-if="showDropdown" class="search-dropdown">
          <div class="d-flex ml-3 pt-2" v-if="searchCorrection.text">
            <div>{{ $t('knowledge.search.correction') }}</div>
            <div
              @click.stop="searchInputFilter = searchCorrection.text"
              class="search-correction"
              v-html="sanitize(searchCorrection.highlighted)"
            ></div>
          </div>
          <div class="d-flex ml-3 pt-2" v-if="displayFilterSuggestion">
            <div>{{ $t('knowledge.search.suggestion-filter') }}</div>
            <div class="ml-2">
              <Tag :tag="searchFilterSuggestion" @focus="useSuggestFilter" />
            </div>
          </div>

          <AskWrapper
            v-if="displayAsk"
            :search-input="searchInputFilter"
            :is-question="isQuestion"
            @go-to-document="goToDocument($event, 'ask')"
            @open-sidebar="handleOpenSidebar"
            @copy-document-url="handleCopyDocumentUrl"
            @update-search-input="handleUpdateSearchInputFilter"
            @update-ask-status="askStatus = $event"
          ></AskWrapper>
          <div class="d-flex flex-col relative mt-2">
            <SearchHeader
              class="search-header"
              :display="showToggleMoreFilters"
              :toggle="toggleMoreFilters"
              @toggle-filters="toggleMoreFilters = !toggleMoreFilters"
            />
            <div :class="{ overflow: results.length }">
              <SearchFilters
                :selected-tags="selectedTags"
                :show-more-filters="toggleMoreFilters"
                :searchable-languages="searchableLanguages"
                :search-language="searchLanguage"
                @update-selected-tags="updateTags"
                @change-language="handleLanguage"
              />
              <SearchRecentlyConsulted
                v-if="displayRecentlyConsulted"
                :contents="
                  orderedUserRecentlyConsultedContents(focusKnowledgeValue)
                "
                @go-to-document="goToDocument($event, 'search')"
              />
              <SearchSuggestions
                v-loading="loading"
                v-if="displaySearchSuggestions"
                class="search-suggestions"
                :results="results"
                :labelSearchedIds="labelSearchedIds"
                :searchLanguage="searchLanguage"
                @go-to-document="goToDocument($event, 'search')"
              />
            </div>
          </div>

          <SearchFooter
            v-if="searchInProgress"
            :loading="loading"
            :steps="searchSteps"
            :count="totalCount"
            @go-to-results-page="goToSearchResultPage"
          />
        </div>
      </div>
      <div
        class="sidebar-transition-wrapper"
        :class="{ 'pointer-events-none': !displaySidebar }"
        ref="sidebar"
      >
        <Transition name="slide-fade">
          <div class="content-sidebar-viewer" v-if="displaySidebar">
            <SearchSidebarContentPreview
              :entityId="sidebarEntityId"
              :denormalizedCaseParameters="sidebarDenormalizedCaseParameters"
              @close="handleCloseSidebar"
              @change-content="handleChangeContentInSidebar"
            ></SearchSidebarContentPreview>
          </div>
        </Transition>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import { v4 as uuid } from 'uuid';
import { resolveSuggestFilter } from './ResolveSuggestFilter';

import SearchInput from './SearchInput';
import LoaderLine from './LoaderLine';
import SearchHeader from './SearchHeader';
import SearchFilters from './SearchFilters';
import SearchSuggestions from './SearchSuggestions';
import SearchRecentlyConsulted from './SearchRecentlyConsulted';
import SearchFooter from './SearchFooter';
import { sanitize } from '@/plugins/dompurify';
import Tag from './Tag';
import AskWrapper from './Ask/AskWrapper';

import SearchSidebarContentPreview from './ContentPreview/SearchSidebarContentPreview.vue';

export default {
  name: 'search-wrapper',
  components: {
    SearchInput,
    LoaderLine,
    SearchFilters,
    SearchSuggestions,
    SearchRecentlyConsulted,
    SearchFooter,
    SearchHeader,
    Tag,
    AskWrapper,
    SearchSidebarContentPreview,
  },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      suggest: {
        corrections: null,
        autocomplete: null,
        filter: null,
      },
      isQuestion: false,
      sanitize: sanitize,
      localDisplay: this.display,
      showDropdown: false,
      toggleMoreFilters: false,
      searchInputFilter: '',
      selectedTags: [],
      loading: false,
      results: [],
      totalCount: 0,
      searchFrom: 0,
      searchLanguage: '',
      size: 10,
      correlationId: 'admin-search-bar-' + uuid(),
      debounceSearchFilters: debounce(() => {
        this.superSearch();
      }, 300),
      debounceSearchAutocomplete: debounce(() => {
        this.handleSearchAutocomplete();
      }, 100),
      dates: { createdAt: {} },
      displaySidebar: false,
      sidebarEntityId: '',
      sidebarDenormalizedCaseParameters: [],
      askStatus: {},
    };
  },
  mounted() {
    document.addEventListener('keydown', this._keyListener.bind(this));
    this.searchLanguage = this.hasCompanyPreferenceWithValue(
      'HAS_ALL_LANGUAGES_SEARCH',
    )
      ? 'all'
      : this.editingLanguage;
  },

  watch: {
    searchInputFilter() {
      if (this.shouldNotSendSearch) {
        this.toggleMoreFilters = false;
        this.results = [];
        this.totalCount = 0;
      } else {
        this.emptySuggest();
        this.loading = true;
        this.debounceSearchAutocomplete();
        this.debounceSearchFilters();
      }
    },
    chosenEntity() {
      this.toggleMoreFilters =
        this.chosenEntity !== 'attached-contents' && this.isParametric
          ? false
          : this.toggleMoreFilters;
    },
    selectedTags() {
      if (this.shouldNotSendSearch) {
        this.results = [];
        this.totalCount = 0;
      } else {
        this.loading = true;
        this.debounceSearchAutocomplete();
        this.debounceSearchFilters();
      }
    },
    localDisplay() {
      if (!this.localDisplay) {
        this.$emit('close');
      }
    },
  },
  computed: {
    searchCorrection() {
      if (
        !this.suggest.corrections ||
        !this.suggest.corrections.text ||
        !this.searchInputFilter
      )
        return { highlighted: '', text: '' };
      return this.suggest.corrections;
    },
    searchFilterSuggestion() {
      if (this.noFilterSuggestion) return {};
      return resolveSuggestFilter(this.suggest.filter);
    },
    noFilterSuggestion() {
      return (
        !this.suggest.filter ||
        !this.suggest.filter.text ||
        !this.searchInputFilter
      );
    },
    displayFilterSuggestion() {
      return this.searchFilterSuggestion && !this.noFilterSuggestion;
    },
    searchAutocompleteContent() {
      if (
        !this.suggest.autocomplete ||
        !this.suggest.autocomplete.label ||
        !this.suggest.autocomplete.remaining ||
        !this.searchInputFilter ||
        this.searchInputFilter === this.suggest.autocomplete.label
      )
        return { id: '', label: '', type: '', remaining: '' };
      return this.suggest.autocomplete;
    },
    chosenEntity() {
      const entity = this.selectedTags.find((tag) => tag.key === 'entity');
      return entity ? entity.value : '';
    },
    entitiesSearchField() {
      if (this.isParametric) {
        return this.chosenEntity === 'attached-contents' ||
          this.chosenEntity === 'new-products'
          ? [this.chosenEntity]
          : ['attached-contents', 'cases', 'new-products'];
      } else return ['attached-contents', 'cases'];
    },
    preFilters() {
      return this.selectedTags.reduce((acc, tag) => {
        if (tag.key !== 'entity') {
          if (acc[tag.key]) {
            tag.noArray
              ? (acc[tag.key] = tag.value)
              : acc[tag.key].push(tag.value);
          } else {
            acc[tag.key] = tag.noArray ? tag.value : [tag.value];
          }
        }
        return acc;
      }, {});
    },
    isProductEntity() {
      return this.chosenEntity === 'new-products';
    },
    hasUnviversTag() {
      return this.selectedTags.map((t) => t.key).includes('knowledge');
    },
    hasFamilyTag() {
      return this.selectedTags.map((t) => t.key).includes('family');
    },
    isUniversStep() {
      return this.isProductEntity && !this.hasUnviversTag;
    },
    isfamilyStep() {
      return this.isProductEntity && this.hasUnviversTag && !this.hasFamilyTag;
    },
    searchSteps() {
      return {
        univers: this.isUniversStep,
        family: this.isfamilyStep,
      };
    },
    searchInProgress() {
      return this.selectedTags.length > 0 || this.searchInputFilter !== '';
    },
    queryParameters() {
      return {
        search: this.searchInputFilter,
        ...this.selectedTags.reduce((acc, tag) => {
          if (
            tag.key === 'createdAt' ||
            tag.key === 'updatedAt' ||
            tag.key === 'verificationPolicy.verificationDueDate'
          ) {
            acc[tag.key] = JSON.stringify(tag.value);
            return acc;
          }
          if (acc[tag.key]) {
            tag.noArray
              ? (acc[tag.key] = tag.value)
              : acc[tag.key].push(tag.value);
          } else {
            acc[tag.key] = tag.noArray ? tag.value : [tag.value];
          }
          return acc;
        }, {}),
        init: true,
        correlation_id: this.correlationId,
      };
    },
    showToggleMoreFilters() {
      return this.chosenEntity === 'attached-contents' || !this.isParametric;
    },
    labelSearchedIds() {
      return this.selectedTags.reduce((acc, tag) => {
        if (tag.key === 'categories') acc.push(tag.id);
        return acc;
      }, []);
    },
    noAutocomplete() {
      return !this.companySearchAutocompletePreference || this.isParametric;
    },
    displayRecentlyConsulted() {
      return (
        (!this.results ||
          !this.results.length ||
          this.searchInputFilter === '') &&
        this.orderedUserRecentlyConsultedContents(this.focusKnowledgeValue) &&
        this.orderedUserRecentlyConsultedContents(this.focusKnowledgeValue)
          .length
      );
    },
    displaySearchSuggestions() {
      return this.results && this.results.length;
    },
    displayAsk() {
      return (
        this.companyAllowAskInSearchPreference ||
        this.userAllowAskInSearchPreference
      );
    },
    labelsFilters() {
      return this.selectedTags.reduce((acc, tag) => {
        if (tag.key === 'categories') {
          acc.push(tag.value);
        }
        return acc;
      }, []);
    },
    knowledgeFilter() {
      const knowledge = this.selectedTags.find(
        (tag) => tag.key === 'knowledge',
      );
      return knowledge ? knowledge.value : '';
    },
    statusFilter() {
      return this.selectedTags.reduce((acc, val) => {
        if (val.key === 'published' || val.key === 'isOutdated') {
          acc.push({ key: val.key, value: val.value });
        }
        return acc;
      }, []);
    },
    currentFilter() {
      return {
        lang: this.searchLanguage,
        filterType: this.entitiesSearchField,
        entityType: this.preFilters.type || [],
        search: this.searchInputFilter,
        knowledge: this.knowledgeFilter,
        product: null,
        cases: null,
        status: this.statusFilter,
        accessRestrictions: null,
        labels: this.labelsFilters,
        isContribution: null,
        searchFrom: 0,
        size: 10,
      };
    },
    shouldNotSendSearch() {
      return (
        !this.searchInputFilter &&
        (!this.selectedTags || !this.selectedTags.length)
      );
    },
    ...mapGetters([
      'isParametric',
      'companySearchAutocompletePreference',
      'orderedUserRecentlyConsultedContents',
      'company',
      'companyAllowAskInSearchPreference',
      'hasCompanyPreferenceWithValue',
      'userAllowAskInSearchPreference',
    ]),
    ...mapState(['accessToken']),
    ...mapGetters('knowledgeModule', [
      'editingLanguage',
      'focusKnowledgeValue',
      'searchableLanguages',
    ]),
  },
  methods: {
    ...mapActions(['searchAction']),
    ...mapActions('knowledgeModule', [
      'searchAutocomplete',
      'goToTranslatedEntity',
      'getTranslatedEntityUrl',
      'storeSearchEvent',
    ]),
    updateTags(tags) {
      this.selectedTags = tags;
    },
    deleteTag(tag) {
      const index = this.selectedTags.findIndex(
        (t) => t.id == tag.id && t.key == tag.key,
      );
      this.selectedTags.splice(index, 1);
      if (tag.key === 'entity') {
        this.selectedTags = [];
      } else if (tag.key === 'knowledge') {
        this.selectedTags = this.selectedTags.filter((t) => t.key === 'entity');
      } else if (tag.key === 'family') {
        this.selectedTags = this.selectedTags.filter(
          (t) => t.key === 'entity' || t.key === 'knowledge',
        );
      }
    },
    deleteAllFilters() {
      this.searchInputFilter = '';
      this.selectedTags = [];
    },
    async handleSearchAutocomplete() {
      if (this.noAutocomplete && !this.displayAsk) return;
      const suggest = await this.searchAutocomplete({
        queryText: this.searchInputFilter,
        pre: {
          ...this.preFilters,
        },
        isQuestion: true,
        correlationId: this.correlationId,
      });
      if (!suggest) return;
      if (!this.noAutocomplete)
        this.suggest = {
          corrections: suggest.corrections,
          autocomplete: suggest.autocomplete,
          filter: suggest.filter,
        };
      this.isQuestion = suggest.isQuestion;
    },
    useSuggestFilter(val) {
      this.selectedTags.push(val);
      this.searchInputFilter = '';
    },
    emptySuggest() {
      this.suggest = {
        corrections: null,
        autocomplete: null,
        filter: null,
      };
    },
    async superSearch() {
      try {
        if (this.shouldNotSendSearch) return;

        const resp = await this.searchAction({
          searchType: 'superSearch',
          searchArgs: {
            entity: this.entitiesSearchField,
            fields: ['entity', 'type'],
            searchFrom: this.searchFrom,
            size: this.size,
            correlationId: this.correlationId,
            lang: this.searchLanguage,
            payload: {
              queryText: this.searchInputFilter,
              pre: {
                ...this.preFilters,
              },
              includeCorrections: true,
              showHidden: true,
              preTag: '<span style="font-weight:900">',
              postTag: '</span>',
            },
          },
        });
        this.results = resp.documents;
        this.totalCount = resp.counts.totalCount;
        if (!this.noAutocomplete) this.suggest.corrections = resp.correction;
        this.loading = false;

        this.storeSearchEvent({
          eventMethod: 'search',
          event: {
            search: this.currentFilter,
            displayedHits: this.results.length,
            totalHits: this.totalCount,
          },
        });
      } catch (e) {
        this.loading = false;
      }
    },
    goToSearchResultPage() {
      this.$router.push({
        name: 'knowledge-filter',
        query: this.queryParameters,
        params: { lang: this.searchLanguage },
      });
      this.localDisplay = false;
    },
    goToDocument(doc, from = 'search') {
      let entityId = doc.id,
        entityType = doc.type || doc.__typename, // Product and Case have no type field and use __typename (graphql)
        parentId;
      const query = { from };

      if (['Step', 'keyStep'].includes(entityType)) {
        parentId = doc.ancestors[0].split('/')[0];
      }

      this.goToTranslatedEntity({ entityId, entityType, parentId, query });
      if (from === 'search') {
        this.storeSearchEvent({
          eventMethod: 'searchSuccess',
          event: {
            content: {
              id: entityId,
              type: entityType,
            },
            search: this.currentFilter,
            itemIndex: doc.index,
            displayedHits: this.results.length,
            totalHits: this.totalCount,
            ask: this.askStatus,
          },
        });
      }
      this.localDisplay = false;
    },
    async handleCopyDocumentUrl(doc) {
      let entityId = doc.id,
        entityType = doc.type || doc.__typename, // Product and Case have no type field and use __typename (graphql)
        parentId;
      const query = { from: 'search' };

      if (['Step', 'keyStep'].includes(entityType)) {
        parentId = doc.ancestors[0].split('/')[0];
      }

      const url = await this.getTranslatedEntityUrl({
        entityId,
        entityType,
        parentId,
        query,
      });

      navigator.clipboard.writeText(`${window.location.origin}${url}`);
      this.$message(this.$t('editor.copy'));
    },
    handleClickOutside() {
      if (!this.displaySidebar) this.localDisplay = false;
    },
    handleClickInside(e) {
      const el1 = this.$refs['search-main'];
      const el2 = this.$refs['sidebar'];
      const contains = el1.contains(e.target) || el2.contains(e.target);
      if (!contains) this.localDisplay = false;
      if (!window.getSelection() && !window.getSelection().toString())
        this.$refs['search-input'].focusInput();
    },

    beforeDestroy() {
      this.debounceSearchFilters.flush();
    },
    handleOpenSidebar({ entityId, denormalizedCaseParameters }) {
      if (!this.displaySidebar) this.displaySidebar = true;
      this.sidebarEntityId = entityId;
      this.sidebarDenormalizedCaseParameters = denormalizedCaseParameters;
    },
    handleCloseSidebar() {
      this.displaySidebar = false;
      this.sidebarEntityId = '';
    },
    handleChangeContentInSidebar({ entityId }) {
      this.sidebarEntityId = entityId;
    },
    handleUpdateSearchInputFilter(e) {
      this.searchInputFilter = e;
    },
    _keyListener(e) {
      if (e.key === 'Tab' && this.displayFilterSuggestion) {
        e.preventDefault();
        this.$refs['search-input'].focusInput();
        this.useSuggestFilter(this.searchFilterSuggestion);
      }
      if (
        e.key === 'Backspace' &&
        this.searchInputFilter === '' &&
        this.selectedTags.length
      ) {
        this.selectedTags.pop();
      }
      if (e.key === 'k' && (e.ctrlKey || e.metaKey)) {
        this.$emit('close');
      }
    },
    handleLanguage(e) {
      this.searchLanguage = e;
      this.loading = true;
      this.debounceSearchFilters();
      this.debounceSearchAutocomplete();
    },
  },
};
</script>

<style lang="scss" scoped>
.search-admin-modal {
  :deep() .modal-dialog {
    max-width: 100% !important;
    margin: 0px;
    height: 100%;
  }

  :deep() .modal-body {
    height: 80vh !important;
  }

  :deep() .modal-content {
    background-color: transparent !important;
    box-shadow: unset;
    -webkit-box-shadow: unset;
    height: 100%;
  }
}

.search-main {
  width: 50%;
  margin-top: 30px;
  max-height: calc(100% - 30px);
}

.search-admin-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100% !important;
}

.content-sidebar-viewer {
  height: 100%;
  overflow: hidden;
  background-color: white;
  border-radius: 8px 0px 0px 8px;
}
.search-input {
  box-shadow: $shadow-lg-mayday;
  -webkit-box-shadow: $shadow-lg-mayday;
  border-radius: 8px 8px 0px 0px;
}
.search-dropdown {
  max-height: calc(100% - 60px);
  overflow: auto;
  background: white;
  border-radius: 0px 0px 8px 8px;
  box-shadow: $shadow-lg-mayday;
  -webkit-box-shadow: $shadow-lg-mayday;
}

.overflow {
  max-height: 40vh;
  overflow: auto;
}

.search-correction {
  margin-left: 4px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  white-space: break-spaces;
  &:hover {
    color: $blue-mayday !important;
    text-decoration: underline !important;
  }
}

.search-autocomplete {
  margin-left: 4px;
  font-weight: 700;
  cursor: pointer;
}

.sidebar-transition-wrapper {
  width: calc(25% - 70px);
  height: 100%;
  margin-left: 70px;
  position: fixed;
  top: 0;
  right: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
  opacity: 0;
}

.pointer-events-none {
  pointer-events: none;
}
.search-header {
  position: absolute;
  right: 12px;
}
</style>
