<template>
  <div class="automations-menu">
    <div>
      <div class="automations-menu-section-title">
        <font-awesome-icon
          :icon="['fas', 'database']"
          class="mr-2"
        />
        <div>{{ $tc('automations.knowledge-base', 2) }}</div>
      </div>
      <div v-for="knowledge in knowledges" :key="knowledge.id" class="automations-menu-section-option">
        <AutomationKnowledgeMenu
          :knowledge="knowledge"
          :collapse="focusedKnowledgeId === knowledge.id"
          @updateFocusedKnowledge="updateFocusedKnowledge"
          @updateFocusedCase="$emit('updateFocusedCase', $event)"
        ></AutomationKnowledgeMenu>
      </div>
    </div>

    <div class="mt-4">
      <div class="automations-menu-section-title">
        <font-awesome-icon
          :icon="['fad', 'user-robot']"
          class="mr-2"
        />
        <div>{{ $tc('automations.attributes', 2) }}</div>
      </div>
      <div
        v-for="integration in integrations"
        :key="integration.value"
        class="mb-2"
      >
        <AutomationIntegrationAttributesMenu
          :title="integration.title"
          :attributes="integration.attributes"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AutomationKnowledgeMenu from './AutomationKnowledgeMenu';
import AutomationIntegrationAttributesMenu from './AutomationIntegrationAttributesMenu';

const INTEGRATIONS = ['zendesk', 'intercom', 'salesforce', 'salesforce_2'];

export default {
  name: 'AutomationsMenu',
  components: {
    AutomationKnowledgeMenu,
    AutomationIntegrationAttributesMenu,
  },
  props: {
    knowledges: {
      type: Array,
      default: () => [''],
    },
    groupedAttributes: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedContent: '',
      focusedKnowledgeId: null,
    };
  },
  computed: {
    hasMultiSalesforce() {
      return !!this.getCompanyPreference('HAS_MULTI_SALESFORCE_ACCOUNT');
    },
    integrations() {
      let integrations = INTEGRATIONS;
      if (!this.hasMultiSalesforce)
        integrations = integrations.filter((el) => el !== 'salesforce_2');
      return integrations
        .map((i) => {
          if (!this.groupedAttributes[i]) return null;
          return {
            title: i.toUpperCase(),
            attributes: this.groupedAttributes[i],
          };
        })
        .filter((el) => !!el);
    },
    ...mapGetters(['getCompanyPreference']),
  },
  methods: {
    updateFocusedKnowledge(event) {
      const { knowledgeId } = event;
      if (this.focusedKnowledgeId === knowledgeId) {
        this.focusedKnowledgeId = null;
      } else {
        this.focusedKnowledgeId = knowledgeId;
        this.$emit('updateFocusedKnowledge', event);
      }
    },
    updateFocus(knowledgeId) {
      this.$emit('changeFocus', {
        id: this.selectedContent,
        label: this.getRootLabel,
        knowledgeId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.automations-menu {
  width: 100%;
  overflow-x: hidden;
}

.automations-menu-section-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: $grey-9-mayday;
  margin-bottom: 8px;
  padding-bottom: 4px;
  border-bottom: 1px solid $grey-2-mayday;
}

.automations-menu-section-option {
  width: 100%;
  overflow: hidden;
  margin-bottom: 4px;
}
</style>
