<template>
  <div class="page-container">
    <div>
      <AnalyticsTitle
        :title="$t('new-dashboard.header.title.analytics-download')"
        :tooltipContent="$t('new-dashboard.header.tooltip.analytics-download')"
      ></AnalyticsTitle>
      <div class="page-section table-container">
        <el-table
          :data="exportsData"
          :default-sort="{ prop: 'startDate', order: 'descending' }"
          cell-class-name="cell-class"
          header-cell-class-name="header-cell-class"
        >
          <el-table-column
            prop="startDate"
            :label="$t('analytics-download.start-date')"
            width="160"
            sortable
            sort-by="sortStartDate"
          >
          </el-table-column>
          <el-table-column
            prop="endDate"
            :label="$t('analytics-download.end-date')"
            width="160"
            sortable
            sort-by="sortEndDate"
          >
          </el-table-column>
          <el-table-column
            prop="exportType"
            :label="$t('analytics-download.type')"
            width="120"
            sortable
          >
            <template slot-scope="scope">
              <text-ellipsis
                :text="scope.row.exportType"
                font-size="font-size: 12px;"
              ></text-ellipsis
            ></template>
          </el-table-column>
          <el-table-column
            prop="periodicity"
            :label="$t('analytics-download.periodicity')"
            width="120"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('analytics-download.name')"
            width="230"
          >
            <template slot-scope="scope">
              <span style="font-weight: 700">{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            :label="$t('analytics-download.address')"
          >
            <template slot-scope="scope">
              <text-ellipsis
                :text="scope.row.address"
                font-size="font-size: 12px;"
              ></text-ellipsis>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('analytics-download.action')"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <div class="d-flex justify-content-center">
                <el-tooltip :content="$t('analytics-download.download')">
                  <base-button
                    icon="arrow-to-bottom"
                    variant="tertiary"
                    size="sm"
                    icon-only
                    @click="handleDownload(scope.row)"
                  />
                </el-tooltip>
                <el-tooltip :content="$t('analytics-download.copy')">
                  <base-button
                    icon="clipboard"
                    variant="tertiary"
                    size="sm"
                    icon-only
                    @click="copyUrl(scope.row.address)"
                  />
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="showExports">
      <AnalyticsTitle
        :title="$t('new-dashboard.header.title.analytics-download-kb')"
        :tooltipContent="
          $t('new-dashboard.header.tooltip.analytics-download-kb')
        "
      ></AnalyticsTitle>
      <div class="page-section">
        <p>{{ $t('new-dashboard.exports.kb') }}</p>
        <div class="download-card-container">
          <div class="download-card">
            <AnalyticsDownloadKB type="overview"></AnalyticsDownloadKB>
          </div>
          <div class="download-card">
            <AnalyticsDownloadKB type="contents"></AnalyticsDownloadKB>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AnalyticsTitle from '../AnalyticsTitle';
import TextEllipsis from 'components/TextEllipsis';
import AnalyticsDownloadKB from './AnalyticsDownloadKB';

export default {
  name: 'analytics-download',
  components: {
    AnalyticsTitle,
    TextEllipsis,
    AnalyticsDownloadKB,
  },
  async created() {
    this.getExports();
  },
  computed: {
    ...mapGetters('dashboard', ['exports']),
    ...mapGetters(['hasCompanyPreferenceWithValue']),
    exportsData() {
      return this.exports.map((item) => ({
        ...item,
        exportType: this.safeGetExportTypeName(item.exportType),
        periodicity: this.$t(`filters.groups.periodicity.${item.periodicity}`),
      }));
    },
    showExports() {
      return this.hasCompanyPreferenceWithValue('ANALYTICS_ENABLE_EXPORTS_KB');
    },
  },
  methods: {
    ...mapActions('dashboard', ['getExports']),
    safeGetExportTypeName(exportType) {
      const i18nKey = `filters.groups.type.${exportType}`;
      return this.$t(i18nKey) == i18nKey ? exportType : this.$t(i18nKey);
    },
    async copyUrl(url) {
      await navigator.clipboard.writeText(url);
      this.$message({
        message: this.$t(
          'knowledge.actions.copy-to-clipboard-confirmation-message',
        ),
        type: 'info',
      });
    },
    handleDownload(e) {
      window.open(e.address, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  max-height: 80vh;
  overflow-y: scroll;
}

.analytics-download-header {
  background-color: white;
  padding: 15px;
  border-bottom: 2px solid $grey-4-mayday;
  overflow-x: auto;
  max-width: 100%;
}

.routers {
  .router-wrapper {
    width: 77px;
    height: 34px;
  }
  .router-link-active {
    background-color: rgba($blue-mayday, 0.1);
    border-radius: 4px;
    color: $blue-mayday !important;
    padding: 5px;
  }
  .router-link {
    width: 100%;
    height: 100%;
    color: $grey-4-mayday;
  }
}

.action-button-wrapper {
  background: none;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0;
  width: 16px;
  height: 21px;
}

.action-button {
  &:hover {
    color: $blue-mayday;
    cursor: pointer;
  }
}
.cell-class {
  background-color: $grey-1-mayday;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-cell-class {
  background-color: $grey-1-mayday;
  border-top: none;
}

.cell .wrapper {
  display: block;
}
.page-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 24px;
}

.page-section {
  margin-top: 20px;
}

.download-card-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.card-container {
}
</style>
