<template>
  <SettingsDrawer
    :show="show"
    icon="passport"
    :title="profile.name"
    :title-options="titleOptions"
    :description="$t(`${pre}.description`)"
    :error-description="$t(`${pre}.error-description`)"
    :disabled="isInvalidProfile"
    :item-id="profile.id"
    @input:title="handleSetProfile('name', $event)"
    @submit="$emit('submit', profile)"
    @close="handleClose"
  >
    <div slot="main">
      <div class="sso-profile-permission">
        <div class="sso-profile-permission__title">
          {{ $tc('main.group', 2) }}
        </div>
        <base-select-wrapper
          :value="parsedGroupIdValue"
          :options="selectableGroups"
          :multiple="false"
          :searchable="true"
          :icon="true"
          @select="handleSetProfile('group', $event)"
        />
      </div>
      <div class="sso-profile-permission mt-4">
        <div class="sso-profile-permission__title">
          {{ $tc('main.role', 2) }}
        </div>
        <base-select-wrapper
          :value="parsedRoleIdsValue"
          :options="selectableRoles"
          :multiple="true"
          :searchable="true"
          :icon="true"
          value-key="id"
          @select="handleSetProfile('role', $event)"
        />
      </div>
    </div>
  </SettingsDrawer>
</template>

<script>
import { mapGetters } from 'vuex';

import SettingsDrawer from '@/components/Settings/SettingsDrawer/SettingsDrawer.vue';

const i18nPrefix =
  'settings-layout.menu-data.authentication.sso-profile.drawer';

export default {
  name: 'SsoProfileSettingsDrawer',
  components: { SettingsDrawer },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedProfile: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    selectableGroups() {
      return this.groups.map((g) => ({
        ...g,
        label: g.name,
        key: String(g.id),
      }));
    },
    parsedGroupIdValue() {
      if (!this.profile.groupIds || !this.profile.groupIds.length) return '';
      return String(this.profile.groupIds[0]);
    },
    selectableRoles() {
      return this.roles.map((g) => ({
        ...g,
        label: g.name,
        key: String(g.id),
      }));
    },
    parsedRoleIdsValue() {
      if (!this.profile.roleIds || !this.profile.roleIds.length) return [];
      return this.profile.roleIds.map((id) => String(id));
    },
    isInvalidProfile() {
      const { name, groupIds, roleIds } = this.profile;
      if (!name || !name.length || !this.titleValidationMethod(name))
        return true;
      if (!groupIds || !groupIds.length) return true;
      if (!roleIds || !roleIds.length) return true;
      return false;
    },
    ...mapGetters('adminModule', ['groups', 'roles']),
  },
  data() {
    return {
      pre: `${i18nPrefix}`,
      titleOptions: {
        editable: true,
        placeholder: this.$t(`${i18nPrefix}.placeholder`),
        validationMethod: this.titleValidationMethod,
      },
      profile: {
        name: '',
        // we match database schema even thought we only allow a single value
        groupIds: [],
        roleIds: [],
      },
    };
  },
  methods: {
    titleValidationMethod(name) {
      return !(name && name.length > 70);
    },
    handleClose() {
      this.handleSetProfile('reset', null);
      this.$emit('close');
    },
    handleSetProfile(type, evt) {
      switch (type) {
        case 'group':
          this.profile = { ...this.profile, groupIds: [evt] };
          break;
        case 'name':
          this.profile = { ...this.profile, name: evt };
          break;
        case 'reset':
          this.profile = {
            name: '',
            groupId: null,
            roleIds: [],
          };
          break;
        case 'role':
          this.profile = { ...this.profile, roleIds: evt };
          break;

        default:
          break;
      }
    },
  },
  mounted() {
    if (!this.selectedProfile) {
      this.handleSetProfile('reset', null);
    } else {
      this.profile = this.selectedProfile;
    }
  },
};
</script>

<style lang="scss" scoped>
.sso-profile-permission {
  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
  }
}
</style>
