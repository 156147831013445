<template>
  <div
    class="root-collection-card my-2 py-2 px-4 d-flex align-items-center"
    @click="$emit('click')"
  >
    <font-awesome-icon
      class="collapse-parameter-icon mr-1"
      :icon="['fad', iconValue]"
      :style="iconStyle"
    />
    {{ collection.label }}
  </div>
</template>

<script>
export default {
  name: 'root-collection-card',
  props: {
    collection: {
      type: Object,
      default: () => ({
        label: 'DYSFONCTIONNEMENTS',
        icon: 'wrench',
      }),
    },
  },
  computed: {
    iconValue() {
      return this.collection.icon && this.collection.icon.value
        ? this.collection.icon.value
        : 'folder';
    },
    iconStyle() {
      return this.collection.icon && this.collection.icon.color
        ? { color: this.collection.icon.color }
        : {};
    },
  },
};
</script>

<style lang="scss" scoped>
.root-collection-card {
  color: $grey-7-mayday;
  border-radius: 2px;
  background: white;
  box-shadow: $shadow-mayday;
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}
.collapse-parameter-icon {
  color: $grey-5-mayday;
}
</style>
