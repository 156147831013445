<template>
  <div class="templates-view-container">
    <!-- RESULTS TITLE -->
    <div class="templates-view-main">
      <div class="templates-view-wrapper col-8">
        <div class="templates-view-header">
          <div class="templates-view-icon">
            <div class="icon-wrapper">
              <font-awesome-icon :icon="['fas', 'file-certificate']" />
            </div>
          </div>
          <div class="templates-view-title">
            {{ $t('knowledge.templates.description') }}
          </div>
          <div class="templates-view-recap">
            <div class="recap-item">
              <div class="d-flex align-items-center justify-content-center">
                <img :src="icons.Article" />
              </div>
              <div>
                {{
                  templatesArticles.length
                    ? $tc(
                        'knowledge.templates.recap.article.label',
                        templatesArticles.length,
                      )
                    : $t('knowledge.templates.recap.article.empty-label')
                }}
              </div>
            </div>
            <div class="recap-item">
              <div class="d-flex align-items-center justify-content-center">
                <img :src="icons.Diagnostic" />
              </div>
              <div>
                {{
                  templatesDiagnostics.length
                    ? $tc(
                        'knowledge.templates.recap.diagnostic.label',
                        templatesDiagnostics.length,
                      )
                    : $t('knowledge.templates.recap.diagnostic.empty-label')
                }}
              </div>
            </div>
            <TranslationDropdown
              v-if="focusKnowledgeIsMultilinguale"
              class="mx-1"
              :languages="focusKnowledgeLanguages"
              :current-language="editingLanguage"
              @change-language="switchEditingLanguage($event)"
            />
          </div>
        </div>

        <div v-if="!templates.length" class="d-flex justify-content-center">
          <div class="templates-view-empty">
            <div class="title">
              {{ $t('knowledge.templates.empty-state.title') }}
            </div>
            <div class="icon">
              <img src="~assets/empty-states/empty-state-templates.png" />
            </div>
            <div class="description">
              {{ $t('knowledge.templates.empty-state.description') }}
            </div>
            <div>
              <el-button
                type="primary"
                size="small"
                @click="openCreateTemplate"
              >
                {{ $t('knowledge.templates.empty-state.cta') }}</el-button
              >
            </div>
          </div>
        </div>
        <TemplatesList
          v-loading="templatesLoading"
          :templates="templates"
          @delete-template="openDeleteModal($event)"
        />
      </div>
    </div>
    <ModalEvents
      modal-name="DeleteContentModal"
      :uid="deleteModalUniqueKey"
      @choice="handleDelete"
    />
    <ModalEvents
      modal-name="CreateTemplateModal"
      :uid="createTemplateUniqueKey"
      @choice="handleCreateTemplate"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TemplatesList from './TemplatesComponents/TemplatesList';
import ModalEvents from '@/components/Modals/ModalEvents';
import TranslationDropdown from '@/components/TranslationDropdown';

import articleIcon from '@/assets/article-icon-v2.1.svg';
import diagIcon from '@/assets/diag-icon-v2.1.svg';

export default {
  name: 'templates-viewer',
  components: {
    TemplatesList,
    ModalEvents,
    TranslationDropdown,
  },
  data() {
    return {
      templatesLoading: false,
      loadingMessage: null,
      templateToDelete: null,
      icons: {
        Diagnostic: diagIcon,
        Article: articleIcon,
      },
    };
  },
  computed: {
    deleteModalUniqueKey() {
      return `${this.$options.name}-delete-template-${this.templateToDelete}`;
    },
    createTemplateUniqueKey() {
      return `${this.$options.name}-create-template-${this.templateToDelete}`;
    },
    templatesArticles() {
      return this.templates.filter(({ content }) => content.type === 'Article');
    },
    templatesDiagnostics() {
      return this.templates.filter(
        ({ content }) => content.type === 'Diagnostic',
      );
    },
    ...mapGetters('knowledgeModule', [
      'templates',
      'templatesPathIds',
      'editingLanguage',
      'focusKnowledgeLanguages',
      'focusKnowledgeIsMultilinguale',
    ]),
  },
  async created() {
    this.resetTemplatesPath();
    this.$emit('change-route-type', 'Template');
    this.fetchTemplates();
  },
  methods: {
    async fetchTemplates() {
      this.templatesLoading = true;

      await this.getTemplates();

      this.templatesLoading = false;
    },
    openDeleteModal(templateId) {
      this.templateToDelete = templateId;
      const payload = {
        component: 'DeleteContentModal',
        uid: this.deleteModalUniqueKey,
        props: {
          display: true,
          deleteType: 'template',
        },
      };
      this.openModal(payload);
    },
    handleDelete(choice) {
      if (choice) {
        const res = this.deleteTemplate(this.templateToDelete);
        this.templateToDelete = null;
        if (res)
          return this.$message({
            type: 'success',
            message: this.$t('knowledge.templates.delete-success'),
          });
        return this.$message({
          type: 'error',
          message: this.$t('knowledge.templates.delete-failure'),
        });
      }
    },
    resetTemplatesPath() {
      this.updateTemplatesPath([]);
    },
    async openCreateTemplate() {
      const payload = {
        component: 'CreateTemplateModal',
        uid: this.createTemplateUniqueKey,
        props: { display: true, name: '', type: true },
      };
      this.openModal(payload);
    },
    async handleCreateTemplate(template) {
      await this.createTemplate(template);
    },
    ...mapActions('knowledgeModule', [
      'createTemplate',
      'getTemplates',
      'deleteTemplate',
      'updateTemplatesPath',
      'switchEditingLanguage',
    ]),
    ...mapActions('modalsModule', ['openModal']),
  },
  watch: {
    $route() {
      if (this.$route.path.includes('templates')) {
        return this.resetTemplatesPath();
      }
    },
    editingLanguage() {
      this.fetchTemplates();
    },
  },
};
</script>

<style lang="scss" scoped>
.templates-view-container {
  height: calc(100vh - 68px);
  padding-bottom: 64px;
  background-color: white;
  box-shadow: $shadow-mayday;
  overflow-y: auto;
}

.templates-view-main {
  padding-top: 16px;
  display: flex;
  justify-content: center;
}

.templates-view-header {
  .templates-view-icon {
    .icon-wrapper {
      color: $grey-4-mayday;
      font-size: 42px;
      border: 4px solid $grey-2-mayday;
      border-radius: 4px;
      width: 64px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .templates-view-title {
    margin-top: 4px;
    font-size: 48px;
    font-weight: bold;
    color: black;
  }

  .templates-view-recap {
    display: flex;
    gap: 8px;
    margin-bottom: 32px;
    .recap-item {
      display: flex;
    }
  }
}

.templates-view-empty {
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  background-color: $grey-1-mayday;
  border-radius: 4px;
  padding: 42px;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .icon {
    width: 96px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .description {
    font-size: 12px;
    color: $grey-7-mayday;
  }
}
</style>
