<template>
  <div>
    <base-button-old
      v-if="!edit"
      class="add-step-button mt-1 mb-1"
      type="primary"
      @click="initiChild()"
      outline
    >
      <img class="add-step-btn-icon" src="~assets/add-step-button.png" />
      {{ $t('knowledge.diagnostic-builder.actions.add-step') }}
    </base-button-old>
    <input
      v-show="edit"
      class="col-auto add-step-input mt-1 mb-1"
      ref="newChildInput"
      placeholder="Nom de la nouvelle étape"
      v-model="label"
      @blur="submitChild()"
      @keyup.enter="$event.target.blur()"
      @keyup.esc="abortEdition()"
    />
  </div>
</template>

<script>
export default {
  name: 'new-child-button',
  props: {
    nodeId: String,
  },
  data() {
    return {
      edit: false,
      label: '',
    };
  },
  methods: {
    initiChild() {
      this.label = '';
      this.edit = true;
      this.$nextTick(() => {
        this.$refs.newChildInput.focus();
      });
    },
    abortEdition() {
      this.label = '';
      this.edit = false;
    },
    submitChild() {
      if (this.label.length > 0)
        this.$emit('add-child', {
          id: this.nodeId,
          label: this.label,
          type: 'Step',
        });
      this.label = '';
      this.edit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-step-button {
  border: 1px solid lightgrey;
  border-radius: 0.375rem;
  color: $grey-5-mayday;
}

.add-step-button:hover {
  color: white;
}

.add-step-button:hover img {
  filter: invert(99%) sepia(0%) saturate(2%) hue-rotate(201deg) brightness(104%)
    contrast(100%);
}

.add-step-btn-icon {
  max-width: 15px;
  filter: invert(85%) sepia(12%) saturate(189%) hue-rotate(169deg)
    brightness(84%) contrast(88%);
  margin-right: 15px;
  margin-bottom: 2px;
}

.add-step-input {
  letter-spacing: 0.025em;
  display: block;
  width: 190px;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
  color: $grey-5-mayday;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid $grey-3-mayday;
  border-radius: 0.375rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.add-step-input::placeholder {
  color: lightgrey;
}

.add-step-input:focus {
  outline: none;
  border-color: $blue-mayday;
  color: $blue-mayday;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  will-change: transform;
}
</style>
