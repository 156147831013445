<template>
  <div>
    <AnalyticsStatsCard
      class="analytics-ask-stats-card"
      :loading="askMetricsIsLoading"
      :stats="askCardMetrics"
      :title="$t('new-dashboard.header.title.analytics-ask-stats')"
      flex
    >
      <template v-slot:trendBadge="{ additionalStat }">
        <font-awesome-icon
          :class="additionalStat"
          class="trend-badge"
          :icon="['fad', 'arrow-right']"
        />
      </template>
      <template v-slot:questions="{ footerStat }">
        <p class="stat-footer">
          {{
            $t(`new-dashboard.stat-card.footer-stats-ask.${footerStat.trend}`, {
              percentChange: footerStat.percentChange
                ? footerStat.percentChange.toFixed(0)
                : 0,
            })
          }}
        </p>
      </template>
      <template v-slot:questionsWithAnswers="{ footerStat }">
        <p class="stat-footer">
          {{
            $t(
              'new-dashboard.stat-card.footer-stats-ask.equivalent',
              footerStat,
            )
          }}
          <br />
          {{
            $t(`new-dashboard.stat-card.footer-stats-ask.${footerStat.trend}`, {
              percentChange: footerStat.percentChange
                ? footerStat.percentChange.toFixed(0)
                : 0,
            })
          }}
        </p>
      </template>
      <template v-slot:questionsWithoutAnswers="{ footerStat }">
        <p class="stat-footer">
          {{
            $t(
              'new-dashboard.stat-card.footer-stats-ask.equivalent',
              footerStat,
            )
          }}
          <br />
          {{
            $t(`new-dashboard.stat-card.footer-stats-ask.${footerStat.trend}`, {
              percentChange: footerStat.percentChange
                ? footerStat.percentChange.toFixed(0)
                : 0,
            })
          }}
        </p>
      </template>
      <template v-slot:feedbacks="{ footerStat }">
        <p class="stat-footer">
          {{
            $t(`new-dashboard.stat-card.footer-stats-ask.${footerStat.trend}`)
          }}
        </p>
      </template>
      <template v-slot:satisfaction="{ footerStat }">
        <p class="stat-footer">
          {{
            $t(`new-dashboard.stat-card.footer-stats-ask.${footerStat.trend}`)
          }}
        </p>
      </template>
    </AnalyticsStatsCard>
    <div class="download-panel">
      <i class="download-panel-icon"></i>
      <h4 class="download-panel-label">
        {{ $t('new-dashboard.ask-download.label') }}
      </h4>
      <p class="download-panel-text mb-2">
        {{ $t('new-dashboard.ask-download.description') }}
      </p>
      <base-button
        :text="$t('new-dashboard.ask-download.button-label')"
        variant="primary"
        size="md"
        @click="exportAskAnalytics"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AnalyticsStatsCard from '../AnalyticsStatsCard';

export default {
  props: {
    externalDocUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      stats: [
        {
          name: 'statName',
          stat: 10,
          footerSlot: 'footerSlot',
          badgeSlot: 'badge',
        },
      ],
    };
  },
  created() {
    this.initAskMetrics();
  },
  components: {
    AnalyticsStatsCard,
  },
  methods: mapActions('dashboard', [
    'getAskMetrics',
    'initAskMetrics',
    'exportAskAnalytics',
  ]),
  computed: {
    ...mapGetters('dashboard', ['askMetricsIsLoading', 'askCardMetrics']),
  },
};
</script>

<style lang="scss" scoped>
.stat-footer {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: $grey-6-mayday;
}

.trend-badge {
  width: 90%;
  height: 90%;

  &.up {
    transform: rotate(-45deg);
  }
  &.down {
    transform: rotate(45deg);
  }
}

.analytics-ask-stats-card {
  :deep() .stat-cards-wrapper.flex-wrapper .stat-container {
    flex-basis: 33%;
  }
}

.download-panel {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-icon {
    width: 100px;
    height: 100px;
    background-image: url('~@/assets/empty-state-data.svg');
    background-size: cover;
    background-position: center;
    display: inline-block;
  }

  &-label {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0.02em;

    color: black;
  }

  &-text {
    margin-bottom: unset;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: $grey-5-mayday;
  }
}
</style>
