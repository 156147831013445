var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thread__message",class:{
    'thread__message-response': !_vm.isFirst,
    'thread__message-focus': _vm.isFocused,
  },attrs:{"id":`message-${_vm.id}`}},[_c('div',{staticClass:"thread__message-header"},[_c('ThreadAuthor',{attrs:{"author":_vm.author,"date":_vm.displayDate,"edited":_vm.edited}}),(_vm.options.edit && _vm.canEdit && (!_vm.isEditing || _vm.isDeleting))?_c('div',{staticClass:"thread__options"},[_c('button',{staticClass:"thread__action",on:{"click":_vm.initEditMessage}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'pen']}})],1),(_vm.options.delete && !_vm.deletingMessage)?_c('button',{staticClass:"thread__action",on:{"click":_vm.initDeleteMessage}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'trash']}})],1):_vm._e()]):_vm._e(),(_vm.isEditing)?_c('div',{staticClass:"thread__edit"},[_c('AddAttachment',{staticClass:"thread__action",class:{
          disabled: _vm.attachmentLoading || _vm.fileInputDisable,
        },attrs:{"attachment-loading":_vm.attachmentLoading,"disabled":_vm.fileInputDisable},on:{"disable-click":function($event){return _vm.showDisableFileUpload(
            'task-view.task-focus-modal.upload-error.disable-file-upload',
          )},"new-attachment":function($event){_vm.handleFileChange($event), (_vm.attachmentsEdited = true)}}},[_c('font-awesome-icon',{attrs:{"slot":"slot-button","icon":['fal', 'paperclip']},slot:"slot-button"})],1),_c('button',{staticClass:"thread__action",class:{ disabled: _vm.disabled },attrs:{"disabled":_vm.disabled},on:{"click":_vm.handleEditMessage}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'check']}})],1),_c('button',{staticClass:"thread__action",on:{"click":_vm.cancelEditMessage}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'times']}})],1)],1):_vm._e()],1),_c('div',{staticClass:"thread__message-content",class:{
      'thread__message-content-response': !_vm.isFirst,
      'thread__message-content-last': !_vm.options.edit && _vm.isLast,
    }},[(_vm.body || _vm.isEditing)?_c('div',{staticClass:"thread__body"},[_c('MessageEditor',{key:_vm.isEditing ? `${_vm.id}-edit` : `${_vm.id}-read`,attrs:{"body":_vm.localBody,"editable":_vm.isEditing,"autofocus":_vm.isEditing},on:{"input":function($event){_vm.localBody = $event}}})],1):_vm._e(),_c('AttachmentList',{staticClass:"thread__attachments-list",attrs:{"is-input":_vm.isEditing,"attachments":_vm.attachments,"attachment-width":'calc(33.3333% - 28px)',"display-disable-file-upload":_vm.displayDisableFileUpload,"error-text":_vm.errorText},on:{"delete-attachment":function($event){_vm.handleDeleteAttachment($event), (_vm.attachmentsEdited = true)},"disable-click":function($event){return _vm.showDisableFileUpload()}}}),_c('el-tooltip',{attrs:{"disabled":!_vm.votes.length,"content":_vm.userVotes,"popper-class":"small-tooltip"}},[(_vm.options.vote || (!_vm.options.vote && _vm.votes.length))?_c('button',{staticClass:"thread__message-votes",class:[
          _vm.options.vote
            ? 'thread__message-votes-active'
            : 'thread__message-votes-disabled',
          {
            'thread__message-votes-me': _vm.votes.includes(_vm.userId),
            'thread__message-votes-empty': !_vm.votes.length,
            'thread__message-votes-deleted': _vm.author.isDeleted,
          },
        ],on:{"click":_vm.handleVoteMessage}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'thumbs-up']}}),(_vm.votes.length)?_c('span',[_vm._v(_vm._s(_vm.votes.length))]):_vm._e()],1):_vm._e()])],1),(_vm.isDeleting)?_c('div',{staticClass:"thread__message-delete"},[(_vm.ongoingDelete)?_c('div',{staticClass:"thread__message-delete-loader"},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'spinner-third'],"spin":""}})],1):_c('div',{staticClass:"thread__message-delete-info"},[_c('div',{staticClass:"thread__message-delete-confirm"},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'check']}}),_c('span',[_vm._v(_vm._s(_vm.$t('threads.messages.delete.confirm')))])],1),_c('base-button',{attrs:{"text":_vm.$t('threads.messages.delete.cancel')},on:{"click":_vm.cancelDeleteMessage}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }