<template>
  <div>
    <div class="information-paragraph mt-2">
      <div class="paragraph-title">
        {{ $t('settings.users-administration.drawer.main.informations') }}
      </div>
      <div class="paragraph-content">
        <div class="d-flex flex-row align-items-center mb-2">
          <div
            class="input-wrapper small-input d-flex flex-column align-items-left mr-2"
            v-if="!pending"
          >
            {{ $t('settings.users-administration.drawer.main.first-name') }}
            <base-input
              class="w-full"
              :model-value="model.givenName"
              @update:model-value="
                handleUserChange('givenName', $event.detail[0])
              "
            />
          </div>
          <div
            class="input-wrapper small-input d-flex flex-column align-items-left"
            v-if="!pending"
          >
            {{ $t('settings.users-administration.drawer.main.last-name') }}
            <base-input
              class="w-full"
              :model-value="model.familyName"
              @update:model-value="
                handleUserChange('familyName', $event.detail[0])
              "
            />
          </div>
        </div>
        <div class="d-flex flex-row align-items-center">
          <div class="email d-flex flex-column align-items-left">
            {{ $t('settings.users-administration.drawer.main.email') }}
            <base-input
              class="w-full"
              :model-value="model.email"
              @update:model-value="handleUserChange('email', $event.detail[0])"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
    <div class="group-paragraph mt-4">
      <div class="paragraph-title">
        {{ $t('settings.users-administration.drawer.main.group-paragraph') }}
      </div>
      <ContentViewSettingsCallout
        class="input-wrapper callout-wrapper"
        trad="settings.users-administration.drawer.main"
        icon="exclamation-triangle"
        type="warning"
      />

      <div class="paragraph-content">
        <div class="d-flex flex-row align-items-center mb-2">
          <div class="input-wrapper d-flex flex-column align-items-left">
            {{ $t('settings.users-administration.drawer.main.group') }}
            <base-select-wrapper
              class="w-full"
              :options="groupOptions"
              :icon="true"
              :value="model.group"
              :loading="loading"
              :placeholder="
                $t(
                  'components.modals.update-user-group.group-select-placeholder',
                )
              "
              @select="handleUserChange('group', $event)"
            />
          </div>
        </div>
        <div class="d-flex flex-row align-items-center mb-2">
          <div class="input-wrapper d-flex flex-column align-items-left">
            {{ $t('settings.users-administration.drawer.main.role') }}
            <base-select-wrapper
              class="w-full"
              :options="roleOptions"
              :icon="true"
              :value="model.role"
              :multiple="true"
              :placeholder="
                $t('components.modals.update-user-role.role-select-placeholder')
              "
              :loading="loading"
              @select="handleUserChange('role', $event)"
            />
          </div>
        </div>
        <div class="d-flex flex-row align-items-center mb-2">
          <div class="input-wrapper d-flex flex-column align-items-left">
            {{ $t('settings.users-administration.drawer.main.label') }}
            <base-select-wrapper
              class="w-full"
              :options="labelCategoriesOptions"
              :icon="true"
              :value="model.labels"
              :placeholder="
                $t('settings.permissions.user-invite-modal.form.labels')
              "
              :loading="loading"
              :is-label="true"
              @select="handleUserChange('labels', $event)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="configuration-paragraph mt-4" v-if="hasZendesk">
      <div class="paragraph-title">
        {{ $t('settings.users-administration.drawer.main.configuration') }}
      </div>
      <div class="paragraph-content">
        <div class="d-flex flex-row align-items-center mb-2">
          <div class="input-wrapper d-flex flex-column align-items-left mr-2">
            <div
              class="d-flex flex-row align-items-center justify-content-left mb-1"
            >
              {{ $t('settings.users-administration.drawer.main.helpdeskid') }}
              <font-awesome-icon
                :icon="['fal', 'sync']"
                class="ml-2 refresh-icon"
                @click="$emit('refresh-helpdesk-id', user.id)"
              ></font-awesome-icon>
            </div>
            <base-input
              class="w-full"
              :model-value="model.helpdeskId"
              @update:model-value="model.helpdeskId = $event.detail[0]"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="paragraph-content" v-if="model.externalId">
        <div class="d-flex flex-row align-items-center mb-2">
          <div class="input-wrapper d-flex flex-column align-items-left mr-2">
            <div
              class="d-flex flex-row align-items-center justify-content-left mb-1"
            >
              {{ $t('settings.users-administration.drawer.main.externalId') }}
            </div>
            <base-input
              class="w-full"
              :model-value="model.externalId"
              @update:model-value="model.externalId = $event.detail[0]"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
    <div class="actions-paragraph mt-4 mb-3">
      <div class="paragraph-title mb-2">
        {{ $t('settings.users-administration.drawer.main.actions') }}
      </div>
      <div class="paragraph-content">
        <div
          class="d-flex flex-row align-items-center"
          v-if="allowResetPassword"
        >
          <div slot="reference">
            <base-button
              variant="primary"
              icon="key"
              size="md"
              class="mb-2"
              :loading="loader.reset"
              :text="
                $t('settings.users-administration.drawer.main.reset-password')
              "
              @click="handleResetPassword"
            ></base-button>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center">
          <base-button
            @click="showDeleteUser = true"
            variant="danger"
            size="md"
            :text="$t('settings.users-administration.drawer.main.delete-user')"
          ></base-button>
        </div>
      </div>
    </div>
    <BaseDialog
      v-if="showDeleteUser"
      :title="$tc(deleteUserDialog.title, 1)"
      :description="$tc(deleteUserDialog.description, 1)"
      :open="showDeleteUser"
      :confirmText="$t('generic.delete')"
      :cancelText="$t('generic.cancel')"
      variant="danger"
      @confirm="handleDeleteUser"
      @cancel="showDeleteUser = false"
      @close="showDeleteUser = false"
    >
    </BaseDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ContentViewSettingsCallout from '@/components/Drawers/ContentViewSettingDrawer/ContentViewSettingsComponents/ContentViewSettingsCallout';

export default {
  name: 'user-drawer-main',
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ContentViewSettingsCallout,
  },
  data() {
    return {
      model: {
        familyName: '',
        givenName: '',
        email: '',
        group: '',
        role: [],
        labels: [],
        helpdeskId: '',
        externalId: '',
        lang: '',
        id: '',
      },
      loader: {
        reset: false,
      },
      showDeleteUser: false,
    };
  },
  mounted() {
    this.initModel();
  },
  computed: {
    labelToCategoryLookup() {
      const lookup = new Map();
      this.userLabelCategories.forEach((category) => {
        category.companyUserLabels.forEach((label) => {
          lookup.set(label.id, category.id);
        });
      });
      return lookup;
    },
    labelCategoriesOptions() {
      return this.userLabelCategories.map((category) => {
        return {
          ...category,
          key: category.id,
          title: category.name,
          children: category.companyUserLabels.map((label) => {
            return { ...label, title: label.name };
          }),
        };
      });
    },
    allowResetPassword() {
      return this.hasCompanyPreferenceWithValue('ALLOW_NO_EMAIL_USERS');
    },
    deleteUserDialog() {
      return {
        title: `settings.users-administration.drawer.main.confirm-title${
          this.pending ? '-pending' : ''
        }`,
        description: `settings.users-administration.drawer.main.confirm-description${
          this.pending ? '-pending' : ''
        }`,
      };
    },
    groupOptions() {
      return this.groups.map((group) => {
        return { ...group, key: group.id, label: group.name };
      });
    },
    roleOptions() {
      return this.roles.map((role) => {
        return { ...role, key: role.id, label: role.name };
      });
    },
    ...mapGetters('adminModule', ['groups', 'roles', 'userLabelCategories']),
    ...mapGetters(['hasZendesk', 'hasCompanyPreferenceWithValue']),
  },
  methods: {
    initModel() {
      Object.keys(this.model).map((key) => {
        switch (key) {
          case 'group':
            this.model.group = this.user.groups[0]
              ? this.user.groups[0].id
              : [];
            break;
          case 'role':
            this.model.role = this.user.role.reduce((acc, val) => {
              acc.push(val.id);
              return acc;
            }, []);
            break;
          case 'labels':
            this.model.labels = this.user.labels.reduce((acc, val) => {
              acc.push(val.id);
              return acc;
            }, []);
            break;
          case 'helpdeskId':
            this.model.helpdeskId = this.user.helpdeskId
              ? this.user.helpdeskId
              : this.$t(`settings.users-administration.empty-state.helpdeskId`);
            break;
          case 'externalId':
            this.model.externalId = this.user.externalId
              ? this.user.externalId
              : this.$t(`settings.users-administration.empty-state.externalId`);
            break;
          default:
            this.model[key] = this.user[key];
        }
      });
    },
    handleUserChange(key, value) {
      if (key === 'labels')
        this.model.labels = [...this.uniqueValuePerCategory(value).values()];
      else this.model[key] = value;

      if (this.verifyUser(this.model))
        this.$emit('update-focused-user', { user: this.model });
    },
    uniqueValuePerCategory(newSelectedValues) {
      const lookup = new Map();
      newSelectedValues.forEach((labelId) => {
        lookup.set(this.labelToCategoryLookup.get(labelId), labelId);
      });
      return lookup;
    },
    verifyUser(user) {
      if (user.role.length === 0) {
        this.$message({
          message: this.$t(
            'settings.users-administration.drawer.main.select-role',
          ),
          type: 'error',
          center: true,
        });
        return false;
      }
      return true;
    },
    handleDeleteUser() {
      this.$emit('delete-user', { userIds: [this.user.id] });
      this.$emit('close');
    },
    async handleResetPassword() {
      this.loader.reset = true;
      try {
        const resetLink = await this.resetEmployeePasswordFromAdmin(
          this.user.id,
        );
        await navigator.clipboard.writeText(resetLink);
        this.$message({
          message: this.$t(
            'settings.users-administration.drawer.main.reset-password-success',
          ),
          type: 'success',
        });
      } catch (err) {
        console.log(err);
        this.$message({
          message: this.$t(
            'settings.users-administration.drawer.main.reset-password-failure',
          ),
          type: 'error',
        });
      }
      this.loader.reset = false;
    },
    ...mapActions('adminModule', ['resetEmployeePasswordFromAdmin']),
  },
  watch: {
    'model.role'(newVal, oldVal) {
      if (newVal.length === 0 && oldVal.length !== 0) {
        if (this.user.role)
          this.model.role = this.user.role.map((role) => role.id);
        else this.model.role = [this.roles[0].id];
      }
    },
    'user.helpdeskId'(newVal) {
      this.model.helpdeskId = newVal
        ? newVal
        : this.$t(`settings.users-administration.empty-state.helpdeskId`);
    },
  },
};
</script>

<style lang="scss" scoped>
.paragraph-title {
  font-size: 12px;
  color: $grey-6-mayday;
}

.email {
  width: 80% !important;
  min-width: 280px;
}

.small-input {
  :deep() .el-input--small {
    width: 100% !important;
  }
}

.el-select--small {
  min-width: 280px;
}

:deep() .el-input {
  height: 32px;
}

:deep() .el-select .el-input .el-input__inner {
  height: 100%;
}

.option-group-title {
  display: flex;
  margin-left: 12px;
  .option-icon {
    margin-right: 4px;
  }
}

.input-wrapper {
  width: 55%;
}

.refresh-icon {
  width: 12px;
  &:hover {
    color: $blue-mayday;
    cursor: pointer;
  }
}

.callout-wrapper {
  min-width: 280px;
}
</style>
