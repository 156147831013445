<template>
  <div
    class="language-item"
    :class="{ 'language-item__disable': status === 'loading' }"
  >
    <div class="d-flex align-items-center">
      <StatusIndicator v-if="status" class="mr-2" :status="status" />
      <div
        :class="{
          shrink: !!status,
        }"
      >
        <div class="label">
          <LanguageFlag class="flag-emoji" :language="language" emoji />
          <span>{{ $t(`knowledge.languages.${language}`) }}</span>
        </div>
        <div class="item-label ellipsis" v-if="itemLabel">
          {{ itemLabel }}
        </div>
      </div>
      <el-tooltip
        class="d-flex ml-auto edit-action-btn"
        placement="top"
        :content="getEditActionTooltipContent(language)"
        :enterable="false"
        v-if="!!editingAction && status !== 'loading'"
      >
        <base-button
          data-testid="edit-action-btn"
          variant="tertiary"
          icon-only
          :icon="editActionIcon"
          @click.prevent.stop="handleEmitAction"
        />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import LanguageFlag from '@/components/LanguageFlag.vue';
import StatusIndicator from '@/components/StatusIndicator.vue';

export default {
  name: 'translation-item',
  props: {
    language: {
      type: String,
      default: 'default',
    },
    itemLabel: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: null,
      validator: (v) =>
        ['published', 'draft', 'no-status', 'loading', null].includes(v),
    },
    editingAction: {
      type: String,
      default: null,
      validator: (v) => ['set-language', 'unset-language'].includes(v),
    },
  },
  components: {
    LanguageFlag,
    StatusIndicator,
  },
  computed: {
    editActionIcon() {
      return this.editingAction === 'set-language' ? 'plus-square' : 'trash';
    },
  },
  methods: {
    getEditActionTooltipContent(lang) {
      return this.$t(`translation.${this.editingAction}`, {
        lang: this.$t(`knowledge.languages.${lang}`),
      });
    },
    handleEmitAction() {
      if (this.status === 'loading') return;
      this.$emit(this.editingAction, this.language);
    },
  },
};
</script>

<style lang="scss" scoped>
.language-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 49px;
  padding: 0px 18px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  color: black;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $grey-3-mayday;
  }

  &:hover {
    background-color: $grey-1-mayday;

    .label {
      transition: transform 250ms;
      transform: translateX(24px);

      .flag-emoji {
        visibility: visible;
        opacity: 1;
        transition: visibility 0ms, opacity 150ms linear;
      }
    }

    .item-label {
      width: 200px;
      transition: all 0.15ms linear;
    }

    .edit-action-btn {
      visibility: visible;
      opacity: 1;
      transform: translateX(-21px);
      transition: transform 150ms, visibility 0ms, opacity 150ms linear;
    }
  }

  &:active {
    background-color: $grey-2-mayday;
    transition: 0s;
  }
  &__disable {
    cursor: not-allowed !important;
  }
}

.flag-emoji {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: normal;
  font-size: 16px;
  margin-right: 4px;
  width: 20px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 250ms, opacity 150ms linear;
}

.edit-action-icon {
  font-size: 12px;
  color: $grey-6-mayday;
}

.edit-action-btn {
  padding: 6px;
  line-height: 6px;
  border-radius: 2px;
  margin-right: -22px;
  visibility: hidden;
  opacity: 0;
  transition: transform 250ms, visibility 250ms, opacity 150ms linear;
}

.label {
  display: flex;
  align-items: center;
  margin-left: -24px;
  transition: transform 250ms;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-label {
  font-size: 12px;
  line-height: 14px;
  color: $grey-7-mayday;
  font-weight: 700;
  width: 231px;
}

.indicator {
  min-width: 8px;
  height: 8px;
  border-radius: 2px;
  border: 0.5px solid $grey-5-mayday;
  background-color: $grey-5-mayday;
}

.shrink {
  .item-label {
    width: 203px;
  }
}
</style>
