<template>
  <div class="atg-modal__background">
    <div class="atg-modal">
      <div class="atg-modal__close" @click="$emit('close')">
        <font-awesome-icon :icon="['fal', 'times']" />
      </div>
      <!-- HEADER -->
      <div class="atg-modal__header">
        <font-awesome-icon :icon="['fal', 'sync-alt']" />
        <span>{{ $t('knowledge.modals.article-to-guide.title') }}</span>
        <AiBadge />
      </div>
      <!-- BODY -->
      <div class="atg-modal__body">
        <div>
          <div class="atg-modal__body__title">
            {{ $t('knowledge.modals.article-to-guide.text.title') }}
          </div>
          <div class="atg-modal__body__description">
            {{ $t('knowledge.modals.article-to-guide.text.description') }}
          </div>
        </div>
        <!-- INPUT -->
        <div
          :class="[
            'atg-modal__body__input',
            { error: !!getPromptError && !loading },
            { loading: loading },
          ]"
        >
          <font-awesome-icon
            v-if="loading"
            :icon="['fas', 'spinner-third']"
            spin
          />
          <input
            v-model="prompt"
            :disabled="loading"
            :placeholder="
              $t(
                `knowledge.modals.article-to-guide.${
                  loading ? 'loading' : 'placeholder'
                }`,
              )
            "
            type="text"
          />
          <span v-if="!loading">{{ getPromptError }}</span>
        </div>
        <!-- INFOS -->
        <div class="atg-modal__body__infos">
          <!-- COLLAPSE -->
          <div
            :class="[
              'atg-modal__body__infos__collapse',
              { expanded: collapseExpanded },
            ]"
          >
            <div
              class="atg-modal__body__infos__collapse__header"
              @click="collapseExpanded = !collapseExpanded"
            >
              <div>
                <font-awesome-icon :icon="['fal', 'info-circle']" />
                <span>
                  {{ $t('knowledge.modals.article-to-guide.collapse.title') }}
                </span>
              </div>
              <font-awesome-icon :icon="['fal', 'caret-right']" />
            </div>
            <div class="atg-modal__body__infos__collapse__body">
              {{ $t('knowledge.modals.article-to-guide.collapse.content') }}
            </div>
          </div>
          <!-- CALLOUT -->
          <div class="atg-modal__body__infos__callout">
            <font-awesome-icon :icon="['fal', 'exclamation-triangle']" />
            <div>
              <span>
                {{ $t('knowledge.modals.article-to-guide.callout.title') }}
              </span>
              <span>{{
                $t('knowledge.modals.article-to-guide.callout.content')
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- FOOTER -->
      <div class="atg-modal__footer">
        <button class="btn btn-secondary btn-sm" @click="$emit('close')">
          {{ $t('knowledge.modals.article-to-guide.cancel') }}
        </button>
        <button
          :disabled="!!getPromptError || loading"
          class="btn btn-primary btn-sm"
          @click="$emit('generate-guide', prompt)"
        >
          {{ $t('knowledge.modals.article-to-guide.create') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AiBadge from 'components/AI/AiBadge.vue';
import { mapActions } from 'vuex';

const MINIMUM_PROMPT_LENGTH = 20;
const MAXIMUM_PROMPT_LENGTH = 100;

export default {
  name: 'article-to-guide-modal',
  components: { AiBadge },
  props: {
    label: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    prompt: '',
    loading: false,
    collapseExpanded: false,
  }),
  computed: {
    getPromptError() {
      if (this.prompt.length < MINIMUM_PROMPT_LENGTH) {
        return this.$t('knowledge.modals.article-to-guide.errors.too-short', {
          count: this.prompt.length,
          minimum: MINIMUM_PROMPT_LENGTH,
        });
      } else if (this.prompt.length > MAXIMUM_PROMPT_LENGTH) {
        return this.$t('knowledge.modals.article-to-guide.errors.too-long', {
          count: this.prompt.length,
          maximum: MAXIMUM_PROMPT_LENGTH,
        });
      }
      return null;
    },
  },
  methods: {
    ...mapActions('brainModule', ['getArticleToGuideSuggestion']),
  },
  async created() {
    try {
      this.loading = true;
      const { data } = await this.getArticleToGuideSuggestion({
        label: this.label,
        body: this.body,
        language: this.language,
      });
      this.prompt = data.objective;
    } catch (error) {
      this.prompt = '';
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.atg-modal__background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.atg-modal {
  background-color: white;
  position: relative;
  border-radius: 12px;
  box-shadow: $shadow-lg-mayday;
  transition: all 0.3s ease-in-out;
  width: 600px;

  &__close {
    position: absolute;
    top: -24px;
    right: -24px;
    width: 22px;
    height: 22px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    background-color: $purple-5-mayday;
    transition: all 0.2s ease-in-out;

    &:hover {
      scale: 1.1;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $purple-5-mayday;
    background-color: $grey-1-mayday;
    font-size: 16px;
    font-weight: 700;
    padding: 12px 16px;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
  }

  &__body {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__title {
      font-size: 16px;
      font-weight: 700;
    }

    &__description {
      font-size: 14px;
      font-weight: 400;
      color: $grey-6-mayday;
    }

    &__input {
      position: relative;
      input {
        width: 100%;
        color: $purple-5-mayday;
        border: 2px solid $purple-5-mayday;
        border-radius: 4px;
        padding: 8px 10px;
        transition: all 0.2s ease-in-out;

        &:focus {
          color: black;
          box-shadow: 0 0 0 4px rgba($purple-5-mayday, 0.2);
        }
      }

      span {
        color: $red-5-mayday;
        font-size: 12px;
        font-weight: 400;
      }

      &.error {
        input {
          border-color: $red-5-mayday;
          color: $red-5-mayday;

          &:focus {
            box-shadow: 0 0 0 4px rgba($red-5-mayday, 0.2);
          }
        }
      }

      &.loading {
        input {
          border-color: $grey-5-mayday;
          color: $grey-5-mayday;
          padding-left: 30px;
          transition: padding-left 0.5s ease-in-out;
        }
        svg {
          position: absolute;
          font-size: 18px;
          top: 12px;
          left: 10px;
          color: $purple-5-mayday;
        }
      }
    }

    &__infos {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__collapse {
        background-color: rgba($purple-5-mayday, 0.2);
        border-radius: 8px;
        overflow: hidden;
        padding: 8px 12px;

        &.expanded {
          .fa-caret-right {
            transform: rotate(90deg);
          }
          .atg-modal__body__infos__collapse__body {
            max-height: 200px;
          }
        }

        &__header {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          font-weight: 700;
          div {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .fa-caret-right {
            transition: all 0.2s ease-in-out;
          }
        }

        &__body {
          max-height: 0;
          overflow: hidden;
          padding-left: 22px;
          transition: max-height 0.2s ease-in-out;
          font-size: 14px;
          font-weight: 400;
        }
      }

      &__callout {
        font-size: 14px;
        font-weight: 700;
        padding: 8px 12px;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        align-items: baseline;
        background-color: rgba($yellow-5-mayday, 0.2);
        div {
          display: flex;
          flex-direction: column;
          :last-child {
            font-weight: 400;
            white-space: pre;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 12px 16px;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
    background-color: $grey-1-mayday;

    .btn-secondary {
      color: $purple-5-mayday;
    }

    .btn-primary {
      background-color: $purple-5-mayday;
      border-color: $purple-5-mayday;
    }
  }
}
</style>
