<template>
  <div class="product-parameter-side-bar">
    <menu-collapse
      class="parameters-collapse"
      :title="$t('knowledge.product.parameters')"
    >
      <h6 class="text-uppercase mb-0">
        {{ $t('knowledge.product.global-parameters') }}
      </h6>
      <div v-for="family in enrichedParameters" :key="family.key">
        <el-divider class="my-1"></el-divider>
        <h6 class="row align-items-center mx-0 mb-0">
          <font-awesome-icon
            class="collapse-parameter-icon mr-1"
            :icon="['fad', getHierarchyByValue(family.key).icon]"
          />
          {{ getHierarchyByValue(family.key).label }}
        </h6>
        <div
          class="d-flex align-items-center"
          v-for="value in family.values"
          :key="value.id"
        >
          <small class="collapse-parameter-label" :key="value.id">{{
            value.label
          }}</small>
        </div>
      </div>
    </menu-collapse>
  </div>
</template>

<script>
import ProductMixin from 'mixins/KnowledgeMixin/ProductMixin';
import MenuCollapse from '@/components/Collapse/MenuCollapse';

export default {
  name: 'product-parameter-side-bar',
  mixins: [ProductMixin],
  props: {
    enrichedParameters: {
      type: Array,
      required: true,
    },
  },
  components: {
    MenuCollapse,
  },
  methods: {
    getHierarchyByValue(value) {
      const hierarchy = this.mxFlatHierarchy.find((h) => h.value === value);
      return hierarchy || { label: '', icon: 'books' };
    },
  },
};
</script>
<style lang="scss" scoped>
.product-parameter-side-bar {
  margin-top: 16px;
}
</style>
