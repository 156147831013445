import { render, staticRenderFns } from "./ContentAttributesModal.vue?vue&type=template&id=682be260&scoped=true&"
import script from "./ContentAttributesModal.vue?vue&type=script&lang=js&"
export * from "./ContentAttributesModal.vue?vue&type=script&lang=js&"
import style0 from "./ContentAttributesModal.vue?vue&type=style&index=0&id=682be260&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "682be260",
  null
  
)

export default component.exports