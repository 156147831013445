<template>
  <modal
    class="content-parameters-edition-modal"
    :show.sync="localDisplay"
    :close-icon="true"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <h3 slot="header" class="m-0">
      Remplacement des paramètres produit de {{ contentQty }} contenus
    </h3>

    <!-- ATTRIBUTES SELECTIONS -->
    <div class="parameters-selection-main mx-4">
      <base-alert type="danger">
        <template v-slot:text>
          {{ warning1 }}
          <strong>REMPLACER</strong>
          {{ warning2 }}
        </template>
      </base-alert>
      <GlobalParameterSelector @update-product-filters="parameters = $event" />
    </div>

    <div slot="footer" class="advanced-search-modal-footer">
      <base-button-old
        class="ml-2"
        type="link"
        outline
        size="sm"
        @click="$emit('close')"
      >
        {{ $t('components.modals.knowledge-migration.cancel') }}
      </base-button-old>

      <base-button-old
        class="mr-2"
        type="success"
        size="sm"
        @click="submitUpdates"
        :disabled="!$v.parameters.isValid"
        >Modifier</base-button-old
      >
    </div>
  </modal>
</template>

<script>
// COMPONENTS
import GlobalParameterSelector from './CreateProductNotificationModal/GlobalParameterSelector';

export default {
  name: 'content-parameters-edition-modal',
  components: { GlobalParameterSelector },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    contentQty: Number,
  },
  data() {
    return {
      localDisplay: this.display,
      parameters: [],
      warning1: 'Attention, vous êtes sur le point de',
      warning2: ` les paramètres produit des ${this.contentQty} contenus sélectionnés.
        En effectuant ce remplacement, vous supprimerez définitivement les anciennes configurations produit de ces contenus.`,
    };
  },
  validations: {
    parameters: {
      isValid: (parameters) => {
        return (
          parameters.map((p) => p.key).includes('knowledge') &&
          parameters.length > 1
        );
      },
    },
  },
  methods: {
    submitUpdates() {
      const parameters = [...this.parameters];
      const knowledgeIdx = parameters.findIndex((p) => p.key === 'knowledge');
      const knowledge = parameters.splice(knowledgeIdx, 1)[0].values[0];
      this.$emit('update-parameters', {
        knowledge,
        parameters,
      });
      this.$emit('close');
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.parameters-selection-main {
  max-height: 65vh;
  overflow: auto;
}

.attribute-title {
  color: $grey-5-mayday;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.is-checked {
  .attribute-title {
    color: $blue-mayday;
  }
}

.attribute-selection-main {
  max-height: 60vh;
  overflow: auto;
}

.case-selection-overflow {
  max-height: 25vh;
  overflow: auto;
}

.add-case {
  :deep() .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $green-mayday;
  }
}

.remove-case {
  :deep() .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $red-mayday;
  }
}
</style>
