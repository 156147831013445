<template>
  <div class="notifications-option">
    <side-bar-item
      icon="paper-plane"
      :description="tooltipDescription"
      :type="type"
      @click="$emit('click')"
    />
  </div>
</template>

<script>
import SideBarItem from './SideBarItem';

export default {
  name: 'historization-option',
  components: {
    SideBarItem,
  },
  props: {
    type: {
      type: String,
      default: 'diagnostic',
    },
  },
  data() {
    return {
      tooltipDescription: 'Retourner au CRM',
    };
  },
  methods: {},
};
</script>
