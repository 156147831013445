<template>
  <div class="hub-settings-drawer-content">
    <div class="hub-settings-drawer-content-left"></div>
    <div class="hub-settings-drawer-content-right">
      <div>
        <div class="filter-title">
          {{
            $t(
              'settings.general.collaborative-hub.drawer.content.description.title',
            )
          }}
        </div>
        <base-input
          :model-value="description"
          @update:model-value="$emit('update-description', $event.detail[0])"
          :maxlength="200"
          :placeholder="
            $t(
              'settings.general.collaborative-hub.drawer.content.description.placeholder',
            )
          "
        />
      </div>
      <div v-if="selectedCategories.length">
        <div class="filter-title">
          {{
            $t(
              'settings.general.collaborative-hub.drawer.content.selected-filters',
            )
          }}
        </div>
        <div class="hub-categories-container">
          <HubCategoryDropdown
            v-for="(category, i) in selectedCategories"
            :key="i"
            :title="$t(filter(category).label)"
            :filter="filters[filter(category).key]"
            :options="filter(category).options"
            :translation-path="filter(category).translationPath"
            :category="category"
            @update-category="$emit('update-category', $event)"
            @remove-category="$emit('remove-category', category)"
            @update-list-filter="$emit('update-list-filter', category, $event)"
            @update-filter="$emit('update-filter', $event)"
            @remove-filter="$emit('remove-filter', $event)"
          >
          </HubCategoryDropdown>
        </div>
      </div>
      <!-- ADD CATEGORY BUTTON -->
      <div class="add-filter-button">
        <BaseDropdownOptionsWrapper
          :options="availableFilters"
          icon="plus"
          variant="primary"
          :trigger-text="
            $t('settings.general.collaborative-hub.drawer.content.add-filter')
          "
          :icon-only="false"
          :disabled="!availableFilters.length"
          @select="handleDropdownSelect"
        ></BaseDropdownOptionsWrapper>
      </div>
    </div>
  </div>
</template>

<script>
import HubCategoryDropdown from 'views/Settings/GeneralSettings/HubSettings/HubCategoryDropdown.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'HubSettingsDrawerContent',
  components: { HubCategoryDropdown },
  props: {
    filters: Object,
    description: String,
  },
  data: () => ({
    displayNewOption: false,
  }),
  methods: {
    filter(key) {
      return this.categoryMap.find((category) => category.key === key);
    },
    handleAddCategory(key) {
      this.displayNewOption = false;
      this.$emit('add-category', key);
    },
    handleDropdownSelect(e) {
      const key = e.detail[0];
      this.handleAddCategory(key);
    },
  },
  computed: {
    availableFilters() {
      return this.categoryMap.filter(
        (category) => !Object.keys(this.filters).includes(category.key),
      );
    },
    selectedCategories() {
      return Object.keys(this.filters);
    },
    categoryMap() {
      return [
        {
          key: 'author',
          icon: 'user-edit',
          label: this.$t(
            'settings.general.collaborative-hub.rows.filters.author',
          ),
        },
        {
          key: 'assignee',
          icon: 'user-check',
          label: this.$t(
            'settings.general.collaborative-hub.rows.filters.assignee',
          ),
        },
        {
          key: 'type',
          category: 'task',
          icon: 'comment-alt',
          label: this.$t(
            'settings.general.collaborative-hub.rows.filters.type.title',
          ),
          //options: ['FEEDBACK', 'CONTENT_OBSOLETE', 'INFORMATION', 'AI_INSIGHT'], Hidden for the moment, since we don't have these types yet
          options: [
            'FEEDBACK',
            'CONTENT_FEEDBACK',
            ...(this.companyAllowAcademyPreference
              ? ['FEEDBACK_ACADEMY', 'CONTENT_FEEDBACK_ACADEMY']
              : []),
            'CONTENT_OBSOLETE',
          ],
          translationPath:
            'settings.general.collaborative-hub.rows.filters.type',
        },
      ];
    },
    ...mapGetters(['companyAllowAcademyPreference']),
  },
};
</script>

<style lang="scss" scoped>
.hub-settings-drawer-content {
  display: flex;
  padding-top: 12px;
  padding-left: 30px;
  padding-right: 30px;
}
.hub-settings-drawer-content-left {
  padding-top: 60px;
  width: 170px;
  padding-right: 30px;
}
.hub-settings-drawer-content-right {
  flex: 1;
  & > div {
    margin-bottom: 16px;
  }
}

.filter-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}

.filter-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  font-size: 14px;
}

.hub-categories-container {
  & > div {
    margin-bottom: 8px;
  }
}

.button-icon {
  margin-right: 4px;
}

.category-button {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
}

.filter-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px;
  &:hover {
    background-color: $grey-2-mayday;
    color: $blue-5-mayday;
  }
  :first-child {
    margin-right: 4px;
    height: 16px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.add-filter-button {
  width: 118px;
}
</style>
