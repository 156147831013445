<template>
  <div class="page-container">
    <div v-loading="loading">
      <AnalyticsTitle
        :title="$t('new-dashboard.header.title.academy-download')"
        :tooltipContent="$t('new-dashboard.header.tooltip.academy-download')"
      ></AnalyticsTitle>
      <div class="page-section">
        <p>{{ $t('new-dashboard.exports.academy') }}</p>
        <div class="download-card-container">
          <div class="download-card">
            <AnalyticsPredefinedFiltersDownload
              type="academy_global_attempts"
              :options="computedPredefinedFilters"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AnalyticsTitle from '../AnalyticsTitle';
import AnalyticsPredefinedFiltersDownload from './AnalyticsPredefinedFiltersDownload';

export default {
  name: 'analytics-download',
  components: {
    AnalyticsTitle,
    AnalyticsPredefinedFiltersDownload,
  },
  async created() {
    this.loading = true;
    this.predefinedFilters = await this.getAcademyExportsPredefinedFilters();
    this.loading = false;
  },
  data() {
    return {
      predefinedFilters: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters('dashboard', ['exports']),
    ...mapGetters(['hasCompanyPreferenceWithValue']),
    computedPredefinedFilters() {
      return this.predefinedFilters.map((filter) => ({
        id: filter.id,
        label: filter.name,
      }));
    },
  },
  methods: {
    ...mapActions('kbExportModule', ['getAcademyExportsPredefinedFilters']),
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 24px;
}

.page-section {
  margin-top: 20px;
}

.download-card-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
</style>
