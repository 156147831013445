<template>
  <div class="collection-card-bis" type="frame">
    <div class="creation">
      <div class="main-card-body text-center">
        <div class="row mx-0 justify-content-center">
          <font-awesome-icon class="home-card-icon" :icon="['fad', 'folder']" />
        </div>
        <div class="row mx-0 justify-content-center">
          <input
            alternative
            :placeholder="$t('knowledge.root.title-placeholder')"
            :maxlength="maxCaracter"
            class="inputNodeLabel ls-1 pl-0 pt-O m-0"
            ref="label"
            v-model="newLabel"
            @blur="updateContent()"
            @keyup.enter="$event.target.blur()"
            @input="notifyUserLength"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'add-root-folder-card',
  components: {},
  data() {
    return {
      newLabel: '',
      maxCaracter: 35,
    };
  },
  mounted() {
    this.$refs.label.focus();
  },
  methods: {
    updateContent() {
      if (this.newLabel !== '')
        this.$emit('add-child', { newLabel: this.newLabel });
      else this.$emit('blur');
    },
    notifyUserLength() {
      if (this.newLabel.length === this.maxCaracter)
        this.$notify({
          title: 'Attention',
          message: 'Ne donnez pas des titres trop longs à vos collections',
          type: 'info',
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.home-card-icon {
  color: $grey-4-mayday;
  font-size: 48px;
  margin-top: 32px;
}

.collection-card-bis {
  border: 1px solid;
  border-color: $grey-2-mayday;
  border-radius: 4px;
  height: 200px;
  width: 300px;
  transition: all 0.3s;
  border-color: $grey-5-mayday;
  box-shadow: $shadow-mayday;
}

.creation {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.inputNodeLabel::placeholder {
  color: $grey-4-mayday;
}

.inputNodeLabel:focus,
.inputNodeLabel:active,
.inputNodeLabel {
  font-size: 1.25rem;
  color: $purple-9-mayday;
  outline: none;
  border: none;
  font-weight: 800 !important;
  text-align: center;
  width: 100%;
  min-height: 100px;
}

.node-wraper {
  min-height: 170px;
}

.md-icon {
  max-width: 25px;
}

.sample-icon {
  max-width: 30px;
}

.icon-col {
  margin-top: auto;
  margin-bottom: auto;
}

.title-col {
  margin-top: auto;
  margin-bottom: auto;
}

.row-title {
  text-transform: uppercase;
}

.card-small-padding {
  cursor: pointer;
}

.card-small-padding {
  border-radius: 0px 0px 5px 5px;
}
.wrapping-row {
  height: 60px;
  margin-right: 30px;
  margin-left: 30px;
  border-bottom: 0.5px solid $grey-2-mayday;
  cursor: pointer;
}

.add-card {
  border-top: 5px solid $grey-5-mayday;
}

.subtitle {
  color: $grey-5-mayday;
  font-weight: 200 !important;
  width: 450px;
  max-height: 30px;
  text-overflow: clip;
  font-size: 70%;
}
</style>
