<template>
  <div
    v-if="!isSearchSynonyms"
    class="synonyms-table-row"
    :class="{
      'synonyms-table-row__editing': isEditing,
      'synonyms-table-row__deleting': deleting,
    }"
  >
    <!-- aSynonym -->

    <base-input
      v-if="isEditing"
      class="mb-3"
      :model-value="newLabel"
      @update:model-value="newLabel = $event.detail[0]"
      :placeholder="$t('settings.search.synonyms.placeholder-main')"
      max-length="255"
      :error="isEditing && !validity.aSynonym"
    />

    <div v-else class="col-main">
      <text-overflow-tooltip :text="newLabel" />
    </div>

    <!-- bSynonyms -->
    <div class="col-synonyms">
      <div
        ref="synonyms-wrapper"
        class="synonyms-wrapper"
        :class="{
          'synonyms-wrapper__editing': isEditing,
          'synonyms-wrapper__error': !validity.bSynonyms || error,
        }"
      >
        <div
          v-for="synonym in renderedSynonyms"
          :key="synonym.id"
          class="synonyms"
          :class="{
            synonyms__editing: isEditing,
            synonyms__search: search ? computeSearch(synonym.bSynonym) : false,
            'synonyms__not-search': search
              ? !computeSearch(synonym.bSynonym)
              : false,
          }"
        >
          <div>{{ synonym.bSynonym }}</div>
          <base-button
            @click="removeSynonym(synonym)"
            variant="tertiary"
            size="sm"
            :icon-only="true"
            icon="times"
          >
          </base-button>
        </div>
        <input
          ref="new-synonym"
          v-if="isEditing"
          class="synonyms-input"
          v-model="newSynonym"
          autofocus
          :placeholder="$t('settings.search.synonyms.placeholder')"
          @keyup.esc="resetSynonym"
          @keyup.enter="$event.target.blur()"
          @blur="addSynonym"
        />
      </div>
      <div
        class="synonyms-wrapper-hint"
        :class="{ 'synonyms-wrapper-hint__editing': isEditing }"
      >
        {{ $t('settings.search.synonyms.description') }}
      </div>
    </div>
    <!-- ACTIONS -->
    <div class="table-actions" :class="{ 'table-actions__editing': isEditing }">
      <!-- EDIT -->
      <base-button
        v-if="!isEditing"
        @click="isEditing = true"
        variant="tertiary"
        size="sm"
        :icon-only="true"
        icon="pen"
      >
      </base-button>
      <!-- DELETE -->

      <base-button
        v-if="!isEditing"
        @click="deleteSynonym = true"
        variant="tertiary"
        size="sm"
        :icon-only="true"
        icon="trash"
      >
      </base-button>

      <!-- CANCEL UPDATE -->

      <base-button
        v-if="isEditing && hasEdit"
        @click="cancelSynonym = true"
        variant="tertiary"
        size="sm"
        :icon-only="true"
        icon="times"
      >
      </base-button>

      <base-button
        v-else-if="isEditing && !hasEdit"
        @click="resetUpdate"
        variant="tertiary"
        size="sm"
        :icon-only="true"
        icon="times"
      >
      </base-button>

      <!-- HANDLE UPDATE -->
      <base-button
        v-if="isEditing"
        @click="!loading && !save ? handleUpdate() : ''"
        :disabled="saveNewSynonymsDisabled"
        variant="tertiary"
        size="sm"
        :icon-only="true"
        icon="check"
        :loading="loading"
      >
      </base-button>
    </div>
    <BaseDialog
      v-if="deleteSynonym || cancelSynonym"
      :open="deleteSynonym || cancelSynonym"
      variant="danger"
      :title="$t(confirmDialog.title)"
      :description="$t(confirmDialog.description, { name: aSynonym })"
      :confirm-text="$t(confirmDialog.confirmText)"
      :cancel-text="$t(confirmDialog.cancelText)"
      @confirm="handleConfirmDialog"
      @cancel="handleCancelDialog"
      @close="handleCancelDialog"
    ></BaseDialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import TextOverflowTooltip from '@/components/Commons/TextOverflowTooltip';

export default {
  name: 'company-synonyms-table-row',
  components: {
    TextOverflowTooltip,
  },
  props: {
    aSynonym: {
      type: String,
      default: '',
    },
    bSynonyms: {
      type: Array,
      default: () => [],
    },
    forceEditing: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isEditing: this.forceEditing,
      newSynonyms: [],
      deletedSynonyms: [],
      newSynonym: '',
      newLabel: this.aSynonym,
      loading: false,
      validity: {
        aSynonym: !!this.aSynonym.length,
        bSynonyms: !!this.bSynonyms.length,
      },
      success: false,
      error: false,
      save: false,
      deleting: false,
      deleteSynonym: false,
      cancelSynonym: false,
    };
  },
  computed: {
    renderedSynonyms() {
      const filteredSynonyms = this.bSynonyms.filter(
        ({ id }) => !this.deletedSynonyms.includes(id),
      );
      if (!this.isEditing) return filteredSynonyms;
      return [...filteredSynonyms, ...this.newSynonyms];
    },
    hasEdit() {
      return (
        this.deletedSynonyms.length ||
        this.newSynonyms.length ||
        this.newLabel.trim() !== this.aSynonym
      );
    },
    isValid() {
      return this.validity.aSynonym && this.validity.bSynonyms;
    },
    isSearch() {
      return this.aSynonym
        .toLowerCase()
        .includes(this.search.trim().toLowerCase());
    },
    isSearchSynonyms() {
      const searchResults = [
        { bSynonym: this.aSynonym },
        ...this.renderedSynonyms,
      ].map(
        ({ bSynonym }) =>
          !bSynonym.toLowerCase().includes(this.search.trim().toLowerCase()),
      );

      return searchResults.every(Boolean);
    },
    saveNewSynonymsDisabled() {
      return this.loading || this.save || !this.hasEdit || !this.isValid;
    },
    confirmDialog() {
      return {
        title: `settings.search.synonyms.${
          this.deleteSynonym ? 'delete' : 'cancel'
        }.title`,
        description: `settings.search.synonyms.${
          this.deleteSynonym ? 'delete' : 'cancel'
        }.description`,
        cancelText: this.deleteSynonym
          ? 'generic.cancel'
          : 'settings.search.synonyms.cancel.cancel-text',
        confirmText: this.deleteSynonym
          ? 'generic.delete'
          : 'settings.search.synonyms.cancel.confirm-text',
      };
    },
  },
  methods: {
    computeSearch(text) {
      return text.toLowerCase().includes(this.search.trim().toLowerCase());
    },
    focusInput() {
      this.$refs['new-synonym'].focus();
    },
    resetUpdate() {
      this.isEditing = false;
      this.newSynonyms = [];
      this.deletedSynonyms = [];
      this.newSynonym = '';
      this.newLabel = this.aSynonym;
      this.success = false;
      this.error = false;
      this.save = false;
      this.cancelSynonym = false;

      if (!this.aSynonym) return this.$emit('abort');
    },
    resetSynonym() {
      this.newSynonym = '';
    },
    addSynonym() {
      if (!this.newSynonym) return;
      const multipleSynonyms = this.newSynonym
        .split(',')
        .map((s) => s.trim())
        .filter(Boolean);
      multipleSynonyms.forEach((s) => {
        this.newSynonyms.push({
          id: `new-${this.newSynonyms.length}`,
          bSynonym: s,
        });
      });

      this.resetSynonym();
      this.focusInput();
    },
    removeSynonym({ id }) {
      if (id.includes('new')) {
        this.newSynonyms = this.newSynonyms.filter((tag) => tag.id !== id);
      } else {
        this.deletedSynonyms.push(id);
      }
    },
    // MAIN
    async handleUpdate() {
      if (!this.hasEdit || !this.isValid) return;
      this.loading = true;
      // deleteSynonyms
      if (this.deletedSynonyms.length) {
        this.success = await this.deleteCompanySynonyms(this.deletedSynonyms);
        if (!this.success) return this.handleError();
      }

      // update aSynonym
      if (
        this.aSynonym &&
        !!this.newLabel.trim() &&
        this.newLabel.trim() !== this.aSynonym
      ) {
        this.success = await this.updateCompanySynonyms({
          ids: this.bSynonyms.map((s) => s.id),
          aSynonym: this.newLabel.trim(),
        });
        if (!this.success) return this.handleError();
      }

      // newSynonyms
      if (this.newSynonyms.length) {
        this.success = await this.createCompanySynonyms({
          synonyms: this.newSynonyms.map((s) => ({
            aSynonym: this.newLabel.trim(),
            bSynonym: s.bSynonym,
          })),
        });
        if (!this.success) return this.handleError();
        this.newSynonyms = [];
      }

      this.loading = false;
      this.error = false;
      this.save = true;

      // CREATE FROM NOTHING
      if (!this.aSynonym) return this.resetUpdate();

      // SAVE ANIMATION
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.resetUpdate();
    },
    handleError() {
      this.loading = false;
      this.error = true;
    },
    async deleteAll() {
      this.deleting = true;
      this.success = await this.deleteCompanySynonyms(
        this.bSynonyms.map((s) => s.id),
      );
      this.deleteSynonym = false;
      this.deleting = false;
    },
    handleConfirmDialog() {
      if (this.deleteSynonym) return this.deleteAll();
      this.resetUpdate();
    },
    handleCancelDialog() {
      this.deleteSynonym = false;
      this.cancelSynonym = false;
    },
    ...mapActions('adminModule', [
      'createCompanySynonyms',
      'updateCompanySynonyms',
      'deleteCompanySynonyms',
    ]),
  },
  watch: {
    renderedSynonyms(newValue) {
      this.validity.bSynonyms = !!newValue.length;
    },
    aSynonym(newValue) {
      this.newLabel = newValue;
      this.validity.aSynonym = !!newValue.length;
    },
    newLabel(newValue) {
      this.validity.aSynonym = !!newValue.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.synonyms-table-row {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid $grey-5-mayday;
  background-color: white;
  &__editing {
    background-color: $grey-1-mayday;
  }
  &__deleting {
    opacity: 0.5;
    pointer-events: none;
  }
}

.col-main {
  margin-left: 26px;
  width: 200px;
  display: flex;
  flex: 0 0 auto;
  font-size: 12px;
  line-height: 14px;
  transition: all 250ms ease-in-out;
  border-radius: 0px;
  padding: 0px;
  border: 1px solid transparent;
  background-color: transparent;

  &__editing {
    border-radius: 4px;
    padding: 13px 8px;
    margin-bottom: 20px;
    border: 1px solid $grey-5-mayday;
    background-color: white;
  }

  &__search {
    font-weight: 700;
  }

  &__not-search {
    opacity: 0.5;
  }

  &-input {
    outline: none;
    padding: 0px;
    border: none;
    font-size: 12px;
    line-height: 14px;
    width: 100%;
    &::placeholder {
      color: $grey-5-mayday;
    }
  }

  &__error {
    border: 1px solid $red-mayday;
  }

  &-label {
    padding: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.col-synonyms {
  width: 100%;
}

.synonyms-wrapper {
  width: 100%;
  display: flex;
  transition: all 250ms ease-in-out;
  gap: 8px;
  border-radius: 4px;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0px;
  border: 1px solid transparent;
  background-color: transparent;

  &__editing {
    padding: 8px;
    border: 1px solid $grey-5-mayday;
    background-color: white;
    transition: all 250ms ease-in-out;
    flex-wrap: wrap !important;
  }

  &__error {
    padding: 8px;
    border: 1px solid $red-mayday;
    background-color: white;
  }

  &-hint {
    padding-left: 8px;
    padding-top: 0;
    opacity: 0;
    height: 0;
    font-size: 12px;
    &__editing {
      opacity: 1;
      height: auto;
      padding-top: 4px;
    }
    transition: opacity 250ms ease-in-out, height 250ms ease-in-out,
      padding 250ms ease-in-out;
  }

  .synonyms {
    display: flex;
    align-items: center;

    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.1);
    border-radius: 4px;
    height: 24px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    padding: 5px 0px 5px 8px;
    gap: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &__editing {
      gap: 8px;
    }
    &__search {
      color: white;
      background-color: $blue-mayday;
    }
    &__not-search {
      opacity: 0.5;
    }
    transition: all 250ms ease-in-out;

    &-input {
      display: flex;
      align-items: center;
      height: 24px;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      border: none;
      outline: none;
      &::placeholder {
        color: $grey-5-mayday;
      }
    }

    &-icon {
      cursor: pointer;
      width: 0px;
      opacity: 0;
      height: 100%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      pointer-events: none;
      &__editing {
        pointer-events: auto;
        width: 12px;
        opacity: 1;
      }
      transition: opacity 250ms ease-in-out, width 250ms ease-in-out;
    }
  }
}

.icon-wrapper {
  outline: none;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 150ms ease-in-out;
  &-edit {
    color: $grey-7-mayday;
    background-color: transparent;
    &:hover {
      background-color: $grey-2-mayday;
    }
  }
  &-cancel {
    color: $red-mayday;
    background-color: rgba($red-mayday, 0.1);
    &:hover {
      color: white;
      background-color: $red-mayday;
    }
  }
  &-validate {
    color: $green-mayday;
    background-color: rgba($green-mayday, 0.1);
    &:hover {
      color: white;
      background-color: $green-mayday;
    }
    &__editing {
      color: white;
      background-color: $green-mayday;
    }
  }
  &-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.table-actions {
  display: flex;
  gap: 8px;
  justify-content: center;
  padding-right: 20px;
  padding-bottom: 0px;

  &__editing {
    padding-bottom: 20px;
  }
  transition: padding 250ms ease-in-out;
}
</style>
