<template>
  <div class="side-option" @click="$emit('click')">
    <el-tooltip
      :content="description"
      :disabled="!tooltip"
      placement="right"
      class="group-tooltip"
    >
      <base-button
        variant="tertiary"
        size="lg"
        icon-only
        :icon="icon"
        :pressed="active"
      />
    </el-tooltip>
    <div v-if="aiFeature" class="ai-badge__container">
      <AiBadge :icon="false" />
    </div>
  </div>
</template>

<script>
import AiBadge from 'components/AI/AiBadge.vue';

export default {
  name: 'side-bar-item',
  components: { AiBadge },
  props: {
    description: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'diagnostic',
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'user',
    },
    active: {
      type: Boolean,
      default: false,
    },
    aiFeature: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.side-option {
  width: fit-content;
}

.item-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ai-badge__container {
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 1;
}
</style>
