<template>
  <div class="column-container">
    <div v-if="icon" class="icon-wrapper" :style="`background: ${color}`">
      <font-awesome-icon class="initials" :icon="['fas', icon]" />
    </div>

    <div v-else class="initials-wrapper" :style="colorStyle">
      <div class="initials">
        {{ getInitials }}
      </div>
    </div>

    <TextEllipsis
      style="background: a1a1a1a1"
      v-if="!isEditing"
      :text="text"
      @dblclick.native="canEdit ? $emit('edit-text') : ''"
    />

    <ItemEditor
      class="edit-text"
      v-else
      :value="text"
      @edit="$emit('update-text', $event)"
      :isLabel="true"
      @cancel="$emit('cancel')"
      placeholder="settings.permissions.user-labels.category-input-placeholder"
    />
    <slot name="badge" />
  </div>
</template>

<script>
import { userColor } from 'utils/utils';
import TextEllipsis from '@/components/TextEllipsis.vue';
import ItemEditor from '@/views/Settings/PermissionSettings/AccessSettings/LabelCategorySettings/ItemEditor';

import { blueMayday } from '@/assets/scss/_colors.scss';

export default {
  name: 'settings-dropdown-capitalized-column',
  components: {
    TextEllipsis,
    ItemEditor,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    initials: {
      type: String,
      default: '',
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canEditIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cleanStr() {
      return this.text.replace(/[\p{Emoji}\u200d]+/gu, '').trim();
    },
    colorStyle() {
      if (!this.initials) return {};
      return {
        background:
          this.initials !== null && this.initials !== '?'
            ? userColor(this.initials, 70, 50)
            : blueMayday,
        color: 'white',
        ...(this.getInitials.length > 1 ? { fontSize: '12px' } : {}),
      };
    },
    getInitials() {
      if (!this.initials) return this.cleanStr.charAt(0).toUpperCase();
      const fullName = this.initials.split(' ');
      let initials;
      if (fullName.length >= 2)
        initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      else initials = fullName.shift().charAt(0);
      return initials.toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.column-container {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;

  text-overflow: ellipsis;
}

.initials-wrapper {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: $grey-4-mayday;
  color: white;
  border-radius: 2px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.icon-wrapper {
  width: 24px;
  min-width: 24px;
  height: 24px;
  color: white;
  border-radius: 2px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 4px;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.edit-text {
  margin-left: 5px;
}
</style>
