<template>
  <button @click="handleClick">
    <slot name="slot-button" v-if="!attachmentLoading || !showLoader"></slot>
    <font-awesome-icon
      v-else-if="showLoader"
      :icon="['fas', 'spinner-third']"
      class="loading-icon"
      spin
    ></font-awesome-icon>

    <input
      ref="fileInput"
      type="file"
      @change="$emit('new-attachment', $event)"
      @click="handleClickOnInput"
      id="file-upload"
      accept="image/png, image/jpeg, .doc, .docx, application/pdf"
      :disabled="disabled || attachmentLoading"
      multiple
    />
  </button>
</template>
<script>
export default {
  name: 'add-attachment',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    attachmentLoading: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClick() {
      if (this.disabled) this.$emit('disable-click');
      this.$refs.fileInput.click();
    },
    handleClickOnInput(event) {
      event.target.value = '';
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  background: transparent;
  border: none;
  outline: none;
}

input[type='file'] {
  display: none;
}
.md-btn:hover {
  box-shadow: $shadow-mayday;
  transform: translateY(-1px);
  cursor: pointer;
}
.add-button {
  min-width: 57px;
  min-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-icon {
  color: $grey-5-mayday;
}
</style>
