<template>
  <div class="toast">
    <div class="toast__body">
      <span class="d-block">
        {{ $t(`translation.toast.entity-type.${entityType.toLowerCase()}`) }}
        {{ ' ' }}
        {{
          $t(
            `${
              isStagedContent
                ? 'translation.toast.info-staged'
                : 'translation.toast.info'
            }`,
            {
              language: $t(`knowledge.languages.${language}`),
            },
          )
        }}
      </span>
      <el-button
        @click="$emit('open-set-language-modal')"
        class="mt-2"
        type="primary"
        size="mini"
      >
        {{
          $t('translation.toast.set-language', {
            lang: $t(`knowledge.languages.fem.${language}`),
          })
        }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'set-language-toast',
  props: {
    language: {
      type: String,
    },
    entityType: {
      type: String,
      default: 'case',
    },
    isStagedContent: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  position: absolute;
  top: 80px !important;
  max-width: 250px;
  font-size: 14px;
  border-radius: 2px;
  border-left: 4px solid $red-mayday;
  background-color: white;
  overflow: hidden;
  z-index: 999;

  &__body {
    padding: 8px;
    background: $grey-1-mayday;
  }
}
</style>
