<template>
  <div class="popover-step-mover">
    <ChildButtonOptionsPopover
      :state="state"
      :radioButtons="radioButtons"
      :stateCancelButtonKeepGoing="
        $t('knowledge.actions.step-mover.keep-going')
      "
      :stateCancelButtonQuit="$t('knowledge.actions.step-mover.quit')"
      @methodChange="method = $event"
      @close="close()"
      @cancel-quit="state = ''"
    >
      <template v-slot:title>
        {{ $t('knowledge.actions.step-mover.title') }}
      </template>
      <template v-slot:state-over>
        {{ $t('knowledge.actions.step-mover.over') }}
      </template>
      <template v-slot:state-cancel>
        {{ $t('knowledge.actions.step-mover.cancel-validation') }}
      </template>
      <template v-slot:popover-content>
        <div v-if="method === 'cascader'">
          <StepMoverCascaderWrapper
            @close="state = 'quit'"
            @focus-option="setFocusOption"
            @back-search="setFocusOption"
            @options-update="currentOptions = $event"
            :forbiddenIds="forbiddenIds"
            :parent="parent"
            :rootId="rootId"
            :method="'cascader'"
            :focusedOption="focusedOption"
          />
        </div>
        <div v-if="method === 'search'">
          <StepMoverSearchWrapper
            :forbiddenIds="forbiddenIds"
            :parent="parent"
            :rootId="rootId"
            :focusedOption="focusedOption"
            @focus-option="setFocusOption"
            @close="state = 'quit'"
          />
        </div>
      </template>
      <template v-slot:validation-buttons>
        <div class="d-flex justify-content-end gap-2 footer">
          <base-button
            variant="secondary"
            :text="$t('knowledge.actions.step-mover.cancel')"
            @click="state = 'quit'"
          />
          <base-button
            :text="
              focusedOptionAtLevel
                ? $t('knowledge.actions.step-mover.move')
                : $t('knowledge.actions.step-mover.move-here')
            "
            @click="handleRelocate"
            :disabled="!moveValidation"
          />
        </div>
      </template>
    </ChildButtonOptionsPopover>
  </div>
</template>

<script>
import ChildButtonOptionsPopover from '../../views/KnowledgeEditor/ParametricContentBuilder/ParametricDiagnosticBuilder/DiagnosticBuilderMain/DiagnosticBuilderChildren/ChildButton/ChildButtonOptionsPopover';
import StepMoverCascaderWrapper from './StepMoverCascaderWrapper';
import StepMoverSearchWrapper from './StepMoverSearchWrapper';

import { mapActions } from 'vuex';

export default {
  name: 'popover-step-mover',
  components: {
    StepMoverCascaderWrapper,
    StepMoverSearchWrapper,
    ChildButtonOptionsPopover,
  },
  props: {
    rootId: String,
    forbiddenIds: {
      type: Object,
      default: () => {},
    },
    parent: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      path: [],
      state: '',
      method: 'cascader',
      radioButtons: [
        { label: 'cascader', title: 'knowledge.actions.step-mover.this-diag' },
        { label: 'search', title: 'knowledge.actions.step-mover.other-diag' },
      ],
      focusedOption: null,
      currentOptions: [],
    };
  },
  computed: {
    moveValidation() {
      return this.focusedOption && !!this.focusedOption.type;
    },
    focusedOptionAtLevel() {
      return (
        this.focusedOption && this.optionIds.includes(this.focusedOption.id)
      );
    },
    optionIds() {
      return this.currentOptions.map((op) => op.id);
    },
  },
  methods: {
    setMethod($event) {
      this.method = $event.method;
    },
    setFocusOption(option) {
      if (
        option === null ||
        ((option.type === 'Diagnostic' ||
          option.type === 'Step' ||
          option.type === 'keyStep') &&
          !this.isForbidden(option.id))
      )
        this.focusedOption = option;
    },
    appearChild(idx) {
      this.path = this.path.concat([idx]);
    },
    appearParent() {
      this.path = this.path.slice(0, -1);
    },
    async handleRelocate() {
      this.state = 'loading';
      const res = await this.relocateChild({
        childId: this.forbiddenIds.childId,
        formerParentId: this.forbiddenIds.parent,
        newParentId: this.focusedOption.id,
      });
      if (res) {
        this.$emit('move-to-diag', {
          newParent: this.focusedOption,
          onlyLocalUpdate: true,
        });
        this.state = 'over';
      }
    },
    close() {
      this.$emit('close');
      this.state = '';
    },
    isForbidden(optionId) {
      return Object.values(this.forbiddenIds).includes(optionId);
    },
    ...mapActions('knowledgeModule', ['relocateChild']),
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: $grey-1-mayday;
  border-bottom: 2px solid $grey-4-mayday;
  font-weight: bold;
}

.footer {
  padding: 8px 16px 8px 16px;
  background-color: $grey-2-mayday;
}

:deep() .el-radio-button__inner {
  width: 100% !important;
  background-color: rgba($blue-mayday, 0.1);
  border-color: $blue-mayday !important;
}

.user-guiding {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 32px;
  height: 32px;
  color: $blue-mayday;
}

.quit {
  width: 32px;
  height: 32px;
  color: $red-mayday;
}

.cancel-button {
  background-color: $grey-2-mayday;
  border: none;
  &:hover {
    color: $blue-mayday !important;
    background-color: $grey-2-mayday !important;
    border: none;
  }
}
</style>
