<template>
  <div class="ai-drawer">
    <div :class="['ai-drawer--header', { expanded: expanded }]" @click="toggle">
      <div class="ai-drawer--tabs">
        <div
          v-for="(tab, index) in filteredTabs"
          :key="index"
          :class="[
            'ai-drawer--tab',
            { active: activeTab === tab.key },
            { expanded: expanded },
          ]"
          @click.stop="handleSelectTab(tab.key)"
        >
          <font-awesome-icon :icon="['fal', tab.icon]" />
          {{ $t(`${tab.label}`) }}
          <div class="ai-drawer__badge-container">
            <AiBadge />
          </div>
        </div>
      </div>
      <div class="ai-drawer--actions">
        <div
          class="ai-drawer--button cancel"
          v-if="tabs[activeTab].generating"
          @click.stop="handleCancel(activeTab)"
        >
          {{ $t(`knowledge.ai-drawer.cancel`) }}
        </div>
        <div :class="['ai-drawer--button', { expanded: expanded }]">
          <font-awesome-icon :icon="['fal', 'chevron-up']" />
        </div>
        <div class="ai-drawer--button" @click.stop="handleClose">
          <font-awesome-icon :icon="['fal', 'times']" />
        </div>
      </div>
    </div>
    <div :class="['ai-drawer--body', { expanded: expanded }]">
      <div class="ai-drawer--content-container">
        <SummaryTabs
          :hidden="activeTab !== 'summary'"
          :long="tabs.summary.long"
          :short="tabs.summary.short"
          :active-tab="activeSummaryTab"
          :process-interrupted="processInterrupted"
          :doc-last-update="docLastUpdate"
          @cancel="handleCancel"
          @save="handleSaveSummary"
          @delete="handleDeleteSummary"
          @generate-summary="handleGenerateSummary"
          @change-tab="handleSelectSummaryTab"
        />
        <ActionItemsSection
          :hidden="activeTab !== 'action-items'"
          :action-items="actionItems.body"
          :manual-edit="actionItems.manualEdit || false"
          :last-update="actionItems.bodyUpdatedAt"
          :is-visible="actionItems.isVisible || false"
          :generating="tabs['action-items'].generating"
          :deleting="tabs['action-items'].deleting"
          :process-interrupted="processInterrupted"
          :doc-last-update="docLastUpdate"
          @cancel="handleCancel('action-items')"
          @save="handleSaveActionItems"
          @delete="handleDeleteActionItems"
          @generate-action-items="handleGenerateActionItems"
        />
        <KnowledgeManager
          :hidden="activeTab !== 'knowledge-manager'"
          :kmRecommendations="kmRecommendations.body"
          :last-update="kmRecommendations.bodyUpdatedAt"
          :generating="tabs['knowledge-manager'].generating"
          :deleting="tabs['knowledge-manager'].deleting"
          :process-interrupted="processInterrupted"
          :doc-last-update="docLastUpdate"
          @cancel="handleCancel('knowledge-manager')"
          @save="handleSaveKMRecommendations"
          @delete="handleDeleteKMRecommendations"
          @generate-knowledge-manager="handleGenerateKnowledgeManager"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AiBadge from 'components/AI/AiBadge.vue';
import ActionItemsSection from 'components/AI/ActionItemsSection.vue';
import KnowledgeManager from 'components/AI/KnowledgeManager.vue';
import SummaryTabs from 'components/AI/SummaryTabs.vue';

export default {
  name: 'AiDrawer',
  components: { SummaryTabs, ActionItemsSection, AiBadge, KnowledgeManager },
  props: {
    contentId: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    generatingSummary: {
      type: Boolean,
      default: false,
    },
    generatingShortSummary: {
      type: Boolean,
      default: false,
    },
    generatingActionItems: {
      type: Boolean,
      default: false,
    },
    summary: {
      type: Object,
      default: () => ({}),
    },
    shortSummary: {
      type: Object,
      default: () => ({}),
    },
    actionItems: {
      type: Object,
      default: () => ({}),
    },
    kmRecommendations: {
      type: Object,
      default: () => ({}),
    },
    focusedTabFromParent: {
      type: String,
      default: null,
    },
    docLastUpdate: {
      type: String || Number,
      default: null,
    },
  },
  data: () => ({
    expanded: false,
    processInterrupted: false,
    timeout: null,
    abortController: null,
    tabs: {
      summary: {
        label: 'knowledge.ai-drawer.tabs.summary',
        icon: 'file-edit',
        key: 'summary',
        isDisabled: true,
        long: {
          data: {
            body: '',
            manualEdit: false,
          },
          generating: false,
          deleting: false,
        },
        short: {
          data: {
            body: '',
            manualEdit: false,
          },
          generating: false,
          deleting: false,
        },
      },
      'action-items': {
        label: 'knowledge.ai-drawer.tabs.action-items',
        icon: 'file-check',
        key: 'action-items',
        generating: false,
        deleting: false,
        isDisabled: true,
        data: {
          body: [],
          manualEdit: false,
        },
      },
      'knowledge-manager': {
        label: 'knowledge.ai-drawer.tabs.knowledge-manager',
        icon: 'user',
        key: 'knowledge-manager',
        generating: false,
        deleting: false,
        isDisabled: true,
        data: {},
      },
    },
    activeTab: 'summary',
    activeSummaryTab: 'long',
  }),
  created() {
    this.expanded = !!this.focusedTabFromParent;
    this.abortController = new AbortController();
    this.tabs['action-items'].data = this.actionItems;
    this.tabs.summary.long.data = this.summary;
    this.tabs.summary.short.data = this.shortSummary;
    this.tabs.summary.isDisabled = !this.displaySummary;
    this.tabs['action-items'].isDisabled = !this.displayActionItems;
    if (!this.displaySummary) {
      this.activeTab = 'action-items';
    }
    this.tabs['knowledge-manager'].isDisabled = !this.displayKnowledgeManager;
    this.tabs['knowledge-manager'].data = this.kmRecommendations;
  },
  computed: {
    filteredTabs() {
      return Object.values(this.tabs).filter((tab) => !tab.isDisabled);
    },
    displaySummary() {
      return this.companyAllowSummaryPreference;
    },
    displayActionItems() {
      return this.companyAllowActionItemsPreference;
    },
    displayKnowledgeManager() {
      return this.companyAllowKnowledgeManagerPreference;
    },
    ...mapGetters('knowledgeModule', ['focusContent', 'focusContentId']),
    ...mapGetters([
      'companyAllowSummaryPreference',
      'companyAllowActionItemsPreference',
      'companyAllowKnowledgeManagerPreference',
    ]),
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
      this.$emit('expand', this.expanded ? this.activeTab : null);
    },
    handleSelectTab(tab) {
      this.activeTab = tab;
      if (!this.expanded) this.toggle();
      else this.$emit('expand', tab);
    },
    handleSelectSummaryTab(tab) {
      this.activeSummaryTab = tab;
      this.$emit('expand', tab === 'long' ? 'summary' : 'short-summary');
    },
    handleClose() {
      this.$emit('close');
    },
    handleDeleteSummary(isShortSummary) {
      this.processInterrupted = false;
      this.tabs.summary[isShortSummary ? 'short' : 'long'].deleting = true;
      this.timeout = setTimeout(() => {
        this.tabs.summary[isShortSummary ? 'short' : 'long'].deleting = false;
        if (!this.processInterrupted)
          this.$emit(
            `summary-generated`,
            {
              body: null,
              manualEdit: false,
              isVisible: false,
            },
            isShortSummary,
          );
      }, 2000);
    },
    handleDeleteActionItems() {
      this.processInterrupted = false;
      this.tabs['action-items'].deleting = true;
      this.timeout = setTimeout(() => {
        this.tabs['action-items'].deleting = false;
        if (!this.processInterrupted)
          this.$emit(`action-items-generated`, {
            body: null,
            manualEdit: false,
            isVisible: false,
          });
      }, 2000);
    },
    handleDeleteKMRecommendations() {
      this.processInterrupted = false;
      this.tabs['knowledge-manager'].deleting = true;
      this.timeout = setTimeout(() => {
        this.tabs['knowledge-manager'].deleting = false;
        if (!this.processInterrupted)
          this.$emit(`knowledge-manager-generated`, {
            body: null,
            manualEdit: false,
            isVisible: false,
          });
      }, 2000);
    },
    handleCancel(target) {
      this.processInterrupted = true;
      this.tabs[target].deleting = false;
      this.tabs[target].generating = false;
      clearTimeout(this.timeout);
    },
    handleSaveSummary(body, isShortSummary = false) {
      this.$emit(`summary-generated`, body, isShortSummary);
    },
    handleSaveActionItems(body) {
      this.$emit('action-items-generated', body);
    },
    handleSaveKMRecommendations(body) {
      this.$emit('km-recommendations-generated', body);
    },
    async handleGenerateSummary(isShortSummary = false) {
      this.expanded = true;

      if (isShortSummary) this.tabs.summary.short.generating = true;
      else this.tabs.summary.long.generating = true;

      this.processInterrupted = false;
      this.$emit(
        isShortSummary ? 'generating-short-summary' : 'generating-summary',
      );
      const summary = {
        body: '',
        manualEdit: false,
      };
      try {
        for await (const chunk of await this.fetchSummary({
          body: this.focusContent.body,
          summaryType: isShortSummary ? 'short' : 'long',
          contentId: this.focusContentId,
          feature: isShortSummary ? 'shortSummary' : 'longSummary',
        })) {
          if (this.processInterrupted) {
            this.processInterrupted = false;
            return;
          }
          switch (chunk.type) {
            case 'chunk':
              summary.body += chunk.content;
              break;
            case 'error':
              if (isShortSummary) this.tabs.summary.short.generating = false;
              else this.tabs.summary.long.generating = false;
              this.processInterrupted = true;
              this.$emit(
                'summary-generated',
                { error: chunk.content },
                isShortSummary,
              );
              return;
            default:
              break;
          }
        }
        this.$emit('summary-generated', summary, isShortSummary);
        if (isShortSummary) this.tabs.summary.short.generating = false;
        else this.tabs.summary.long.generating = false;
      } catch (error) {
        this.processInterrupted = true;
        this.$emit('summary-generated', { error: error }, isShortSummary);
      }
    },
    async handleGenerateActionItems() {
      this.expanded = true;
      this.tabs['action-items'].generating = true;
      this.processInterrupted = false;
      this.$emit('generating-action-items');
      this.tabs['action-items'].data.body = [];
      this.tabs['action-items'].data.manualEdit = false;
      try {
        for await (const chunk of await this.fetchActionItems({
          body: this.focusContent.body,
          abortController: this.abortController,
        })) {
          switch (chunk.type) {
            case 'chunk':
              this.tabs['action-items'].data.body.push(chunk.content);
              this.tabs['action-items'].generating = false;
              break;
            case 'error':
              this.tabs['action-items'].generating = false;
              this.processInterrupted = true;
              this.$emit('action-items-generated', { error: chunk.content });
              return;
          }
        }
        const { body, manualEdit } = this.tabs['action-items'].data;
        this.$emit('action-items-generated', {
          body,
          manualEdit,
          isVisible: false,
        });
        this.tabs['action-items'].generating = false;
      } catch (error) {
        this.processInterrupted = true;
        this.tabs['action-items'].generating = false;
        this.$emit('action-items-generated', { error: error });
      }
    },
    async handleGenerateKnowledgeManager() {
      this.expanded = true;
      this.tabs['knowledge-manager'].generating = true;
      this.processInterrupted = false;
      this.$emit('generating-knowledge-manager');
      this.tabs['knowledge-manager'].data.body = {};
      try {
        const data = await this.fetchKnowledgeManagerRecommendations({
          body: this.focusContent.body,
        });
        this.tabs['knowledge-manager'].data.body = data.data;
        this.$emit('km-recommendations-generated', {
          body: data.data,
        });
        this.tabs['knowledge-manager'].generating = false;
      } catch (error) {
        this.processInterrupted = true;
        this.tabs['knowledge-manager'].generating = false;
      }
    },
    ...mapActions('brainModule', [
      'fetchSummary',
      'fetchActionItems',
      'fetchKnowledgeManagerRecommendations',
    ]),
  },
  watch: {
    generatingSummary: {
      handler(newVal) {
        if (newVal && !this.tabs.summary.long.generating) {
          this.handleSelectTab('summary');
          this.handleGenerateSummary();
        }
      },
      immediate: true,
    },
    generatingShortSummary: {
      handler(newVal) {
        if (newVal && !this.tabs.summary.short.generating) {
          this.activeSummaryTab = 'short';
          this.handleGenerateSummary(true);
        }
      },
      immediate: true,
    },
    generatingActionItems: {
      handler(newVal) {
        if (newVal && !this.tabs['action-items'].generating) {
          this.handleSelectTab('action-items');
          this.handleGenerateActionItems();
        }
      },
      immediate: true,
    },
    summary(newVal) {
      this.tabs.summary.long.data = {
        ...this.tabs.summary.long.data,
        ...newVal,
      };
    },
    shortSummary(newVal) {
      this.tabs.summary.short.data = {
        ...this.tabs.summary.short.data,
        ...newVal,
      };
    },
    actionItems(newVal) {
      this.tabs['action-items'].data = {
        ...this.tabs['action-items'].data,
        ...newVal,
      };
    },
    kmRecommendations(newVal) {
      this.tabs['knowledge-manager'].data = {
        ...this.tabs['knowledge-manager'].data,
        ...newVal,
      };
    },
    focusedTabFromParent(newVal) {
      if (newVal) {
        if (newVal === 'short-summary' || newVal === 'summary') {
          this.activeSummaryTab = newVal === 'short-summary' ? 'short' : 'long';
          newVal = 'summary';
        }
        this.activeTab = newVal;
        if (!this.expanded) this.expanded = true;
      }
    },
    focusContent(newVal, oldVal) {
      if (this.tabs.summary.generating) {
        if (newVal && oldVal && newVal.body !== oldVal.body) {
          this.handleCancel();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ai-drawer {
  position: fixed;
  background-color: white;
  bottom: 16px;
  width: 50%;
  z-index: 10;
  box-shadow: $shadow-mayday;
  border-radius: 12px;
  overflow: hidden;
}

.ai-drawer--header {
  background-color: $grey-1-mayday;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  .ai-drawer--tabs {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: $grey-5-mayday;
    gap: 8px;

    .ai-drawer--tab {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 8px;
      gap: 4px;
      transition: all 0.2s ease-in-out;

      &.expanded {
        &.active {
          background-color: white;
          border-radius: 8px 8px 0 0;
          padding-bottom: 14px;
          margin-bottom: -8px;
        }
      }

      &.active {
        color: $purple-5-mayday;
      }

      &:hover {
        color: $purple-5-mayday;
      }

      .ai-drawer__badge-container {
        margin-top: -4px;
      }
    }
  }

  .ai-drawer--actions {
    display: flex;
    gap: 4px;
    align-items: center;

    .ai-drawer--button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $grey-7-mayday;
      cursor: pointer;
      font-size: 16px;
      width: 24px;
      height: 24px;
      padding: 2px;
      border-radius: 4px;

      &.cancel {
        width: fit-content;
        font-size: 14px;

        &:hover {
          color: $red-mayday;
          background-color: transparent;
        }
      }

      svg {
        transition: transform 0.2s ease-in-out;
      }

      &:hover {
        color: $purple-5-mayday;
        background-color: $grey-3-mayday;
      }

      &.expanded svg {
        transform: rotate(180deg);
      }
    }
  }
}

.ai-drawer--body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;

  &.expanded {
    max-height: 300px;
    overflow: auto;
  }

  .ai-drawer--content-container {
    padding: 16px;
  }
}
</style>
