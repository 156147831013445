<template>
  <div>
    <base-header class="header d-flex align-items-center"></base-header>
    <div class="container-fluid pt-4 pb-4 automations-scrollbar-container">
      <div class="row justify-content-center">
        <!-- S'il n'y a pas encore d'application installée -->
        <card shadow class="col-10" v-if="!automationPossible">
          <div class="text-center py-4">
            <img class="mb-4" src="~assets/automations.svg" width="50px" />
            <h4 class="text-muted text-upercase ls-1 my-4">
              Vous ne pouvez pas encore créer de règles intelligentes car vous
              n’avez pas encore installé d’applications Mayday
            </h4>
            <p class="col-8 mx-auto my-4">
              Tout d’abord rendez-vous dans l’onglet intégrations pour installer
              l’intégration de Mayday qui convient à votre espace de travail.
              Vous pourrez-ensuite commencer à créer des règles intelligentes et
              des automatisations.
            </p>
            <router-link to="/integrate" class="link-to-integrations">
              <base-button-old class="mt-4 link-button" type="primary" outline
                >Installer une intégration</base-button-old
              >
            </router-link>
          </div>
        </card>
        <!-- S'il n'y a pas encore d'attributs importés -->
        <card class="col-10" v-else-if="companyAttributes.length == 0">
          <div class="text-center py-4">
            <img class="mb-4" src="~assets/automations.svg" width="50px" />
            <h4 class="text-muted text-upercase ls-1 my-4">
              Préparation de vos règles
            </h4>
            <p class="col-8 mx-auto my-4">
              Mayday vous permet de paramètrer des règles de raccourcis dans
              votre base de connaissance afin d’avoir la bonne information au
              bon moment encore plus facilement et rapidement Pour créer ces
              règles vous devez d’abord importer les attributs customs de votre
              compte
              <span v-if="isIntercom">Intercom</span>
              <span v-else>Zendesk</span>
              .
            </p>
            <base-button-old
              class="mt-4 link-button"
              type="primary"
              outline
              @click="modals.attributesImport = true"
              >Importer les attributs</base-button-old
            >
          </div>
        </card>
        <!-- S'il y a des attributs mais pas encore de règle -->
        <div
          v-else-if="companyAttributes.length > 0 && rules.length == 0"
          class="col-12 pl-0 d-flex"
        >
          <div class="col-4">
            <card shadow type="secondary" class="attribute-table-card">
              <div slot="header" class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Mes attributs</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a
                      class="btn btn-sm btn-primary text-white"
                      @click="modals.attributesImport = true"
                      >Rafraichir</a
                    >
                  </div>
                </div>
              </div>
              <template>
                <attributes-table @remove-attribute="removeAttribute" />
              </template>
            </card>
          </div>
          <card shadow class="col" v-loading="isAutomationLoading">
            <div class="text-center py-4">
              <img class="mb-4" src="~assets/automations.svg" width="50px" />
              <h4 class="text-muted text-upercase ls-1 my-4">
                Préparation de vos règles
              </h4>
              <p class="col-8 mx-auto my-4">
                Vous avez importé vos attributs personnalisés ! Vous êtes fin
                prêts pour créer vos premières automatisations.
              </p>
              <base-button-old
                class="mt-4 link-button"
                type="primary"
                outline
                @click="modals.tutorialModal = true"
                >Découvrir comment créer la première règle</base-button-old
              >
              <modal
                :show="modals.tutorialModal"
                modal-classes="modal-dialog-centered modal-xl"
              >
                <template slot="header">
                  <h3 class="modal-title" id="exampleModalLabel">
                    Tutoriel de création d'automatisations
                  </h3>
                </template>
                <div>
                  <p>
                    📺 Découvrez comment créer la première règle intelligente en
                    visionnant la video 📺
                  </p>
                  <br />
                  <iframe
                    class="tutorial-video"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/IWmB5_ZGQy8"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <br />
                  <div class="row justify-content-center">
                    <router-link to="/knowledge-editor">
                      <base-button-old class="mt-4 link-button" type="primary"
                        >Créer la première règle</base-button-old
                      >
                    </router-link>
                  </div>
                </div>
                <template slot="footer">
                  <base-button-old
                    type="primary"
                    size="sm"
                    outline
                    @click="
                      modals.tutorialModal = false;
                      isChosen = false;
                    "
                    >Fermer</base-button-old
                  >
                </template>
              </modal>
            </div>
          </card>
        </div>
        <!-- S'il y a des attributs ET DES règles -->
        <div
          v-else-if="companyAttributes.length > 0 && rules.length > 0"
          class="col-12 d-flex"
        >
          <div class="col-4">
            <card
              shadow
              type="secondary"
              class="attribute-table-card sticky-top"
            >
              <div slot="header" class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Mes attributs</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a
                      class="btn btn-sm btn-primary text-white"
                      @click="modals.attributesImport = true"
                      >Rafraichir</a
                    >
                  </div>
                </div>
              </div>
              <template>
                <attributes-table @remove-attribute="removeAttribute" />
              </template>
            </card>
          </div>
          <div class="col">
            <div
              class="language-rules"
              v-for="knowledge in rules"
              :key="knowledge.language"
            >
              <rule-card
                class="rule-card mb-4"
                v-for="rule in knowledge.rules"
                :key="rule.alias"
                :rule="rule"
                @update-rule="updateRule"
                @delete-rule="deleteRule"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <import-company-attributes-modal
      v-if="modals.attributesImport"
      :display="modals.attributesImport"
      :integration="integration"
      :company-attributes="companyAttributes"
      @choice="importUserChoice"
      @close="modals.attributesImport = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import RuleCard from '@/components/RuleCard.vue';
import AttributesTable from './Tables/AttributesTable.vue';
import ImportCompanyAttributesModal from 'components/Modals/ImportCompanyAttributesModal';
import {
  setCompanyAttributesMutation,
  getCompanyRulesQuery,
} from 'utils/gqlQueries/companyQueries';
import {
  updateGroupMutation,
  deleteGroupMutation,
} from 'utils/gqlQueries/groupQueries';
import modal from '@/components/Modal.vue';

export default {
  name: 'automations',
  components: {
    RuleCard,
    AttributesTable,
    ImportCompanyAttributesModal,
    modal,
  },
  created() {
    this.$apollo
      .query({
        query: getCompanyRulesQuery,
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        const { knowledges } = response.data.currentCompany;
        knowledges.forEach(({ language, groups }) => {
          const rules = groups.filter(
            ({ type }) => type === 'ALL' || type === 'ANY',
          );
          if (rules.length) this.rules.push({ language, rules });
          this.isAutomationLoading = false;
        });
      });
  },
  data() {
    return {
      modals: {
        attributesImport: false,
        tutorialModal: false,
      },
      rules: [],
      currentPage: 1,
      tutorialModal: false,
      isAutomationLoading: true,
    };
  },
  computed: {
    isZendesk() {
      return this.$store.state.integrations.includes('Zendesk');
    },
    isIntercom() {
      return this.$store.state.integrations.includes('Intercom');
    },
    automationPossible() {
      return this.isZendesk || this.isIntercom;
    },
    integration() {
      if (this.isZendesk) return 'zendesk';
      if (this.isIntercom) return 'intercom';
      return null;
    },
    ...mapState(['companyAttributes', 'groups', 'languages']),
  },
  watch: {
    groups() {
      const newRules = [];
      this.rules.forEach((elem) => {
        const lang = this.languages.find(
          (allLangues) => elem.language === allLangues.language,
        );
        const newRule = this.groups.filter(
          (group) => group.knowledgeId === lang.id,
        );
        if (newRule !== undefined) {
          newRules.push({
            language: elem.language,
            rules: newRule,
          });
        }
      });
      this.rules = newRules;
    },
  },
  methods: {
    setCompanyAttributes(selectedRows) {
      this.$apollo
        .mutate({
          mutation: setCompanyAttributesMutation,
          variables: { attributes: selectedRows },
        })
        .then((response) => {
          const { attributes } = response.data.setCompanyAttributes;
          this.$store.dispatch('updateCompanyAttributes', attributes);
        });
    },
    removeAttribute({ id }) {
      const newAttributes = this.companyAttributes.filter(
        (row) => row.id !== id,
      );
      this.setCompanyAttributes(newAttributes);
    },
    importUserChoice(choice) {
      if (choice) this.setCompanyAttributes(choice);
      this.modals.attributesImport = false;
    },
    updateRule({ id, alias, rules }) {
      this.$apollo
        .mutate({
          mutation: updateGroupMutation,
          variables: { id, alias, rules },
        })
        .then((response) => {
          const knowledge = response.data.updateGroup;
          if (knowledge) {
            this.$message('Votre règle a été mis à jour avec succès');
          } else {
            this.$message('Problème durant la mise à jour de votre règle');
          }
        });
    },
    deleteRule({ id }) {
      this.$apollo
        .mutate({ mutation: deleteGroupMutation, variables: { id } })
        .then((response) => {
          const knowledge = response.data.deleteGroup;
          if (knowledge) {
            this.$message('Votre règle a été supprimée avec succès');
          } else {
            this.$message('Problème durant la supression de votre règle');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tutorial-video {
  border-radius: 5px;
}

.attribute-table-card :deep(.card-body) {
  padding: 0px;
}

.link-button:hover {
  :deep() a {
    color: white;
  }
}

.attribute-table {
  :deep() .el-table__body-wrapper {
    max-height: 250px;
    overflow: auto;
    overflow-x: hidden;
  }
}

.attribute-table {
  :deep() .el-checkbox {
    margin-bottom: 0px;
  }
}
</style>
