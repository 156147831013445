<template>
  <div class="ask-box" :style="`border-color:${borderColor}`">
    <div class="header-wrapper" :style="`color:${headerColor}`">
      <font-awesome-icon class="mr-1 icon" :icon="icon"></font-awesome-icon>
      <div class="title">{{ $t(title) }}</div>
    </div>
    <div class="ask-box-slot-wrapper">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import { grey3Mayday, grey8Mayday } from '@/assets/scss/_colors.scss';

export default {
  name: 'ask-box',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: Array,
      default: () => ['fal', 'book'],
    },
    borderColor: {
      type: String,
      default: grey3Mayday,
    },
    headerColor: {
      type: String,
      default: grey8Mayday,
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-box {
  border-radius: 4px;
  border: 1px solid;
  max-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.header-wrapper {
  background-color: $purple-1-mayday;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  position: absolute;
  top: -10px;
  left: 12px;
  height: 15px;
  .title {
    font-size: 12px;
    font-weight: 700;
  }
}

.ask-box-slot-wrapper {
  height: fit-content;
  max-height: 100%;
  overflow: auto;
}
</style>
