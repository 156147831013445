<template>
  <modal
    class="import-article-modal"
    body-classes="p-0 m-0"
    :show.sync="importArticle"
    :closeIcon="true"
  >
    <div class="row sticky-top modal-search-header m-0">
      <el-select
        class="col-4 change-plateform pr-0"
        v-model="selectedPlateform"
        nullaceholder="Select"
        @change="modifyPlateform()"
      >
        <template slot="prefix">
          <img
            v-if="selectedPlateform === 'Notion'"
            class="prefix selected-logo"
            src="~assets/notion-round.svg"
          />
          <img
            v-if="selectedPlateform === 'Zendesk'"
            class="prefix selected-logo"
            src="~assets/zendesk-round.svg"
          />
        </template>
        <el-option
          v-for="(name, idx) in getImportIntegrations"
          :key="idx"
          :label="name"
          :value="name"
        >
          <img
            v-if="name === 'Notion'"
            class="mr-2 imported-logo"
            src="~assets/notion-round.svg"
          />
          <img
            v-if="name === 'Zendesk'"
            class="mr-2 imported-logo"
            src="~assets/zendesk-round.svg"
          />
          {{ integrations[name].label }}
        </el-option>
      </el-select>
      <el-input
        :placeholder="
          $t(
            'knowledge.article-builder.side-bar.imported-articles.modal.search',
          )
        "
        v-model="toSearch"
        @input="onInput()"
        class="col px-0 search-article-input"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-close"
          @click="importArticle = false"
        ></i>
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
    </div>
    <div class="d-flex" v-if="selectedPlateform === 'Notion'">
      <el-input
        :placeholder="
          $t(
            'knowledge.article-builder.side-bar.imported-articles.modal.via-url',
          )
        "
        v-model="articleUrl"
        @keyup.enter.native="importArticleFromUrl"
        class="px-0 d-flex justify-content-center"
      >
      </el-input>
      <base-button-old
        outline
        size="sm"
        type="primary"
        class="import-url"
        @click="importArticleFromUrl"
        >{{
          $t(
            'knowledge.article-builder.side-bar.imported-articles.modal.url-button',
          )
        }}</base-button-old
      >
    </div>
    <div
      v-if="this.selectedPlateformArticles.articles.length !== 0"
      v-loading="loadingArticle"
    >
      <div
        v-for="(article, idx) in selectedPlateformArticles.articles"
        :key="idx"
        :label="article.title"
        :value="article.id"
      >
        <div class="container row m-0 p-0">
          <div class="my-card col p-0">
            <div class="card card-body" style="padding: 16px !important">
              <div class="container row p-0 mx-auto">
                <img
                  class="col-2 img-fluid"
                  src="~assets/article-import-color.svg"
                  style="max-width: 10%"
                />
                <small
                  class="col-9 text-left ml-0 my-auto ellipsis-text"
                  :class="{ 'draft-article': article.draft }"
                  >{{ article.title }}</small
                >
                <el-tooltip
                  v-if="article.draft"
                  :content="
                    $t(
                      'knowledge.article-builder.side-bar.imported-articles.modal.warning-tooltip',
                    )
                  "
                  placement="right"
                  class="group-tooltip pr-0"
                >
                  <font-awesome-icon
                    class="import-article-icon my-auto col"
                    :icon="['fad', 'exclamation-triangle']"
                    size="sm"
                  ></font-awesome-icon>
                </el-tooltip>
                <el-tooltip
                  v-else
                  :content="
                    $t(
                      'knowledge.article-builder.side-bar.imported-articles.modal.download-tooltip',
                    )
                  "
                  placement="right"
                  class="group-tooltip pr-0"
                >
                  <font-awesome-icon
                    @click="importArticleMayday(article.id)"
                    class="import-article-icon my-auto col"
                    :icon="['fad', 'download']"
                    size="sm"
                  ></font-awesome-icon>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center pt-2 pb-2">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="10"
          @current-change="selectNewPage"
          :total="countArticle"
        ></el-pagination>
        <!-- :current-page.sync="page_count" -->
      </div>
    </div>
    <div v-else class="container row" v-loading="loadingArticle">
      <p class="col mx-auto my-auto text-center pt-3 pb-3">
        <small>{{
          $t(
            'knowledge.article-builder.side-bar.imported-articles.modal.not-found',
          )
        }}</small>
      </p>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';

import debounce from 'lodash.debounce';

export default {
  name: 'imported-article',
  props: {
    display: Boolean,
  },
  computed: {
    ...mapGetters(['getImportIntegrations']),
  },
  data() {
    return {
      toSearch: '',
      integrations: {
        Salesforce: {
          label: 'Salesforce (Alpha)',
          value: 'Salesforce',
          source: 'salesforce',
        },
        Notion: {
          label: 'Notion (Beta)',
          value: 'Notion',
          source: 'notion',
        },
        Zendesk: {
          label: 'Zendesk',
          value: 'Zendesk',
          source: 'zendesk',
        },
      },
      importArticle: this.display,
      selectedArticleId: null,
      selectedPlateform: '',
      selectedPlateformArticles: { articles: [] },
      loadingArticle: true,
      page_count: 1,
      countArticle: 0,
      articleUrl: '',
    };
  },
  methods: {
    importArticleFromUrl() {
      const url = this.articleUrl;
      const regExp = /^.*(notion.so\/)(\w*\/)([^?]*)/;
      const match = url.match(regExp);

      if (match) {
        const id = match[3].split('-').pop();

        const externalId = `${
          match[3].split('-').length > 1 ? 'pages' : 'databases'
        }@${id}`;

        if (id.length === 32) {
          this.$emit('import-article', externalId, this.selectedPlateform);
          return (this.importArticle = false);
        }
      }
      return this.$message({
        message: this.$t(
          'knowledge.article-builder.side-bar.imported-articles.notification.error-url',
        ),
        type: 'error',
        center: true,
      });
    },
    onInput() {
      this.loadingArticle = true;
      if (this.toSearch === '') {
        this.getImportedArticles();
      } else {
        this.debounceSearchArticle();
      }
    },
    selectNewPage(pageNb) {
      this.getImportedArticles(pageNb);
    },
    debounceSearchArticle: debounce(function () {
      this.getImportedArticles(null, this.toSearch);
    }, 1000),
    modifyPlateform() {
      // this.selectedPlateform = newPlatform;
      this.getImportedArticles(this.page_count, this.toSearch);
    },
    importArticleMayday(id) {
      this.$emit('import-article', id, this.getSource(this.selectedPlateform));
      this.importArticle = false;
    },
    getImportedArticles(page = null, query = null) {
      const source = this.getSource(this.selectedPlateform);
      this.loadingArticle = true;
      this.$services.integrations
        .getImportedArticles(page, query, source)
        .then((res) => {
          this.selectedPlateformArticles = res;
          this.countArticle = this.selectedPlateformArticles.count;
          this.page_count = this.selectedPlateformArticles.page_count;

          this.loadingArticle = false;
          return res;
        })
        .catch((error) => {
          console.error(error);
          this.$message({
            message: 'Une erreur est survenue...',
            type: 'error',
            center: true,
          });
          this.loadingArticle = false;
        });
    },
    getSource(plateform) {
      return this.integrations[plateform].source;
    },
  },
  created() {
    this.getImportIntegrations.map((integration) => {
      return this.integrations[integration].label;
    });
    this.selectedPlateform = this.getImportIntegrations[0];
    this.loadingArticle = true;
    this.getImportedArticles();
  },

  watch: {
    importArticle() {
      if (!this.importArticle) this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.ellipsis-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.import-article-icon {
  color: $grey-4-mayday;
  max-width: 12%;
  cursor: pointer;
}

.import-article-icon:hover {
  color: $blue-mayday;
  font-size: 110%;
}

.import-article-modal {
  :deep() .modal-body {
    max-height: 680px;
    overflow: auto;
  }
}

.search-article-input {
  :deep() input {
    border: 0px;
    border-left: 1px solid $grey-4-mayday !important;
    border-radius: 0px 5px 0px 0px;
    height: 43px;
  }
}
.change-plateform {
  :deep() input {
    border: 0px;
    border-radius: 5px 5px 0px 0px;
  }
}

.change-plateform {
  :deep() .prefix {
    margin-top: 12px;
    margin-left: 3px;
  }
}

.change-plateform {
  :deep() .el-input__inner {
    margin-left: 5px;
  }
}

.change-plateform {
  :deep() input:focus {
    border: 0px !important;
    border-radius: 5px 5px 0px 0px;
  }
}

.modal-search-header {
  border-bottom: 1px solid $grey-4-mayday;
}

.search-article-input {
  :deep() .el-icon-close {
    cursor: pointer !important;
  }
}

.search-article-input {
  :deep() .el-icon-search {
    cursor: pointer !important;
  }
}

.my-card {
  :deep() .card-body {
    padding: 10px;
    border-radius: 0px !important;
  }
}

.draft-article {
  color: gre;
}
</style>
<style lang="scss" scoped>
.draft-article {
  color: $grey-5-mayday;
}

.imported-logo {
  width: 14px;
}

.selected-logo {
  width: 14px;
  margin-top: 14px !important;
}

.import-url {
  padding: 8px;
  margin: auto 0;
}
</style>
