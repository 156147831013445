<template>
  <div class="completely-hidden"></div>
</template>

<script>
export default {
  props: {
    locale: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      WIDGET_URL: 'https://documentation.mayday.fr/widget/md-selfcare.umd.js',
    };
  },
  methods: {
    loadScript() {
      if (window.location.pathname.includes('widget')) {
        return;
      }

      const scriptEl = document.createElement('script');
      scriptEl.setAttribute('src', this.WIDGET_URL);
      document.body.append(scriptEl);
    },

    initSelfcare() {
      if (window.location.pathname.includes('widget')) {
        return;
      }

      window.addEventListener('MdSelfcareLoaded', (e) => {
        const detail = e.detail;
        window.__MAYDAY_SELFCARE__ = detail;
        window.__MAYDAY_SELFCARE__.init({
          accessKey: '589aa1ac-970c-404b-8ee3-60b893af90ba',
          position: 'bottom-left',
          widgetOpen: true,
          hideWidgetToggle: true,
          locale: this.locale,
          widgetWrapperStyle: {
            top: '60px',
            left: '64px',
            bottom: '60px',
            height: 'calc(100vh - 118px)',
          },
        });

        setTimeout(() => {
          window.__MAYDAY_SELFCARE__.setWidgetOpeningState(true);
          window.__MAYDAY_SELFCARE__.updateLocale(this.locale);
        }, 500);
      });
    },
    toggleWidget(value) {
      if (!this.loaded) {
        this.loadScript();
        this.initSelfcare();
        this.loaded = true;
      } else if (window.__MAYDAY_SELFCARE__) {
        window.__MAYDAY_SELFCARE__.setWidgetOpeningState(value);
        window.__MAYDAY_SELFCARE__.updateLocale(this.locale);
      }
    },
    closeWidget() {
      if (this.loaded) {
        window.__MAYDAY_SELFCARE__.setWidgetOpeningState(false);
      }
    },

    cleanupScript() {
      const scriptEl = document.querySelector(
        `script[src="${this.WIDGET_URL}"]`,
      );
      if (scriptEl) {
        scriptEl.remove();
      }
      window.removeEventListener('MdSelfcareUpdated', this.initSelfcare);
    },
  },

  watch: {
    locale(newLocale) {
      if (this.loaded) {
        window.__MAYDAY_SELFCARE__.updateLocale(newLocale);
      }
    },
    open(newOpen) {
      this.toggleWidget(newOpen);
    },
  },

  beforeDestroy() {
    this.cleanupScript();
  },
};
</script>

<style scoped lang="scss">
.completely-hidden {
  display: none;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  width: 0;
  height: 0;
}
</style>
