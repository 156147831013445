<template>
  <div class="product-agora-documentation-side-bar">
    <menu-collapse class="parameters-collapse" title="Agora">
      <product-agora-documentation
        v-if="documentations"
        :documentations="documentations"
        context="side-bar"
      />
      <product-agora-documentation-error
        v-if="error"
        :error="error"
        @click:retry="$emit('click:retry')"
      />
    </menu-collapse>
  </div>
</template>

<script>
import MenuCollapse from '@/components/Collapse/MenuCollapse';
import ProductAgoraDocumentation from './ProductAgoraDocumentation.vue';
import ProductAgoraDocumentationError from './ProductAgoraDocumentationError.vue';

export default {
  name: 'product-agora-documentation-side-bar',
  props: {
    documentations: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Object,
    },
  },
  components: {
    MenuCollapse,
    ProductAgoraDocumentation,
    ProductAgoraDocumentationError,
  },
};
</script>
<style lang="scss" scoped>
.product-agora-documentation-side-bar {
  box-shadow: $shadow-lg-mayday;
  margin-top: 16px;
}
</style>
