import { render, staticRenderFns } from "./TemplatesNavigationList.vue?vue&type=template&id=58d60ee9&scoped=true&"
import script from "./TemplatesNavigationList.vue?vue&type=script&lang=js&"
export * from "./TemplatesNavigationList.vue?vue&type=script&lang=js&"
import style0 from "./TemplatesNavigationList.vue?vue&type=style&index=0&id=58d60ee9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58d60ee9",
  null
  
)

export default component.exports