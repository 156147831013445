<template>
  <div class="popover-step-mover">
    <div class="header p-2 popover-title">
      <slot name="title"></slot>
    </div>
    <div
      v-if="
        localState === 'loading' ||
        localState === 'over' ||
        localState === 'over-removed' ||
        localState === 'quit'
      "
      class="user-guiding"
    >
      <div
        v-if="localState === 'loading'"
        class="flex justify-content-center w-100"
      >
        <div class="icon-container">
          <font-awesome-icon
            :icon="['fal', 'spinner-third']"
            spin
            class="loader"
          />
        </div>
      </div>
      <div
        v-else-if="localState === 'over' || localState === 'over-removed'"
        class="flex justify-content-center align-items-center w-100 flex-column"
      >
        <div class="icon-container">
          <font-awesome-icon :icon="['fal', 'check']" class="loader" />
        </div>
        <div class="state-explanation mt-3">
          <slot name="state-over"></slot>
        </div>
      </div>
      <div
        v-else-if="localState === 'quit'"
        class="flex justify-content-center align-items-center w-100 flex-column"
      >
        <div class="icon-container">
          <font-awesome-icon
            :icon="['fal', 'exclamation-circle']"
            class="quit"
          />
        </div>
        <div class="state-explanation mt-3 text-center bold">
          <slot name="state-cancel"></slot>
        </div>
        <div class="base-dialog-footer">
          <base-button
            class="mb-2"
            style="width: 100%"
            size="md"
            variant="danger"
            :text="stateCancelButtonQuit"
            @click="close()"
          />
          <base-button
            class="mb-2"
            style="width: 100%"
            size="md"
            variant="tertiary"
            :text="stateCancelButtonKeepGoing"
            @click="cancelQuit()"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="method-picker mt-2 w-100 px-2">
        <el-radio-group
          v-model="method"
          size="mini"
          class="w-100 d-flex align-items-center justify-content-center"
          @change="$emit('methodChange', $event)"
        >
          <el-radio-button
            v-for="button in radioButtons"
            :key="button.label"
            :label="button.label"
            class="w-50"
          >
            {{ $t(button.title) }}</el-radio-button
          >
        </el-radio-group>
      </div>
      <slot name="popover-content"></slot>
      <slot name="validation-buttons"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'child-button-options-popover',
  props: {
    radioButtons: {
      type: Array,
      default: () => [],
    },
    state: {
      type: String,
      default: '',
    },
    stateCancelButtonKeepGoing: {
      type: String,
      default: '',
    },
    stateCancelButtonQuit: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      method: this.radioButtons[0].label,
      localState: this.state,
    };
  },
  methods: {
    close() {
      this.$emit('close');
      this.localState = '';
    },
    cancelQuit() {
      this.$emit('cancel-quit');
      this.localState = '';
    },
  },
  watch: {
    state() {
      this.localState = this.state;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: $grey-1-mayday;
  border-bottom: 2px solid $grey-4-mayday;
  font-weight: bold;
}

.state-explanation {
  word-break: keep-all;
  padding-inline: 24px;
  padding-bottom: 20px;
}

:deep() .el-radio-button__inner {
  width: 100% !important;
  background-color: rgba($blue-mayday, 0.1);
  border-color: $blue-mayday !important;
}

.user-guiding {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  color: $blue-mayday;
}

.quit {
  color: $red-6-mayday;
}

.icon-container {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 42px;
  width: 48px;
  height: 48px;
}

.base-dialog-footer {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}

.cancel-button {
  background-color: $grey-2-mayday;
  border: none;
  &:hover {
    color: $blue-mayday !important;
    background-color: $grey-2-mayday !important;
    border: none;
  }
}
</style>
