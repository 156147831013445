<template>
  <div>
    <el-popover placement="top" trigger="manual" v-model="showPopover">
      <div slot="reference" class="cascader-container">
        <StepMoverBreadcrumb
          v-if="method === 'cascader'"
          :parents="pathToDisplay"
          :contentParents="[]"
          @back-home="$emit('back-home')"
          @click:folder="breadcrumbNav($event)"
        />
        <div
          v-else
          class="search-section d-flex flex-row justify-content-between align-items-center px-2"
        >
          <div class="d-flex flex-row align-items-center">
            <div @click="$emit('prev')" class="prev-wrapper">
              <font-awesome-icon
                v-if="pathToDisplay.length > 1"
                :icon="['fal', 'chevron-left']"
                class="chevron"
              />
            </div>
            <div class="mx-2 content-icon">
              <img src="~assets/diag-icon-v2.svg" class="content-search-img" />
            </div>
            <TextEllipsis
              :text="pathToDisplay.slice(-1)[0].label"
              font-size="14px"
            />
          </div>
          <base-button
            icon="times"
            variant="tertiary"
            icon-only
            @click="$emit('back-search')"
          />
        </div>
        <div class="overflow">
          <div>
            <div
              class="d-flex align-items-center option"
              :class="{
                'is-focus': focusedOption && focusedOption.id == option.id,
                'is-forbidden': isForbidden(option.id),
              }"
              v-for="option in options"
              :key="option.id"
              @mouseenter="hoveredOptionId = option.id"
              @mouseleave="hoveredOptionId = null"
              @click.prevent="
                hasChildren(option) ? next(option) : focusOption(option)
              "
            >
              <el-tooltip
                :content="$t(tooltipMessage(option.id))"
                :disabled="!isForbidden(option.id)"
                theme="light"
                placement="bottom"
              >
                <div
                  class="w-100 d-flex align-items-center justify-content-between"
                >
                  <div class="pl-2">{{ option.label }}</div>
                  <div class="option-children-selector" v-if="option.caseId">
                    <font-awesome-icon
                      v-if="hoveredOptionId == option.id"
                      class="icon blue"
                      :icon="['fas', 'chevron-right']"
                    />
                  </div>
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import StepMoverBreadcrumb from './Breadcrumb/StepMoverBreadcrumb.vue';
import TextEllipsis from '@/components/TextEllipsis.vue';

export default {
  name: 'base-cascader',
  components: {
    StepMoverBreadcrumb,
    TextEllipsis,
  },
  props: {
    pathToDisplay: {
      type: Array,
    },
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '1', //String
            caseId: null, //String
            knowledge: 'knowledge1', //String
            label: 'Ma base', //String
          },
        ];
      },
    },
    forbiddenIds: {
      type: Object,
      default: () => {},
    },
    method: {
      type: String,
      default: 'cascader',
    },
    focusedOption: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showPopover: false,
      hoveredOptionId: null,
    };
  },
  computed: {
    isInit() {
      return !this.options[0].caseId;
    },
  },
  methods: {
    focusOption(option) {
      this.$emit('focus-option', option);
    },
    hasChildren(option) {
      if (
        (option.caseId || option.children.length) &&
        option.id !== this.forbiddenIds.childId
      ) {
        return true;
      }
      return false;
    },
    isForbidden(optionId) {
      return Object.values(this.forbiddenIds).includes(optionId);
    },
    tooltipMessage(optionId) {
      if (optionId === this.forbiddenIds.parent)
        return 'knowledge.actions.step-mover.forbidden-tooltip.parent';
      if (optionId === this.forbiddenIds.childId)
        return 'knowledge.actions.step-mover.forbidden-tooltip.same';
      else return 'knowledge.actions.step-mover.forbidden-tooltip.redirect';
    },
    getOptionIcon(caseId) {
      return caseId ? ['fas', 'folder'] : ['fad', 'books'];
    },
    next(option) {
      if (option.caseId) {
        this.$emit('focus-option', null);
      } else {
        if (!this.isForbidden(option.id)) {
          this.$emit('focus-option', option);
        } else {
          this.$emit('focus-option', null);
        }
      }
      this.$emit('next', option);
    },
    breadcrumbNav(optionIdx) {
      const option = this.pathToDisplay[optionIdx];
      if (
        (option.type === 'Diagnostic' || option.type === 'Step') &&
        !this.isForbidden(option.id)
      )
        this.focusOption(option);
      this.$emit('click:folder', optionIdx);
    },
  },
};
</script>

<style lang="scss" scoped>
.cascader-container {
  width: 100%;
  background: white;
  border-radius: 2px;
  color: $grey-8-mayday;
  font-weight: bold;
}
.cascader-header {
  padding: 14px 10px;
  background-color: $grey-2-mayday;

  &.is-init {
    color: $grey-6-mayday;
  }
}
.option {
  font-size: 12px;
  padding: 12px 10px;
  cursor: pointer;
  &.is-focus {
    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.4);
  }
}
.option:not(.is-forbidden):hover {
  color: $blue-mayday !important;
  background-color: $grey-1-mayday;
}
.icon {
  width: 16px;
  height: 16px;
  cursor: pointer;

  &__selector {
    border: 1px solid $blue-mayday;
    border-radius: 1px;
  }
}
.grey-6 {
  color: $grey-6-mayday;
}
.grey-5 {
  color: $grey-5-mayday;
}
.blue {
  color: $blue-mayday;
}
.red {
  color: $red-mayday;
}
.cascader-footer {
  padding: 8px 16px 8px 16px;
  background-color: $grey-2-mayday;
}
.instruction {
  padding: 12px 14px;
  font-weight: bold;
}
.overflow {
  height: 40vh;
  max-height: 200px !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.is-forbidden {
  color: $grey-5-mayday;
  background-color: $grey-1-mayday;
}
.breadcrumb-overflow {
  padding: 0px 10px;
}
.cancel-button {
  background-color: $grey-2-mayday;
  border: none;
  &:hover {
    color: $blue-mayday !important;
    background-color: $grey-2-mayday !important;
    border: none;
  }
}

.search-section {
  height: 38px;
  background-color: $grey-2-mayday;
}

.prev-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.75px;
  height: 8.5px;
}

.prev-wrapper:hover {
  color: $blue-mayday;
  cursor: pointer;
}
.chevron {
  width: 80%;
  height: 100%;
}
</style>
