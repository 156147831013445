<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="element-title">
        {{ $t('settings.academy.module-labels.title') }}
      </div>
      <base-button
        variant="primary"
        size="sm"
        class="mb-1"
        :disabled="moduleLabelsCategories === null"
        @click="newCategory = true"
        :text="$t('settings.academy.module-labels.cta')"
      ></base-button>
    </div>
    <div class="element-description">
      {{ $t('settings.academy.module-labels.description') }}
    </div>
    <div v-if="success">
      <!-- EMPTY STATE -->
      <div
        class="empty-state-module-labels"
        v-if="!moduleLabelsCategories.length && !newCategory"
      >
        {{ $t('settings.academy.module-labels.empty-state') }}
      </div>

      <ModuleLabelsSettingsTable
        class="mt-2"
        v-loading="moduleLabelsCategoriesIsLoading"
        :module-labels-categories="moduleLabelsCategories"
        @delete-category="confirmDeleteCategory"
        @update-category="updateCategory"
        @update-label-title="updateLabelTitle"
        @update-label-icon="updateLabelIcon"
        @create-label="createLabel"
        @delete-label="deleteLabel"
      />

      <NewModuleLabelsSettings
        v-if="newCategory"
        @create-category="addNewCategory"
        @cancel-category="newCategory = false"
      />
    </div>
    <!-- FAILED RETRY BUTTON -->
    <div
      v-else
      class="d-flex justify-content-center mt-2"
      v-loading="moduleLabelsCategoriesIsLoading"
    >
      <base-button
        variant="primary"
        size="md"
        @click="getCategories"
        :text="$t('settings.reload')"
      ></base-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NewModuleLabelsSettings from './NewModuleLabelsSettings';
import ModuleLabelsSettingsTable from './ModuleLabelsSettingsTable';

export default {
  name: 'ModuleLabelsSettings',
  components: {
    NewModuleLabelsSettings,
    ModuleLabelsSettingsTable,
  },
  props: {
    generalSuccess: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.getCategories();
  },
  data() {
    return {
      localSuccess: true,
      newCategory: false,
    };
  },
  computed: {
    success() {
      return this.generalSuccess && this.localSuccess;
    },
    ...mapGetters('academyModule', [
      'moduleLabelsCategories',
      'moduleLabelsCategoriesIsLoading',
    ]),
  },
  methods: {
    /** RETRY */
    async getCategories() {
      this.localSuccess = await this.getModuleLabelsCategories();
    },

    async addNewCategory({ title, icon }) {
      this.newCategory = false;
      this.localSuccess = await this.createModuleLabelCategory({
        title,
        icon,
      });
    },

    /** DELETE CATEGORY */
    async confirmDeleteCategory(id) {
      if (!id) return;
      this.localSuccess = await this.deleteModuleLabelCategory(id);
    },

    /** UPDATE CATEGORY */
    async updateCategory({ id, key, value }) {
      if (key === 'title') {
        this.localSuccess = this.updateModuleLabelCategoryTitle({
          id,
          title: value,
        });
      } else if (key === 'icon') {
        this.localSuccess = await this.updateModuleLabelCategoryIcon({
          id,
          icon: value,
        });
      }
    },

    /** ADD NEW LABEL */
    async createLabel({ title, icon, parentId }) {
      this.localSuccess = await this.createModuleLabel({
        title,
        icon,
        parentId,
      });
    },

    /** UPDATE LABEL */
    async updateLabelTitle({ id, title, parentId }) {
      this.localSuccess = await this.updateModuleLabelTitle({
        id,
        title,
        parentId,
      });
    },

    async updateLabelIcon({ id, icon, parentId }) {
      this.localSuccess = await this.updateModuleLabelIcon({
        id,
        icon,
        parentId,
      });
    },

    /** DELETE LABEL */
    async deleteLabel({ id, parentId }) {
      this.localSuccess = await this.deleteModuleLabel({
        id,
        parentId,
      });
    },
    ...mapActions('academyModule', [
      // CATEGORIES
      'getModuleLabelsCategories',
      'createModuleLabelCategory',
      'updateModuleLabelCategoryTitle',
      'updateModuleLabelCategoryIcon',
      'deleteModuleLabelCategory',
      // LABELS
      'createModuleLabel',
      'deleteModuleLabel',
      'updateModuleLabelTitle',
      'updateModuleLabelIcon',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
.empty-state-user-labels {
  margin-left: 20px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  .empty-state-labels-img {
    cursor: pointer;
    max-width: 40px;
    margin-right: 16px;
  }
}
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon-title {
    color: black;
    margin-right: 1px;
  }
}
.settings-card-header-description {
  color: $grey-6-mayday;
  font-size: 80%;
}
.label-item-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.label-category {
  margin-top: 8px;
}
.new-category-input {
  margin-top: 24px;
}
</style>
