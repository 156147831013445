<template>
  <div
    v-loading="loading"
    class="stats-card-container"
    :class="leftBorder ? 'stat-border-left' : ''"
  >
    <header class="stats-header">
      <AnalyticsTitle
        v-if="showTitle"
        :title="title"
        :tooltip-content="tooltipContent"
      />
      <div><slot name="header-right"></slot></div>
    </header>
    <div class="stat-cards-wrapper" :class="flex ? 'flex-wrapper' : ''">
      <div
        v-for="(stat, idx) in stats"
        :key="`stats-card-${stat.name}-${idx}`"
        class="stat-container"
      >
        <div
          class="stat-wrapper"
          :class="stat.noExtraSpace ? 'no-extra-space' : ''"
        >
          <div class="stat-content">
            <AnalyticsTitle
              v-if="shouldShowTooltip(stat)"
              :title="$t(stat.name)"
              :tooltip-content="$t(stat.tooltipContent)"
            >
              <template v-slot:title="{ title }">
                <h5 class="stat-name">{{ title }}</h5>
              </template>
              <template v-slot:tooltip-content>
                <slot
                  name="tooltip-content"
                  :content="stat.tooltipContent"
                ></slot>
              </template>
            </AnalyticsTitle>
            <h5 v-else class="stat-name">{{ $t(stat.name) }}</h5>
            <div class="stat-value-wrapper">
              <div class="stat-value-row-wrapper">
                <div v-if="statIsEmpty(stat)" class="empty-state-placeholder">
                  <div class="empty-state-text-container">
                    <h4 class="empty-state-label">
                      {{ $t('new-dashboard.empty-state.label') }}
                    </h4>
                    <p class="empty-state-text">
                      {{ $t('new-dashboard.empty-state.text-before-cta') }}
                      <a target="_blank" :href="computeExternalDocLink(stat)">{{
                        $t('new-dashboard.empty-state.cta-text')
                      }}</a>
                      {{ $t('new-dashboard.empty-state.text-after-cta') }}
                    </p>
                  </div>
                </div>
                <div v-else class="stat-value">
                  <span>
                    <slot
                      name="stat"
                      :stat="stat.stat"
                      :tooltip-stat="stat.tooltipStat"
                      >{{ stat.stat }}</slot
                    >
                  </span>
                  <el-tag
                    class="stat-badge"
                    v-if="shouldShowBadge(stat)"
                    :type="computeBadgeType(stat)"
                  >
                    <slot
                      v-if="stat.badgeSlot"
                      :name="stat.badgeSlot"
                      :additional-stat="stat.additionalStat"
                    ></slot>
                    <template v-else>
                      {{ stat.badgeValue || '' }}
                    </template>
                  </el-tag>
                  <span v-if="shouldShowStatTooltip(stat)">
                    <slot name="stat-tooltip" :tooltip-stat="stat.tooltipStat">
                    </slot>
                  </span>
                </div>
              </div>
              <slot
                v-if="shouldSendFooterSlot(stat)"
                :name="stat.footerSlot"
                :footer-stat="stat.footerStat"
              ></slot>
            </div>
          </div>
          <template v-if="!statIsEmpty(stat)">
            <font-awesome-icon
              v-if="stat.icon"
              class="stat-icon"
              :class="stat.iconColor"
              :icon="['fad', stat.icon]"
            ></font-awesome-icon>
            <slot
              v-else-if="stat.iconSlot && stat.iconSlot.length"
              :name="stat.iconSlot"
              :base-class="'custom-icon'"
              :additional-stat="stat.additionalStat"
            ></slot>
          </template>
          <img
            v-if="statIsEmpty(stat)"
            class="empty-state-icon"
            src="@/assets/empty-state-percent.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsTitle from '@/views/NewDashboard/AnalyticsTitle';

export default {
  name: 'analytics-stats-card',

  props: {
    title: {
      type: String,
      default: () => '',
    },

    tooltipContent: {
      type: String,
      default: () => '',
    },

    stats: {
      validator(stats) {
        const isArray = Array.isArray(stats);
        const isStatsValid =
          isArray &&
          stats.every((el) => {
            const baseValid =
              el.hasOwnProperty('name') &&
              typeof el.name === 'string' &&
              el.hasOwnProperty('stat') &&
              (typeof el.stat === 'number' || typeof el.stat === 'string');
            const footerSlotValid =
              (el.hasOwnProperty('footerSlot') &&
                typeof el.footerSlot === 'string' &&
                !!el.footerSlot.length) ||
              !el.hasOwnProperty('footerSlot');
            const badgeValid =
              (el.hasOwnProperty('badgeSlot') &&
                typeof el.badgeSlot === 'string' &&
                !!el.badgeSlot.length) ||
              !el.hasOwnProperty('badgeSlot');
            return baseValid && footerSlotValid && badgeValid;
          });

        return isArray && isStatsValid;
      },
    },

    leftBorder: {
      type: Boolean,
      default: false,
    },

    flex: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    externalDocUrl: {
      type: String,
      default: () => '',
    },
  },

  components: {
    AnalyticsTitle,
  },

  computed: {
    showTitle() {
      return this.title && !!this.title.length;
    },
  },

  methods: {
    shouldShowStatTooltip({ tooltipStat }) {
      return !!tooltipStat && Object.keys(tooltipStat).length;
    },
    shouldShowBadge({ badgeValue, badgeSlot }) {
      return (!!badgeValue && !!badgeValue.length) || !!badgeSlot;
    },

    computeBadgeType({ badgeType, badgeValue }) {
      if (badgeType) {
        return badgeType.length ? badgeType : 'success';
      }
      return Number(badgeValue) > 0 ? 'success' : 'error';
    },

    shouldShowTooltip({ tooltipContent }) {
      return tooltipContent != null && !!tooltipContent.length;
    },

    statIsEmpty({ isEmpty }) {
      return isEmpty != null && isEmpty;
    },

    shouldSendFooterSlot(stat) {
      return !this.statIsEmpty(stat) && stat.footerSlot != null;
    },

    computeExternalDocLink({ externalDocAnchor }) {
      return externalDocAnchor != null && externalDocAnchor.length
        ? `${this.externalDocUrl}${externalDocAnchor}`
        : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.stats-card-container {
  &.stat-border-left {
    border-left: 2px solid $grey-2-mayday;
  }

  .stat-cards-wrapper {
    margin-top: 16px;
    margin-bottom: 16px;
    &.flex-wrapper {
      display: flex;
      .stat-container {
        flex-basis: 20%;
        padding-right: 16px;
      }
      .stat-container:not(:first-child) {
        border-left: 2px solid $grey-2-mayday;
        padding-left: 16px;
      }
    }
  }

  .empty-state-icon {
    width: 56px;
    height: 56px;
    display: block;
    align-self: flex-end;
  }

  .stat-container {
    margin-top: 16px;

    .stats-header {
      display: flex;
    }
    .stat-wrapper {
      display: flex;
      &:not(.no-extra-space) {
        justify-content: space-between;
      }
      align-items: center;

      .stat-name {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: $grey-8-mayday;
        margin-bottom: unset;
      }

      .stat-value-wrapper {
        height: 50px;

        %base-flex {
          width: 100%;
          display: flex;
          align-items: center;
        }

        .empty-state-placeholder {
          @extend %base-flex;
          padding-top: 8px;
          gap: 16px;

          .empty-state-label {
            font-family: Lato;
            margin-bottom: 4px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: black;
          }

          .empty-state-text {
            font-family: Lato;
            margin-bottom: 4px;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 10px;
            letter-spacing: 0.02em;
            color: $grey-6-mayday;
          }
        }

        .stat-value {
          @extend %base-flex;

          > span:not(.empty-state) {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            line-height: 38px;
            letter-spacing: 0.02em;
            color: black;
          }

          .stat-badge {
            border-radius: 100%;
            height: 20px;
            width: 20px;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            padding: 2px;

            &.el-tag--error {
              background: rgba($red-mayday, 0.1);
              color: $red-mayday;
              border: 1px solid $red-mayday;
            }

            &.el-tag--success {
              color: $green-mayday;
              border: 1px solid $green-mayday;
            }

            &.el-tag--flat {
              background: rgba($yellow-mayday, 0.5);
              color: $yellow-mayday;
              border: 1px solid $yellow-mayday;
            }
          }
        }
      }

      .stat-icon {
        color: $grey-5-mayday;
      }
      .blue {
        color: $blue-mayday;
      }

      .stat-icon,
      .custom-icon {
        width: 40px;
        height: 40px;
      }
    }
  }
}
</style>
