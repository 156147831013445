<template>
  <div>
    <modal :show.sync="localDisplay" :closeIcon="false">
      <modal-header
        :style-name="{ backgroundColor: currentStep.themeColor }"
        class="mb-3"
      >
        <div slot="icon" class="d-inline-flex">
          <IconWrapper :icon="currentStep.icon" :size="32" />
        </div>
        <div>
          <p class="mt-3 mb-0 font-weight-bold">
            {{ currentStep.title }}
          </p>
        </div>
      </modal-header>
      <div class="input-label">{{ currentStep.inputLabel }}</div>
      <div v-show="currentStepType === 'Home'">
        <div class="row justify-content-around">
          <button
            class="contribution-button col-3 text-center"
            @click.prevent="currentStepType = 'Collection'"
          >
            <img :src="steps.Collection.icon.value" class="custom-icon" />
            <h4 class="mt-2 mb-0 text-small">{{ steps.Collection.name }}</h4>
          </button>

          <button
            class="contribution-button col-3 text-center"
            @click.prevent="currentStepType = 'Diagnostic'"
          >
            <img :src="steps.Diagnostic.icon.value" class="custom-icon" />
            <h4 class="mt-2 mb-0 text-small">{{ steps.Diagnostic.name }}</h4>
          </button>

          <button
            class="contribution-button col-3 text-center"
            @click.prevent="currentStepType = 'Article'"
          >
            <img :src="steps.Article.icon.value" class="custom-icon" />
            <h4 class="mt-2 mb-0 text-small">{{ steps.Article.name }}</h4>
          </button>
        </div>
      </div>
      <div class="text-center" v-show="currentStepType !== 'Home'">
        <div>
          <transition name="slide-x-right-fade">
            <IconWrapper
              v-if="currentStepType === 'Collection'"
              class="mt-3"
              :icon="currentStep.icon"
              :size="42"
            />
          </transition>
          <IconWrapper
            v-if="currentStepType === 'Diagnostic'"
            class="mt-3"
            :icon="currentStep.icon"
            :size="42"
          />
          <transition name="slide-x-left-fade">
            <IconWrapper
              v-if="currentStepType === 'Article'"
              class="mt-3"
              :icon="currentStep.icon"
              :size="42"
            />
          </transition>
        </div>
        <input
          ref="input"
          type="text"
          class="mt-2 col-8 text-center input-alternative"
          v-model="input"
          @keyup.enter="handleItem"
        />
      </div>
      <!-- TEMPLATES -->

      <div v-show="currentStepType === 'Home'">
        <el-divider class="options-divider">
          {{ $t('knowledge.templates.import.or') }}
        </el-divider>
      </div>

      <div class="template-button-wrapper" v-show="currentStepType === 'Home'">
        <button
          class="template-button"
          @click.prevent="$emit('add-item-from-template')"
        >
          <div class="template-icon-wrapper">
            <font-awesome-icon
              class="template-icon"
              :icon="['fas', 'file-certificate']"
            />
          </div>
          <div class="template-info">
            <div class="title">
              {{ $t('knowledge.templates.import.options.title') }}
            </div>
            <div class="description">
              {{ $t('knowledge.templates.import.options.description') }}
            </div>
          </div>
        </button>
      </div>

      <div slot="footer" class="d-flex justify-content-end">
        <ModalActions
          :primaryEnabled="input.length > 0"
          :secondaryLabel="secondaryButtonLabel"
          @primary="handleItem"
          @secondary="currentStepType = 'Home'"
        />
      </div>
    </modal>
  </div>
</template>
<script>
import Modal from '@/components/Modal';
import ModalHeader from '@/components/Modals/ModalHeader';
import IconWrapper from '@/components/IconWrapper';
import ModalActions from '@/components/Modals/ModalActions';

// IMAGES
import folderIcon from '@/assets/open-folder.svg';
import articleIcon from '@/assets/article-icon-v2.1.svg';
import diagIcon from '@/assets/diag-icon-v2.1.svg';

import {
  blueMayday,
  grey6Mayday,
  grey1Mayday,
  yellow5Mayday,
} from '@/assets/scss/_colors.scss';

export default {
  name: 'add-collection-item-modal',
  components: {
    Modal,
    ModalHeader,
    IconWrapper,
    ModalActions,
  },
  props: {
    display: Boolean,
  },
  computed: {
    currentStep() {
      return this.steps[this.currentStepType];
    },
    secondaryButtonLabel() {
      return this.currentStepType !== 'Home'
        ? this.$tc('knowledge.modals.actions.previous')
        : '';
    },
  },
  data() {
    return {
      localDisplay: this.display,
      input: '',
      templateId: null,
      steps: {
        Home: {
          name: '',
          icon: {
            iconType: 'fa',
            value: 'plus',
            color: grey6Mayday,
          },
          themeColor: grey1Mayday,
          title: this.$tc('knowledge.modals.add-item.home.title'),
          inputLabel: this.$tc('knowledge.modals.add-item.home.input-label'),
        },
        Collection: {
          name: this.$tc('knowledge.modals.add-item.collection.name'),
          icon: {
            iconType: 'url',
            value: folderIcon,
            color: null,
          },
          themeColor: grey1Mayday,
          title: this.$tc('knowledge.modals.add-item.collection.title'),
          inputLabel: this.$tc(
            'knowledge.modals.add-item.collection.input-label',
          ),
        },
        Diagnostic: {
          name: this.$tc('knowledge.modals.add-item.diagnostic.name'),
          icon: {
            iconType: 'url',
            value: diagIcon,
            color: null,
          },
          themeColor: blueMayday + '1A',
          title: this.$tc('knowledge.modals.add-item.diagnostic.title'),
          inputLabel: this.$tc(
            'knowledge.modals.add-item.diagnostic.input-label',
          ),
        },
        Article: {
          name: this.$tc('knowledge.modals.add-item.article.name'),
          icon: {
            iconType: 'url',
            value: articleIcon,
            color: null,
          },
          themeColor: yellow5Mayday + '1A',
          title: this.$tc('knowledge.modals.add-item.article.title'),
          inputLabel: this.$tc('knowledge.modals.add-item.article.input-label'),
        },
      },
      currentStepType: 'Home',
    };
  },
  methods: {
    handleItem() {
      if (this.input) {
        this.$emit('add-item', {
          type: this.currentStepType,
          label: this.input,
        });
      }
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
    currentStepType(val) {
      if (val !== 'Home') {
        this.input = '';
        this.$nextTick(() => this.$refs.input.focus());
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-icon {
  width: 42px;
  height: 42px;
}
.contribution-button {
  background-color: transparent;
  border: 1px solid transparent;
  padding-top: 16px;
  padding-bottom: 10px;
  &:hover {
    background-color: $grey-1-mayday;
    border: 1px solid $grey-3-mayday;
    border-radius: 4px;
  }
}
.input-label {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}
.input-alternative {
  box-shadow: none;
  border: 0;
}
.slide-x-left-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-x-left-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-x-left-fade-enter,
.slide-x-left-fade-leave-to {
  transform: translateX(150px);
}
.slide-x-right-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-x-right-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-x-right-fade-enter,
.slide-x-right-fade-leave-to {
  transform: translateX(-150px);
}

.options-divider {
  width: 50%;
  margin: 16px auto;
  text-transform: uppercase;
  color: $grey-5-mayday;
}

.template-button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.template-button {
  display: flex;
  margin-top: 8px;
  gap: 12px;
  width: 100%;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 8px 16px;
  &:hover {
    background-color: $grey-1-mayday;
    border: 1px solid $grey-3-mayday;
    border-radius: 4px;
  }

  .template-icon-wrapper {
    border: 2px solid $grey-2-mayday;
    background-color: white;
    color: $grey-7-mayday;
    border-radius: 4px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    .template-icon {
      width: 20px;
      height: 20px;
    }
  }

  .template-info {
    display: flex;
    flex-direction: column;
    text-align: left;
    .title {
      font-size: 18px;
    }
    .description {
      font-size: 14px;
      color: $grey-5-mayday;
    }
  }
}
</style>
