<template>
  <aside class="col-2 justify-content-center product__slider">
    <div v-if="!emptyState" class>
      <div class="sticky-top">
        <div class="d-flex justify-content-center align-items-center pt-2">
          <font-awesome-icon
            class="trigger-slideshow"
            :icon="['fal', 'chevron-left']"
            @click="goToPreviousSlide"
          />
          <p class="mb-0 px-2">{{ imageIndexBis + 1 }}/{{ imageCount + 1 }}</p>
          <font-awesome-icon
            class="trigger-slideshow"
            :icon="['fal', 'chevron-right']"
            @click="goToNextSlide"
          />
        </div>
        <div @click="openCarouselModal" class="product-viewer-aside__carousel">
          <b-carousel
            id="carousel-no-animation"
            style="text-shadow: 0px 0px 2px black"
            :interval="0"
            no-animation
            img-width="1024"
            img-height="1024"
            ref="carousel"
            v-model="imageIndexBis"
          >
            <b-carousel-slide
              v-for="(image, id) in images"
              :key="id"
              :img-alt="contentTitle"
              :img-src="image.medium"
            ></b-carousel-slide>
          </b-carousel>
        </div>
        <TableOfContents v-if="routes" :routes="routes" />
      </div>
    </div>
    <div v-else>
      <div class="sticky-top">
        <img src="@/assets/empty-state-image-v2.svg" alt="No product image" />
        <TableOfContents v-if="routes" :routes="routes" />
      </div>
    </div>
  </aside>
</template>

<script>
import TableOfContents from '@/components/Commons/TableOfContents';

export default {
  name: 'product-viewer-aside',
  components: { TableOfContents },
  props: {
    contentTitle: {
      type: String,
    },
    images: {
      type: Array,
    },
    imageIndex: {
      type: Number,
      required: true,
    },
    slideShow: {
      type: Boolean,
      required: true,
    },
    routes: {
      type: Array,
    },
  },
  data() {
    return {
      imageIndexBis: this.imageIndex ? this.imageIndex : 0,
      imageCount: this.images ? this.images.length - 1 : 0,
      emptyState: this.images.length === 0,
    };
  },
  methods: {
    openCarouselModal() {
      this.$emit('open-carousel');
    },
    async goToPreviousSlide() {
      await this.$refs.carousel.prev();
      this.$emit('update-image-index', this.imageIndexBis);
    },
    async goToNextSlide() {
      await this.$refs.carousel.next();
      this.$emit('update-image-index', this.imageIndexBis);
    },
  },
};
</script>

<style lang="scss" scoped>
.trigger-slideshow {
  cursor: pointer;
  width: 16px;
  height: 16px;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.product-viewer-aside__carousel {
  cursor: pointer;
  width: 100%;
  height: auto;
}
</style>
