<template>
    <base-toast
      :key="type"
      :description="content.description"
      :progress='progressProps'
      :title="content.title"
      :type="type"
      use-emit
      @close="$emit('close')"
    />
</template>
<script>

export default {
  name: 'PDFExportToast',
  props: {
    type: String,
    contentLabel: String,
  },
  data: () => ({
    percentage: 0,
    loadingInterval: null,
    closeTimeout: null,
  }),
  computed: {
    progressProps() {
      return JSON.stringify({
        percentage: this.percentage,
        fileName: `${this.contentLabel}.pdf`,
      })
    },
    content() {
      if(this.type === 'success') {
        return {
          title: this.$t('knowledge.actions.export.success'),
          description: null
        };
      }
      if(this.type === 'error') {
        return {
          title: this.$t('knowledge.actions.export.error'),
          description: null,
        };
      }
      return {
        title: this.$t('knowledge.actions.export.info'),
        description: this.$t('knowledge.actions.export.info-description'),
      };
    }
  },
  mounted() {
    this.loadingInterval = setInterval(() => {
      this.percentage += Math.floor(Math.random() * 10) + 1;
      if(this.percentage >= 99) {
        clearInterval(this.loadingInterval);
      }
    }, 2000);
  },
  beforeDestroy() {
    clearInterval(this.loadingInterval);
    clearTimeout(this.closeTimeout);
  },
  watch: {
    type: {
      handler: function(val) {
        if(val === "success" || val === "error") {
          clearInterval(this.loadingInterval);
          this.closeTimeout = setTimeout(() => {
            this.$emit('close');
          }, 5000);
        }
      },
      immediate: true
    }
  }
};
</script>

<style lang='scss' scoped>
</style>