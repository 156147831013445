<template>
  <div class="row d-flex justify-content-between">
    <h1
      class="col-11 display-3 content-main-title"
      v-if="!editable || !edit"
      @dblclick="triggerLabelInput"
    >
      {{ label }}
    </h1>
    <div class v-else>
      <input
        v-autowidth="{
          maxWidth: '700px',
          minWidth: '300px',
          comfortZone: 0,
        }"
        class="col-11 title-input display-3"
        placeholder="Nom de la nouvelle étape"
        :maxlength="inputMaxLength"
        ref="newLabelEdition"
        v-model="newLabel"
        @blur="updateLabel()"
        @keyup.enter="$event.target.blur()"
        @input="notifyUserLength"
      />
    </div>
    <div class="col-1 d-flex align-items-center" v-if="editable && !edit">
      <el-tooltip :content="$t('knowledge.actions.rename')" placement="top">
        <base-button
          icon="pen"
          variant="tertiary"
          size="sm"
          icon-only
          @click="triggerLabelInput"
        />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'node-title-editor',
  props: {
    label: {
      type: String,
      default: '',
    },
    nodeType: String,
    groupReviewers: Array,
    content: Object,
    editable: Boolean,
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false,
      newLabel: '',
      maxCharacter: 35,
      isModalOpen: false,
    };
  },
  computed: {
    inputMaxLength() {
      if (this.nodeType === 'Folder') return this.maxCharacter;
      return undefined;
    },
    ...mapGetters(['isParametric']),
  },
  methods: {
    updateLabel() {
      if (this.newLabel !== this.label) {
        this.$emit('update-label', {
          label: this.newLabel,
          title: this.newLabel,
        });
      }
      this.edit = false;
      this.newLabel = '';
    },
    triggerLabelInput() {
      this.newLabel = this.label;
      this.edit = true;
      this.$nextTick(() => this.$refs.newLabelEdition.focus());
    },
    notifyUserLength() {
      if (this.inputMaxLength && this.newLabel.length === this.maxCharacter)
        this.$notify({
          title: 'Attention',
          message: 'Ne donnez pas des titres trop longs à vos collections',
          type: 'info',
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-main-title {
  line-height: 1.2;
  word-break: break-word;
}
.article-title:hover .edit-title {
  visibility: visible;
}

.diag-title:hover .edit-title {
  visibility: visible;
}

.folder-title:hover .edit-title {
  visibility: visible;
}

.edit-title {
  visibility: hidden;
}

.title-input {
  margin-bottom: 8px;
  line-height: 1.2;
}

.title-input:focus {
  outline: none;
  border: none;
}

.modify-icon {
  max-width: 20px;
  cursor: pointer;
  color: $grey-5-mayday;
  padding: 2px;
  &:hover {
    color: $blue-mayday;
  }
}

.modify-icon:hover {
  border-radius: 3px;
  background-color: $grey-2-mayday;
}
</style>
