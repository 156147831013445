<template>
  <div class="product-characteristics-side-bar">
    <menu-collapse
      class="parameters-collapse"
      :title="$t('knowledge.product.characteristics')"
    >
      <h6 class="text-uppercase mb-0">
        {{ $t('knowledge.product.product-characteristics') }}
      </h6>
      <h5 v-for="(characteristic, idx) in enrichedCharacteristics" :key="idx">
        <el-divider class="my-1"></el-divider>
        <font-awesome-icon
          class="collapse-parameter-icon mr-1"
          :icon="['fad', 'wrench']"
        />
        {{ characteristic }}
      </h5>
    </menu-collapse>
  </div>
</template>

<script>
import ProductMixin from 'mixins/KnowledgeMixin/ProductMixin';
import MenuCollapse from '@/components/Collapse/MenuCollapse';

export default {
  name: 'product-characteristic-side-bar',
  mixins: [ProductMixin],
  props: {
    enrichedCharacteristics: {
      type: Array,
      default: () => [
        'Compatible Wifi: OUI',
        'Compatible assistants vocaux: NON',
        'Mode de fermeture: Verouillage automatique',
        "Système d'exploitation: Android",
      ],
    },
  },
  components: {
    MenuCollapse,
  },
};
</script>
<style lang="scss" scoped>
.product-characteristics-side-bar {
  box-shadow: $shadow-lg-mayday;
  margin-top: 16px;
}
</style>
