<template>
  <div :class="['d-flex flex-row', { checked: checked }]">
    <div
      v-for="headerData in columns"
      :key="headerData.key"
      class="column-header"
      :style="headerData.style"
      @click="$emit('expand-user', rowData.id)"
    >
      <div
        v-if="!isArray(rowData[headerData.key])"
        class="content-wrapper d-flex align-items-center"
      >
        <div
          v-if="headerData.key === 'username'"
          class="d-flex align-items-center"
          @click.stop
        >
          <el-checkbox
            v-if="headerData.key === 'username'"
            :value="checked"
            @change="handleSelectUser"
            size="small"
            class="mr-1 pl-3 mb-0"
          ></el-checkbox>
          <div
            class="initials-wrapper"
            :style="colorStyle(rowData[headerData.key])"
          >
            <div class="initials">
              {{ getInitials(rowData[headerData.key]) }}
            </div>
          </div>
        </div>
        <div
          v-if="headerData.key === 'invitation'"
          class="flex items-center gap-2"
        >
          <div
            class="pending"
            :class="{
              expired: isExpired,
            }"
          >
            <div class="status">
              <font-awesome-icon
                :icon="['fal', isPending ? 'paper-plane' : 'clock']"
              />
              <div class="py-1">
                {{
                  $t(
                    `settings.permissions.pending-table.${rowData.invitationToken}`,
                  )
                }}
              </div>
            </div>
            <el-tooltip
              :content="invitationTooltip"
              placement="bottom-end"
              effect="light"
            >
              <font-awesome-icon
                :icon="['fal', 'info-circle']"
                class="text-gray text-xs"
              />
            </el-tooltip>
          </div>
          <div
            v-if="isExpired"
            class="icon-wrapper"
            @click.stop="$emit('renew-invite', [rowData.id])"
          >
            <font-awesome-icon
              class="user-actions-icon expand text-gray"
              :icon="['fal', 'sync']"
            />
          </div>
        </div>

        <div
          v-if="headerData.key !== 'action' && headerData.key !== 'invitation'"
          :class="[
            'content',
            { 'empty-case': !rowData[headerData.key] },
            { 'user-column': headerData.key === 'username' },
          ]"
          :ref="`${rowData.id}-${headerData.key}-content`"
        >
          <el-tooltip
            :content="
              rowData[headerData.key]
                ? rowData[headerData.key]
                : $t(
                    `settings.users-administration.empty-state.${headerData.key}`,
                  )
            "
            placement="bottom"
            :disabled="tooltip[headerData.key]"
            ><div class="overflow" :ref="`${rowData.id}-${headerData.key}`">
              {{
                rowData[headerData.key]
                  ? rowData[headerData.key]
                  : $t(
                      `settings.users-administration.empty-state.${headerData.key}`,
                    )
              }}
            </div></el-tooltip
          >
        </div>
      </div>
      <div v-else class="d-flex flex-row align-items-center content-wrapper">
        <div
          v-if="
            headerData.key === 'labels' && rowData[headerData.key].length > 0
          "
        >
          <label-tag
            class="label-tag"
            :name="rowData[headerData.key][0].name"
            :color="
              categorySettingsLookup[
                rowData[headerData.key][0].companyUserLabelCategoryId
              ].color
            "
            :icon="
              categorySettingsLookup[
                rowData[headerData.key][0].companyUserLabelCategoryId
              ].icon
            "
          />
        </div>
        <div v-else :class="[{ 'empty-case': !rowData[headerData.key][0] }]">
          {{
            rowData[headerData.key][0]
              ? rowData[headerData.key][0]
              : $t(
                  `settings.users-administration.empty-state.${headerData.key}`,
                )
          }}
        </div>
        <el-tooltip effect="light">
          <div slot="content">
            <div v-if="headerData.key === 'labels'" class="d-flex flex-column">
              <label-tag
                class="label-tag mb-1"
                v-for="label in rowData[headerData.key]"
                :key="label.id"
                :name="label.name"
                :color="
                  categorySettingsLookup[label.companyUserLabelCategoryId].color
                "
                :icon="
                  categorySettingsLookup[label.companyUserLabelCategoryId].icon
                "
              />
            </div>
            <div v-else>
              <div v-for="(el, index) in rowData[headerData.key]" :key="index">
                {{ el }}
              </div>
            </div>
          </div>
          <div
            v-if="rowData[headerData.key].length > 1"
            class="plus-display ml-3 px-1"
          >
            +{{ rowData[headerData.key].length - 1 }}
          </div>
        </el-tooltip>
      </div>
      <div v-if="headerData.key === 'action'">
        <div class="d-flex flex-row align-items-center justify-content-left">
          <div @click.stop="showDeleteUser = true">
            <div class="icon-wrapper">
              <font-awesome-icon
                :icon="['fal', 'trash']"
                class="user-actions-icon trash"
              ></font-awesome-icon>
            </div>
          </div>

          <div
            v-if="pending"
            class="icon-wrapper"
            @click.stop="$emit('copy-link', rowData.id)"
          >
            <font-awesome-icon
              class="user-actions-icon expand"
              :icon="['fal', 'clipboard']"
            ></font-awesome-icon>
          </div>
          <div
            v-if="pending"
            class="icon-wrapper"
            @click.stop="$emit('renew-invite', [rowData.id])"
          >
            <font-awesome-icon
              class="user-actions-icon expand"
              :icon="['fal', 'sync']"
            ></font-awesome-icon>
          </div>
          <div
            class="icon-wrapper"
            @click.stop="$emit('expand-user', rowData.id)"
          >
            <font-awesome-icon
              class="user-actions-icon expand"
              :icon="['fal', 'expand-alt']"
            ></font-awesome-icon>
          </div>
        </div>
      </div>
    </div>
    <BaseDialog
      v-if="showDeleteUser"
      :title="$tc(deleteUserDialog.title, 1)"
      :description="$tc(deleteUserDialog.description, 1)"
      :open="showDeleteUser"
      :confirmText="$t('generic.delete')"
      :cancelText="$t('generic.cancel')"
      variant="danger"
      @confirm="$emit('delete-user', { userIds: [rowData.id] })"
      @cancel="showDeleteUser = false"
      @close="showDeleteUser = false"
    >
    </BaseDialog>
  </div>
</template>

<script>
import LabelTag from '@/components/Settings/LabelTag';
import { mapGetters } from 'vuex';
import { userColor } from 'utils/utils';

import { blueMayday } from '@/assets/scss/_colors.scss';

export default {
  name: 'users-administration-table-row',
  props: {
    rowData: {
      type: Object,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LabelTag,
  },
  data() {
    return {
      tooltip: {
        email: true,
        username: true,
        group: true,
      },
      showDeleteUser: false,
    };
  },
  mounted() {
    this.columns.forEach((col) => {
      this.isTooltipDisabled(this.rowData.id, col.key);
    });
  },
  computed: {
    deleteUserDialog() {
      return {
        title: `settings.users-administration.drawer.main.confirm-title${
          this.pending ? '-pending' : ''
        }`,
        description: `settings.users-administration.drawer.main.confirm-description${
          this.pending ? '-pending' : ''
        }`,
      };
    },
    invitationTooltip() {
      if (this.rowData.isExpired)
        return `${this.$t('settings.users-administration.table.is-expired')} ${
          this.rowData.expirationDate
        }`;
      return `${this.$t(
        'settings.users-administration.table.expiration-date',
      )} ${this.rowData.expirationDate}`;
    },
    isPending() {
      return this.rowData.invitationToken === 'pending';
    },
    isExpired() {
      return this.rowData.invitationToken === 'expired';
    },
    ...mapGetters('adminModule', ['categorySettingsLookup']),
  },
  methods: {
    isArray(arg) {
      return typeof arg === 'object' && arg;
    },
    handleSelectUser(val) {
      this.$emit('handle-select-user', {
        operation: val,
        userId: this.rowData.id,
      });
    },
    isTooltipDisabled(id, key) {
      this.$nextTick(() => {
        const textEl = this.$refs[`${id}-${key}`];
        if (textEl) {
          this.tooltip[key] = textEl[0].scrollWidth <= textEl[0].clientWidth;
        }
      });
    },
    colorStyle(username) {
      return {
        background:
          username !== null && username !== '?'
            ? userColor(username, 70, 50)
            : blueMayday,
        color: 'white',
      };
    },
    getInitials(username) {
      const fullName = username.split(' ');
      let initials;
      if (fullName.length >= 2)
        initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      else initials = fullName.shift().charAt(0);
      return initials.toUpperCase();
    },
  },
  watch: {
    selectAll() {
      this.checked = this.selectAll;
    },
  },
};
</script>

<style lang="scss" scoped>
.plus-display {
  background-color: $grey-2-mayday;
  border-radius: 4px;
  height: 18px;
  font-size: 12px;
}
.content-wrapper {
  font-size: 14px;
  line-height: 17px;
}
.content {
  max-width: 100%;
}
.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-actions-icon {
  width: 12px;
  height: 12px;
  color: $grey-7-mayday;
}
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
    background-color: $grey-4-mayday;
    border-radius: 4px;

    .expand {
      color: $blue-mayday;
    }
    .trash {
      color: $red-mayday;
    }
  }
}

.checked {
  background-color: $grey-1-mayday;
}

.empty-case {
  color: $grey-6-mayday;
}

.user-column {
  max-width: 70%;
}

.initials-wrapper {
  width: 20px;
  height: 20px;

  border-radius: 2px;

  font-size: 12px;
  font-weight: 700;
  margin-right: 4px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

:deep() .el-checkbox__input {
  display: flex;
  align-items: center;
}

.pending {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
  padding: 0 6px;
  background: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  border-radius: 2px;
  margin: 0 auto;
}

.expired {
  background: rgba($red-mayday, 0.1);
  color: $red-mayday;
}

.status {
  display: flex;
  gap: 4px;
  align-items: center;
}
</style>
