<template>
  <div class="settings-container">
    <div class="settings-wrapper">
      <div class="settings-menu-wrapper">
        <SettingsMenu :data="menuData" />
      </div>
      <div class="settings-list-wrapper">
        <router-view :items="menuData" :success="success" />
      </div>
    </div>
  </div>
</template>
<script>
import SettingsMenu from 'components/Settings/SettingsMenu';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SettingsLayout',
  components: {
    SettingsMenu,
  },
  async created() {
    this.success = await this.getCompanyDetails();
  },
  data() {
    return {
      success: true,
    };
  },
  computed: {
    hasWorkflowActivated() {
      return this.hasCompanyPreferenceWithValue('WORKFLOW_ACTIVATED');
    },
    hasBrainFeatures() {
      return this.hasCompanyPreferenceLike('BRAIN_');
    },
    menuData() {
      return [
        {
          title: 'settings-layout.menu-data.general',
          icon: 'sliders-v',
          children: [
            {
              title: 'settings-layout.menu-data.kb-manage',
              name: 'generalSettings',
              hash: '#knowledges',
              icon: 'books',
              component: 'KnowledgeManageSettings',
            },
            {
              title: 'settings-layout.menu-data.alerts-notifications',
              name: 'generalSettings',
              hash: '#alerts',
              icon: 'bell',
              component: 'AlertsSettings',
            },
            {
              title: 'settings-layout.menu-data.collaborative-hub',
              name: 'generalSettings',
              hash: '#collaborative-hub',
              icon: 'sliders-v-square',
              component: 'HubSettings',
            },
          ],
        },
        {
          title: 'settings-layout.menu-data.integrations',
          icon: 'layer-group',
          children: [
            {
              title: 'settings-layout.menu-data.integration-list',
              name: 'integrationSettings',
              hash: '#catalog',
              icon: 'chart-network',
              component: 'IntegrationSettingsPage',
            },
          ],
        },
        {
          title: 'settings-layout.menu-data.plugins.title',
          icon: 'puzzle-piece',
          children: [
            {
              title: 'settings-layout.menu-data.plugins.plugins-catalog',
              name: 'pluginsCatalog',
              hash: '#plugin-catalog',
              component: 'PluginCatalog',
              icon: 'plug',
              props: {
                children: [
                  {
                    title:
                      'settings-layout.menu-data.plugins.company-favorites',
                    name: 'pluginsSetting',
                    hash: '#company-favorites',

                    component: 'PluginSetting',
                    props: {
                      label:
                        'settings-layout.menu-data.plugins.company-favorites.title',
                      description:
                        'settings-layout.menu-data.plugins.company-favorites.description',
                      activeText:
                        'settings-layout.menu-data.plugins.company-favorites.active-text',
                      disableText:
                        'settings-layout.menu-data.plugins.company-favorites.disable-text',
                      companyPreference: 'ALLOW_COMPANY_FAVORITES',
                      getter: 'companyAllowCompanyFavoritesPreference',
                      pluginType: 'CompanyFavoritesEntity',
                    },
                  },
                  {
                    title: 'settings-layout.menu-data.plugins.embedded-iframe',
                    name: 'pluginsSetting',
                    hash: '#embedded-iframe',

                    component: 'PluginSetting',
                    props: {
                      label:
                        'settings-layout.menu-data.plugins.embedded-iframe.title',
                      description:
                        'settings-layout.menu-data.plugins.embedded-iframe.description',
                      shortDescription:
                        'settings-layout.menu-data.plugins.embedded-iframe.short-description',
                      activeText:
                        'settings-layout.menu-data.plugins.embedded-iframe.active-text',
                      disableText:
                        'settings-layout.menu-data.plugins.embedded-iframe.disable-text',
                      companyPreference: 'ALLOW_EMBEDDED_IFRAME',
                      getter: 'companyAllowEmbeddedIframePreference',
                      advancedSettingsConfig: {
                        integrations: true,
                        title: true,
                        icon: true,
                        description: true,
                        url: true,
                      },
                      icon: 'code',
                      pluginType: 'EmbeddedIframeEntity',
                    },
                  },
                  {
                    title: 'settings-layout.menu-data.plugins.trending-content',
                    name: 'pluginsSetting',
                    hash: '#trending-content',

                    component: 'PluginSetting',
                    props: {
                      label:
                        'settings-layout.menu-data.plugins.trending-content.title',
                      description:
                        'settings-layout.menu-data.plugins.trending-content.description',
                      shortDescription:
                        'settings-layout.menu-data.plugins.trending-content.short-description',
                      activeText:
                        'settings-layout.menu-data.plugins.trending-content.active-text',
                      disableText:
                        'settings-layout.menu-data.plugins.trending-content.disable-text',
                      companyPreference: 'ALLOW_TRENDING_CONTENT',
                      getter: 'companyAllowTrendingContentPreference',
                      advancedSettingsConfig: {
                        integrations: true,
                      },
                      icon: 'chart-line',
                      pluginType: 'TrendingContentEntity',
                    },
                  },
                  {
                    title: 'settings-layout.menu-data.plugins.new-content',
                    name: 'pluginsSetting',
                    hash: '#new-content',

                    component: 'PluginSetting',
                    props: {
                      label:
                        'settings-layout.menu-data.plugins.new-content.title',
                      description:
                        'settings-layout.menu-data.plugins.new-content.description',
                      shortDescription:
                        'settings-layout.menu-data.plugins.new-content.short-description',
                      activeText:
                        'settings-layout.menu-data.plugins.new-content.active-text',
                      disableText:
                        'settings-layout.menu-data.plugins.new-content.disable-text',
                      companyPreference: 'DESK_NEW_CONTENTS_PLUGIN',
                      getter: 'companyAllowNewContentPreference',
                      advancedSettingsConfig: {
                        integrations: true,
                      },
                      icon: 'gift',
                      pluginType: 'NewContentEntity',
                    },
                  },
                  {
                    title: 'settings-layout.menu-data.plugins.smart-actions',
                    name: 'pluginsSetting',
                    hash: '#smart-actions',

                    component: 'PluginSetting',
                    props: {
                      label:
                        'settings-layout.menu-data.plugins.smart-actions.title',
                      description:
                        'settings-layout.menu-data.plugins.smart-actions.description',
                      shortDescription:
                        'settings-layout.menu-data.plugins.smart-actions.short-description',
                      activeText:
                        'settings-layout.menu-data.plugins.smart-actions.active-text',
                      disableText:
                        'settings-layout.menu-data.plugins.smart-actions.disable-text',
                      companyPreference: 'ALLOW_SMART_ACTIONS',
                      getter: 'companyAllowSmartActionsPreference',
                      advancedSettingsConfig: {
                        integrations: true,
                        title: true,
                        color: true,
                        subtypes: {
                          RedirectionSmartAction: {
                            label:
                              'settings-layout.menu-data.plugins.smart-actions.subtypes.redirection',
                            value: 'RedirectionSmartAction',
                            properties: {
                              url: true,
                            },
                          },
                        },
                        allowMultiple: true,
                      },
                      icon: 'external-link',
                      pluginType: 'SmartActionEntity',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          title: 'settings-layout.menu-data.permissions',
          icon: 'unlock',
          children: [
            {
              title: 'settings-layout.menu-data.users',
              name: 'usersSettings',
              hash: `#users`,
              icon: 'users',
              component: 'UsersSettings',
            },
            {
              title: 'settings-layout.menu-data.invitations',
              name: 'usersSettings',
              hash: `#pending-users`,
              icon: 'paper-plane',
              component: 'UsersSettings',
              props: {
                pending: true,
              },
            },
            {
              title: 'settings-layout.menu-data.groups',
              name: 'permissionsSettings',
              hash: `#groups`,
              icon: 'user-friends',
              component: 'GroupsSettingsMain',
            },
            {
              title: 'settings-layout.menu-data.roles',
              name: 'permissionsSettings',
              hash: `#roles`,
              icon: 'medal',
              component: 'RolesSettingsMain',
            },
            ...(this.companyAllowSsoSettings
              ? [
                  {
                    title:
                      'settings-layout.menu-data.authentication.sso-profile.title',
                    name: 'ssoProfile',
                    hash: `#sso-profiles`,
                    i18nPre: 'sso-profile',
                    icon: 'passport',
                    component: 'SsoProfileSettings',
                  },
                ]
              : []),
            {
              title: 'settings-layout.menu-data.labels',
              name: 'permissionsSettings',
              hash: `#labels`,
              icon: 'tags',
              component: 'LabelCategorySettings',
            },
          ],
        },
        {
          ...(this.hasBrainFeatures
            ? {
                title: 'settings-layout.menu-data.ai',
                children: [
                  {
                    title: 'settings-layout.menu-data.ai-settings.title',
                    name: 'aiSettings',
                    hash: `#ai-settings`,
                    icon: 'brain',
                    component: 'ConfigureAi',
                  },
                ],
              }
            : {}),
        },
        {
          title: 'settings-layout.menu-data.search',
          children: [
            {
              title: 'settings-layout.menu-data.synonyms',
              name: 'searchSettings',
              hash: '#search-settings',
              icon: 'search',
              component: 'SearchSettings',
              props: {
                children: [
                  {
                    title: 'settings-layout.menu-data.synonyms',
                    name: 'searchSettings',
                    icon: 'search',
                    component: 'SynonymsSettingsCard',
                  },
                  {
                    title: 'settings-layout.menu-data.exclusive-search.title',
                    name: 'searchSettings',
                    hash: '#exclusive-search',
                    icon: 'union',
                    component: 'SearchLogicSelect',
                    props: {
                      label: 'settings-layout.menu-data.exclusive-search.title',
                      description:
                        'settings-layout.menu-data.exclusive-search.description',
                      options: [
                        {
                          value: 'OR',
                          label:
                            'settings-layout.menu-data.exclusive-search.or',
                          description:
                            'settings-layout.menu-data.exclusive-search.or-description',
                          subDescription:
                            'settings-layout.menu-data.exclusive-search.or-sub-description',
                        },
                        {
                          value: 'AND',
                          label:
                            'settings-layout.menu-data.exclusive-search.and',
                          description:
                            'settings-layout.menu-data.exclusive-search.and-description',
                          subDescription:
                            'settings-layout.menu-data.exclusive-search.and-sub-description',
                        },
                      ],
                      placeholder:
                        'settings-layout.menu-data.exclusive-search.placeholder',
                      companyPreference: 'SEARCH_CATEGORIES_AND_FILTER',
                      getter: 'companyApplyExclusiveSearchPreference',
                    },
                  },
                  {
                    title: 'settings-layout.menu-data.search-boost.title',
                    name: 'searchSettings',
                    hash: '#search-boost',

                    component: 'PluginSetting',
                    props: {
                      label: 'settings-layout.menu-data.search-boost.title',
                      description:
                        'settings-layout.menu-data.search-boost.description',
                      activeText:
                        'settings-layout.menu-data.search-boost.active-text',
                      disableText:
                        'settings-layout.menu-data.search-boost.disable-text',
                      companyPreference: 'SEARCH_USER_CONSULTATION_BOOST',
                      getter: 'companySearchUserBoostPreference',
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          title: 'settings-layout.menu-data.parametric-knowledge',
          icon: 'project-diagram',
          children: [
            ...(this.isParametric
              ? [
                  {
                    title: 'settings-layout.menu-data.global-parameters',
                    name: 'knowledgesSettings',
                    hash: '#global',
                    icon: 'server',
                    component: 'GlobalParameters',
                  },
                ]
              : []),
            {
              title: 'settings-layout.menu-data.content-parameters',
              name: 'knowledgesSettings',
              hash: '#contents',
              icon: 'tags',
              component: 'ContentParameters',
            },
            ...(this.hasWorkflowActivated
              ? [
                  {
                    title: 'settings-layout.menu-data.context-variables',
                    name: 'knowledgesSettings',
                    hash: '#context-variables',
                    icon: 'code',
                    component: 'ContextVariables',
                  },
                ]
              : []),
          ],
        },
        ...(this.hasAcademy
          ? [
              {
                title: 'settings-layout.menu-data.academy',
                icon: 'graduation-cap',
                children: [
                  {
                    title: 'settings-layout.menu-data.module-labels',
                    name: 'academySettings',
                    hash: '#module-labels',
                    icon: 'graduation-cap',
                    component: 'ModuleLabelsSettings',
                  },
                ],
              },
            ]
          : []),
        {
          title: 'settings-layout.menu-data.security',
          icon: 'cog',
          children: [
            {
              title: 'settings-layout.menu-data.authentication.title',
              name: 'securitySettings',
              hash: '#security',
              icon: 'fingerprint',
              component: 'AuthenticationSettings',
              props: {
                children: [
                  {
                    title:
                      'settings-layout.menu-data.authentication.ip-restriction.title',
                    name: 'IpRestriction',
                    i18nPre: 'ip-restriction',
                    icon: 'fingerprint',
                    component: 'IpRestrictionSettings',
                  },
                ],
              },
            },

            ...(this.companyAllowSsoSettings
              ? [
                  {
                    title:
                      'settings-layout.menu-data.authentication.sso-mapping.title',
                    name: 'ssoAttributes',
                    hash: '#sso-attributes',
                    icon: 'network-wired',
                    component: 'AuthenticationSettings',
                    props: {
                      children: [
                        {
                          title:
                            'settings-layout.menu-data.authentication.sso-mapping.title',
                          name: 'ssoAttributes',
                          hash: '#sso-attributes',
                          i18nPre: 'sso-mapping',
                          icon: 'network-wired',
                          component: 'SsoAttributesMappingSettings',
                        },
                      ],
                    },
                  },
                ]
              : []),
          ],
        },
        {
          title: 'settings-layout.menu-data.mayday-account',
          icon: 'cog',
          children: [
            {
              title: 'settings-layout.menu-data.general-settings',
              name: 'companySettings',
              hash: '#company',
              icon: 'cogs',
              component: 'CompanySettings',
            },
          ],
        },
      ];
    },
    hasAcademy() {
      return this.companyAllowAcademyPreference && this.userAccess.academy;
    },
    ...mapGetters([
      'processedCompanyLanguages',
      'isParametric',
      'companyAllowCompanyFavoritesPreference',
      'companyAllowTrendingContentPreference',
      'companyAllowNewContentPreference',
      'companyAllowSmartActionsPreference',
      'companyAllowToastSoundPreference',
      'companyAllowAcademyPreference',
      'companyAllowSsoSettings',
      'hasCompanyPreferenceWithValue',
      'hasCompanyPreferenceLike',
      'userAccess',
    ]),
  },
  methods: {
    ...mapActions('adminModule', ['getCompanyDetails']),
  },
};
</script>

<style lang="scss" scoped>
.settings-container {
  width: 100%;
  height: 100%;
  padding-top: 24px;
  padding-left: 2px;
  display: flex !important;
  flex-direction: column;
  overflow: hidden;
}

.settings-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: stretch;
  background-color: white;
  border-top-left-radius: 16px;
  box-shadow: $shadow-sm-mayday;
  overflow: hidden;
}

.settings-menu-wrapper {
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px 32px;
  border-right: $grey-2-mayday 1px solid;
  flex: none;
}

.settings-list-wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
}
</style>
