<template>
  <div class="ask-skeleton-loader">
    <div class="loader-bar-wrapper">
      <span
        class="skeleton-loader-gradient"
        v-for="index in 3"
        :key="index"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ask-skeleton-loader',
  mounted() {
    document.addEventListener('keydown', this._keyListener, true);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener, true);
  },
  methods: {
    _keyListener(e) {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        this.$emit('cancel-ask');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-skeleton-loader {
  height: 100%;
  width: 100%;
}
.loader-bar-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skeleton-loader-gradient {
  width: 100%;
  height: 16px;
  border-radius: 4px;
}

span:nth-of-type(odd) {
  background: linear-gradient(
    to right,
    $purple-1-mayday,
    rgba($purple-5-mayday, 0.2) 50%,
    rgba($purple-5-mayday, 0.2) 80%
  );

  animation: grow 1.5s forwards infinite alternate;
}
span:nth-of-type(even) {
  background: linear-gradient(
      to right,
      $purple-1-mayday,
      rgba($purple-5-mayday, 0.2) 50% rgba($purple-5-mayday, 0.2) 80%
    ),
    rgba($purple-5-mayday, 0.2);
  animation: reveal 1.5s forwards infinite alternate;
}
@keyframes grow {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes reveal {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
</style>
