<template>
  <div>
    <div class="d-flex flex-column table mb-1" v-loading="loading" ref="table">
      <div
        class="d-flex flex-row align-items-center table-header sticky-top py-2"
      >
        <div
          v-for="headerData in columns"
          :key="headerData.key"
          class="column-header"
          :style="headerData.style"
        >
          <el-checkbox
            v-if="headerData.key === 'username'"
            v-model="selectAll"
            size="small"
            class="mr-1 mb-0 pl-3"
          ></el-checkbox>
          {{ $t(headerData.label) }}
        </div>
      </div>
      <div v-if="isFilteredAndEmpty" class="empty-filtered mt-3 rows">
        <div class="empty-msg">
          {{ $t('settings.users-administration.empty-state.empty-msg') }}
        </div>
        <img src="@/assets/empty-task.svg" alt="empty-task" class="empty-img" />
        <div class="empty-explanation">
          {{
            $t('settings.users-administration.empty-state.empty-explaination')
          }}
        </div>
        <base-button
          variant="primary"
          size="md"
          class="mt-3"
          :text="$t('settings.users-administration.empty-state.empty-button')"
          @click="$emit('empty-filters-search')"
        ></base-button>
      </div>
      <div v-else class="rows">
        <users-administration-table-row
          class="py-3 table-row"
          v-for="rowData in tableData"
          :key="rowData.id"
          :columns="columns"
          :rowData="rowData"
          :selectAll="selectAll"
          :checked="selectedUsers.includes(rowData.id)"
          :pending="pending"
          @expand-user="$emit('expand-user', $event)"
          @delete-user="$emit('delete-user', $event)"
          @handle-select-user="$emit('handle-select-user', $event)"
          @copy-link="$emit('copy-link', $event)"
          @renew-invite="$emit('renew-invite', $event)"
        ></users-administration-table-row>
      </div>
    </div>
    <div
      class="d-flex flex-row justify-content-between align-items-center table-footer px-3 py-2"
    >
      <div class="count">{{ contentSeen }} / {{ totalCount }}</div>
      <el-pagination
        background
        layout=" prev, pager, next"
        :total="totalCount"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="$emit('handle-change-page', $event)"
        :disabled="selectAll"
      >
      </el-pagination>
      <div class="d-flex">
        <el-select
          v-model="localPageSize"
          :disabled="selectAll"
          @change="$emit('change-page-size', $event)"
        >
          <el-option
            v-for="size in [15, 20, 50]"
            :key="size"
            :value="size"
            size="small"
          ></el-option>
        </el-select>
        <div v-loading="exportLoading" class="download-icon ml-2">
          <font-awesome-icon
            @click="$emit('export-users')"
            :icon="['fas', 'download']"
            class="download-icon ml-2"
          ></font-awesome-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsersAdministrationTableRow from './UsersAdministrationTableRow.vue';

export default {
  name: 'users-administration-table',
  components: {
    UsersAdministrationTableRow,
  },
  data() {
    return {
      localPageSize: '',
      selectAll: false,
    };
  },
  mounted() {
    this.localPageSize = this.pageSize;
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    currentPage: { type: Number, default: 1 },
    totalCount: { type: Number },
    pageSize: { type: Number, default: 10 },
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectedUsers: {
      type: Array,
      default: () => [],
    },
    isFilteredAndEmpty: {
      type: Boolean,
      default: false,
    },
    exportLoading: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    contentSeen() {
      return this.currentPage * this.pageSize > this.totalCount
        ? this.totalCount
        : this.currentPage * this.pageSize;
    },
  },
  watch: {
    selectAll() {
      this.$emit('select-all', this.selectAll);
    },

    selectedUsers() {
      if (this.selectAll && this.selectedUsers.length === 0)
        this.selectAll = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.column-header {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: $grey-8-mayday;
  display: flex;
  align-items: center;
}

.table {
  height: calc(100% - 33.7px);
  background-color: white;
}

.table-header {
  border-bottom: 1px solid $grey-4-mayday;
}

.rows {
  height: calc(100vh - 48px - 50px);
  background-color: white;
  overflow: auto;
  padding-bottom: 48px;
}

.table-row {
  background-color: white;
  border-bottom: 1px solid $grey-4-mayday;
  align-items: center;
  &:hover {
    background-color: $grey-1-mayday;
    cursor: pointer;
  }
}

.table-footer {
  background-color: $grey-1-mayday;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 60px;
  z-index: 50;
  bottom: 0;
}

.count {
  font-size: 12px;
}

.el-select {
  width: 60px;
  height: 24px;
}

:deep() .el-input {
  height: 100% !important;
}

:deep() .el-select .el-input .el-input__inner {
  height: 100%;
  padding: 0;
  padding-left: 10px;
}

.empty-filtered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.download-icon {
  &:hover {
    cursor: pointer;
    color: $blue-mayday;
  }
}
</style>
