<template>
  <div class="back-btn">
    <base-button
      @click="backToArchive"
      variant="tertiary"
      icon="chevron-left"
      size="md"
      :text="$t('knowledge.archives.back-to-archive')"
    >
    </base-button>
  </div>
</template>

<script>
export default {
  name: 'parametric-content-archive-back-button',
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    backToArchive() {
      this.$emit('back-to-archive');
    },
  },
};
</script>

<style lang="scss" scoped>
.back-btn {
  height: 68px;
  display: flex;
  align-items: center;
  padding-left: 24px;
}
</style>
