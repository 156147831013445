<template>
  <el-tooltip
    :disabled="disableTooltip || !hasPermissions || isPublished"
    :content="
      $t('knowledge.drawers.content-view-setting.settings.public.disabled')
    "
  >
    <el-radio
      :label="name"
      :disabled="disabled"
      :class="{ disabled: disabled }"
    >
      <div class="settings__radio-container" :class="{ checked: isChecked }">
        <div class="left">
          <div class="radio-icon">
            <font-awesome-icon :icon="['fal', icon]" />
          </div>
        </div>
        <div class="main">
          <div class="radio-title">{{ $t(trad + '.title') }}</div>
          <div class="radio-desc" :class="{ 'blue-mayday': isChecked }">
            {{ $t(trad + '.description') }}
          </div>
        </div>
        <div class="right">
          <div class="radio-icon">
            <font-awesome-icon
              v-if="isChecked"
              :icon="['fas', 'check-circle']"
            />
            <font-awesome-icon v-else :icon="['fal', 'circle']" />
          </div>
        </div>
      </div>
    </el-radio>
  </el-tooltip>
</template>

<script>
export default {
  name: 'content-view-settings-radio',
  props: {
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    disableTooltip: {
      type: Boolean,
      required: false,
    },
    isPublished: {
      type: Boolean,
      required: false,
    },
    hasPermissions: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    trad() {
      return `knowledge.drawers.content-view-setting.settings.radio.${this.name}`;
    },
    isChecked() {
      return this.name === this.checked;
    },
    disabled() {
      return !this.isPublished || !this.hasPermissions;
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  .settings__radio-container {
    border: 1px solid $grey-4-mayday !important;
    background-color: $grey-1-mayday !important;
    cursor: not-allowed !important;
    :deep() .radio-title,
    .radio-icon {
      color: $grey-6-mayday !important;
    }
    :deep() .radio-desc {
      color: $grey-5-mayday !important;
    }

    :deep() .left {
      background-color: rgba($grey-5-mayday, 0.1);
    }
  }
}
.settings__radio-container {
  display: flex;
  flex-direction: row;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  color: $unknown-blue-1-mayday;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  overflow: hidden;
  cursor: pointer;
  white-space: normal !important;
  background-color: white;
  transition: background-color 300ms ease;
  overflow: hidden;
  &:hover {
    border: 1px solid $blue-mayday;
    :deep() .left {
      color: $blue-mayday !important;
      background-color: rgba($blue-mayday, 0.1);
    }
  }
}

.left,
.right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  .radio-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    margin: 0px;
  }
}

.left {
  background-color: rgba($grey-6-mayday, 0.1);
  border-radius: 4px;
  color: $grey-6-mayday;
}

.main {
  width: 80%;
  padding: 4px 0;
  .radio-title {
    color: $unknown-blue-1-mayday !important;
    margin-bottom: 4px;
    font-weight: 700;
    font-size: 14px;
  }
  .radio-desc {
    color: $grey-7-mayday;
    font-size: 12px;
  }
  .blue-mayday {
    color: $blue-mayday !important;
  }
}

.checked {
  border: 1px solid $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  :deep() .left {
    color: $blue-mayday !important;
    background-color: rgba($blue-mayday, 0.1);
  }
}

label {
  width: 100%;
}

:deep() .el-radio {
  white-space: normal !important;
}

:deep() .el-radio__input {
  display: none;
}

:deep() .el-radio__label {
  padding: 0;
}
</style>
