<template>
  <div class="attribute-selection">
    <div class="collections">
      <div class="collection-select">
        <div class="collection-title-wrapper">
          {{ $t(`knowledge.bulk-actions.modal.${option}.addText`)
          }}<el-tooltip
            effect="dark"
            :content="$t(`knowledge.bulk-actions.modal.${option}.addTooltip`)"
            placement="top"
          >
            <font-awesome-icon
              :icon="['fal', 'info-circle']"
              class="ml-2 info-icon"
            />
          </el-tooltip>
        </div>
        <template>
          <slot name="add">
            <el-input
              :placeholder="
                $t(`knowledge.bulk-actions.modal.search`, { option })
              "
              v-model="searchAdd"
              class="mr-2 search"
              clearable
            >
              <i slot="prefix" class="el-input__icon"
                ><font-awesome-icon
                  :icon="['fal', 'search']"
                  class="search-icon"
                ></font-awesome-icon
              ></i>
            </el-input>
            <div
              v-if="!toCollapse"
              class="d-flex flex-row align-items-center select-all-remove"
            >
              <el-checkbox
                @change="handleSelectAllAdd($event)"
                class="mr-2 mb-0"
                v-model="selectAllAdd"
              ></el-checkbox>
              {{
                $t(
                  `knowledge.bulk-actions.${
                    selectAllAdd ? 'unselect-all' : 'select-all'
                  }`,
                )
              }}
            </div>
            <el-collapse
              :class="
                toCollapse ? 'categories-collapse' : 'categories-no-collapse'
              "
              v-model="activeCategories"
            >
              <el-collapse-item
                v-for="category in Object.keys(optionsToDisplay)"
                :key="category"
                :name="category"
                :title="category"
              >
                <template slot="title">
                  <div class="d-flex flex-row align-items-center title-wrapper">
                    <el-checkbox
                      @change="handleSelectCategory($event, category)"
                      class="mr-2 mb-0"
                      v-model="selectAll[category]"
                    ></el-checkbox>
                    <TextEllipsis :text="category" />
                  </div>
                </template>
                <el-checkbox-group
                  v-model="add"
                  @change="$emit('add', $event)"
                  class="d-flex flex-column checkbox-group"
                  :class="{ 'checkbox-group__to-collapse': toCollapse }"
                >
                  <el-checkbox
                    v-for="item in optionsToDisplay[category]"
                    :key="item.id"
                    :label="item.id"
                    class="checkbox-item"
                  >
                    <ContentListItemFullLabel
                      v-if="item.labelName"
                      :key="item ? item.id : ''"
                      :label="item"
                    />
                    <div v-else>{{ item[labelKey] }}</div>
                  </el-checkbox>
                </el-checkbox-group></el-collapse-item
              >
            </el-collapse>
          </slot>
        </template>
      </div>
      <div class="collection-select">
        <div class="collection-title-wrapper">
          {{ $t(`knowledge.bulk-actions.modal.${option}.removeText`)
          }}<el-tooltip
            effect="dark"
            :content="
              $t(`knowledge.bulk-actions.modal.${option}.removeTooltip`)
            "
            placement="top"
          >
            <font-awesome-icon
              :icon="['fal', 'info-circle']"
              class="ml-2 info-icon"
            />
          </el-tooltip>
        </div>
        <template>
          <slot name="remove">
            <el-input
              :placeholder="
                $t(`knowledge.bulk-actions.modal.search`, { option })
              "
              v-model="searchRemove"
              class="mr-2 search"
              clearable
            >
              <i slot="prefix" class="el-input__icon"
                ><font-awesome-icon
                  :icon="['fal', 'search']"
                  class="search-icon"
                ></font-awesome-icon
              ></i>
            </el-input>

            <div
              v-if="optionsDeleteToDisplay.length"
              class="d-flex flex-row align-items-center select-all-remove"
            >
              <el-checkbox
                @change="handleSelectAllRemove($event)"
                class="mr-2 mb-0 checkbox-item__is-remove"
                v-model="selectAllRemove"
              ></el-checkbox>
              {{
                $t(
                  `knowledge.bulk-actions.${
                    selectAllRemove ? 'unselect-all' : 'select-all'
                  }`,
                )
              }}
            </div>

            <el-checkbox-group
              v-model="remove"
              @change="$emit('remove', $event)"
              class="d-flex flex-column checkbox-group"
            >
              <el-checkbox
                v-for="item in optionsDeleteToDisplay"
                :key="item.id"
                :label="item.id"
                class="checkbox-item checkbox-item__is-remove"
              >
                <ContentListItemFullLabel
                  v-if="item.labelName"
                  :key="item ? item.id : ''"
                  :label="item"
                />
                <div v-else>{{ item[labelKey] }}</div>
              </el-checkbox>
            </el-checkbox-group>
          </slot>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ContentListItemFullLabel from '@/components/ListItems/ContentListItemFullLabel';
import TextEllipsis from '@/components/TextEllipsis.vue';

export default {
  name: 'attribute-edition-row',
  components: {
    ContentListItemFullLabel,
    TextEllipsis,
  },
  props: {
    addPlaceholder: {
      type: String,
      default: 'Sélectionner',
    },
    option: {
      type: String,
      default: 'labels',
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionsToDelete: {
      type: Array,
      default: () => [],
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    valuekey: {
      type: String,
      default: 'value',
    },
    toCollapse: {
      type: Boolean,
      default: false,
    },
    collapseKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      add: [],
      searchAdd: '',
      remove: [],
      searchRemove: '',
      activeCategories: [],
      selectAll: {},
      selectAllRemove: false,
      selectAllAdd: false,
    };
  },
  created() {
    if (!this.toCollapse)
      this.activeCategories.push(Object.keys(this.optionsToDisplay)[0]);
  },
  computed: {
    optionsToDisplay() {
      let reg;
      if (this.searchAdd) reg = new RegExp(`${this.searchAdd}.*`, 'i');
      const filteredArray = this.searchAdd
        ? this.options.filter((val) => reg.test(val[this.labelKey]))
        : this.options;
      if (!this.toCollapse) return { noCollapse: filteredArray };
      return filteredArray.reduce((acc, val) => {
        if (acc[val[this.collapseKey]]) {
          acc[val[this.collapseKey]].push(val);
        } else acc[val[this.collapseKey]] = [val];
        return acc;
      }, {});
    },
    optionsDeleteToDisplay() {
      let reg;
      if (this.searchRemove) reg = new RegExp(`${this.searchRemove}.*`, 'i');
      return this.searchRemove
        ? this.optionsToDelete.filter((val) => reg.test(val[this.labelKey]))
        : this.optionsToDelete;
    },
  },
  methods: {
    handleSelectCategory(e, category) {
      if (e) {
        this.add.push(...this.optionsToDisplay[category].map((op) => op.id));
        this.$emit('add', this.add);
      } else {
        const labelIdsToRm = this.optionsToDisplay[category].map((op) => op.id);
        this.add = this.add.filter((id) => !labelIdsToRm.includes(id));
        this.$emit('add', this.add);
      }
    },
    categoryIsChecked() {
      this.selectAll = Object.keys(this.optionsToDisplay).reduce(
        (acc, category) => {
          const categoryIds = this.optionsToDisplay[category].map(
            (op) => op.id,
          );
          acc[category] = categoryIds.every((id) => this.add.includes(id));
          return acc;
        },
        {},
      );
    },
    selectAllRemoveIsChecked() {
      const optionsDeleteToDisplayIds = this.optionsDeleteToDisplay.map(
        (op) => op.id,
      );
      this.selectAllRemove = optionsDeleteToDisplayIds.every((val) =>
        this.remove.includes(val),
      );
    },
    selectAllAddIsChecked() {
      if (!this.optionsToDisplay.noCollapse) return;
      const optionsToDisplayIds = this.optionsToDisplay.noCollapse.map(
        (op) => op.id,
      );
      this.selectAllAdd = optionsToDisplayIds.every((val) =>
        this.add.includes(val),
      );
    },
    handleSelectAllRemove(e) {
      if (e)
        return (this.remove = this.optionsDeleteToDisplay.map((op) => op.id));

      this.remove = [];
    },
    handleSelectAllAdd(e) {
      if (e) {
        this.add = this.optionsToDisplay.noCollapse.map((op) => op.id);
      } else {
        this.add = [];
      }
      this.$emit('add', this.add);
    },
  },
  watch: {
    add() {
      this.categoryIsChecked();
      this.selectAllAddIsChecked();
    },
    remove() {
      this.selectAllRemoveIsChecked();
      this.$emit('remove', this.remove);
    },
    searchAdd() {
      if (!this.searchAdd || this.searchAdd === '') this.activeCategories = [];
      else this.activeCategories = Object.keys(this.optionsToDisplay);
    },
  },
};
</script>

<style lang="scss" scoped>
.collections {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: center;
  gap: 16px;
}

.collection-select {
  width: 50%;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.categories-collapse {
  gap: 4px;
  display: flex;
  flex-direction: column;
  border: none;
}

.categories-no-collapse {
  display: flex;
  flex-direction: column;
  border: none;
  :deep() .el-collapse-item__header {
    display: none;
  }
}

.collection-title-wrapper {
  padding: 8px 15px 8px 15px;
  font-size: 16px;
  font-weight: 700;
  color: $blue-mayday;
  background-color: $grey-1-mayday;
}

.info-icon {
  color: $grey-6-mayday;
}

:deep() .el-input__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-icon:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.search {
  height: 34px;
  max-width: 300px;
  min-width: 150px;
  width: 100%;
  :deep() .el-input__inner {
    height: 100%;
  }
}

:deep() .el-collapse-item__header {
  padding: 8px 12px;
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  border: none;
  font-size: 14px;
  font-weight: 700;
  height: 34px;
}

:deep() .el-collapse-item__wrap {
  border: none;
}

.checkbox-group {
  padding-left: 12px;
  &__to-collapse {
    margin-top: 8px;
    padding-left: 30px;
  }
}

.checkbox-item {
  height: 21px;
}

.attribute-title {
  color: $grey-5-mayday;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.is-checked {
  .attribute-title {
    color: $blue-mayday;
  }
}

.title-wrapper {
  max-width: 195px;
}

.add {
  :deep() .el-tag {
    background-color: $green-1-mayday;
    border-color: $green-2-mayday;
    color: $green-mayday;
    .el-tag__close.el-icon-close {
      color: $green-mayday !important;
    }
  }
}

.remove {
  :deep() .el-tag {
    background-color: $red-1-mayday;
    border-color: $red-2-mayday;
    color: $red-mayday;
    .el-tag__close.el-icon-close {
      color: $red-mayday !important;
    }
  }
}

.select-all-remove {
  height: 34px;
  padding: 8px 12px;
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  font-size: 14px;
  font-weight: 700;
}

:deep() .checkbox-item__is-remove > .is-checked > .el-checkbox__inner {
  background-color: $red-mayday;
  border-color: $red-mayday;
}
</style>
