<template>
  <div class="drawer-footer">
    <base-button
      v-if="!hideButtons"
      variant="tertiary"
      size="md"
      class="mr-1"
      @click="$emit('cancel')"
      :text="$t('generic.quit')"
    ></base-button>
    <base-button
      v-if="!hideButtons"
      variant="primary"
      size="md"
      @click="$emit('save')"
      :text="$t('generic.confirm')"
    ></base-button>
  </div>
</template>

<script>
export default {
  name: 'roles-drawer-footer',
  props: {
    hideButtons: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: $grey-1-mayday;
}
</style>
