<template>
  <div class="case-viewer-header-title px-0">
    <div class="fake-loader__wrapper" v-if="fakeLoading">
      <SkeletonText
        class="fake-loader"
        :style="`width: ${newTitle.length * 22}px`"
      />
    </div>
    <div v-else-if="!isEditing" class="row">
      <h1
        class="case-viewer-header-title__title col-11 display-3"
        @dblclick="triggerLabelInput()"
      >
        {{ title }}
      </h1>
      <div v-if="!isEditing && editable" class="edit-title">
        <base-button
          icon="pen"
          variant="tertiary"
          size="sm"
          icon-only
          @click="triggerLabelInput"
        />
      </div>
    </div>

    <div v-else class="row">
      <input
        v-autowidth="{
          maxWidth: '700px',
          minWidth: '300px',
          comfortZone: 0,
        }"
        class="case-viewer-header-title__title case-viewer-header-title__title--input col-11 display-3"
        :placeholder="$t('knowledge.case-title-placeholder')"
        ref="newLabelEdition"
        v-model="newTitle"
        @blur="updateTitle()"
        @keyup.enter="$event.target.blur()"
        @keyup.escape="escape($event)"
      />
    </div>
  </div>
</template>

<script>
import SkeletonText from '@/components/Loaders/SkeletonText';
export default {
  name: 'case-viewer-header-title',
  components: {
    SkeletonText,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isEditing: false,
      fakeLoading: false,
      newTitle: '',
    };
  },
  methods: {
    triggerLabelInput() {
      this.newTitle = this.title;
      this.isEditing = true;
      this.$nextTick(() => this.$refs.newLabelEdition.focus());
    },
    updateTitle() {
      this.newTitle = this.newTitle.trim();
      if (this.newTitle !== this.title) {
        this.fakeLoading = true;
        this.$emit('update-title', this.newTitle);
        // fake delay to simulate wait of request response to avoid visual tilt
        setTimeout(() => {
          this.isEditing = false;
          this.fakeLoading = false;
        }, 1000);
      } else this.isEditing = false;
    },
    escape(event) {
      this.newTitle = this.title;
      event.target.blur();
    },
  },
};
</script>

<style lang="scss" scoped>
.case-viewer-header-title__title {
  margin-bottom: 8px;
  line-height: 57.6px;
}

.case-viewer-header-title {
  input {
    outline: none;
    border: none;
    line-height: 55.6px;
  }
}

.edit-title {
  visibility: hidden;
  margin-top: auto;
  margin-bottom: auto;
}

.fake-loader {
  width: 100%;
  height: 37px;

  &__wrapper {
    display: flex;
    padding: 14px 0;
  }
}

.case-viewer-header-title:hover .edit-title {
  visibility: visible;
}

.icon-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $grey-5-mayday;
  &:hover {
    border-radius: 3px;
    background-color: $grey-2-mayday;
    color: $blue-mayday;
  }
}
.modify-icon {
  width: 12px;
  height: 12px;
}
</style>
