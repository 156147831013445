<template>
  <div class="builder-side-bar">
    <div class="sticky-top d-flex flex-column gap-2">
      <!-- AI OPTION -->
      <AiDrawerOption
        v-if="displayAiDrawerOptions && !isStagedContent"
        :type="type"
        :generating-summary="generatingSummary"
        :generating-action-items="generatingActionItems"
        :generating-guide="generatingGuide"
        :summary="content.summary"
        :short-summary="content.shortSummary"
        :action-items="content.actionItems"
        :ai-drawer-tab="aiDrawerTab"
        :body-length="content.body ? content.body.length : 0"
        :minimum-content-size="minimumContentSize"
        @generate-content="$emit('generate-content', $event)"
        @generate-guide="$emit('generate-guide')"
        @generate-open-prompt="$emit('generate-open-prompt')"
      />

      <!-- LOGIGRAM OPTION -->
      <LogigramOption
        v-if="rootId && displayOptions.logigram && editable && !isTemplate"
        :diagnostic-id="rootId"
        :type="type"
        :user-permissions="userPermissions"
        :is-staged-content="isStagedContent"
        @relocate-child="$emit('relocate-child', $event)"
      />

      <!-- IMPORTED ARTICLES -->
      <ImportedArticlesOption
        v-if="
          displayOptions.import &&
          editable &&
          !isStagedContent &&
          content.isReadOnly
        "
        :is-read-only="content.isReadOnly"
        :content-id="content.id"
        :type="type"
      />

      <!-- KEY STEP -->
      <KeyStepOption
        v-if="displayOptions.keyStep && editable && !isStagedContent"
        :type="type"
        :is-key-step="isKeyStep"
        @click="$emit('toggle-key-step', $event)"
      />

      <!-- LABELS EDITOR -->
      <SearchOption
        v-if="displayOptions.labels && editable && !isStagedContent"
        :tags="content.tags"
        :type="type"
        :user-permissions="userPermissions"
        :is-hidden="isHidden"
        @update-content="$emit('update-content', $event)"
      />

      <!-- TRIGGER OPTION -->
      <TriggerOption
        v-if="displayOptions.trigger && editable && !isStagedContent"
        :type="type"
        :active="triggerActive"
        @click="$emit('click:trigger', $event)"
      />

      <HistorizationOption
        v-if="showHistorization && !isStagedContent"
        :type="type"
        @click="$emit('click:historization', payload)"
      />

      <!-- NOTIFICATIONS -->
      <NotificationsOption
        v-if="displayOptions.notifications && editable && !isStagedContent"
        :node-id="nodeId"
        :node-label="content.label"
        :node-lang="content.returnLanguage"
        @add-notification="$emit('add-notification', $event)"
        :type="content.type"
        source="CONTENT"
      />

      <!-- AUTOMATIONS OPTION -->
      <AutomationsOption
        v-if="
          displayOptions.automations &&
          (hasIntercom || hasZendesk || hasSalesforce) &&
          editable &&
          !isStagedContent
        "
        :type="type"
        :knowledge-id="knowledgeId"
        :node-id="nodeId"
        :automations="automations"
        :new-automation-id="newAutomationId"
        :user-permissions="userPermissions"
        @create-automation="$emit('create-automation', $event)"
        @update-automation="$emit('update-automation', $event)"
        @delete-automation="$emit('delete-automation', $event)"
      />

      <!-- WORKFLOW OPTION -->
      <WorkflowActionOption
        v-if="hasCompanyPreferenceWithValue('WORKFLOW_ACTIVATED')"
        :type="type"
        :active="!!workflowActive"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { sideBarDisplayOptions } from 'utils/displayOptions';

import LogigramOption from './LogigramOption';
import ImportedArticlesOption from './ImportedArticlesOption';
import SearchOption from './SearchOption';
import KeyStepOption from './KeyStepOption';
import TriggerOption from './TriggerOption';
import HistorizationOption from './HistorizationOption';
import NotificationsOption from './NotificationsOption';
import AutomationsOption from './AutomationsOption/AutomationsOption';
import AiDrawerOption from 'views/KnowledgeEditor/ParametricContentBuilder/ParametricBuilderSibeBar/AiDrawerOption.vue';
import WorkflowActionOption from './WorkflowActionOption.vue';

export default {
  name: 'builder-side-bar',
  components: {
    AiDrawerOption,
    LogigramOption,
    ImportedArticlesOption,
    SearchOption,
    KeyStepOption,
    TriggerOption,
    NotificationsOption,
    AutomationsOption,
    HistorizationOption,
    WorkflowActionOption,
  },
  props: {
    type: {
      type: String,
      default: () => 'article',
    },
    knowledgeId: String,
    rootId: {
      type: String,
    },
    nodeId: {
      type: String,
      required: true,
    },
    isKeyStep: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      required: true,
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
    automations: Array,
    newAutomationId: {
      type: String,
      required: false,
    },
    attachedTreeNodes: Array,
    editable: {
      type: Boolean,
      default: true,
    },
    triggerActive: {
      type: Boolean,
      default: false,
    },
    workflowActive: {
      type: Boolean,
      default: false,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
    generatingSummary: {
      type: Boolean,
      default: false,
    },
    generatingShortSummary: {
      type: Boolean,
      default: false,
    },
    generatingActionItems: {
      type: Boolean,
      default: false,
    },
    generatingGuide: {
      type: Boolean,
      default: false,
    },
    aiDrawerTab: {
      type: String,
      default: null,
    },
    minimumContentSize: {
      type: Number,
      default: 300,
    },
    isStagedContent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFirst() {
      return this.content.type === 'Diagnostic';
    },
    displayOptions() {
      return sideBarDisplayOptions({
        contentType: this.type,
        isFirst: this.isFirst,
        isKeyStep: this.isKeyStep,
        isPublished: this.isPublished,
        hasRestriction: this.hasAgentRestrictions,
        isParametric: this.isParametric,
      });
    },
    hasPayload() {
      return this.payload && !!this.payload.symptom;
    },
    payload() {
      const html = new DOMParser().parseFromString(
        this.content.body,
        'text/html',
      ).documentElement;
      const body = html.getElementsByTagName('body');
      const children = Array.from(body)[0].children;

      const symptomIdx = this.extractTextIdx(children, 'Problème');
      const diagnosticIdx = this.extractTextIdx(children, 'Cause');
      const resolutionIdx = this.extractTextIdx(children, 'Solution');
      const compIdx = this.extractTextIdx(
        children,
        'Informations complémentaires',
      );

      return {
        symptom: this.extractInnerText(children, symptomIdx, diagnosticIdx),
        diagnose: this.extractInnerText(children, diagnosticIdx, resolutionIdx),
        resolution: this.extractInnerText(children, resolutionIdx, compIdx),
      };
    },
    showHistorization() {
      return this.session && !this.editable && this.hasPayload;
    },
    displayAiDrawerOptions() {
      if (!this.editable) return false;

      const hasSomeAiFeaturesAvailable =
        this.companyAllowActionItemsPreference ||
        this.companyAllowSummaryPreference ||
        this.companyAllowDecisionTreePreference ||
        this.companyAllowFileImportPreference ||
        this.companyAllowFilesMergePreference;

      if (!hasSomeAiFeaturesAvailable) return false;

      if (
        this.type === 'diagnostic' &&
        !this.companyAllowFileImportPreference
      ) {
        return false;
      }

      return true;
    },
    ...mapState(['session']),
    ...mapGetters([
      'hasZendesk',
      'hasIntercom',
      'hasSalesforce',
      'hasAgentRestrictions',
      'hasCompanyPreferenceWithValue',
    ]),
    ...mapGetters([
      'isParametric',
      'companyAllowSummaryPreference',
      'companyAllowActionItemsPreference',
      'companyAllowDecisionTreePreference',
      'companyAllowFileImportPreference',
      'companyAllowFilesMergePreference',
    ]),
  },
  methods: {
    extractTextIdx(htmlCollection, text) {
      if (!htmlCollection) return -1;
      return Array.from(htmlCollection).findIndex((el) => {
        if (el.tagName != 'H2') return false;
        return el.innerText.includes(text);
      });
    },
    extractInnerText(htmlCollection, startIdx, endIdx) {
      if (!htmlCollection || startIdx === -1) return '';
      return Array.from(htmlCollection)
        .slice(startIdx + 1, endIdx)
        .map((el) => el.innerText)
        .join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.builder-side-bar {
  width: 40px;
  margin-right: 24px;
  margin-left: calc(16% - 96px);
}

.builder-side-bar .sticky-top {
  top: 24px;
}
</style>
