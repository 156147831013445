<template>
  <div class="ask-header">
    <div class="ask-header-title">
      <div class="title-and-filters">
        <div class="title-wrapper" :style="titleWrapperStyle">
          <AiBadge size="medium" class="mr-1" />

          <div v-if="!loading" class="title">
            {{ $t('ask.search.welcome') }}
          </div>
          <div v-else class="title">
            {{ $t('ask.search.generating') }}
          </div>
        </div>
        <div ref="tags-input-wrapper">
          <TagsInputWrapper
            class="ml-2 mw-100"
            data-testid="tag-input-wrapper"
            :tags="selectedTags"
            :showClose="!isTriggered"
            :clickable="!isTriggered"
            @delete="$emit('delete-tag', $event)"
          />
        </div>
      </div>

      <div class="ask-header-buttons">
        <div v-if="!loading" class="d-flex flex-row align-items-center gap-2">
          <base-button
            v-if="!isTriggered"
            icon-only
            icon="sliders-v"
            variant="tertiary"
            size="sm"
            @click.prevent.stop="$emit('show-filters')"
          />
          <base-button
            icon-only
            icon="times"
            variant="tertiary"
            size="sm"
            @click.prevent.stop="$emit('close-ask')"
          />
        </div>
        <div
          v-else
          class="cancel-wrapper"
          @click.prevent.stop="$emit('cancel-ask')"
        >
          {{ $t('ask.search.cancel') }}
          <div class="cancel-as-esc">{{ $t('ask.search.escape') }}</div>
        </div>
      </div>
    </div>
    <AskBox
      v-if="showFilters"
      :title="'ask.search.filters'"
      :icon="['fal', 'sliders-v']"
      class="ask-filters"
      ><template v-slot:content>
        <SearchFilters
          :display="true"
          :is-in-ask="true"
          :selected-tags="selectedTags"
          @update-selected-tags="$emit('update-selected-tags', $event)"
        ></SearchFilters> </template
    ></AskBox>
  </div>
</template>

<script>
import AiBadge from 'components/AI/AiBadge.vue';
import SearchFilters from '../SearchFilters.vue';
import TagsInputWrapper from '../TagsInputWrapper.vue';
import AskBox from './AskBox.vue';
export default {
  name: 'ask-header',
  components: { AiBadge, SearchFilters, AskBox, TagsInputWrapper },
  data() {
    return {
      titleWrapperStyle: '',
    };
  },
  props: {
    loading: { type: Boolean, default: false },
    selectedTags: {
      type: Array,
      default: () => [],
    },
    isTriggered: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setTitleWrapperStyle() {
      this.$nextTick(() => {
        const el = this.$refs['tags-input-wrapper'];
        if (el) {
          this.titleWrapperStyle = `max-width: calc(100% - ${el.clientWidth}px)`;
        } else this.titleWrapperStyle = `max-width: calc(100% - 50px)`;
      });
    },
  },
  watch: {
    selectedTags: {
      handler() {
        this.setTitleWrapperStyle();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-header {
  display: flex;
  flex-direction: column;
}
.ask-header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 26px;
}

.title-and-filters {
  display: flex;
  flex-direction: row;
  max-width: calc(100% - 50px);
  align-items: center;
  justify-content: flex-start;
}
.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  overflow: hidden;
}
.title {
  color: $purple-5-mayday;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.subtitle {
  @extend .title;
  font-weight: 400;
  padding-left: 2px;
}
.ask-header-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.btn-close {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  justify-content: center;
  background: $purple-5-mayday;
  cursor: pointer;
}
.btn-close-icon {
  color: white;
  height: 12px;
  width: 12px;
}
.btn-filters {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  justify-content: center;
  cursor: pointer;
  &__show {
    background-color: $purple-2-mayday;
  }
  :hover {
    background-color: white;
  }
}
.btn-close-icon {
  color: white;
  height: 12px;
  width: 12px;
}
.btn-filters-icon {
  color: $purple-5-mayday;
  height: 10px;
  width: 10px;
}
.cancel-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $purple-5-mayday;
  font-size: 14px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
}
.cancel-as-esc {
  padding: 2px 5px 2px 5px;
  font-size: 12px;
  margin-left: 8px;
  border-radius: 4px;
  background-color: rgba($purple-5-mayday, 0.2);
  font-weight: 400;
}

.ask-filters {
  padding-top: 16px;
  margin-top: 16px;
}
</style>
