<template>
  <div class="template-navigation-list">
    <div class="template-navigation-items">
      <div v-if="!templates.length">
        {{ $t('knowledge.templates.empty-label') }}
      </div>
      <TemplatesNavigationItem
        v-bind="template.content"
        v-for="template in templates"
        :key="template.id"
        :template-id="template.id"
        :is-translated="template.content.returnLanguage === navigationLanguage"
        @click.native="openTemplates(template.id)"
      />
    </div>

    <slot></slot>
  </div>
</template>

<script>
import TemplatesNavigationItem from '@/views/KnowledgeEditor/NavigationBar/Templates/TemplatesNavigationItem';
import { mapGetters } from 'vuex';

export default {
  name: 'templates-navigation-list',
  components: {
    TemplatesNavigationItem,
  },
  computed: {
    ...mapGetters('knowledgeModule', ['templates', 'navigationLanguage']),
  },
  methods: {
    openTemplates(id) {
      return this.$router.push(`/knowledge/templates/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.template-navigation-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding: 16px;
  height: calc(100vh - 68px);
  background-color: white;
  box-shadow: $shadow-mayday;
  border-top-left-radius: 16px;
  border-right: 1px solid $grey-2-mayday;
}

.template-navigation-items {
  flex: 1 1 0%;
}
</style>
