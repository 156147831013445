<template>
  <div
    class="d-flex flex-row mx-0 align-items-center card-row"
    :class="{ isDraft: isDraft }"
  >
    <div
      class="d-flex flex-row align-items-center"
      @click.exact="$emit('focus-component')"
      @click.ctrl.exact="$emit('focus-component-tab')"
      @click.meta.exact="$emit('focus-component-tab')"
    >
      <div class="icon-div d-flex align-items-center justify-content-center">
        <img :src="icon" class="root-folder-card-icon" />
      </div>
      <p class="pl-1 mb-0 item-label">{{ node.label }}</p>
    </div>
    <div class="ml-auto d-flex items-align-center">
      <CommonNodeStatus
        :isNode="true"
        :content="node"
        class="node-status flex-row justify-content-end d-flex align-items-center mr-1"
        @update-content="updateContent"
        @update-content-verification="updateContentVerification"
      />
      <TranslationTooltip
        :is-translated="isTranslated"
        :is-content="!!node.type"
        :has-translated-contents="hasTranslatedContents"
      />
    </div>
  </div>
</template>
<script>
// IMAGES
const diagIcon = require('assets/diag-icon-v2.svg');
const articleIcon = require('assets/article-icon-v2.svg');
const folderIcon = require('assets/close-folder.svg');
import CommonNodeStatus from '../NavigationBar/Navigator/NavigatorNode/CommonNode/CommonNodeStatus';
import TranslationTooltip from '@/components/Commons/TranslationTooltip';

import { mapGetters } from 'vuex';
export default {
  name: 'parametric-root-children',
  components: {
    CommonNodeStatus,
    TranslationTooltip,
  },
  props: {
    node: Object,
  },
  computed: {
    icon() {
      switch (this.node.type) {
        case 'Article':
          return articleIcon;
        case 'Diagnostic':
          return diagIcon;
        default:
          return folderIcon;
      }
    },
    isTranslated() {
      return this.node.returnLanguage === this.navigationLanguage;
    },
    hasTranslatedContents() {
      return this.node.untranslatedWithTranslatedContents;
    },
    isDraft() {
      return this.node.type ? !this.node.published : false;
    },
    ...mapGetters('knowledgeModule', ['navigationLanguage']),
  },
  methods: {
    updateContent(payload) {
      this.$emit('update-content', {
        id: this.node.id,
        payload,
      });
      this.edit_label = false;
    },
    updateContentVerification(isOutdated) {
      this.$emit('update-content-verification', {
        id: this.node.id,
        isOutdated,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.card-row {
  cursor: pointer;
  margin-top: 4px;
  margin-bottom: 4px;
}

.icon-div {
  width: 16px;
}

.root-folder-card-icon {
  max-width: 12px;
  max-height: 12px;
}

.item-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  max-width: 200px;
}

.isDraft .item-label {
  color: $grey-5-mayday;
}

.node-status {
  position: relative;
  z-index: 1;
  right: 0;
}
</style>
