<template>
  <div
    class="thread__dropdown"
    :class="[
      `thread__dropdown-${type.toLowerCase()}`,
      {
        'thread__dropdown-hidden': !displayOptions,
        'thread__dropdown-focus': isFocused,
      },
    ]"
  >
    <div class="thread__dropdown-first">
      <ThreadStatusDropdown
        class="thread__dropdown-statuses"
        :options="statusOptions[type]"
        @update-status="$emit('update-status', $event)"
        @dropdown="displayOptions = $event"
      />
      <ThreadMessage
        v-bind="messages[0]"
        :message-attachments="messages[0].attachments"
        :index="0"
        :options="options"
        :contributors="contributorsDict"
      />

      <button
        @click="display = !display"
        class="thread__dropdown-display"
        v-if="messages.length > 1"
      >
        <font-awesome-icon :icon="['fal', 'comment-alt']" />
        <span class="thread__dropdown-display-text">
          {{ $tc('threads.messages.display', messages.length - 1) }}
        </span>
        <font-awesome-icon
          :icon="['fal', display ? 'chevron-up' : 'chevron-down']"
        />
      </button>
    </div>
    <div
      class="thread__dropdown-others"
      :class="{ 'thread__dropdown-others-display': display }"
      v-if="display"
    >
      <ThreadMessage
        v-for="(message, index) in messages.slice(1)"
        :key="message.id"
        v-bind="message"
        :message-attachments="message.attachments"
        :index="index + 1"
        :options="options"
        :length="messages.length"
        :contributors="contributorsDict"
      />
      <button
        @click="display = false"
        class="thread__dropdown-display"
        v-if="display"
      >
        {{ $t('threads.messages.hide') }}
      </button>
    </div>
  </div>
</template>

<script>
import ThreadMessage from './ThreadMessage';
import ThreadStatusDropdown from './ThreadStatusDropdown';

export default {
  components: { ThreadMessage, ThreadStatusDropdown },
  name: 'thread-dropdown',
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    contributors: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'OPEN',
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      display: false,
      displayOptions: false,
      options: {
        edit: false,
        delete: false,
        vote: false,
      },
      statusOptions: {
        OPEN: { SOLVED: true, ARCHIVED: true },
        SOLVED: { SOLVED: false, ARCHIVED: true },
        ARCHIVED: { SOLVED: true, ARCHIVED: false },
      },
    };
  },
  computed: {
    contributorsDict() {
      if (!this.contributors) return {};
      return Object.fromEntries(this.contributors.map((c) => [c.id, c]));
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  background: transparent;
  border: none;
  outline: none;
}

.thread__dropdown {
  position: relative;
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: $shadow-mayday !important;

  &-focus {
    animation: blink 1000ms linear;
  }

  &-hidden {
    overflow: hidden;
  }

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 4px;
    top: 0;
    left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &-solved::before {
    background-color: $green-mayday;
  }
  &-archived::before {
    background-color: $red-mayday;
  }
  &-open::before {
    background-color: $blue-mayday;
  }

  &-display {
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-weight: 700;
    font-size: 12px;
    color: $grey-8-mayday;

    &:hover {
      color: $blue-mayday;
    }
  }

  &-statuses {
    top: 0;
    right: 0;
    z-index: 1;
    position: absolute;
  }

  &-first {
    position: relative;
  }

  &-others {
    max-height: 0;
    overflow: hidden;
    transition: max-height 250m ease-in-out;
    position: relative;

    &-display {
      max-height: 10000px;
    }
  }
}

@keyframes blink {
  0% {
    background-color: transparent;
  }
  30% {
    background-color: $grey-2-mayday;
  }
  100% {
    background-color: transparent;
  }
}
</style>
