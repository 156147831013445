<template>
  <div class="automations-option">
    <el-popover
      :title="$t('knowledge.diagnostic-builder.side-bar.rules.tooltip')"
      placement="right"
      trigger="hover"
      v-model="showPopover"
    >
      <AutomationsMenu
        :automations="automations"
        :user-permissions="userPermissions"
        @create-automation="toggleRuleModal"
        @edit-automation="toggleRuleModal"
        @delete-automation="$emit('delete-automation', $event)"
      />
      <side-bar-item
        slot="reference"
        icon="wand-magic-sparkles"
        :description="tooltipDescription"
        :type="type"
        :active="showPopover"
        :tooltip="false"
      />
    </el-popover>
    <RuleEditionModal
      v-if="edit"
      :display="edit"
      :group="automation"
      @close="
        edit = false;
        focusAutomationId = null;
      "
      @update-group="updateGroup"
    />
  </div>
</template>

<script>
import SideBarItem from '../SideBarItem';
import AutomationsMenu from './AutomationsMenu';
import RuleEditionModal from 'components/Modals/RuleEditionModal';

export default {
  name: 'automations-option',
  components: {
    SideBarItem,
    AutomationsMenu,
    RuleEditionModal,
  },
  props: {
    type: {
      type: String,
      default: 'diagnostic',
    },
    newAutomationId: {
      type: String,
      required: false,
    },
    knowledgeId: String,
    nodeId: String,
    automations: Array,
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    automation() {
      if (this.focusAutomationId) {
        return this.automations.find(({ id }) => id === this.focusAutomationId);
      }
      return null;
    },
  },
  data() {
    return {
      showPopover: false,
      edit: false,
      focusAutomationId: null,
      tooltipDescription: '',
    };
  },
  methods: {
    toggleRuleModal(id) {
      this.focusAutomationId = id;
      this.edit = true;
      this.showPopover = false;
    },
    itemClick() {
      if (!this.automations) this.edit = true;
    },
    updateGroup(automation) {
      if (!automation) return;
      const { id, alias, rules } = automation;

      if (id) {
        this.$emit('update-automation', {
          id,
          alias,
          rules,
        });
      } else {
        this.addGroup({ alias, rules });
      }
    },
    addGroup({ alias, rules }) {
      this.$emit('create-automation', {
        alias,
        rules,
        contentId: this.nodeId,
        knowledgeId: this.knowledgeId,
      });
    },
  },
  watch: {
    newAutomationId() {
      this.focusAutomationId = this.newAutomationId;
    },
  },
};
</script>
<style lang="scss" scoped>
.is-focused {
  color: $green-mayday;
}
</style>
