<template>
  <div v-if="!isEmptyBody || editable" v-click-outside="stopEditing">
    <div class="diagnostic-editor-card mt-4">
      <img
        v-if="isEmptyBody && editable && !isEditing"
        class="diagnostic-message-editor-empty-state"
        :src="emptyStateSrc"
        @click.prevent.stop="startEditing"
      />
      <MaydayEditorCEWrapper
        v-if="!isEmptyBody || isEditing"
        :key="editorKey"
        class="editor-ce-wrapper"
        :body="editorBody"
        :editable="editable"
        :locale="$i18n.locale"
        :focus-editor="true"
        @update-written-content="delayedUpdateContent"
        @generate-summary="$emit('generate-summary')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MaydayEditorCEWrapper from 'components/Editors/MaydayEditorCEWrapper';

export default {
  name: 'diagnostic-builder-editor-card',
  components: {
    MaydayEditorCEWrapper,
  },
  props: {
    content: Object,
    editable: {
      type: Boolean,
      default: true,
    },
    appLanguage: {
      type: String,
      default: 'fr',
    },
    isStagedContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
      timeoutId: null,
    };
  },
  computed: {
    emptyStateSrc() {
      return require(`assets/${this.appLanguage}-diag-placeholder.png`);
    },
    returnLanguage() {
      return this.isStagedContent && this.content.stagedData
        ? this.content.stagedData.returnLanguage
        : this.content.returnLanguage;
    },
    editorKey() {
      return `${this.content.id}-${this.returnLanguage}-${
        this.content.forceRefreshCount
      }-${this.isStagedContent ? '1' : '0'}-${
        this.content.stagedData ? '1' : '0'
      }`;
    },
    editorBody() {
      if (this.isStagedContent && this.content.stagedData)
        return this.content.stagedData.body;
      return this.content.body;
    },
    isEmptyBody() {
      return (
        !this.editorBody ||
        this.editorBody === '<p></p>' ||
        !!this.editorBody.match(/^<p data-uid="[^"]+"><\/p>$/)
      );
    },
    ...mapGetters('knowledgeModule', ['navigationLanguage']),
  },
  methods: {
    startEditing() {
      clearTimeout(this.timeoutId);
      this.isEditing = true;
    },
    // DEBOUNCE CONTENT UPDATE
    delayedUpdateContent(newContent) {
      this.$emit('delayed-update-content', {
        id: this.content.id,
        payload: newContent,
      });
    },
    stopEditing() {
      this.timeoutId = setTimeout(() => {
        this.isEditing = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.diagnostic-editor-card {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  padding: 24px;
}

.diagnostic-message-editor-empty-state {
  width: 100%;
  &:hover {
    box-shadow: $shadow-mayday;
    cursor: pointer;
  }
}

.editor-ce-wrapper {
  padding-left: 30px;
  padding-right: 30px;
}
</style>
