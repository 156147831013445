<template>
  <div>
    <div class="header">
      <span class="title">
        {{
          $t(
            'knowledge.drawers.content-view-setting.settings.general.contributors',
          )
        }}
        <span class="total">{{ total }}</span>
      </span>
    </div>
    <div class="user-row__container">
      <div :key="i" v-for="(contributor, i) in contributors" class="user-row">
        <div class="initials-wrapper" :style="colorStyle(contributor.username)">
          <div class="initials">
            {{ getInitials(contributor.username) }}
          </div>
        </div>
        <div class="user-row__infos">
          <div class="user-row__username">
            {{ contributor.username }}
            <span class="user-row__role">
              {{
                contributor.authorId === authorId
                  ? $t(
                      'knowledge.drawers.content-view-setting.settings.general.author',
                    )
                  : $t(
                      'knowledge.drawers.content-view-setting.settings.general.editor',
                    )
              }}
            </span>
          </div>
          <div class="user-row__last-update">
            <TimeAgo :date="contributor.updatedAt"></TimeAgo>
          </div>
        </div>
      </div>
    </div>
    <div v-if="total > 5" class="pagination">
      <div
        :class="['pagination__button', { disabled: page === 1 }]"
        @click="handleSelectPage(page - 1)"
      >
        <font-awesome-icon :icon="['fal', 'chevron-left']" />
      </div>
      <div class="pagination__pages">
        <div v-for="displayedPage in displayedPages" :key="displayedPage">
          <div
            :class="[
              'pagination__button',
              { selected: displayedPage === page },
            ]"
            @click="handleSelectPage(displayedPage)"
          >
            {{ displayedPage }}
          </div>
        </div>
      </div>
      <div
        :class="['pagination__button', { disabled: page === pages }]"
        @click="handleSelectPage(page + 1)"
      >
        <font-awesome-icon :icon="['fal', 'chevron-right']" />
      </div>
    </div>
  </div>
</template>

<script>
import TimeAgo from 'components/Commons/TimeAgo.vue';
import { userColor } from 'utils/utils';

import { blueMayday } from '@/assets/scss/_colors.scss';

export default {
  name: 'ContentHistory',
  components: { TimeAgo },
  props: {
    contributors: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    pages: {
      type: Number,
      required: true,
    },
    authorId: {
      type: String,
    },
  },
  computed: {
    displayedPages() {
      const pages = [];
      if (this.pages <= 3) {
        for (let i = 1; i <= this.pages; i++) {
          pages.push(i);
        }
      } else {
        if (this.page === 1) {
          pages.push(1, 2, 3);
        } else if (this.page === this.pages) {
          pages.push(this.pages - 2, this.pages - 1, this.pages);
        } else {
          pages.push(this.page - 1, this.page, this.page + 1);
        }
      }
      return pages;
    },
  },
  methods: {
    colorStyle(username) {
      return {
        background:
          username !== null && username !== '?'
            ? userColor(username, 70, 50)
            : blueMayday,
        color: 'white',
      };
    },
    getInitials(username) {
      const fullName = username.split(' ');
      let initials;
      if (fullName.length >= 2)
        initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      else initials = fullName.shift().charAt(0);
      return initials.toUpperCase();
    },
    handleSelectPage(page) {
      if (page !== this.page && page > 0 && page <= this.pages) {
        this.$emit('select-page', page);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  gap: 4px;
  align-items: center;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: $grey-9-mayday;
}
.total {
  font-size: 8px;
  padding: 2px 4px;
  border: 1px solid $grey-9-mayday;
  border-radius: 8px;
}

.user-row {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  padding: 8px;
  padding-left: 0;
  border-bottom: 1px solid $grey-4-mayday;
}

.user-row__infos {
}

.initials-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.user-row__infos {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  width: 100%;
}

.user-row__username {
  font-family: Lato, sans-serif;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  color: $grey-9-mayday;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-row__role {
  font-size: 11px;
  font-weight: 500;
  color: $grey-6-mayday;
}

:deep() .time-ago {
  font-weight: 700;
}

.user-row__last-update {
  height: 12px;
  display: flex;
  align-items: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px;
  width: 100%;
  margin: 0 auto;
}

.pagination__button {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background: $grey-1-mayday;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    color: $blue-mayday;
  }

  &.selected {
    background: $blue-mayday;
    color: white;
  }

  &.disabled {
    cursor: not-allowed;
    color: $grey-4-mayday;
  }
}

.pagination__pages {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
