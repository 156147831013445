import { render, staticRenderFns } from "./ModuleLabelsSettingsOverview.vue?vue&type=template&id=42e13ee1&scoped=true&"
import script from "./ModuleLabelsSettingsOverview.vue?vue&type=script&lang=js&"
export * from "./ModuleLabelsSettingsOverview.vue?vue&type=script&lang=js&"
import style0 from "./ModuleLabelsSettingsOverview.vue?vue&type=style&index=0&id=42e13ee1&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42e13ee1",
  null
  
)

export default component.exports