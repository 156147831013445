<template>
  <div>
    <div class="title">{{ $t('knowledge.search-admin.terms') }}</div>
    <div class="d-flex align-items-center mr-auto">
      <TagsInputWrapper :tags="tags" @delete="$emit('delete-tag', $event)" />
      <input
        ref="search"
        type="text"
        class="flex-grow-1 input"
        :class="{ 'pl-2': tags.length }"
        :value="value"
        :maxlength="inputMaxLength"
        v-on="listeners"
        v-bind="$attrs"
        spellcheck="false"
        size="1"
      />
      <div class="px-3 d-flex">
        <font-awesome-icon
          :icon="['fal', 'times']"
          class="icon-delete"
          @click="$emit('delete-all')"
        />
      </div>
      <font-awesome-icon
        :icon="['fad', 'sliders-v']"
        class="icon-filters"
        :class="{ 'text-blue': showFilters }"
        @click="toggleFilters"
      />
    </div>
  </div>
</template>

<script>
import TagsInputWrapper from '../TagsInputWrapper';
export default {
  inheritAttrs: false,
  name: 'result-input',
  components: {
    TagsInputWrapper,
  },
  props: {
    value: String,
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showFilters: false,
      inputMaxLength: 500,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      };
    },
  },
  watch: {
    value() {
      this.focusOnInput();
    },
    tags() {
      this.focusOnInput();
    },
  },
  mounted() {
    this.focusOnInput();
  },
  methods: {
    toggleFilters() {
      this.showFilters = !this.showFilters;
      this.$emit('toggle-filters');
      this.focusOnInput();
    },
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit('input', value);
    },
    focusOnInput() {
      this.$nextTick(() => {
        this.$refs.search.focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  font-size: 24px;
  line-height: 29px;
  border: none;
}
input::placeholder {
  font-weight: bold;
  color: $grey-6-mayday;
}
.title {
  font-size: 12px;
  margin-bottom: 10px;
  color: $grey-8-mayday;
  font-weight: bold;
}
.icon-delete {
  font-size: 22px;
  color: $grey-5-mayday;
  cursor: pointer;
}
.icon-filters {
  font-size: 16px;
  color: $grey-7-mayday;
  cursor: pointer;
}
</style>
