<template>
  <base-sidebar
    class="base-navbar"
    :knowledges="JSON.stringify(computedKnowledges)"
    :knowledge-id="focusKnowledge ? focusKnowledge.id : undefined"
    :user-access="JSON.stringify(computedUserAccess)"
    :hub-badge="hubBadge"
    :notification-badge="notificationBadge"
    :active-href="activeHref"
    :username="username"
    :avatar="avatar"
    :redirect-url="redirectUrl"
    :active-search="activeSearch"
    :active-notification="activeNotification"
    :active-documentation="activeDocumentation"
    :knowledge-url="homeLink"
    :hide-language-switch="hideLanguageSwitch"
    :home-redirect-url="redirectToHome"
    @toggle-locale="$emit('update-language', otherLanguage)"
    @logout="$emit('logout')"
    @open-notification-popover="handleOpenNotification"
    @open-documentation-popover="handleOpenDocumentation"
    @open-admin-search="$emit('open-admin-search', true)"
    @select-knowledge="handleSelectKnowledge"
  ></base-sidebar>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {
  getDeskTranslatedEntityUrl,
  getDeskParametricTranslatedEntityUrl,
} from '@/utils/adminToDeskRouteMapping';
import { permissionsRedirection } from '@/utils/permissionsRedirection';

export default {
  name: 'NavBar',
  props: {
    hubBadge: {
      type: [Number, String],
    },
    notificationBadge: {
      type: [Number, String],
    },
    activeSearch: {
      type: Boolean,
      default: false,
    },
    activeNotification: {
      type: Boolean,
      default: false,
    },
    activeDocumentation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {
    document.addEventListener('keydown', this.keyListener);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyListener);
  },
  methods: {
    keyListener(e) {
      if (!this.userAccess.search) return;
      if (e.key === 'k' && (e.metaKey || e.altKey || e.ctrlKey)) {
        this.$emit('open-admin-search', true);

        e.preventDefault();
      } else if (e.key === 'Escape') {
        this.$emit('open-admin-search'), false;
      }
    },
    handleOpenNotification() {
      this.$emit('open-notification-popover');
    },
    handleOpenDocumentation() {
      this.$emit('open-documentation-popover');
    },
    handleSelectKnowledge(event) {
      const knowledge = this.knowledges.find(
        ({ id }) => id === event.detail[0],
      );
      this.$emit('select-knowledge', knowledge);
    },
  },
  computed: {
    computedKnowledges() {
      return this.knowledges.map(({ id, label }) => ({
        id,
        label,
      }));
    },
    computedUserAccess() {
      const access = {
        knowledge: this.userAccess.knowledgeEditor,
        dashboard: this.userAccess.dashboards,
        hub: this.userAccess.tasksBoard,
        automations: this.userAccess.automations && this.hasIntegrations,
        notifications: this.userAccess.notifications,
        ask: this.userAccess.ask,
        academy: this.companyAllowAcademyPreference && this.userAccess.academy,
        search: this.userAccess.search,
        settings: this.userAccess.settings,
      };
      return Object.keys(access).reduce((acc, key) => {
        if (access[key]) {
          acc[key] = access[key];
        }
        return acc;
      }, {});
    },
    redirectToHome() {
      if (this.userAccess.knowledge) {
        return this.homeLink;
      }

      const routeName = permissionsRedirection(
        this.userPermissions,
        this.isParametric,
      );

      const routeData = this.$router.resolve(
        {
          name: routeName,
          params: { lang: this.editingLanguage },
        },
        this.$route,
      );
      return routeData.href;
    },
    hasIntegrations() {
      return this.hasZendesk || this.hasChrome || this.hasSalesforce;
    },
    activeHref() {
      return `/${this.$route.meta.navBarKey}`;
    },
    otherLanguage() {
      return this.appLanguage === 'fr' ? 'en' : 'fr';
    },
    hideLanguageSwitch() {
      return this.hasCompanyPreferenceWithValue('EXTENDED_I18N');
    },
    redirectToDeskParametric() {
      const lang = this.editingLanguage;
      if (!this.focusKnowledge) return `/desk/parametric/${lang}`;

      const { name, query, hash, params } = this.$route;
      const url = getDeskParametricTranslatedEntityUrl({
        name,
        entityId:
          params.stepId ||
          params.contentId ||
          params.productId ||
          params.externalId,
        lang,
        hash,
        query,
      });

      return url;
    },
    redirectToDesk() {
      if (!this.focusKnowledge) return '/desk';

      const { query, hash, params } = this.$route;

      const url = getDeskTranslatedEntityUrl({
        entityId: params.stepId || params.contentId || params.caseId,
        entityType: params.caseId ? 'Case' : 'Content',
        lang: this.editingLanguage,
        hash,
        query,
        knowledgeId: this.focusKnowledge.id,
      });

      return url;
    },
    redirectUrl() {
      if (this.isParametric) return this.redirectToDeskParametric;

      return this.redirectToDesk;
    },
    homeLink() {
      if (!this.focusKnowledge) return '/home';
      return `/home/${this.editingLanguage}/${this.focusKnowledge.id}`;
    },
    ...mapState(['appLanguage']),
    ...mapGetters([
      'username',
      'avatar',
      'hasCompanyPreferenceWithValue',
      'isParametric',
      'hasZendesk',
      'hasChrome',
      'hasSalesforce',
      'userAccess',
      'companyAllowAcademyPreference',
    ]),
    ...mapGetters('knowledgeModule', [
      'knowledges',
      'focusKnowledgeValue',
      'focusKnowledge',
      'editingLanguage',
      'focusKnowledgeDefaultLanguage',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.base-navbar {
  display: flex;
}
</style>
