<template>
  <div class="labels-option">
    <div>
      <el-popover placement="right" v-model="open">
        <div class="search-option-popover">
          <div class="header">
            {{
              $t('knowledge.diagnostic-builder.side-bar.search.hidden-title')
            }}
          </div>
          <div class="switch-wrapper">
            <el-switch
              class="hidden-switch"
              v-model="newHidden"
              @change="handleNewHidden"
            ></el-switch>
            <div :class="{ 'is-active': newHidden }">
              {{
                $t(
                  `knowledge.diagnostic-builder.side-bar.search.${
                    !newHidden ? 'inactive' : 'active'
                  }-text`,
                )
              }}
            </div>
          </div>

          <div class="header">
            {{
              $t('knowledge.diagnostic-builder.side-bar.search.labels-title')
            }}
          </div>

          <LabelsEditor
            :tags="tags"
            :type="type"
            :user-permissions="userPermissions"
            @add-tag="addTag"
            @delete-tag="deleteTag"
          />
        </div>
        <side-bar-item
          slot="reference"
          icon="magnifying-glass-chart"
          :description="
            $t('knowledge.diagnostic-builder.side-bar.search.tooltip')
          "
          :active="open"
          :type="type"
        />
      </el-popover>
    </div>
  </div>
</template>

<script>
import LabelsEditor from '../../LabelsEditor';
import SideBarItem from './SideBarItem';

export default {
  name: 'search-option',
  components: {
    LabelsEditor,
    SideBarItem,
  },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: () => 'diagnostic',
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.newHidden = !this.isHidden;
  },
  data() {
    return { open: false, newHidden: true };
  },
  methods: {
    addTag(tag) {
      let tags = this.tags;
      tags.push(tag);
      this.$emit('update-content', { tags });
    },
    deleteTag(tag) {
      let tags = this.tags;
      tags.splice(tags.indexOf(tag), 1);
      this.$emit('update-content', { tags });
    },
    handleNewHidden(val) {
      this.$emit('update-content', { isHidden: !val });
    },
  },
};
</script>
<style lang="scss" scoped>
.info-icon {
  color: black;
  font-size: 12px;
}

.search-option-popover {
  display: flex;
  flex-direction: column;
}

.header {
  font-weight: 700;
}
.is-active {
  color: $blue-mayday;
}
.switch-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 8px 0px 8px 4px;
}
</style>
