<template>
  <Transition name="expand" mode="out-in">
    <div
      :class="`ask-wrapper ask-wrapper-${
        showMore ? 'show-more' : 'show-less'
      } `"
      v-if="displayAskInSearch"
      key="ask-wrapper"
    >
      <AskHeader
        :loading="loading"
        :selected-tags="selectedTags"
        :is-triggered="isTriggered"
        :show-filters="showFilters"
        @cancel-ask="handleCancelAsk"
        @close-ask="handleCloseAsk"
        @update-selected-tags="updateTags"
        @delete-tag="deleteTag"
        @show-filters="showFilters = !showFilters"
      ></AskHeader>
      <AskAnswer
        v-if="isTriggered"
        :answerStream="answer"
        :sources="sources"
        :is-decline="isDecline"
        :is-inappropriate="isInappropriate"
        :loading="loading"
        :is-error="isError"
        :citation-labels="citationLabels"
        :feedback-status="feedbackStatus"
        :end-of-stream="endOfStream"
        :language="askLanguage"
        @go-to-document="goToDocument"
        @copy-document-url="handleCopyDocumentUrl"
        @retry="handleRetry"
        @cancel-ask="handleCancelAsk"
        @open-sidebar="handleOpenSidebar"
        @update-feedback-status="handleUpdateFeedbackStatus"
        @copy-answer="handleCopyAnswerEvent"
      ></AskAnswer>
      <AskEmptyState
        v-else-if="displayEmptyState"
        :search-input="searchInput"
        :last-question="lastAskQuery.question"
        :is-question="isQuestion"
        :try-out-questions="tryOutQuestions"
        :try-out-questions-loading="tryOutQuestionsLoading"
        :try-out-questions-error="tryOutQuestionsError"
        @trigger-ask="handleTriggerAsk"
        @latest-search="handleDisplayLastAnswer"
      ></AskEmptyState>
      <div class="show-more" v-if="isTriggered && !loading">
        <div
          class="show-more-button-wrapper"
          @click.prevent.stop="showMore = !showMore"
        >
          {{ $t(showMore ? 'ask.search.show-less' : 'ask.search.show-more') }}
          <font-awesome-icon
            :icon="['fal', `chevron-${showMore ? 'up' : 'down'}`]"
            class="ml-2"
          />
        </div>
      </div>
    </div>

    <div key="ask-button" v-if="!displayAskInSearch" class="open-ask">
      <div
        class="open-icon-wrapper"
        @click.prevent.stop="setDisplayAskInSearch(true)"
      >
        <AiBadge size="large" class="mr-1" text="Ask" />
      </div>
    </div>
  </Transition>
</template>

<script>
import AskAnswer from './AskAnswer.vue';
import AskHeader from './AskHeader.vue';
import AskEmptyState from './AskEmptyState.vue';
import AiBadge from 'components/AI/AiBadge.vue';

import { v4 as uuid } from 'uuid';
import { mapActions, mapGetters } from 'vuex';
import { aiClientStreamParser } from 'utils/aiClientStreamParser';

export default {
  name: 'ask-wrapper',
  components: { AskHeader, AskAnswer, AskEmptyState, AiBadge },
  async mounted() {
    this.askLanguage = this.hasCompanyPreferenceWithValue(
      'HAS_ALL_LANGUAGES_SEARCH',
    )
      ? 'none'
      : this.editingLanguage;
    this.getTryOutQuestions();
  },
  data() {
    return {
      query: {},
      loading: false,
      sources: [],
      answer: '',
      question: '',
      citationLabels: {},
      answerType: '',
      isDecline: false,
      isInappropriate: false,
      isError: false,
      isTriggered: false,
      showMore: true,
      feedbackStatus: '',
      triggerSource: '',
      tryOutQuestions: [],
      tryOutQuestionsLoading: false,
      tryOutQuestionsError: false,
      endOfStream: false,
      askLanguage: '',
      selectedTags: [],
      showFilters: false,
      requestTime: null,
      firstTextChunkTime: null,
    };
  },

  props: {
    searchInput: {
      type: String,
      default: '',
    },
    isQuestion: { type: Boolean, default: false },
  },
  computed: {
    displayEmptyState() {
      return (
        (this.lastAskQuery.question && this.lastAskQuery.question.length > 0) ||
        this.tryOutQuestions.length ||
        this.tryOutQuestionsLoading ||
        this.tryOutQuestionsError ||
        this.isQuestion
      );
    },
    linkIds() {
      return this.sources ? [...this.sources.map((source) => source.id)] : null;
    },
    options() {
      return {
        includeTypes: [
          'answer',
          'answer-type',
          'documents',
          'meta',
          'full-answer',
        ],
        language: this.askLanguage,
      };
    },
    preFilters() {
      return {
        categories: this.selectedTags.map((tag) =>
          tag.id === 'none' ? null : tag.id,
        ),
      };
    },
    ...mapGetters('brainModule', [
      'displayAskInSearch',
      'lastAskQuery',
      'hasPreviousAskQuery',
    ]),
    ...mapGetters('knowledgeModule', ['editingLanguage']),
    ...mapGetters(['hasCompanyPreferenceWithValue']),
  },
  methods: {
    resetAsk() {
      this.answer = '';
      this.sources = [];
      this.answerType = '';
      this.isDecline = false;
      this.isInappropriate = false;
      this.isTriggered = false;
      this.loading = false;
      this.showMore = true;
      this.isError = false;
      this.query = {};
      this.question = '';
      this.citationLabels = {};
      this.feedbackStatus = '';
      this.triggerSource = '';
      this.endOfStream = false;
      this.showFilters = false;
      this.requestTime = null;
      this.firstTextChunkTime = null;
    },
    handleCloseAsk() {
      this.resetAsk();
      this.setDisplayAskInSearch(false);
    },
    handleCancelAsk() {
      this.resetAsk();
    },
    getCorrelationId() {
      return 'web-' + uuid();
    },
    async getTryOutQuestions() {
      this.tryOutQuestionsLoading = true;
      try {
        this.tryOutQuestions = await this.postTryOutQuestions({
          lang: this.askLanguage,
          n: 3,
        });
      } catch (e) {
        this.tryOutQuestionsError = true;
      } finally {
        this.tryOutQuestionsLoading = false;
      }
    },
    async fetchStream(triggerSource) {
      try {
        this.requestTime = Date.now();
        for await (const chunk of await this.fetchAsk({
          queryId: this.query.queryId,
          queryBody: this.query.query,
          dev: false,
          askInterface: 'PRIVATE',
        })) {
          let final = false;
          for (let usableChunk of aiClientStreamParser(chunk)) {
            if (usableChunk && usableChunk.content) {
              if (usableChunk.type === 'documents') {
                this.sources = chunk.content;
              }

              if (usableChunk.type === 'answer-type') {
                this.answerType = chunk.content;
                if (this.answerType === 'decline') this.isDecline = true;
                if (this.answerType === 'inappropriate')
                  this.isInappropriate = true;
              }

              if (chunk.type === 'full-answer' && !final) {
                this.answer = '';
                final = true;
                this.citationLabels = {};
              }
              if (
                usableChunk.type === 'answer' ||
                chunk.type === 'full-answer'
              ) {
                if (this.firstTextChunkTime === null)
                  this.firstTextChunkTime = Date.now();
                if (
                  usableChunk.isLink &&
                  usableChunk.sourceId &&
                  usableChunk.linkId &&
                  this.isNumeric(usableChunk.linkId)
                ) {
                  const copy = { ...this.citationLabels };
                  copy[usableChunk.sourceId] = usableChunk.linkId;
                  this.citationLabels = copy;
                }
                this.loading = false;
                this.answer += usableChunk.content;
              }
              if (usableChunk.type === 'meta') {
                this.loading = false;
              }
            }
          }
          this.$emit('update-ask-status', {
            isDecline: this.isDecline,
            isInappropriate: this.isInappropriate,
            isError: this.isError,
            queryId: this.query.queryId,
            answer: this.answer,
            links: this.sources,
            trigger: triggerSource,
            source: 'search',
          });
          if (this.isError) return;
          this.triggerSetLastAskQuery();
        }

        // EVENT

        this.$services.events.ask.answer({
          queryId: this.query.queryId,
          index: 1,
          answer: this.answer,
          queryText: this.question,
          answerType: this.answerType,
          links: this.linkIds,
          source: 'search',
          trigger: triggerSource,
          filters: this.preFilters,
          info: {
            requestTime: this.requestTime,
            firstTextChunkTime: this.firstTextChunkTime,
          },
        });
        this.endOfStream = true;
      } catch (e) {
        this.loading = false;
        this.isError = true;
        this.answer = this.$t('ask.search.error-message');
        // EVENT

        this.$services.events.ask.error({
          queryId: this.query.queryId,
          index: 0,
          answer: this.answer,
          links: this.linkIds,
          errorMessage: String(e),
          source: 'search',
          trigger: triggerSource,
          filters: this.preFilters,
        });
      }
    },
    isNumeric(str) {
      if (typeof str !== 'string') return false;
      return /^[0-9]*$/.test(str);
    },
    triggerSetLastAskQuery() {
      this.setLastAskQuery({
        query: {
          question: this.question,
          selectedTags: this.selectedTags,
        },
        answer: {
          answerStream: this.answer,
          sources: this.sources,
          citationLabels: this.citationLabels,
          isDecline: this.isDecline,
          isInappropriate: this.isInappropriate,
          query: this.query,
          feedbackStatus: this.feedbackStatus,
        },
      });
    },
    handleRetry() {
      this.handleTriggerAsk({
        question: this.question,
        triggerSource: 'retry',
      });
    },

    handleTriggerAsk(e) {
      const queryId = this.getCorrelationId();

      try {
        this.resetAsk();
        this.question = e.question;
        if (e.triggerSource === 'try_out_question')
          this.$emit('update-search-input', e.question);
        this.isTriggered = true;
        this.loading = true;
        this.triggerSource = e.triggerSource;
        //EVENT
        this.$services.events.ask.question({
          queryText: this.question,
          queryId,
          index: 0,
          source: 'search',
          trigger: e.triggerSource,
          filters: this.preFilters,
        });

        this.query = {
          query: {
            queryText: e.question,
            ...this.options,
            pre: this.preFilters,
          },
          createdAt: new Date(),
          queryId: queryId,
        };
        this.fetchStream(e.triggerSource);
      } catch (e) {
        this.isError = true;
        this.answer = this.$t('ask.search.error-message');
        this.loading = false;

        // EVENT
        this.$services.events.ask.error({
          queryId,
          index: 0,
          answer: this.answer,
          links: this.linkIds,
          errorMessage: e,
          source: 'search',
          trigger: e.triggerSource,
        });
      }
    },
    handleDisplayLastAnswer() {
      const { answer, query } = this.lastAskQuery;
      const {
        answerStream,
        sources,
        citationLabels,
        isDecline,
        query: queryFromAnswer,
        feedbackStatus,
        isInappropriate,
      } = answer;
      this.$emit('update-search-input', query.question);
      this.$nextTick(() => {
        this.question = query.question;
        this.selectedTags = query.selectedTags;
        this.isTriggered = true;
        this.loading = false;
        this.answer = answerStream;
        this.sources = sources;
        this.citationLabels = citationLabels;
        this.isDecline = isDecline;
        this.isInappropriate = isInappropriate;
        this.query = queryFromAnswer;
        this.feedbackStatus = feedbackStatus;
        this.triggerSource = 'last-search';
        this.$emit('update-ask-status', {
          isDecline: this.isDecline,
          isInappropriate: this.isInappropriate,
          isError: this.isError,
          queryId: this.query.queryId,
          answer: this.answer,
          links: this.sources,
          trigger: this.triggerSource,
          source: 'search',
        });
      });
    },
    goToDocument({ entityId, entityType }) {
      this.handleSourceUseEvent({ sourceId: entityId, trigger: 'full-page' });
      this.$emit('go-to-document', { id: entityId, type: entityType });
    },
    handleCopyDocumentUrl({ entityId, entityType }) {
      this.handleSourceUseEvent({ sourceId: entityId, trigger: 'copy' });
      this.$emit('copy-document-url', { id: entityId, type: entityType });
    },
    handleOpenSidebar(e) {
      this.handleSourceUseEvent({ sourceId: e.entityId, trigger: 'sidebar' });
      this.$emit('open-sidebar', e);
    },
    handleUpdateFeedbackStatus(status) {
      this.feedbackStatus = status;
      this.sendAskFeedback({
        queryId: this.query.queryId,
        queryBody: this.query.query,
        feedback: {
          feedbackType: null,
          rating: status,
          content: null,
        },
      });
      this.triggerSetLastAskQuery();
      this.$services.events.ask.feedback(
        {
          queryId: this.query.queryId,
          queryText: this.question,
          index: 0,
          source: 'search',
          trigger: this.triggerSource,
          feedback: {
            feedbackType: '',
            rating: status,
            content: '',
          },
        },
        window.location.href,
      );
    },
    updateTags(tags) {
      this.selectedTags = tags;
    },
    deleteTag(tag) {
      const index = this.selectedTags.findIndex(
        (t) => t.id == tag.id && t.key == tag.key,
      );
      this.selectedTags.splice(index, 1);
    },
    handleSourceUseEvent({ sourceId, trigger }) {
      this.$services.events.ask.useSource({
        queryId: this.query.queryId,
        index: 0,
        sourceId,
        trigger,
        infos: {
          answer: this.answer,
          links: this.linkIds,
          source: 'search',
          trigger: this.triggerSource,
        },
      });
    },
    handleCopyAnswerEvent() {
      this.$services.events.ask.copyAnswer({
        queryId: this.query.queryId,
        index: 0,
        answer: this.answer,
        source: 'search',
        trigger: this.triggerSource,
      });
    },
    ...mapActions('brainModule', [
      'fetchAsk',
      'sendAskFeedback',
      'setDisplayAskInSearch',
      'setLastAskQuery',
      'postTryOutQuestions',
    ]),
    ...mapActions('knowledgeModule', ['goToTranslatedEntity']),
  },
  watch: {
    searchInput(newVal) {
      if (newVal !== this.question) this.resetAsk();
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-wrapper {
  background-color: $purple-1-mayday;
  padding: 16px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  &__expanded {
    max-height: 1000px;
  }
}
.ask-wrapper-show-less {
  max-height: 150px;
}
.ask-wrapper-show-more {
  max-height: 400px;
}
.open-ask {
  background-color: white;
  padding: 10px 24px 0px 24px;
  display: flex;
  justify-content: right;
  transition: all 0.5s ease-in-out;
}
.open-icon-wrapper {
  height: 24px;
  width: 24px;
  color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
}

.expand-enter-active,
.expand-leave-active,
.expand {
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  &.ask-wrapper,
  &.open-ask {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.expand-enter-to {
  max-height: 400px;
}

.show-more-button-wrapper {
  width: fit-content;
  height: 25px;
  position: absolute;
  z-index: 1;
  top: 10px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid $purple-5-mayday;
  color: $purple-5-mayday;
  padding: 8px 5px 8px 5px;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.show-more {
  display: flex;
  justify-content: center;
  position: relative;
  &:hover .show-more-button-wrapper {
    color: white;
    background-color: $purple-5-mayday;
    cursor: pointer;
  }
}
</style>
