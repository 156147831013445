<template>
  <div class="delete-modal">
    <modal :show.sync="localDisplay">
      <div class="row d-flex mt-3 justify-content-center">
        <div class="col-11 text-center">
          <modal-header
            :style-name="{
              backgroundColor: enableAutoTranslate ? '#e7ddff' : '#0081f90d',
            }"
            class="mb-3"
          >
            <div slot="icon" class="d-inline-flex">
              <font-awesome-icon
                class="icon blue-mayday"
                :icon="['fal', 'globe-americas']"
              />
            </div>
            <div class="mt-3">
              <div>
                <p class="mb-0 font-weight-bold">
                  {{
                    $t(
                      `${
                        isStagedContent
                          ? 'translation.modal.header-staged'
                          : 'translation.modal.header'
                      }`,
                      {
                        language: $t(`knowledge.languages.${targetLanguage}`),
                      },
                    )
                  }}
                </p>
              </div>
            </div>
          </modal-header>
        </div>
      </div>
      <div
        class="row d-flex justify-content-center mb-2"
        v-if="displayAutomaticTranslationOption"
      >
        <div class="col-11 text-center">
          <div class="input-label">
            {{ $t('translation.modal.translation-title') }}<AiBadge />
          </div>
          <div
            class="rounded-input d-flex flex-row align-items-center justify-between p-2"
          >
            {{
              targetLanguage === sourceLanguage
                ? $t('translation.modal.translation-description-same-lang', {
                    targetLanguage: $t(`knowledge.languages.${targetLanguage}`),
                  })
                : $t('translation.modal.translation-description', {
                    sourceLanguage: $t(`knowledge.languages.${sourceLanguage}`),
                    targetLanguage: $t(`knowledge.languages.${targetLanguage}`),
                  })
            }}
            <el-switch
              v-model="enableAutoTranslate"
              :disabled="!allowAutomaticTranslation"
            ></el-switch>
          </div>
        </div>
      </div>
      <div
        class="row d-flex justify-content-center"
        v-if="displaySourceLanguage"
      >
        <div class="col-11 text-center">
          <div class="input-label">
            {{ $t('translation.modal.source-language-title') }}
          </div>
          <div
            class="rounded-input d-flex flex-row align-items-center justify-between p-2 text-left"
          >
            {{
              $t('translation.modal.source-language-description', {
                targetLanguage: $t(`knowledge.languages.${targetLanguage}`),
              })
            }}
            <el-select
              v-model="selectedSourceLanguage"
              class="select"
              :placeholder="$t('generic.choose')"
            >
              <el-option
                v-for="lang in availableSourceLanguages"
                :key="lang"
                :value="lang"
                :label="$t(`knowledge.languages.${lang}`)"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div
        class="row d-flex justify-content-center"
        v-if="!enableAutoTranslate"
      >
        <div class="col-11 text-center">
          <div class="input-label">
            {{
              $t('translation.modal.input-label', {
                language: $t(`knowledge.languages.${targetLanguage}`),
              })
            }}
          </div>
          <div class="rounded-input">
            <input
              ref="input"
              :placeholder="$t('translation.modal.input-placeholder')"
              v-model="label"
              @keyup.enter="$emit('set-new-language', label)"
            />
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-end footer-container"
      >
        <base-button
          variant="secondary"
          size="md"
          :text="$t('translation.modal.cancel')"
          @click="$emit('close')"
        />
        <base-button
          class=""
          size="md"
          :text="$t('translation.modal.validate')"
          :disabled="disableSendButton"
          @click="handleSetNewLanguage"
        />
      </div>
    </modal>
  </div>
</template>
<script>
import ModalHeader from '@/components/Modals/ModalHeader';
import Modal from '@/components/Modal';
import AiBadge from '@/components/AI/AiBadge.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'set-language-modal',
  props: {
    display: Boolean,
    targetLanguage: String,
    sourceLanguage: String,
    allowAutomaticTranslation: Boolean,
    availableSourceLanguages: {
      type: Array,
      default: () => [],
    },
    hasPendingTranslation: Boolean,
    isStagedContent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Modal,
    ModalHeader,
    AiBadge,
  },
  data() {
    return {
      localDisplay: this.display,
      label: '',
      enableAutoTranslate: false,
      selectedSourceLanguage: '',
    };
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
    enableAutoTranslate(newVal) {
      if (newVal) this.label = '';
    },
  },
  mounted() {
    this.storeVersionEvent({
      eventName: 'openModal',
      event: {
        knowledgeId: this.focusContent.knowledgeId,
        contentId: this.focusContent.id,
        contentType: this.focusContent.type,
        sourceLanguage: this.sourceLanguage || null,
      },
    });
    this.$refs.input.focus();
    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener.bind(this));
  },
  computed: {
    displaySourceLanguage() {
      return (
        this.targetLanguage === this.sourceLanguage && this.enableAutoTranslate
      );
    },
    disableSendButton() {
      if (this.displaySourceLanguage) {
        return (
          !this.label &&
          (!this.enableAutoTranslate || !this.selectedSourceLanguage)
        );
      }
      return !this.label && !this.enableAutoTranslate;
    },

    displayAutomaticTranslationOption() {
      return (
        this.companyAllowAutomaticTranslationPreference &&
        !this.hasPendingTranslation &&
        this.allowAutomaticTranslation &&
        !this.isStagedContent
      );
    },
    ...mapGetters(['companyAllowAutomaticTranslationPreference']),
    ...mapGetters('knowledgeModule', ['focusContent']),
  },
  methods: {
    handleSetNewLanguage() {
      this.$emit('set-new-language', {
        label: this.label,
        enableAutoTranslate: this.enableAutoTranslate,
        ...(this.displaySourceLanguage
          ? { sourceLanguage: this.selectedSourceLanguage }
          : {}),
      });
    },
    _keyListener(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (!this.disableSendButton) this.handleSetNewLanguage();
      }
    },
    ...mapActions('knowledgeModule', ['storeVersionEvent']),
  },
};
</script>
<style lang="scss" scoped>
.footer-container {
  gap: 8px;
  margin-right: 4px;
}

.delete-modal {
  :deep() .modal-body {
    padding-bottom: 0px;
  }
}
.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 26px;
  height: 26px;
}

.input-label {
  font-weight: 700;
  text-align: left;
  color: black;
  display: flex;
  align-items: center;
  gap: 8px;
}

.rounded-input {
  border: 1px solid $grey-5-mayday;
  padding: 4px 0px;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  input {
    width: 100%;
    outline: none;
    border: none;
    padding: 4px 8px;
  }
}
:deep() .el-select {
  .el-input .el-input__inner {
    height: 24px;
    width: 100px;
    display: flex;
    align-items: center;
    padding-left: 4px;
    font-size: 12px;
  }
}
:deep() .el-input__suffix {
  display: flex;
  align-items: center;
}
</style>
