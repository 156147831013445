<template>
  <div class="notifications-option">
    <side-bar-item
      icon="bell"
      :description="
        $t('knowledge.article-builder.side-bar.notifications.tooltip')
      "
      :type="type"
      @click="openNotificationModal"
    />
    <ModalEvents
      modal-name="CreateNotificationModal"
      :uid="createNotificationUniqueKey"
      @choice="addNotification"
    />
  </div>
</template>

<script>
import SideBarItem from './SideBarItem';
import ModalEvents from '@/components/Modals/ModalEvents';
import { mapActions } from 'vuex';

export default {
  name: 'notifications-option',
  components: {
    SideBarItem,
    ModalEvents,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    nodeLabel: {
      type: String,
      required: true,
    },
    nodeLang: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'diagnostic',
    },
    source: {
      type: String,
      default: 'NOTIFICATION_CENTER',
    },
  },
  computed: {
    createNotificationUniqueKey() {
      return `${this.$options.name}-modal-${this.nodeId}`;
    },
  },
  methods: {
    openNotificationModal() {
      const payload = {
        component: 'CreateNotificationModal',
        uid: this.createNotificationUniqueKey,
        props: {
          open: true,
          entityId: this.nodeId,
          entityType: this.type,
          entityLabel: this.nodeLabel,
          entityLang: this.nodeLang,
          linkContent: true,
          source: this.source,
        },
      };
      this.openModal(payload);
    },
    addNotification({ notification }) {
      if (notification) {
        this.$emit('add-notification', notification);
      }
    },
    ...mapActions('modalsModule', ['openModal']),
  },
};
</script>
