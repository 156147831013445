<template>
  <div class="logigram-option">
    <side-bar-item
      icon="sitemap"
      :description="tooltipDescription"
      :type="type"
      @click="logigramModal = true"
    />
    <DiagArchiModal
      v-if="logigramModal"
      :display="logigramModal"
      :diagnostic-id="diagnosticId"
      :user-permissions="userPermissions"
      :is-staged-content="isStagedContent"
      :display-options="displayOptions"
      @close="logigramModal = false"
      @relocate-child="$emit('relocate-child', $event)"
    />
  </div>
</template>

<script>
import SideBarItem from './SideBarItem';
import DiagArchiModal from 'components/Modals/DiagArchiModal';

export default {
  name: 'logigram-option',
  components: {
    SideBarItem,
    DiagArchiModal,
  },
  props: {
    diagnosticId: {
      type: String,
    },
    type: {
      type: String,
      default: 'diagnostic',
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
    isStagedContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltipDescription: this.$t(
        'knowledge.diagnostic-builder.side-bar.show-architecture.tooltip',
      ),
      logigramModal: false,
    };
  },
  computed: {
    displayOptions() {
      return {
        showFooterSwitches: !this.isStagedContent,
        showAliases: !this.isStagedContent,
        showAutomations: !this.isStagedContent,
        showTriggers: !this.isStagedContent,
      };
    },
  },
};
</script>
