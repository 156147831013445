<template>
  <div class="drawer-footer">
    <base-button
      variant="secondary"
      size="md"
      @click="$emit('close')"
      :text="$t('generic.close')"
    ></base-button>
  </div>
</template>

<script>
export default {
  name: 'user-drawer-footer',
};
</script>

<style lang="scss" scoped>
.drawer-footer {
  display: flex;
  align-items: center;
  justify-content: right;
  background-color: $grey-1-mayday;
}
</style>
