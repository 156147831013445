<template>
  <div class="new-knowledge-choose-type">
    <!-- MAIN TITLE -->
    <div
      class="main-title d-flex flex-column justify-content-center align-items-center mb-5"
      :class="{ disappear: step > 1 }"
    >
      <div class="title">
        {{ $t('settings.new-knowledge.choose-type.title') }}
      </div>
      <div class="description">
        {{ $t('settings.new-knowledge.choose-type.description') }}
      </div>
    </div>

    <div class="option-cards">
      <!-- PRIVATE CARD -->
      <option-card
        class="new-option-card new-option-card-left"
        :class="[
          { disappear: choice === 'public' && step === 2 },
          { 'chosen-left': choice === 'private' && step === 2 },
        ]"
        :icon="{ value: 'lock', type: 'fal', color: '#677184' }"
        :isChosen="choice === 'private'"
        :title="$t('settings.new-knowledge.choose-type.private.title')"
        :description="
          $t('settings.new-knowledge.choose-type.private.description')
        "
        :warning="$t('settings.new-knowledge.choose-type.private.warning')"
        @click="choice = 'private'"
      >
        <img
          slot="picture"
          src="~assets/private-new-knowledge.png"
          alt="private-knowledge"
          class="picture"
        />
        <el-radio
          :class="{ disappear: step === 2 }"
          slot="radio"
          v-model="choice"
          label="private"
        ></el-radio>
      </option-card>
      <!-- PUBLIC CARD -->
      <option-card
        class="new-option-card new-option-card-right"
        :icon="{ value: 'globe', type: 'fal', color: '#677184' }"
        :isChosen="choice === 'public'"
        :class="[
          { disappear: choice === 'private' && step === 2 },
          { 'chosen-right': choice === 'public' && step === 2 },
        ]"
        :title="$t('settings.new-knowledge.choose-type.public.title')"
        :description="
          $t('settings.new-knowledge.choose-type.public.description')
        "
        :warning="$t('settings.new-knowledge.choose-type.public.warning')"
        :disabled="FEATURE_FLAG"
        @click="choice = 'public'"
      >
        <img
          slot="picture"
          src="~assets/public-new-knowledge.png"
          alt="public-knowledge"
          class="picture"
        />
        <el-radio
          :class="{ disappear: step === 2 }"
          slot="radio"
          v-model="choice"
          label="public"
        ></el-radio>
      </option-card>
      <!-- CONFIG INPUTS -->
      <new-knowledge-public
        class="hidden-config pb-4"
        :class="{ 'appear-config': step === 2 && choice === 'public' }"
        :hostnameAlreadyExist="hostnameAlreadyExist"
        :validityLoading="validityLoading"
        :group-options="groupOptions"
        @new-kb-models="$emit('new-kb-models', $event)"
        @check-hostname="$emit('check-hostname', $event)"
      ></new-knowledge-public>
      <new-knowledge-private
        class="hidden-config"
        :class="{ 'appear-config': step === 2 && choice === 'private' }"
        :group-options="groupOptions"
        @new-kb-models="$emit('new-kb-models', $event)"
      ></new-knowledge-private>
    </div>
  </div>
</template>

<script>
import OptionCard from 'components/OptionCard.vue';
import NewKnowledgePublic from './NewKnowledgePublic.vue';
import NewKnowledgePrivate from './NewKnowledgePrivate.vue';

export default {
  name: 'new-knowledge-choose-type',
  components: {
    OptionCard,
    NewKnowledgePublic,
    NewKnowledgePrivate,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    hostnameAlreadyExist: {
      type: Boolean,
      default: false,
    },
    validityLoading: {
      type: Boolean,
      default: false,
    },
    groupOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      choice: 'private',
      FEATURE_FLAG: true,
    };
  },
  created() {
    const { mode } = this.$route.query;
    if (mode === 'demo') this.FEATURE_FLAG = false;
  },
};
</script>

<style scoped lang="scss">
.new-knowledge-choose-type {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

.main-title {
  margin-top: 50px;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
  .title {
    font-size: 28px;
    font-weight: 700;
  }
  .description {
    color: $grey-7-mayday;
  }
}

.disappear {
  opacity: 0;
  pointer-events: none;
}

.option-cards {
  position: relative;
  width: calc(278px * 2 + 80px);
  margin: 0 auto;
}

.new-option-card {
  position: absolute;
  &-left {
    transition: all 250ms ease-in-out;
    right: calc(278px + 80px);
    left: 0;
  }
  &-right {
    transition: all 250ms ease-in-out;
    right: 0;
    left: calc(278px + 80px);
  }
}

.chosen {
  &-left {
    left: 0;
    right: unset;
  }
  &-right {
    left: 0;
    right: unset;
  }
}

.hidden-config {
  transition: all 250ms ease-in-out;
  position: absolute;
  left: calc(278px * 2 + 80px * 2);
  opacity: 0;
  pointer-events: none;
}

.appear-config {
  left: calc(278px + 80px);
  opacity: 1;
  pointer-events: auto;
}

.picture {
  height: 100%;
  width: 100%;
}
</style>
