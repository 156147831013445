<template>
  <div>
    <div class="font-bold mb-2">
      {{
        $t(
          'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.icon.label',
        )
      }}
    </div>
    <div v-for="category in displayedIcons" :key="category.label">
      <div v-if="category.icons.length" class="my-1">
        <label class="category">
          <span class="category__label">{{ $t(category.label) }}</span>
          <span
            v-if="category.new"
            class="category__tag"
            style="font-size: 8px"
          >
            {{
              $t(
                'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.icon.new',
              )
            }}
          </span>
        </label>
        <div class="d-flex flex-wrap mt-1">
          <button
            v-for="icon in category.icons"
            :key="icon.name"
            class="single-icon"
            :class="{ selected: selected === icon.name }"
            @click="handleSelect(icon.name)"
          >
            <font-awesome-icon :icon="['fal', icon.name]" />
          </button>
        </div>
      </div>
    </div>
    <div v-if="emptyIcons" class="empty-icons">
      <font-awesome-icon :icon="['fal', 'icons']" />
      <span>{{ $t('select.icon.empty') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'icon-picker',
  props: {
    value: String,
  },
  data() {
    return {
      selected: this.value,
      iconCategories: [
        {
          new: true,
          label:
            'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.icon.categories.academy',
          icons: [
            {
              name: 'graduation-cap',
              synonyms: ['graduation cap', 'chapeau de graduation'],
            },
            {
              name: 'user-graduate',
              synonyms: ['user graduate', 'utilisateur diplômé'],
            },
            {
              name: 'book',
              synonyms: ['book bookmark', 'marque-page de livre'],
            },
            {
              name: 'book-open',
              synonyms: ['book open cover', 'couverture de livre ouverte'],
            },
            {
              name: 'chalkboard-teacher',
              synonyms: ['chalkboard user', 'utilisateur de tableau'],
            },
            {
              name: 'school',
              synonyms: ['school flag', "drapeau d'école"],
            },
            { name: 'diploma', synonyms: ['diploma', 'diplôme'] },
            { name: 'puzzle-piece', synonyms: ['puzzle', 'puzzle'] },
            { name: 'cubes', synonyms: ['cubes', 'cubes'] },
            {
              name: 'file-certificate',
              synonyms: ['file certificate', 'certificat de fichier'],
            },
            {
              name: 'trophy-alt',
              synonyms: ['trophy star', 'trophée étoile'],
            },
          ],
        },
        {
          label:
            'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.icon.categories.general',
          icons: [
            {
              name: 'folder-open',
              synonyms: ['folder open', 'dossier ouvert'],
            },
            { name: 'mailbox', synonyms: ['mailbox', 'boîte aux lettres'] },
            { name: 'anchor', synonyms: ['anchor', 'ancre'] },
            {
              name: 'bags-shopping',
              synonyms: ['bags shopping', 'sacs à provisions'],
            },
            { name: 'bookmark', synonyms: ['bookmark', 'marque-page'] },
            { name: 'stars', synonyms: ['stars', 'étoiles'] },
            { name: 'magic', synonyms: ['magic', 'magie'] },
            { name: 'books', synonyms: ['books', 'livres'] },
            {
              name: 'image-polaroid',
              synonyms: ['image polaroid', 'image polaroid'],
            },
            {
              name: 'map-marker-alt',
              synonyms: ['map marker alt', 'marqueur de carte alt'],
            },
            { name: 'users', synonyms: ['users', 'utilisateurs'] },
            { name: 'medal', synonyms: ['medal', 'médaille'] },
            { name: 'lock-alt', synonyms: ['lock alt', 'verrouiller alt'] },
            { name: 'camera-home', synonyms: ['camera home', 'caméra maison'] },
            { name: 'wrench', synonyms: ['wrench', 'clé à molette'] },
            {
              name: 'bell-exclamation',
              synonyms: ['bell exclamation', "cloche d'exclamation"],
            },
          ],
        },
        {
          label:
            'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.icon.categories.analysis-trade',
          icons: [
            {
              name: 'shopping-cart',
              synonyms: ['shopping cart', 'chariot de courses'],
            },
            {
              name: 'chart-pie',
              synonyms: ['chart pie', 'graphique en secteurs'],
            },
            {
              name: 'chart-area',
              synonyms: ['chart area', 'zone de graphique'],
            },
            { name: 'abacus', synonyms: ['abacus', 'abaque'] },
            {
              name: 'badge-percent',
              synonyms: ['badge percent', 'badge pourcentage'],
            },
          ],
        },
        {
          label:
            'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.icon.categories.transport-location',
          icons: [
            {
              name: 'paper-plane',
              synonyms: ['paper plane', 'avion en papier'],
            },
            { name: 'rocket', synonyms: ['rocket', 'fusée'] },
            { name: 'feather-alt', synonyms: ['feather alt', 'plume alt'] },
            { name: 'radar', synonyms: ['radar', 'radar'] },
            {
              name: 'globe-stand',
              synonyms: ['globe stand', 'support de globe'],
            },
          ],
        },
        {
          label:
            'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.fields.icon.categories.it',
          icons: [
            { name: 'desktop', synonyms: ['desktop', 'ordinateur de bureau'] },
            { name: 'code', synonyms: ['code', 'code'] },
            { name: 'file', synonyms: ['file', 'fichier'] },
            { name: 'database', synonyms: ['database', 'base de données'] },
            { name: 'upload', synonyms: ['upload', 'téléverser'] },
          ],
        },
      ],
    };
  },
  computed: {
    displayedIcons() {
      if (!this.input) return this.iconCategories;

      return this.iconCategories.map((category) => {
        return {
          ...category,
          icons: category.icons.filter((icon) => {
            const term = this.input.toLowerCase();
            const iconLower = icon.name.toLowerCase();

            // Check if the icon or any of its synonyms match the input
            return (
              iconLower.includes(term) ||
              (icon.synonyms &&
                icon.synonyms.some((synonym) => synonym.includes(term)))
            );
          }),
        };
      });
    },
    emptyIcons() {
      return !this.displayedIcons.some((category) => category.icons.length);
    },
  },
  methods: {
    handleSelect(icon) {
      this.selected = icon;
      this.$emit('update', {
        icon: { iconType: 'fa', value: icon },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.category {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  text-transform: uppercase;

  &__label {
    font-weight: bold;
    font-size: 10px;
  }
  &__tag {
    display: inline-flex;
    align-items: center;
    font-size: 8px;
    margin-left: 4px;
    padding: 0 4px;
    border-radius: 50px;
    background-color: $blue-5-mayday;
    color: white;
  }
}

button.selected {
  border: 1px solid black;
}

.single-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  margin-right: 4px;
  background-color: transparent;
  border: 0.8px solid transparent;

  &:hover {
    border-color: $grey-6-mayday;
  }
}

.empty-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
  font-size: 14px;
  color: $grey-6-mayday;
}
</style>
