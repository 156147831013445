<template>
  <div class="template-navigation">
    <div class="templates-navigation-header">
      <div class="d-flex align-items-center">
        <div class="title">
          {{ $tc('knowledge.templates.header', templates.length) }}
        </div>
        <div class="count ml-2">{{ templates.length }}</div>
      </div>
      <div class="templates-navigation-header-options">
        <TranslationDropdown
          v-if="focusKnowledgeIsMultilinguale"
          class="mx-1"
          :languages="focusKnowledgeLanguages"
          :current-language="editingLanguage"
          tight
          @change-language="switchEditingLanguage($event)"
        />
        <base-button
          icon-only
          icon="plus-square"
          variant="tertiary"
          @click="openCreateTemplate"
        />
      </div>
    </div>

    <TemplatesNavigationList v-loading="loading">
      <div class="templates-navigation-footer">
        <base-button
          variant="tertiary"
          icon="arrow-left"
          :text="$t('knowledge.templates.back')"
          @click="goToKnowledge"
        />
      </div>
    </TemplatesNavigationList>

    <ModalEvents
      modal-name="CreateTemplateModal"
      :uid="createTemplateUniqueKey"
      @choice="handleCreateTemplate"
    />
  </div>
</template>

<script>
import TemplatesNavigationList from '@/views/KnowledgeEditor/NavigationBar/Templates/TemplatesNavigationList';
import TranslationDropdown from '@/components/TranslationDropdown';
import ModalEvents from '@/components/Modals/ModalEvents';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'templates-navigation',
  components: {
    TemplatesNavigationList,
    TranslationDropdown,
    ModalEvents,
  },
  props: {
    backLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    createTemplateUniqueKey() {
      return `${this.$options.name}-create-template`;
    },
    ...mapGetters('knowledgeModule', [
      'templates',
      'focusKnowledgeLanguages',
      'editingLanguage',
      'focusKnowledgeIsMultilinguale',
    ]),
  },
  methods: {
    goToKnowledge() {
      if (!this.backLink) return this.$router.push('/knowledge');
      return this.$router.push(this.backLink);
    },
    goToTemplates() {
      return this.$router.push('/knowledge/templates');
    },
    async openCreateTemplate() {
      const payload = {
        component: 'CreateTemplateModal',
        uid: this.createTemplateUniqueKey,
        props: { display: true, name: '', type: true },
      };
      this.openModal(payload);
    },
    async handleCreateTemplate(template) {
      this.loading = true;
      await this.createTemplate(template);
      this.loading = false;
    },
    ...mapActions('knowledgeModule', [
      'createTemplate',
      'switchEditingLanguage',
    ]),
    ...mapActions('modalsModule', ['openModal']),
  },
};
</script>

<style lang="scss" scoped>
.templates-navigation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding-left: 8px;
  .title {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: black;
  }
  .count {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $grey-9-mayday;
    background-color: $grey-1-mayday;
    border-radius: 4px;
    width: 20px;
    height: 20px;
  }
}

.templates-navigation-footer {
  height: 78px;
}

.add-button {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey-7-mayday;
  font-size: 14px;
  &:hover {
    background-color: white;
    color: $blue-mayday;
  }
}

.templates-navigation-header-options {
  display: flex;
  align-items: center;
  height: 24px;
  border-right: 1px solid $grey-2-mayday;
  padding-right: 12px;
}
</style>
