<template>
  <BaseCollapse :title="$t(`${i18nPrefix}.title`)" :display="true">
    <template v-slot:body>
      <div :class="`${base}`">
        <draggable
          v-model="editableActions"
          handle=".workflow-action__grip"
          ghost-class=".ghost"
          animation="150"
          @change="updateContentWorkflowActionsOrder()"
        >
          <WorkflowAction
            v-for="(action, idx) in editableActions"
            :key="action._id || 'TMP'"
            :list-index="idx"
            :workflow-action="action"
            :content="content"
            @change="
              ($evt) => updateContentWorkflowAction(action._id, $evt, idx)
            "
            @click:delete="($evt) => deleteContentWorkflowAction($evt, idx)"
          />
        </draggable>
        <div class="add-workflow-action">
          <AddWorkflowAction @change="initWorkflowAction" />
        </div>
      </div>
    </template>
  </BaseCollapse>
</template>
<script>
import WorkflowAction from './WorkflowAction/WorkflowAction.vue';
import AddWorkflowAction from './WorkflowAction/AddWorkflowAction.vue';
import { mapActions } from 'vuex';
import BaseCollapse from '../../../../../../components/BaseCollapse.vue';
import cloneDeep from 'lodash.clonedeep';

const actionInit = (action) => {
  const { type: actionType } = action;
  switch (actionType) {
    case 'LIBRARY_ACTION':
      return action;
    case 'AUTO_STEP_SELECTION':
    case 'INIT_SESSION':
    case 'SET_CONTEXT_FROM_CONTEXT_SELECTION':
    case 'SET_CONTEXT_FROM_INPUT':
    case 'SET_CONTEXT_FROM_FORM':
    case 'SET_CONTEXT_FROM_SELECT':
    case 'SET_CONTEXT_FROM_RESOURCE_FUNCTION':
      return {
        type: actionType,
        payload: {},
      };
    default:
      throw new Error('UNHANDLED_ACTION');
  }
};

export default {
  name: 'workflow-main-card',
  components: {
    WorkflowAction,
    AddWorkflowAction,
    BaseCollapse,
  },
  props: {
    workflowActions: {
      type: Array,
      default: () => [],
    },
    content: {
      type: Object,
      default: () => ({}),
    },
    isStagedContent: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    base: Object.freeze('workflow-main'),
    i18nPrefix: 'knowledge.diagnostic-builder.side-bar.workflow.main',
    isEmpty: false,
    payload: [],
    loading: {
      save: false,
    },
    editableActions: [],
  }),
  mounted() {
    this.editableActions = cloneDeep(this.workflowActions);
  },
  methods: {
    initWorkflowAction(action) {
      const newAction = actionInit(action);
      this.editableActions.push(newAction);
    },
    async updateContentWorkflowAction(workflowId, workflowAction, idx) {
      const newContent = await this.updateWorkflowAction({
        contentId: this.content.id,
        workflowActionId: workflowId,
        workflowAction,
        formerWorkflowAction: this.workflowActions[idx],
        isStaged: this.isStagedContent,
      });
      this.propagateWorkflowUpdates(newContent);
    },
    async deleteContentWorkflowAction(workflowId, idx) {
      if (!workflowId) {
        this.editableActions.splice(idx, 1);
      } else {
        const newContent = await this.deleteWorkflowAction({
          contentId: this.content.id,
          workflowActionId: workflowId,
          workflowAction: this.workflowActions[idx],
          isStaged: this.isStagedContent,
        });
        this.propagateWorkflowUpdates(newContent);
      }
    },
    async updateContentWorkflowActionsOrder() {
      const actionIds = this.editableActions
        .map((el) => el._id)
        .filter(Boolean);
      const newContent = await this.orderWorkflowActions({
        contentId: this.content.id,
        orderedWorkflowActionIds: actionIds,
        formerOrderedWorkflowActionIds: this.workflowActions
          .map((el) => el._id)
          .filter(Boolean),
        isStaged: this.isStagedContent,
      });
      this.propagateWorkflowUpdates(newContent);
    },
    propagateWorkflowUpdates(content) {
      this.$emit('local-update-content', content);
    },
    ...mapActions('knowledgeModule', [
      'updateWorkflowAction',
      'deleteWorkflowAction',
      'orderWorkflowActions',
    ]),
  },
  watch: {
    workflowActions: {
      handler(val) {
        this.editableActions = cloneDeep(val);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.workflow-main {
  padding: 12px;
  background-color: $grey-1-mayday;
}
.add-condition-cta {
  color: $grey-6-mayday;
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}

.disabled {
  &:hover {
    color: $grey-6-mayday;
    cursor: not-allowed;
  }
}
</style>
