<template>
  <div class="wrapper">
    <div class="add" :class="{ 'is-adding': isAdding }">
      <input
        class="search-input"
        ref="addInput"
        v-if="isAdding"
        v-model="addQueryText"
      />
      <font-awesome-icon
        :class="isAdding ? 'rotated-icon' : 'icon'"
        :icon="['fal', 'plus']"
        @click="toggleIsAdding"
      />
    </div>
    <div v-if="searchConceptResults.length" class="search-concept-panel">
      <div
        class="search-concept-panel-option"
        v-for="concept in searchConceptResults"
        :key="concept.id"
        @click="addConcept(concept)"
      >
        {{ resolveField(concept, 'label') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import getTranslation from '@/utils/getTranslation';

export default {
  props: {
    conceptIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isAdding: false,
      addQueryText: '',
      timeout: null,
      searchConceptResults: [],
    };
  },
  methods: {
    ...mapActions('knowledgeModule', [
      'acceptContentConcept',
      'searchConcepts',
      'editingLanguage',
    ]),
    resolveField(concept, field) {
      return getTranslation(concept, [this.editingLanguage])[field];
    },
    toggleIsAdding() {
      this.isAdding = !this.isAdding;
      this.searchConceptResults = [];
      this.addQueryText = '';
      if (this.isAdding) {
        this.$nextTick(() => {
          this.$refs.addInput.focus();
        });
      }
    },
    debouncedSearch() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.runSearch, 150);
    },
    async runSearch() {
      clearTimeout(this.timeout);
      this.timeout = null;
      const result = await this.searchConcepts({
        queryText: this.addQueryText,
        excludeConcepts: this.conceptIds,
        language: this.editingLanguage,
      });
      this.searchConceptResults = result.concepts;
    },
    addConcept(concept) {
      this.acceptContentConcept({
        conceptId: concept.id,
        documentId: this.focusContentId,
      });
      this.toggleIsAdding();
    },
  },
  watch: {
    async addQueryText() {
      if (this.addQueryText.length > 0) {
        this.debouncedSearch();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.add {
  display: flex;
  align-items: center;
  border-radius: 2px;
  font-size: 10px;
  height: 17px;
  border: solid 1px;
  padding: 4px;
  color: darkgray;
  background-color: transparent;
}

.add:not(.is-adding):hover {
  cursor: pointer;
  background-color: lightgray;
}

.wrapper {
  position: relative;
}

.search-concept-panel {
  position: absolute;
  top: 100%;
  z-index: 1;
  background-color: white;
  border: 1px solid $grey-4-mayday;
  padding: 10px;
  max-width: 300px;
  max-height: 200px;
  overflow-y: auto;
}

.search-concept-panel-option {
  font-size: 10px;
  &:hover {
    background-color: lightgray;
    cursor: pointer;
  }
}

.rotated-icon {
  rotate: 45deg;
}

.search-input {
  border: none;
  background-color: transparent;
}
</style>
