<template>
  <!-- OVERFLOW ... -->
  <span class="breadcrumb-hidden">
    <button class="breadcrumb-hidden-button" @click="toggleDropdown">
      ...
    </button>

    <!-- OVERFLOW DROPDOWN -->
    <div
      class="breadcrumb-dropdown"
      v-if="hiddenParents.length && dropdown"
      :style="dynamicWidth"
      v-click-outside="toggleDropdown"
    >
      <div
        v-for="(hiddenParent, idx) in hiddenParents"
        :key="idx"
        class="breadcrumb-dropdown-item"
        :class="`breadcrumb-dropdown-item-${idx}`"
        @click="$emit('click:folder', idx + 1)"
      >
        <TextEllipsis :text="hiddenParent.label" :width="maxWidth" />
      </div>
    </div>
  </span>
</template>

<script>
import TextEllipsis from './TextEllipsis';

export default {
  name: 'knowledge-breadcrumb',
  components: {
    TextEllipsis,
  },
  props: {
    hiddenParents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dropdown: false,
    };
  },
  computed: {
    maxWidth() {
      const margin = 32;
      const start = 40;
      return 225 - margin - start;
    },
    dynamicWidth() {
      return this.maxWidth
        ? `max-width: ${this.maxWidth}px`
        : 'max-width: 100%';
    },
  },
  methods: {
    toggleDropdown() {
      this.dropdown = !this.dropdown;
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb-hidden {
  cursor: pointer !important;
  position: relative !important;

  .breadcrumb-hidden-button {
    background-color: white;
    border: none;
    cursor: pointer !important;
    &:hover {
      color: $blue-mayday !important;
    }
  }

  .breadcrumb-dropdown {
    position: absolute !important;
    left: -8px !important;
    top: 22px !important;
    padding: 8px !important;
    background-color: white !important;
    border-radius: 4px !important;
    z-index: 10 !important;
    box-shadow: $shadow-mayday !important;

    &-item {
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      font-size: 12px !important;
      color: $grey-7-mayday !important;
      font-weight: 500 !important;
      cursor: pointer !important;
      &:hover {
        color: black !important;
        font-weight: 700 !important;
      }
    }

    .breadcrumb-dropdown-item + .breadcrumb-dropdown-item {
      margin-top: 6px;
    }

    @for $i from 0 through 20 {
      $indent: 12px * $i;
      &-item-#{$i} {
        margin-left: $indent;
      }
    }
  }
}
</style>
