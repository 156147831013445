<template>
  <BaseDropdownOptionsWrapper
    icon="plus-square"
    :options="dropdownOptions"
    :disabled="!canCreateContent"
    @open="$emit('open')"
    @close="$emit('close')"
    @select="handleSelectOption"
  />
</template>

<script>
import BaseDropdownOptionsWrapper from '@/components/BaseDropdownOptionsWrapper.vue';

export default {
  name: 'add-node-options',
  components: {
    BaseDropdownOptionsWrapper,
  },
  props: {
    parentIds: {
      type: Array,
      default: () => [],
    },
    canCreateContent: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    dropdownOptions() {
      return [
        ...(this.isNotRoot
          ? [
              {
                key: 'ADD_ARTICLE',
                label: this.$t('knowledge.actions.add-article'),
                icon: 'article',
              },
              {
                key: 'ADD_DIAGNOSTIC',
                label: this.$t('knowledge.actions.add-diagnostic'),
                icon: 'diagnostic',
              },
              {
                key: 'ADD_FROM_TEMPLATE',
                label: this.$t('knowledge.templates.import.options.title'),
                icon: 'file-certificate',
              },
            ]
          : []),
        {
          key: 'ADD_COLLECTION',
          label: this.$t('knowledge.actions.add-collection'),
          icon: 'folder',
          divider: this.isNotRoot,
        },
      ];
    },
    isNotRoot() {
      return this.parentIds.length > 1;
    },
  },
  methods: {
    handleSelectOption(e) {
      const key = e.detail[0];

      if (!key) return;

      if (key === 'ADD_ARTICLE') {
        this.addChildren('Article');
      } else if (key === 'ADD_DIAGNOSTIC') {
        this.addChildren('Diagnostic');
      } else if (key === 'ADD_FROM_TEMPLATE') {
        this.$emit('add-child-from-template');
      } else if (key === 'ADD_COLLECTION') {
        this.addChildren('Folder');
      }
    },
    addChildren(nodeType) {
      this.$emit('add-child', nodeType);
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown {
  padding: 0px;
}

.dropdown_icon {
  max-width: 20px;
  margin-right: 5px;
}

.fai_icon {
  width: 20px;
  color: $grey-5-mayday;
  * {
    font-size: 20px;
    height: 16px;
  }
}

.options_selection {
  max-width: 15px;
  color: $grey-5-mayday;
}

.options_selection:hover {
  color: $blue-mayday;
}

.dropdown-button {
  border-width: 0px;
  box-shadow: none;
  margin: auto;
}
</style>
