<template>
  <el-drawer
    class="filters-drawer"
    :show-close="false"
    v-bind="drawerProps"
    v-on="$listeners"
    size="25%"
  >
    <div slot="title" class="drawer-header">
      <span class="title">{{ $t('filters.drawer.title') }}</span>
      <span class="close-link"
        ><a class="close-link-anchor" @click="handleClose"
          ><font-awesome-icon :icon="['fad', 'times-circle']" /></a
      ></span>
    </div>
    <div v-loading="!filtersAreReady" class="drawer-container">
      <Filters
        :filters="filters"
        :values="values"
        :additional-values="additionalValues"
        :daterange-shortcut-values="daterangeShortcutValues"
        :handle-change="handleChange"
        :handle-change-additional="handleChangeAdditional"
        :handle-reset="handleReset"
      />
    </div>
  </el-drawer>
</template>

<script>
import Filters from './Filters';

export default {
  name: 'filters-drawer',

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    additionalValues: {
      type: Object,
      required: true,
    },
    filtersAreReady: {
      type: Boolean,
      required: true,
    },
    daterangeShortcutValues: {
      type: Object,
      required: true,
    },
  },

  components: {
    Filters,
  },

  data() {
    return {};
  },

  computed: {
    drawerProps() {
      const { show } = this;
      return {
        visible: show,
        ...this.$attrs,
      };
    },
  },

  methods: {
    handleChange($event) {
      this.$emit('change', $event);
    },
    handleChangeAdditional($event) {
      this.$emit('change-additional', $event);
    },
    handleReset($event) {
      this.$emit('reset', $event);
    },
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.filters-drawer {
  :deep() .el-drawer__header {
    padding: 0;
    .drawer-header {
      display: flex;
      justify-content: space-between;
      padding: 28px 32px;

      .title {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 129.8%;
        letter-spacing: 1px;
        color: black;
        margin: unset;
      }

      .save-cta-link {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: $blue-mayday;
        align-self: center;
      }

      .close-link-anchor {
        cursor: pointer;
      }
    }
  }

  .drawer-container {
    margin: 0 32px;
  }
}
</style>
