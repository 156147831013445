<template>
  <div class="trigger-option">
    <side-bar-item
      icon="plug"
      :description="tooltipDescription"
      :type="type"
      :active="active"
      @click="$emit('click', !active)"
    />
  </div>
</template>

<script>
import SideBarItem from './SideBarItem';

export default {
  name: 'trigger-option',
  components: {
    SideBarItem,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    type: String,
  },
  data() {
    return {
      tooltipDescription: this.$t(
        'knowledge.diagnostic-builder.side-bar.triggers.tooltip',
      ),
    };
  },
};
</script>
