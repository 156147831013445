<template>
  <modal :show.sync="localOpen" modal-classes="modal-dialog-centered modal">
    <div class="row">
      <div class="col-12 d-flex justify-content-center carousel-main">
        <b-carousel
          id="carousel-no-animation"
          style="text-shadow: 0px 0px 2px black"
          :interval="0"
          no-animation
          img-width="70"
          img-height="340"
          ref="carousel"
          v-model="imageIndexBis"
        >
          <b-carousel-slide
            class="carousel-slide"
            v-for="(image, id) in images"
            :key="id"
          >
            <template #img>
              <v-zoomer style="width: 100%; height: 100%">
                <img
                  :src="getLargestImage(image)"
                  :alt="contentTitle"
                  style="object-fit: contain; width: 100%; height: 100%"
                />
              </v-zoomer>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
      <div class="col-12 d-flex justify-content-center align-items-center pt-2">
        <font-awesome-icon
          class="trigger-slideshow"
          :icon="['fad', 'caret-left']"
          @click="goToPreviousSlide"
        />
        <p class="mb-0 px-2">{{ imageIndexBis + 1 }}/{{ imageCount + 1 }}</p>
        <font-awesome-icon
          class="trigger-slideshow"
          :icon="['fad', 'caret-right']"
          @click="goToNextSlide"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import BCollapse from 'bootstrap-vue';

export default {
  name: 'carousel-modal',
  props: {
    contentTitle: {
      type: String,
    },
    modals: {
      type: Object,
      required: true,
    },
    imageIndex: {
      type: Number,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
    slideShow: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      'b-collapse': BCollapse,
      localOpen: this.slideShow,
      imageIndexBis: this.imageIndex,
      imageCount: this.images.length - 1,
    };
  },
  mounted() {
    document.addEventListener('keydown', this.componentEventsListeners);
  },
  methods: {
    componentEventsListeners(e) {
      if (e.key === 'ArrowRight') {
        e.preventDefault();
        this.goToNextSlide();
      } else if (e.key === 'ArrowLeft') {
        e.preventDefault();
        this.goToPreviousSlide();
      }
    },
    async goToPreviousSlide() {
      await this.$refs.carousel.prev();
      this.$emit('update-image-index', this.imageIndexBis);
    },
    async goToNextSlide() {
      await this.$refs.carousel.next();
      this.$emit('update-image-index', this.imageIndexBis);
    },
    getLargestImage(image) {
      return ['large', 'medium', 'small']
        .map((key) => image[key])
        .filter((el) => !!el)[0];
    },
  },
  watch: {
    localOpen() {
      if (!this.localOpen) {
        this.$emit('close');
      }
    },
    imageIndex() {
      this.imageIndexBis = this.imageIndex;
    },
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.componentEventsListeners);
  },
};
</script>

<style lang="scss" scoped>
.carousel-main {
  max-height: 60vh;
  overflow: auto;
}

.trigger-slideshow {
  cursor: pointer;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.carousel-slide {
  cursor: crosshair;
}
</style>
