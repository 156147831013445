<template>
  <div class="preview-window">
    <base-input
      :model-value="iconUrl"
      @update:model-value="handleUpdateUrl($event.detail[0])"
      :placeholder="$t('knowledge.modals.edit-icon.url-placeholder')"
      :error="!isValidUrl"
      class="mb-0"
    />
    <div v-show="isValidUrl">
      <div class="mt-4 mb-2 preview-label">APERÇU</div>
      <img
        class="preview-image"
        :src="iconUrl"
        @load="isValidUrl = true"
        @error="isValidUrl = false"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    focusedIconValue: String,
  },
  data() {
    return {
      iconUrl: this.focusedIconValue,
      isValidUrl: false,
    };
  },
  computed: {
    urlError() {
      return this.isValidUrl || !this.iconUrl
        ? ''
        : this.$tc('knowledge.modals.edit-icon.url-error');
    },
  },
  methods: {
    urlValidation() {
      return this.isValidUrl;
    },
    handleUpdateUrl(value) {
      this.iconUrl = value;
      this.$emit('update', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-window {
  max-height: 40vh;
  overflow: auto;
}
.preview-label {
  font-size: 12px;
  color: $grey-5-mayday;
  font-weight: bold;
}
.preview-image {
  max-width: 280px;
  border: 0.5px solid $grey-5-mayday;
  border-radius: 4px;
}
</style>
