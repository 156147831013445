<template>
  <!-- SECTION ALERT & NOTIFICATION -->
  <div>
    <div class="element-title">
      {{ $t('settings.general.alerts-notifs.general-settings') }}
    </div>
    <div class="element-description mb-3">
      {{ $t('settings.general.alerts-notifs.description') }}
    </div>
    <div
      v-for="preferenceCategory in notificationPreferenceCategories"
      :key="preferenceCategory.title"
    >
      <!-- NOTIFICATIONS PREFERENCES -->
      <settings-dropdown v-if="preferenceCategory.input === 'checkbox'">
        <template slot="title">
          <settings-dropdown-capitalized-column
            :text="$t(preferenceCategory.title)"
          />
        </template>
        <template slot="dropdown-content">
          <table class="preferences-table">
            <thead>
              <tr class="space-under">
                <th class="first-column-header" />
                <th
                  v-for="channel in channels"
                  :key="channel.value"
                  class="text-center"
                >
                  <div>
                    <font-awesome-icon
                      :icon="['fal', channel.icon]"
                      class="channel-icon"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="child in preferenceCategory.children"
                :key="child.value"
              >
                <td>
                  <div class="element-title">{{ $t(child.title) }}</div>
                  <div class="element-description">
                    {{ $t(child.description) }}
                  </div>
                </td>
                <td v-for="checkbox in child.checkboxes" :key="checkbox.type">
                  <div class="checkbox-wrapper">
                    <el-checkbox
                      size="mini"
                      class="checkbox-button"
                      :value="!!checkbox.value"
                      :disabled="checkbox.disabled"
                      @change="
                        updatePreferences(
                          $event,
                          checkbox.type,
                          preferenceCategory.value,
                        )
                      "
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </settings-dropdown>

      <!-- TOAST SOUND PREFERENCE -->
      <plugin-setting
        class="mt-3"
        v-else-if="preferenceCategory.input === 'switch'"
        :label="preferenceCategory.children[0].title"
        :description="preferenceCategory.children[0].description"
        :active-text="preferenceCategory.children[0].activeText"
        :disable-text="preferenceCategory.children[0].disableText"
        :company-preference="preferenceCategory.children[0].value"
        :getter="preferenceCategory.children[0].getter"
      />
    </div>
    <!-- EMAIL LANGUAGE PREFERENCE -->
    <EmailLanguageSelect
      class="mt-3"
      :email-language-preference="emailLanguagePreference"
      @update-preference="
        $event.preferenceId
          ? updateCompanyPreferencesValue($event)
          : handleAdd('EMAIL_PREFERRED_LANGUAGE', $event.preferenceValue)
      "
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SettingsDropdown from '@/components/Settings/SettingsDropdown';
import SettingsDropdownCapitalizedColumn from '@/components/Settings/SettingsDropdownCapitalizedColumn';
import PluginSetting from 'views/Settings/PluginsSettings/PluginSetting.vue';
import EmailLanguageSelect from '@/views/Settings/GeneralSettings/EmailLanguageSelect';

export default {
  name: 'alerts-settings',
  components: {
    PluginSetting,
    SettingsDropdown,
    SettingsDropdownCapitalizedColumn,
    EmailLanguageSelect,
  },
  computed: {
    notificationPreferenceCategories() {
      // Here we want to add a 'checkboxes' key in each of element.children object
      // This checkboxes key is an array of objects { disabled: boolean, type: string, value: boolean }

      const filteredElements = this.elements.filter((element) => {
        return element.companyPreferences
          ? element.companyPreferences.some((cp) => {
              return this.companyPreferences.some((companyPreference) => {
                return companyPreference.type === cp;
              });
            })
          : true;
      });

      return filteredElements.map((element) => {
        return {
          ...element,
          children: element.children.map((child) => {
            return {
              ...child,
              checkboxes: this.channels.map((channel) => {
                const preferenceType = `${child.value}_${channel.value}`;
                const existingPreference = this.companyPreferences.find(
                  (cp) => cp.type === preferenceType,
                );
                // If preference is of DISABLE type, having the preference will disable it
                const isDisableTypePreference =
                  child.value.startsWith('DISABLE');
                const disabledState = child.disabled.find(
                  (disabledItems) => disabledItems.channel === channel.value,
                );
                let value;

                if (disabledState) {
                  value = disabledState.value;
                } else {
                  value = isDisableTypePreference
                    ? !existingPreference
                    : !!existingPreference;
                }
                return {
                  disabled: !!disabledState,
                  type: preferenceType,
                  value,
                };
              }),
            };
          }),
        };
      });
    },
    emailLanguagePreference() {
      return this.companyPreferences.find(
        (preference) => preference.type === 'EMAIL_PREFERRED_LANGUAGE',
      );
    },
    ...mapState(['companyPreferences']),
  },

  data() {
    return {
      elements: [
        {
          value: 'KNOWLEDGE',
          title:
            'settings.card-settings-options.notification-categories.knowledge-base',
          input: 'checkbox',
          children: [
            {
              value: 'DISABLE_SYSTEM_NOTIFICATIONS',
              disabled: [{ channel: 'INAPP', value: false }],
              title:
                'settings.card-settings-options.system-notifications.title',
              description:
                'settings.card-settings-options.system-notifications.description',
            },
            {
              value: 'DISABLE_DAILY_CONTENTS_VERIFICATIONS',
              disabled: [],
              title:
                'settings.card-settings-options.system-daily-notifications.title',
              description:
                'settings.card-settings-options.system-daily-notifications.description',
            },
            {
              value: 'DISABLE_CONTENT_MENTION',
              disabled: [],
              title: 'settings.card-settings-options.content-mention.title',
              description:
                'settings.card-settings-options.content-mention.description',
            },
            {
              value: 'DISABLE_CONTENT_THREAD_MESSAGE',
              disabled: [{ channel: 'EMAIL', value: false }],
              title:
                'settings.card-settings-options.content-thread-message.title',
              description:
                'settings.card-settings-options.content-thread-message.description',
            },
            {
              value: 'DISABLE_CONTENT_THREAD_VOTE',
              disabled: [{ channel: 'EMAIL', value: false }],
              title: 'settings.card-settings-options.content-thread-vote.title',
              description:
                'settings.card-settings-options.content-thread-vote.description',
            },
            {
              value: 'DISABLE_CONTENT_THREAD_STATUS',
              disabled: [{ channel: 'EMAIL', value: false }],
              title:
                'settings.card-settings-options.content-thread-status.title',
              description:
                'settings.card-settings-options.content-thread-status.description',
            },
          ],
        },
        {
          value: 'HUB',
          title: 'settings.card-settings-options.notification-categories.hub',
          input: 'checkbox',
          children: [
            {
              value: 'DISABLE_TASK_ASSIGNEE',
              disabled: [],
              title:
                'settings.card-settings-options.system-task-assignee.title',
              description:
                'settings.card-settings-options.system-task-assignee.description',
            },
            {
              value: 'DISABLE_TASK_COMMENT',
              disabled: [{ channel: 'EMAIL', value: false }],
              title: 'settings.card-settings-options.system-task-comment.title',
              description:
                'settings.card-settings-options.system-task-comment.description',
            },
            {
              value: 'DISABLE_FEEDBACK',
              disabled: [],
              title: 'settings.card-settings-options.feedback.title',
              description:
                'settings.card-settings-options.feedback.description',
            },
          ],
        },
        {
          value: 'SYSTEM',
          title:
            'settings.card-settings-options.notification-categories.system',
          input: 'checkbox',
          children: [
            {
              value: 'ALLOW_AGENT',
              disabled: [{ channel: 'INAPP', value: true }],
              title: 'settings.card-settings-options.agent.title',
              description: 'settings.card-settings-options.agent.description',
            },
            {
              value: 'DISABLE_INVITATIONS',
              disabled: [],
              title: 'settings.card-settings-options.invitations.title',
              description:
                'settings.card-settings-options.invitations.description',
            },
          ],
        },
        {
          value: 'ACADEMY',
          title:
            'settings.card-settings-options.notification-categories.academy',
          input: 'checkbox',
          companyPreferences: ['ALLOW_ACADEMY'],
          children: [
            {
              value: 'DISABLE_ACADEMY_ASSIGNATION',
              disabled: [],
              title: 'settings.card-settings-options.academy-assignation.title',
              description:
                'settings.card-settings-options.academy-assignation.description',
            },
            {
              value: 'DISABLE_ACADEMY_MENTION',
              disabled: [],
              title: 'settings.card-settings-options.academy-mention.title',
              description:
                'settings.card-settings-options.academy-mention.description',
            },
          ],
        },
        {
          value: 'TOASTS',
          title: 'settings-layout.menu-data.toasts.title',
          input: 'switch',
          children: [
            {
              title: 'settings.card-settings-options.toast-sound.title',
              description:
                'settings.card-settings-options.toast-sound.description',
              activeText: 'settings.card-settings-options.toast-sound.active',
              disableText:
                'settings.card-settings-options.toast-sound.disabled',
              value: 'ALLOW_TOAST_SOUND',
              getter: 'companyAllowToastSoundPreference',
              disabled: [],
            },
          ],
        },
      ],
      channels: [
        {
          value: 'INAPP',
          icon: 'bell',
        },
        {
          value: 'EMAIL',
          icon: 'envelope',
        },
      ],
    };
  },

  methods: {
    async updatePreferences(e, preferenceType, preferenceValue) {
      const isDisableType = preferenceType.startsWith('DISABLE');
      if (e) {
        return isDisableType
          ? this.handleDelete(preferenceType)
          : this.handleAdd(preferenceType, preferenceValue);
      } else {
        return isDisableType
          ? this.handleAdd(preferenceType, preferenceValue)
          : this.handleDelete(preferenceType);
      }
    },
    handleAdd(preferenceType, preferenceValue) {
      this.addCompanyPreferences({
        preferenceType,
        preferenceValue: String(preferenceValue),
      });
    },
    async handleDelete(preferenceType) {
      const preference = this.companyPreferences.find(
        ({ type }) => type === preferenceType,
      );
      await this.deleteCompanyPreferences({
        preferenceIds: [preference.id],
      });
    },
    ...mapActions([
      'addCompanyPreferences',
      'deleteCompanyPreferences',
      'updateCompanyPreferencesValue',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.first-column-header {
  width: 80%;
}
.channel-icon {
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 4px;
  background-color: $grey-4-mayday;
  color: white;
}

tr.space-under > th {
  padding-bottom: 1em;
}

.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.preferences-table {
  width: 100%;
  margin-top: -48px;
}

.checkbox-wrapper {
  display: flex;
  justify-content: center;
}

.checkbox-button {
  display: inline;
  :deep() .el-checkbox__label {
    color: black;
    padding: 0px;
  }
  &.is-checked {
    :deep() .el-checkbox__inner {
      border-radius: 2px;
      background-color: $blue-mayday;
      box-sizing: border-box;
      box-shadow: $shadow-sm-mayday;
      border: 1px solid $blue-mayday;
      &:after {
        display: none;
      }
    }
  }
}
</style>
