<template>
  <div
    v-show="visible"
    v-click-outside="handleClose"
    class="notification-popover-container"
  >
    <notification-view-ce
      source="admin"
      :access-token="accessToken"
      :visible="visible"
      :has-comment-permission="
        hasReadTaskPermission ? hasCommentTaskPermission : true
      "
      :has-view-permission="hasReadTaskPermission"
      :contributors="JSON.stringify(allContributors)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'notification-popover',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localVisible: false,
    };
  },
  created() {
    window.___MAYDAY_CE___.userId = this.userId;
    window.addEventListener('md-goToContent', this.goToContent);
    window.addEventListener('md-goToUrl', this.goToUrl);
    window.addEventListener(
      'md-unreadCountUpdate',
      this.handleUnreadCountUpdate,
    );
  },
  destroyed() {
    window.removeEventListener('md-goToContent', this.goToContent);
    window.removeEventListener('md-goToUrl', this.goToUrl);
    window.removeEventListener(
      'md-unreadCountUpdate',
      this.handleUnreadCountUpdate,
    );
  },
  computed: {
    hasCommentTaskPermission() {
      return this.hasPermission('COMMENT_TASK');
    },
    hasReadTaskPermission() {
      return this.hasPermission('READ_TASK');
    },
    ...mapGetters(['userId', 'hasPermission']),
    ...mapGetters('adminModule', ['allContributors']),
    ...mapState(['accessToken']),
  },
  methods: {
    handleUnreadCountUpdate({ detail }) {
      this.$emit('unread-count-update', detail);
    },
    handleClose() {
      if (this.localVisible) this.$emit('close');
    },
    async goToContent({ detail }) {
      const {
        id,
        lang,
        type,
        blockId,
        threadId,
        messageId,
        forceAdmin,
        blockKey,
        parentId,
      } = detail;
      let { entityId, entityType } = detail;
      entityId = id || entityId;
      entityType = type || entityType;

      if (entityType === 'PublishedModule') {
        return this.goToPublishedModule(entityId, lang, forceAdmin, parentId);
      }

      if (entityType === 'Module')
        return this.goToModule(entityId, lang, blockId);

      if (entityType === 'Program')
        return this.goToProgram(entityId, lang, forceAdmin, blockId);

      if (entityType === 'Slide')
        return this.goToSlide(
          entityId,
          parentId,
          lang,
          blockId,
          blockKey,
          forceAdmin,
          parentId,
        );

      if (entityType === 'Task') return this.goToTask(entityId);

      const payload = {
        entityId,
        entityType,
        lang,
        hash: blockId ? `#${blockId}` : '',
        query: {
          from: 'notification',
          ...(threadId ? { threadId } : {}),
          ...(messageId ? { messageId } : {}),
        },
      };

      this.$route.name.includes('mayday-web')
        ? this.goToWebTranslatedEntity(payload)
        : this.goToTranslatedEntity(payload);
    },
    goToTask(taskId) {
      window.location.href = `/hub?taskId=${taskId}`;

      this.visible = false;
    },
    goToPublishedModule(moduleId, lang, forceAdmin, parentId) {
      if (forceAdmin) {
        window.open(
          `/academy/modules/${lang}/${parentId}/version/${moduleId}?from=notifications`,
          '_blank',
        );
        return;
      }

      const url = `/desk/academy/modules/${lang}/${moduleId}`;

      window.open(url, '_blank');
      this.visible = false;
    },
    goToModule(moduleId, lang, blockId) {
      const url = `/academy/modules/${lang}/${moduleId}${
        blockId ? `?blockId=${blockId}#introduction` : ''
      }`;

      window.open(url, '_blank');
      this.visible = false;
    },
    goToProgram(programId, lang, forceAdmin, blockId) {
      const url = `${
        forceAdmin ? '' : '/desk'
      }/academy/programs/${lang}/${programId}${
        blockId ? `?blockId=${blockId}#introduction` : ''
      }`;

      window.open(url, '_blank');
      this.visible = false;
    },
    goToSlide(
      slideId,
      moduleId,
      lang,
      blockId,
      blockKey,
      forceAdmin,
      parentId,
    ) {
      if (forceAdmin) {
        window.open(
          `/academy/modules/${lang}/${parentId}/version/${moduleId}?s=${slideId}&from=notifications#content`,
          '_blank',
        );
        return;
      }

      const url = `/academy/modules/${lang}/${moduleId}?s=${slideId}&blockId=${blockId}&blockKey=${blockKey}#content`;

      window.open(url, '_blank');
      this.visible = false;
    },
    goToUrl({ detail }) {
      this.$router.push({
        path: detail,
        query: {
          invitation: 'expired',
        },
      });
      this.visible = false;
    },
    ...mapActions('knowledgeModule', ['goToTranslatedEntity']),
    ...mapActions('webKnowledgeModule', ['goToWebTranslatedEntity']),
  },
  watch: {
    visible() {
      if (this.visible) {
        window.dispatchEvent(new CustomEvent('md-getNotifications'));
      }
      setTimeout(() => {
        this.localVisible = this.visible;
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.notification-popover-container {
  position: fixed;
  bottom: 90px;
  left: 70px;
  top: 4px;
  z-index: 50;
  width: 350px;
  overflow: auto;
  border-radius: 4px;
  background-color: white;
  box-shadow: $shadow-mayday;
}

:deep() * {
  word-break: normal;
}
</style>
