var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"public-knowledge-container"},[_c('div',{staticClass:"public-knowledge-header"},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('router-link',{staticClass:"mr-4",attrs:{"to":"/settings/general#knowledges"}},[_c('base-button',{attrs:{"text":_vm.$t('settings.new-knowledge.back'),"icon":"chevron-left","size":"md","variant":"tertiary"}})],1),(!_vm.localDeploying)?_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" "+_vm._s(_vm.enrichedFocusCompanyPublicConfig.label)),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.enrichedCompanyPublicConfigs),function(config){return _c('router-link',{key:config.id,staticClass:"dropdown-links",attrs:{"to":{
              name: 'public-settings',
              params: {
                knowledgeId: config.knowledgeId
                  ? config.knowledgeId
                  : 'default',
              },
            }}},[_c('el-dropdown-item',[_vm._v(" "+_vm._s(config.label)+" ")])],1)}),1)],1):_c('span',[_vm._v(" "+_vm._s(_vm.enrichedFocusCompanyPublicConfig.label)+" ")])],1),_c('div',[_c('el-popover',{staticClass:"publish-popover",model:{value:(_vm.publishChanges),callback:function ($$v) {_vm.publishChanges=$$v},expression:"publishChanges"}},[(_vm.publishChanges)?_c('publish-popover',{attrs:{"operations":_vm.getAllOperations},on:{"cancel":function($event){_vm.publishChanges = false},"confirm":_vm.handlePublish,"delete-operation":_vm.handleDeleteOperation}}):_vm._e(),_c('base-button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"reference","variant":"primary","size":"md","disabled":!_vm.hasChanged,"text":_vm.$t('public-knowledge.publish')},slot:"reference"})],1)],1)]),_c('div',{staticClass:"public-knowledge-layout-wrapper"},[_c('div',{staticClass:"public-knowledge-layout"},[(!_vm.localDeploying)?_c('div',{staticClass:"public-knowledge-menu-wrapper"},[_c('SettingsMenu',{attrs:{"data":_vm.items,"knowledge-type":"public"}})],1):_vm._e(),_c('div',{staticClass:"public-knowledge-wrapper"},[(!_vm.localDeploying && !_vm.loading)?_c('router-view',{staticClass:"router-view",attrs:{"items":_vm.items},on:{"new-operation":function($event){return _vm.newOperation($event)},"new-language":function($event){return _vm.addLanguage({ id: _vm.focusKnowledge.id, lang: $event })},"set-multilingual":function($event){return _vm.setMultilingual({ id: _vm.focusKnowledge.id, lang: $event })}}}):(_vm.localDeploying && !_vm.loading)?_c('div',{staticClass:"status"},[_c('deployment-steps',{staticClass:"deployment",attrs:{"httpsWarning":_vm.httpsWarning,"state":_vm.state,"steps":_vm.getSteps,"time":_vm.formattedTime(_vm.deploymentStatus.createdAt)},on:{"return":_vm.handleReturn}})],1):(_vm.loading)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],staticClass:"loader"}):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }