<template>
  <div class="search-table">
    <div v-if="data.length" class="search-stat__search-table">
      <el-table
        :data="data"
        :default-sort="{ prop: 'date', order: 'descending' }"
        style="width: 100%"
        height="300"
      >
        <!-- SEARCH QUERY -->
        <el-table-column
          prop="name"
          :label="
            $t(
              'analytics-knowledge-performance.search-results.table.columns.search-termes',
            )
          "
          sortable
          width=""
        />
        <!-- OCCURENCES -->
        <el-table-column
          prop="count"
          :label="
            $t(
              'analytics-knowledge-performance.search-results.table.columns.occurences',
            )
          "
          sortable
          width="150"
        />
      </el-table>
      <div class="w-100 d-flex align-items-center justify-content-between">
        <p class="pt-3">
          {{ data.length }}
          {{
            $tc(
              'analytics-knowledge-performance.search-results.table.lines',
              data.length > 1 ? 2 : 1,
            )
          }}
        </p>
        <el-button
          :loading="exportSearchLoading"
          @click="$emit('export-search-stats')"
          class="icon-button"
        >
          <font-awesome-icon :icon="['fas', 'download']"></font-awesome-icon>
        </el-button>
      </div>
    </div>
    <div v-if="!data.length" class="row">
      <img
        src="~assets/search-results.png"
        class="search-stat-card__table-illustration"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'search-table',
  props: {
    data: Array,
    exportSearchLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.search-stat__search-table {
  width: 100%;
  background: transparent;

  p {
    font-weight: 400;
    color: $grey-6-mayday;
  }

  :deep() .el-table,
  :deep() .el-table th,
  :deep() .el-table tr {
    background-color: transparent;
  }

  :deep() .el-table th {
    border-top: 0;
    font-weight: 700;
    font-size: 0.9em;
    color: $grey-6-mayday;
  }

  :deep() .el-table tr {
    font-weight: 600;
    color: $unknown-blue-3-mayday;
  }
}

.search-stat-card__table-illustration {
  width: 100%;
}

.icon-button {
  color: $blue-mayday;
  border: none;
}
</style>
