<template>
  <div>
    <div class="d-flex flex-column justify-content-left">
      <!-- KNOWLEDGE NAME -->
      <div class="category">
        <div class="title">
          {{
            $t('settings.new-knowledge.choose-type.private.form.label-title')
          }}
        </div>
        <div class="description">
          {{
            $t(
              'settings.new-knowledge.choose-type.private.form.label-description',
            )
          }}
        </div>
        <base-input
          :model-value="models.label"
          @update:model-value="models.label = $event.detail[0]"
          :placeholder="
            $t(
              'settings.new-knowledge.choose-type.private.form.label-placeholder',
            )
          "
        />
      </div>

      <!-- KNOWLEDGE LANGUAGE -->
      <div class="category mt-3">
        <div class="title">
          {{ $t('settings.new-knowledge.choose-type.private.form.lang-title') }}
        </div>
        <div class="description">
          {{
            $t(
              'settings.new-knowledge.choose-type.private.form.lang-description',
            )
          }}
        </div>
        <base-select-wrapper
          :options="formattedLangOptions"
          :icon="true"
          :value="models.lang"
          @select="models.lang = $event"
        />
      </div>

      <!-- KNOWLEDGE ACCESS RESTRICTIONS -->
      <div class="category mt-3">
        <div class="title">
          {{ $t('settings.new-knowledge.choose-type.groups-access.title') }}
        </div>
        <div class="description">
          {{
            $t('settings.new-knowledge.choose-type.groups-access.description')
          }}
        </div>
        <ContentViewSettingsCallout
          v-if="!models.groups.length"
          trad="settings.new-knowledge.choose-type.groups-access"
          icon="exclamation-triangle"
          type="warning"
        />

        <base-select-wrapper
          class="select"
          :options="formattedGroupOptions"
          :icon="true"
          :multiple="true"
          :value="models.groups"
          @select="models.groups = $event"
          :placeholder="$t('generic.choose')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContentViewSettingsCallout from '@/components/Drawers/ContentViewSettingDrawer/ContentViewSettingsComponents/ContentViewSettingsCallout.vue';
import { langOptions } from '@/utils/langOptions.js';

export default {
  name: 'new-knowledge-private',
  components: {
    ContentViewSettingsCallout,
  },
  props: {
    groupOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      models: {
        label: '',
        lang: 'fr-fr',
        groups: [],
      },
      langOptions: langOptions,
    };
  },
  computed: {
    formattedLangOptions() {
      return this.langOptions.map((lang) => {
        return {
          key: lang,
          label: this.$t(`knowledge.languages.${lang}`),
        };
      });
    },
    formattedGroupOptions() {
      return this.groupOptions.map((group) => {
        return {
          ...group,
          key: group.id,
          label: group.name,
        };
      });
    },
  },
  watch: {
    models: {
      handler(newVal) {
        this.$emit('new-kb-models', newVal);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 6px;
    max-width: 390px;
  }
  .description {
    font-size: 16px;
    color: $grey-8-mayday;
    margin-bottom: 18px;
    max-width: 350px;
  }

  .select {
    width: 60%;
  }

  .el-select {
    height: 32px;
  }
  :deep() .el-input {
    height: 100%;
  }
  :deep() .el-input__inner {
    height: 100%;
  }
  :deep() .el-input__suffix {
    display: flex;
    align-items: center;
  }
  :deep() .el-input__prefix {
    display: flex;
    align-items: center;
  }

  :deep() .el-select__tags {
    max-width: 100% !important;
  }
  .flag-wrapper {
    width: 23px;
    height: 21px;
    background-color: $grey-4-mayday;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }
  .el-input {
    height: 32px;
    width: 350px;
  }
  .placeholder-value {
    font-size: 12px;
    color: $grey-6-mayday;
  }
}
</style>
