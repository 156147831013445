<template>
  <div
    ref="settings-list"
    class="settings-list"
    @scroll="handleScroll"
    @scrollend="focus = false"
  >
    <div class="settings-wrapper">
      <div v-for="item in items" :key="item.title">
        <settings-item
          v-for="child in item.children"
          :id="`settings-item_${child.name}_${child.hash.substring(1)}`"
          :key="child.title"
          :ref="child.hash"
          :active="activeRoute === child.hash"
          :item="child"
        >
          <component
            :is="child.component"
            v-if="child.component"
            :general-success="success"
            v-bind="child.props"
          />
        </settings-item>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsItem from '@/components/Settings/SettingsItem';
import KnowledgeManageSettings from './GeneralSettings//KnowledgeManageSettings/KnowledgeManageSettings';
import AlertsSettings from './GeneralSettings/AlertsSettings';
import IntegrationSettingsPage from './IntegrationSettings/IntegrationSettingsPage';
import PluginCatalog from './PluginsSettings/PluginCatalog';
import UsersSettings from './PermissionSettings/UsersSettings/UsersSettings';
import GroupsSettingsMain from './PermissionSettings/AccessSettings/GroupsSettingsMain';
import RolesSettingsMain from './PermissionSettings/AccessSettings/RolesSettingsMain';
import LabelCategorySettings from './PermissionSettings/AccessSettings/LabelCategorySettings/LabelCategorySettings';
import CompanySettings from './CompanySettings/CompanySettings';
import ContentParameters from './ParametricKnowledgeSettings/ContentParameters';
import ContextVariables from './ParametricKnowledgeSettings/ContextVariables';
import GlobalParameters from './ParametricKnowledgeSettings/GlobalParameters';
import SynonymsSettingsCard from './SearchSettings/SynonymsSettingsCard';
import SearchSettings from './SearchSettings/SearchSettings';
import ModuleLabelsSettings from './ModuleLabelsSettings/ModuleLabelsSettings';
import AuthenticationSettings from './SecuritySettings/AuthenticationSettings/AuthenticationSettings.vue';
import HubSettings from 'views/Settings/GeneralSettings/HubSettings.vue';
import ConfigureAi from './AiSettings/ConfigureAi.vue';
import SsoProfileSettings from './PermissionSettings/SsoProfileSettings/SsoProfileSettings.vue';

const HASH_FALLBACK = {
  general: 'knowledges',
  integrations: 'catalog',
  plugins: 'plugins',
  users: 'users',
  permissions: 'groups',
  search: 'synonyms',
  knowledges: 'contents',
  company: 'company',
  security: 'security',
  configureAi: 'ai-settings',
};

export default {
  name: 'SettingsView',
  components: {
    SettingsItem,
    // GENERAL
    KnowledgeManageSettings,
    AlertsSettings,
    HubSettings,
    // INTEGRATION
    IntegrationSettingsPage,
    // PLUGINS,
    PluginCatalog,
    // PERMISSIONS
    UsersSettings,
    GroupsSettingsMain,
    RolesSettingsMain,
    SsoProfileSettings,
    LabelCategorySettings,
    // SEARCH
    SearchSettings,
    SynonymsSettingsCard,
    // CONTENTS
    ContentParameters,
    ContextVariables,
    GlobalParameters,
    // COMPANY
    CompanySettings,
    // ACADEMY
    ModuleLabelsSettings,
    // SECURITY
    AuthenticationSettings,
    ConfigureAi,
  },
  data() {
    return {
      activeRoute: null,
      focus: false,
      scroll: false,
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    success: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    focusSetting(route) {
      if (this.scroll) return;

      const { path, hash } = route;
      const setting = path.split('/').pop();
      const element = this.$refs[hash];
      this.activeRoute = hash;
      if (!element || !element.length) {
        if (hash) return;
        this.$router.replace({ hash: HASH_FALLBACK[setting] || '' });
        return;
      }
      this.focus = true;
      this.$refs['settings-list'].scrollTo({
        top: element[0].$el.offsetTop - 96,
        behavior: 'smooth',
      });
    },
    handleScroll() {
      if (this.focus) return;
      const top = this.$refs['settings-list'].scrollTop + 180;
      const items = this.getItemsDiv();

      let idx = items.findIndex(
        ({ offsetTop, offsetBottom }) =>
          offsetTop <= top && top <= offsetBottom,
      );
      if (idx < 0) return;

      const [, newName, newId] = items[idx].id.split('_');

      const newHash = '#' + newId;
      const { hash } = this.$route;
      if (hash === newHash) return;

      this.scroll = true;
      this.activeRoute = newHash;
      this.$router.replace({ name: newName, hash: newHash });
      setTimeout(() => {
        this.scroll = false;
      }, 500);
    },
    getItemsDiv() {
      const itemsEl = document.querySelectorAll(`[id^="settings-item_"]`);
      return [...itemsEl].map(({offsetTop, id, offsetHeight}) => ({
        offsetTop,
        id,
        offsetBottom: offsetTop + offsetHeight,
      }));
    }
  },

  watch: {
    $route: {
      handler(newVal) {
        this.focusSetting(newVal);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-list {
  width: 100%;
  flex: 1;
  overflow-y: auto;
}

.settings-wrapper {
  max-width: 60vw;
}
</style>
