<template>
  <div :class="`${baseClass}__error`">
    <small :class="`${baseClass}__error__title`"
      ><strong>Erreur {{ error.status }}</strong></small
    >
    <small :class="`${baseClass}__error__description mt-2`">
      {{ description }}
    </small>
    <span
      :class="`${baseClass}__error__cta mt-3`"
      v-if="!error.retry"
      @click="handleOnClick"
    >
      <font-awesome-icon :spin="loading" class="mr-2" :icon="['fas', 'redo']" />
      Essayer à nouveau
    </span>
  </div>
</template>

<script>
export default {
  name: 'product-agora-documentation-error',
  props: {
    error: {
      type: Object,
    },
  },
  data() {
    return {
      baseClass: 'product-agora-documentation',
      loading: false,
    };
  },
  computed: {
    description() {
      return this.error && this.error.retry
        ? 'Une erreur est survenue à nouveau, veuillez réessayer utltérieurement.'
        : 'Veuillez cliquer sur le bouton ci-dessous pour essayer de nouveau.';
    },
  },
  methods: {
    handleOnClick() {
      this.loading = true;
      this.$emit('click:retry');
    },
  },
};
</script>
<style lang="scss" scoped>
.product-agora-documentation__error {
  background-color: rgba($red-mayday, 0.1);
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  small {
    color: $red-mayday;
  }

  &__description {
    line-height: 14px;
  }

  &__cta {
    padding: 0px 10px 0px 10px;
    border-radius: 4px;
    background-color: white;
    color: $grey-8-mayday;
    cursor: pointer;
    font-size: 12px;
    width: fit-content;
    display: flex;
    align-items: center;
    align-self: flex-end;
  }
}
</style>
