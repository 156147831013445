<template>
  <div>
    <!-- POSITION -->
    <div class="rule-section d-flex my-2">
      <div class="col-2 text-right">
        POSITION
        <span class="mx-2">|</span>
      </div>
      <div>
        <span v-for="(bc, index) in getPath" :key="index">
          <router-link v-if="index === getPath.length - 1" to="#">
            {{ bc }}
          </router-link>
          <span v-else> {{ bc }} ></span>
        </span>
      </div>
    </div>

    <!-- ACTIONS -->
    <div class="rule-section d-flex my-2">
      <div class="col-2 text-right">
        {{ $t('automations.main.row.actions.title').toUpperCase() }}
        <span class="mx-2">|</span>
      </div>
      <div class="collapse-actions" v-if="onStep">
        {{ $t('automations.main.row.actions.description-1') }}
        <span class="collapse-actions-special">{{ getPath.slice(-1)[0] }}</span>
        {{ $t('automations.main.row.actions.description-2') }}
        <span class="collapse-actions-special">{{ rule.content.label }}</span>
      </div>
      <div class="collapse-actions" v-if="onDiag">
        {{ $t('automations.main.row.actions.description-1') }}
        <span class="collapse-actions-special">{{ rule.content.label }}</span>
        {{ $t('automations.main.row.actions.description-2-bis') }}
      </div>
    </div>

    <!-- LOGIQUE -->
    <div class="rule-section d-flex my-2">
      <div class="col-2 text-right">
        {{ $t('automations.main.row.logic.title').toUpperCase() }}
        <span class="mx-2">|</span>
      </div>
      <div class="collapse-actions">
        <el-select v-model="localRule.group.type" class="logic-select" disabled>
          <el-option
            :label="$t('automations.main.row.logic.all')"
            value="ALL"
            class="pl-1"
          ></el-option>
          <el-option
            :label="$t('automations.main.row.logic.any')"
            value="ANY"
          ></el-option>
        </el-select>
      </div>
    </div>

    <!-- CONDITION -->
    <div class="rule-section d-flex my-2">
      <div class="col-2 text-right">
        CONDITION
        <span class="mx-2">|</span>
      </div>
      <div class="collapse-condition-content">
        <div
          v-for="(attribute, index) in rule.group.RuleAttribute"
          :key="attribute.id"
          class="d-flex my-2"
        >
          <span class="mr-2 d-flex logic-operator justify-content-end pr-2">
            <span v-if="index > 0" class="and-operator mr-1">{{
              localRuleType
            }}</span>
            {{ $t('automations.main.row.conditions.if') }}
            <!-- <span class="px-2">|</span> -->
          </span>
          <AutomationsRuleAttribute
            :attribute="attribute"
            :inputDisable="true"
            @delete-attribute="deleteAttribute(attribute.id)"
          />
        </div>
        <div class="d-flex my-2" v-if="addNewAttribute">
          <span
            class="mr-2 mr-2 d-flex logic-operator justify-content-end pr-2"
          >
            <span
              v-if="rule.group.RuleAttribute.length > 0"
              class="and-operator mr-1"
              >{{ localRuleType }}</span
            >
            {{ $t('automations.main.row.conditions.if') }}
            <!-- <span class="px-2">|</span> -->
          </span>
          <AutomationsRuleAttribute
            :attribute="newAttribute"
            @update-rule-attribute="updateRuleAttribute"
            @cancel-new-attribute="addNewAttribute = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutomationsRuleAttribute from './AutomationsRuleAttribute';

export default {
  name: 'AutomationsRule',
  components: {
    AutomationsRuleAttribute,
  },
  props: {
    rule: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localRule: {},
      newAttribute: {
        id: '',
        companyAttribute: {},
        companyAttributeValue: {},
        operator: '',
        value: [],
      },
      addNewAttribute: false,
      number: [
        { operator: 'is', value: 'est égal à' },
        { operator: 'is_not', value: 'est différent de' },
        { operator: 'less_than', value: 'est inférieur à' },
        { operator: 'greater_than', value: 'est supérieur à' },
      ],
      string: [
        { operator: 'is', value: 'est égal à' },
        { operator: 'is_not', value: 'est différent de' },
        { operator: 'includes', value: 'contient' },
        { operator: 'not_includes', value: 'ne contient pas' },
      ],
      text: [
        { operator: 'includes', value: 'contient' },
        { operator: 'not_includes', value: 'ne contient pas' },
      ],
      boolean: [{ operator: 'is', value: 'Est' }],
    };
  },
  created() {
    this.localRule = { ...this.rule };
  },
  computed: {
    onStep() {
      return ['Step', 'keyStep'].includes(this.rule.content.type);
    },
    onDiag() {
      return this.rule.content.type === 'Diagnostic';
    },
    getPath() {
      if (this.rule.content.enrichedCaseParameters) {
        return this.rule.content.enrichedCaseParameters.map(
          (ancestor) => ancestor.label,
        );
      }
      return [];
    },
    localRuleType() {
      return this.localRule.group.type === 'ANY'
        ? this.$t('automations.main.row.conditions.or')
        : this.$t('automations.main.row.conditions.and');
    },
  },
  methods: {
    deleteAttribute(attrId) {
      this.localRule.group.RuleAttribute =
        this.localRule.group.RuleAttribute.filter((ra) => ra.id !== attrId);
      if (attrId !== '') {
        const rules = this.rulesParser();
        this.$root.$emit('automation-update', {
          id: this.localRule.id,
          alias: this.localRule.alias,
          rules: JSON.stringify({
            type: this.localRule.type,
            conditions: rules,
          }),
        });
      }
    },

    rulesParser() {
      return this.localRule.group.RuleAttribute.map((ra) => ({
        id: ra.id,
        companyAttributeId: ra.companyAttribute.id,
        companyAttributeValueId: ra.companyAttributeValue
          ? ra.companyAttributeValue.id
          : null,
        operator: ra.operator,
        value: ra.value,
      }));
    },
  },
  watch: {
    rule() {
      this.localRule = { ...this.rule };
      this.addNewAttribute = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-condition-enable {
  cursor: pointer;
}

.add-condition-enable:hover {
  color: $blue-mayday;
  cursor: pointer;
}

.add-condition-disable {
  cursor: not-allowed;
  font-size: 14px;
  color: $grey-5-mayday;
}
a {
  color: $blue-mayday !important;
}
.rule-section {
  color: $grey-5-mayday;
}

.collapse-actions {
  color: $unknown-blue-1-mayday;
  font-weight: 400;
  .collapse-actions-special {
    font-weight: 700;
    color: $blue-mayday;
  }
}
.collapse-condition-content {
  padding: 10px;
  border: 1px solid $grey-3-mayday;
  background: $grey-1-mayday;
  border-radius: 5px;
  width: 100%;
}
.and-operator {
  color: $blue-mayday;
}
.logic-select {
  :deep() .el-input__inner {
    height: 20px;
    border: none;
    padding-left: 0px;
    font-size: 13px;
  }
}

.logic-select {
  :deep() .el-input__inner:focus {
    height: 20px;
    border: none;
    padding-left: 0px;
    font-size: 13px;
  }
}

.logic-select {
  :deep() .el-input__icon {
    line-height: 20px;
  }
}

.logic-select {
  :deep() .el-input__inner:disabled {
    padding-left: 1px;
  }
}

.logic-operator {
  width: 54px;
  border-right: 1px solid $grey-4-mayday;
}

.add-attribute-btn {
  margin-left: 54px;
}
</style>
