<template>
  <settings-dropdown>
    <template slot="title">
      <settings-dropdown-capitalized-column
        :text="''"
        :is-editing="true"
        can-edit
        icon="tags"
        color="#0081F9"
        @cancel="$emit('cancel-category')"
        @update-text="handleUpdateCategory"
      >
      </settings-dropdown-capitalized-column>
    </template>
  </settings-dropdown>
</template>

<script>
import SettingsDropdown from '@/components/Settings/SettingsDropdown';
import SettingsDropdownCapitalizedColumn from '@/components/Settings/SettingsDropdownCapitalizedColumn';

import { blueMayday } from '@/assets/scss/_colors.scss';

export default {
  name: 'label-category-settings-dropdown',
  components: {
    SettingsDropdown,
    SettingsDropdownCapitalizedColumn,
  },
  methods: {
    handleUpdateCategory(newName) {
      if (!newName && !newName.trim()) return;

      return this.$emit('create-category', {
        name: newName.trim(),
        icon: 'tags',
        color: blueMayday,
        companyUserLabels: [],
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
