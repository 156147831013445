<template>
  <AnalyticsModal :visible.sync="showModel" :fullscreen="false">
    <template v-slot:header>
      <div class="title-wrapper">
        <font-awesome-icon class="title-icon" :icon="['fad', 'chart-line']" />
        <span class="title">{{
          $t('new-dashboard.header.title.analytics-adoption-rate-modal')
        }}</span>
      </div>
    </template>
    <template v-slot:body>
      <div class="adoption-rate-modal-container">
        <div class="range-summary-wrapper">
          <font-awesome-icon
            class="date-icon"
            :icon="['fad', 'calendar-week']"
          />
          <span class="range-summary">{{
            $t('new-dashboard.adoption-rate.date-range-summary', {
              ...dateRange,
            })
          }}</span>
        </div>
        <div class="chart-wrapper">
          <AnalyticsTitle
            :title="$t('new-dashboard.adoption-rate.chart-title')"
            :tooltip-content="
              $t('new-dashboard.header.tooltip.adoption-rate-chart')
            "
          />
          <LineChart
            v-if="show"
            class="adoption-rate-chart"
            :chart-data="chartData"
            :extra-options="chartOptions"
          />
        </div>
      </div>
    </template>
  </AnalyticsModal>
</template>

<script>
import AnalyticsModal from '../AnalyticsModal';
import AnalyticsTitle from '../AnalyticsTitle';
import LineChart from '@/components/Charts/LineChart';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    dateRange: {
      type: Object,
      required: true,
    },
  },

  components: {
    AnalyticsModal,
    AnalyticsTitle,
    LineChart,
  },

  data() {
    return {
      chartOptions: {
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'month',
              },
            },
          ],
        },
      },
    };
  },

  computed: {
    showModel: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
        return value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.title-wrapper {
  display: flex;
  align-items: center;

  .title-icon {
    color: $blue-mayday;
  }

  .title {
    align-self: flex-end;
    margin-left: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: $unknown-blue-1-mayday;
  }
}

.adoption-rate-modal-container {
  padding-top: 25px;

  .date-icon {
    color: $blue-mayday;
    margin-right: 16px;
  }

  .chart-wrapper {
    padding-top: 32px;

    .adoption-rate-chart {
      margin-top: 21px;
    }
  }
}
</style>
