<template>
  <base-dropdown
    ref="dropdown"
    :right="rightDirectionDropdown"
    :disabled="disabled"
    :hide-arrow="hideArrow"
    class="dropdown"
    :closeOnClick="false"
    @change="handleOpening"
  >
    <div
      slot="title"
      v-loading="translationDropdownLoading"
      class="d-flex align-items-center justify-content-between languages-btn"
      :class="{ open: isOpen }"
    >
      <div class="d-flex align-items-cente leading-icon">
        <LanguageFlag class="flag-emoji" :language="currentLanguage" emoji />
        <span :class="[currentLanguage === 'none' ? 'text-grey' : 'ml-2']">{{
          tight
            ? $t(`knowledge.languages.tight.${currentLanguage}`)
            : $t(`knowledge.languages.${currentLanguage}`)
        }}</span>
      </div>
      <font-awesome-icon
        v-if="!disabled"
        :icon="['fal', isOpen ? 'chevron-down' : 'chevron-right']"
        class="ml-2 chevron-icon"
      />
    </div>
    <div>
      <TranslationItem
        data-testid="language-item"
        v-for="language in languages"
        :key="language"
        :language="language"
        :itemLabel="labelTranslations[language]"
        :status="itemStatus(language)"
        :editing-action="editingAction"
        @unset-language="handleUnsetLanguage($event)"
        @click.native="handleLanguageChange(language)"
      />
      <slot></slot>
    </div>
  </base-dropdown>
</template>

<script>
import LanguageFlag from '@/components/LanguageFlag.vue';
import TranslationItem from '@/components/TranslationItem.vue';

export default {
  name: 'translation-dropdown',
  props: {
    rightDirectionDropdown: {
      type: Boolean,
      default: false,
    },
    languages: {
      type: Array,
      default: () => [],
    },
    loadingLanguages: {
      type: Array,
      default: () => [],
    },
    labelTranslations: {
      type: Object,
      default: () => ({}),
    },
    statusTranslations: {
      type: Object,
      default: () => ({}),
    },
    currentLanguage: {
      type: String,
    },
    tight: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    translationDropdownLoading: {
      type: Boolean,
      default: false,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    hasStagedVersion: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LanguageFlag,
    TranslationItem,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    editingAction() {
      return this.editable &&
        this.languages.length > 1 &&
        !this.hasStagedVersion
        ? 'unset-language'
        : null;
    },
  },
  methods: {
    itemStatus(language) {
      if (this.loadingLanguages.includes(language)) return 'loading';
      return this.statusTranslations[language]
        ? 'published'
        : this.statusTranslations[language] !== undefined
        ? 'draft'
        : Object.keys(this.statusTranslations).length
        ? 'no-status'
        : null;
    },
    handleLanguageChange(language) {
      if (
        this.currentLanguage === language ||
        this.loadingLanguages.includes(language)
      )
        return;
      this.$emit('change-language', language);
    },
    handleOpening(val) {
      this.isOpen = val;
    },
    handleUnsetLanguage(language) {
      this.$emit('unset-language', language);
      this.$refs.dropdown.closeDropDown();
    },
  },
};
</script>

<style lang="scss" scoped>
.languages-btn {
  font-size: 16px;
  font-weight: 500;
  line-height: 1px;
  padding: 6px 8px;
  border-radius: 2px;
  color: black;
  background-color: $grey-1-mayday;
  cursor: pointer;

  &:hover {
    background-color: $grey-2-mayday;
  }

  &.open {
    background-color: $grey-2-mayday;
  }
}

.chevron-icon {
  font-size: 10px;
  width: 11px;
}

.dropdown {
  :deep() .dropdown-menu {
    width: 270px;
    padding: 0px !important;
    border: 1px solid $grey-3-mayday;
    box-shadow: $shadow-mayday;
    border-radius: 4px;
    overflow: hidden;
  }
}

.leading-icon {
  line-height: 12px;
}

.flag-emoji {
  font-size: 18px;
}
</style>
