<template>
  <div class="empty-state-main-container">
    <div class="empty-state-text-container">
      <h1 class="mb-0">404</h1>
      <p class="mb-0">{{ $t('knowledge.empty-state.title') }}</p>
    </div>
    <base-button :text="$t('knowledge.empty-state.cta')" @click="backToHome" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'KnowledgeEmptyState',
  methods: {
    backToHome() {
      let knowledge = this.focusKnowledge;

      if (!knowledge) {
        // Fallback to home page without knowledges
        if (!this.knowledges.length) {
          window.location.href = `${window.location.origin}/home`;
        }

        knowledge = this.knowledges[0];
      }

      window.location.href = `${window.location.origin}/home/${knowledge.defaultLanguage}/${knowledge.id}`;
    },
  },
  computed: {
    ...mapGetters('knowledgeModule', ['knowledges', 'focusKnowledge']),
  },
};
</script>

<style lang="scss" scoped>
.empty-state-main-container {
  margin-top: 68px;
  height: calc(100vh - 68px);
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: $shadow-mayday;
}

.empty-state-text-container {
  display: flex;
  align-items: center;
  gap: 16px;
}
</style>
