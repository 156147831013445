<template>
  <base-toast
    :title="$t('knowledge.update-status.error-toast.title')"
    :description="$t('knowledge.update-status.error-toast.description')"
    type="error"
    use-emit
    @close="$emit('close')"
  />
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'UpdateContentToast',
  mounted() {
    window.addEventListener('beforeunload', this.resetUpdateStatus);

    setTimeout(() => {
      this.$emit('close');
    }, 10000);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.resetUpdateStatus);
  },
  methods: {
    ...mapActions('knowledgeModule', ['resetUpdateStatus']),
  },
};
</script>

