<template>
  <div
    :class="['breadcrumb-container', { 'w-100': !ellipseOnly }]"
    ref="breadcrumb-container"
    v-if="items.length > 0"
  >
    <div class="parent">
      <div class="flex items-center w-100" ref="breadcrumb">
        <div
          v-if="showHouse"
          class="d-flex fex-row align-items-center"
          @click="getBackHome"
        >
          <div
            :class="[
              'house-wrapper',
              { 'house-wrapper__clickable': clickable },
            ]"
          >
            <font-awesome-icon :icon="['fal', 'home']" />
          </div>

          <img
            src="~assets/breadcrumb.png"
            class="case-path-navigator__separator"
          />
        </div>
        <div v-if="isTemplate" class="d-flex align-items-center">
          <div
            @click="goToTemplates"
            :class="[
              'element',
              clickable ? 'element__clickable' : 'element__not-clickable',
            ]"
          >
            {{ $t('knowledge.templates.description') }}
          </div>

          <img
            v-if="items.length"
            src="~assets/breadcrumb.png"
            class="case-path-navigator__separator"
          />
        </div>
        <div
          v-for="(parent, idx) in renderedPath"
          :key="idx"
          class="d-flex align-items-center"
        >
          <el-tooltip :disabled="parent.label !== '...'" :effect="'light'"
            ><div
              class="w-100 d-flex align-items-center"
              :class="[
                'element',
                clickable && idx !== renderedPath.length - 1
                  ? 'element__clickable'
                  : 'element__not-clickable',
              ]"
              @click="sendUpdateCasePath(parent, idx)"
            >
              <TextEllipsis
                :text="parent.label"
                :fontSize="'14px'"
                :width="idx === renderedPath.length - 1 ? maxWidth : null"
              ></TextEllipsis>
            </div>
            <template v-slot:content>
              <div
                v-for="(hiddenParent, hiddenIndex) in hiddenParents"
                :key="hiddenIndex"
                @click="sendUpdateCasePath(hiddenParent, hiddenIndex + 2)"
                :class="[
                  'element',
                  'element__tooltip',
                  clickable ? 'element__clickable' : 'element__not-clickable',
                  ,
                ]"
              >
                {{ hiddenParent.label }}
              </div>
            </template>
          </el-tooltip>

          <!-- DIVIDER -->
          <img
            v-if="idx + 1 !== renderedPath.length || ellipseOnly"
            src="~assets/breadcrumb.png"
            class="case-path-navigator__separator ml-1"
          />
        </div>
        <div v-if="isParametric">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TextEllipsis from '../TextEllipsis.vue';

export default {
  name: 'breadcrumb',
  components: { TextEllipsis },
  props: {
    items: {
      type: Array,
      required: true,
    },
    ellipseOnly: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
    showHouse: {
      type: Boolean,
      default: true,
    },
    ajustableMargin: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return { maxWidth: 300, isOverflow: false, isFirstElementOverflow: false };
  },
  computed: {
    renderedPath() {
      if (this.ellipseOnly) return [{ label: '...', id: '-1' }];
      if (this.isOverflow && this.items.length === 2) {
        return [{ label: '...', id: '-1' }, this.items.at(-1)];
      }
      if (!this.isOverflow || this.items.length < 3) return this.items;

      return [
        ...(this.isFirstElementOverflow ? [] : [this.items[0]]),
        { label: '...', id: '-1' },
        this.items.at(-1),
      ];
    },
    hiddenParents() {
      if (this.ellipseOnly) return this.items;
      if (!this.isOverflow || this.items.length === 1) return [];
      if (this.isOverflow && this.items.length === 2)
        return [this.showHouse ? this.items[0] : this.items.at(-1)];

      return this.items.slice(this.isFirstElementOverflow ? 0 : 1, -1);
    },
    ...mapGetters(['isParametric']),
  },
  methods: {
    computeOverflow() {
      this.isOverflow = false;
      this.$nextTick(() => {
        const breadcrumb = this.$refs.breadcrumb;
        if (!breadcrumb) return;
        this.isOverflow = breadcrumb.scrollWidth > breadcrumb.clientWidth;
      });
    },
    computeMaxWidth() {
      this.$nextTick(() => {
        const container = this.$refs['breadcrumb-container'];
        const el = this.$refs.breadcrumb;
        if (!container || !el) return;
        let breadcrumbEls = Array.from(el.children);
        breadcrumbEls.slice(0, this.renderedPath.length);
        if (!breadcrumbEls || breadcrumbEls.length === 0) return;
        this.isFirstElementOverflow =
          breadcrumbEls[this.showHouse ? 1 : 0].clientWidth >
          container.clientWidth;
        this.$nextTick(() => {
          const breadcrumbElementsWidth = breadcrumbEls.reduce(
            (acc, val, idx) => {
              if (idx === breadcrumbEls.length - 1) return acc;
              acc = acc + val.clientWidth;
              return acc;
            },
            0,
          );
          const lastElementMaxWidth =
            container.clientWidth -
            this.ajustableMargin -
            breadcrumbElementsWidth;
          this.maxWidth = lastElementMaxWidth;
        });
      });
    },
    getBackHome() {
      if (this.clickable)
        this.goToTranslatedEntity({ entityType: 'Knowledge' });
    },
    goToTemplates() {
      if (this.clickable) this.$router.push('/knowledge/templates');
    },
    sendUpdateCasePath(route, idx) {
      if (!this.clickable || route.type || route.id === '-1') return;
      this.updateCasePath(this.items.slice(0, idx + 1));

      this.goToTranslatedEntity({
        entityId: route.id,
        entityType: 'Case',
      });
    },
    ...mapActions('knowledgeModule', ['goToTranslatedEntity']),
    ...mapActions('kbStore', ['updateCasePath']),
  },

  watch: {
    items: {
      handler() {
        this.computeOverflow();
        this.computeMaxWidth();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb-container {
  max-width: 100%;
}
.parent {
  position: relative;
}
.case-path-navigator__separator {
  width: 14px;
  height: 14px;
}
.house-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__clickable:hover {
    background-color: $grey-2-mayday;
    cursor: pointer;
  }
  &__not-clickable {
    color: $grey-6-mayday;
  }
}
.element {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  &__clickable:hover {
    background-color: $grey-2-mayday;
    cursor: pointer;
  }
  &__clickable {
    padding: 2px;
    color: black;
  }
  &__not-clickable {
    color: $grey-6-mayday;
  }
  &__tooltip {
    font-size: 14px;
  }
}
</style>
