<template>
  <div class="rule-edition-modal">
    <modal
      :show.sync="localDisplay"
      :close-icon="false"
      modal-classes="modal-dialog-centered modal-xl"
      secondary
    >
      <modal-header :style-name="{ backgroundColor: '#0081F91A' }" class="mb-3">
        <div slot="icon" class="d-inline-flex">
          <font-awesome-icon
            class="icon blue-mayday"
            :icon="['fal', 'brain']"
          />
        </div>
        <div>
          <p class="mt-3 mb-0 font-weight-bold">
            {{ $t('automations.modal.create.title') }}
          </p>
        </div>
      </modal-header>
      <div
        class="row mx-0 text-center align-items-center justify-content-center"
      >
        <!-- RULE UNDEFINED CTA CREATE -->
        <card class="col-12 rule-modal" v-if="!isRuleDefined">
          <div class="d-flex flex-column align-items-center py-4">
            <font-awesome-icon
              :icon="['fad', 'magic']"
              class="empty-automation-icon"
            />
            <h4 class="text-muted text-upercase ls-1 my-2">
              {{ $t('automations.modal.create.subtitle') }}
            </h4>
            <p class="col-8 mx-auto my-2">
              {{ $t('automations.modal.create.description') }}
            </p>
            <base-button
              class="my-2"
              size="md"
              :text="$t('automations.modal.create.cta')"
              @click="isRuleDefined = true"
            />
          </div>
        </card>

        <!-- RULE EDITING -->
        <card class="col-12 rule-modal" v-else>
          <!-- RULE NAME -->
          <div class="d-flex mb-2">
            <div class="col-2 text-right text-muted rule-step-option">
              <small>{{ $t('automations.modal.edit.alias.field') }}</small>
            </div>
            <base-input-old
              v-model="localGroup.alias"
              class="name-input col-4 mb-0"
              :placeholder="$t('automations.modal.edit.alias.placeholder')"
              @blur="updateRuleType()"
              @keyup.enter="$event.target.blur()"
            ></base-input-old>
          </div>

          <!-- RULE LOGIC -->
          <div class="choose-logic d-flex mb-2">
            <div class="col-2 text-right text-muted rule-step-option">
              <small>{{ $t('automations.modal.edit.type.field') }}</small>
            </div>
            <base-input-old class="col-3 mb-0">
              <el-select
                v-model="localGroup.type"
                filterable
                :placeholder="$t('automations.modal.edit.type.placeholder')"
                @input="updateRuleType()"
                class="logic-select"
              >
                <el-option
                  :label="$t('automations.main.row.logic.all')"
                  value="ALL"
                ></el-option>
                <el-option
                  :label="$t('automations.main.row.logic.any')"
                  value="ANY"
                ></el-option>
              </el-select>
            </base-input-old>
          </div>

          <!-- RULE CONDITIONS -->
          <div class="choose-logic d-flex">
            <div class="col-2 text-right text-muted rule-step-option">
              <small>{{ $t('automations.main.row.conditions.label') }}</small>
            </div>
            <div class="collapse-condition-content ml-3 col-10">
              <!-- EXISTING RULE ATTRIBUTES -->
              <div
                v-for="(attribute, index) in localGroup.ruleAttributes"
                :key="attribute.id"
                class="d-flex my-2 rule-section"
              >
                <span
                  class="mr-2 d-flex logic-operator justify-content-end pr-2"
                >
                  <span v-if="index > 0" class="and-operator mr-1">
                    {{ localRuleType }}
                  </span>
                  {{ $t('automations.main.row.conditions.if') }}
                </span>
                <AutomationsRuleAttribute
                  :attribute="attribute"
                  :is-invalid-rule="isInvalidRule"
                  @update-rule-attribute="updateRuleAttribute"
                  @delete-attribute="deleteAttribute(attribute.id)"
                />
              </div>

              <!-- NEW RULE ATTRIBUTE  -->
              <div class="d-flex my-2 rule-section" v-if="addNewAttribute">
                <span
                  class="mr-2 d-flex logic-operator justify-content-end pr-2"
                >
                  <span
                    v-if="localGroup.ruleAttributes.length > 0"
                    class="and-operator mr-1"
                    >{{ localRuleType }}</span
                  >
                  {{ $t('automations.main.row.conditions.if') }}
                </span>
                <AutomationsRuleAttribute
                  :attribute="newAttribute"
                  :is-invalid-rule="isInvalidRule"
                  @update-rule-attribute="updateRuleAttribute"
                  @cancel-new-attribute="addNewAttribute = false"
                />
              </div>
              <base-button
                class="d-flex"
                variant="secondary"
                icon="plus"
                :disabled="addNewAttribute"
                :text="$t('automations.main.row.conditions.add')"
                @click="addCondition"
              />
            </div>
          </div>
        </card>
      </div>
      <template slot="footer">
        <base-button
          variant="secondary"
          :text="$t('automations.modal.close')"
          @click="$emit('close')"
        />
      </template>
    </modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import AutomationsRuleAttribute from 'views/Automations/Main/AutomationsRuleAttribute';
import ModalHeader from '@/components/Modals/ModalHeader';

export default {
  name: 'rule-edition-modal',
  components: {
    AutomationsRuleAttribute,
    ModalHeader,
  },
  props: {
    display: Boolean,
    group: {
      type: Object,
      default: () => ({
        id: null,
        alias: '',
        type: '',
        ruleAttributes: [],
      }),
    },
    update: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    isInvalidRule() {
      return (
        !this.localGroup.alias ||
        this.localGroup.alias === 0 ||
        !this.localGroup.type ||
        this.localGroup.type.length === 0
      );
    },
    groupId() {
      return this.group ? this.group.id : null;
    },
    validate() {
      return [
        this.localGroup.alias > 0,
        this.localGroup.type.length > 0,
        this.localGroup.ruleAttributes.length > 0,
      ];
    },
    cleanRules() {
      return {
        type: this.rule.type,
        conditions: this.rule.conditions.filter(
          ({ companyAttributeId, companyAttributeValueId, value, operator }) =>
            companyAttributeId.length &&
            operator.length &&
            (value.length || companyAttributeValueId.length),
        ),
      };
    },
    conditions() {
      return this.rule.conditions;
    },
    localRuleType() {
      return this.localGroup.type === 'ANY' ? 'OU' : 'ET';
    },
  },
  async mounted() {
    await this.getCompanyAttributes();
    if (this.group && this.group.id) {
      this.localGroup = Object.assign({}, this.localGroup, this.group);
    }
  },
  data() {
    return {
      localGroup: {
        id: null,
        alias: '',
        type: '',
        ruleAttributes: [],
      },
      newAttribute: {
        id: '',
        companyAttribute: {},
        companyAttributeValue: {},
        operator: '',
        value: [],
      },
      rule: { type: '', conditions: [] },
      alias: this.group ? this.group.alias : '',
      createVariable: false,
      localDisplay: this.display,
      isRuleDefined: this.group ? this.group.id !== null : false,
      addNewAttribute: false,
    };
  },
  methods: {
    addCondition() {
      if (!this.addNewAttribute) {
        this.addNewAttribute = true;
      }
    },
    updateRuleType() {
      if (this.localGroup.ruleAttributes.length > 0) {
        let rules = this.rulesParser();
        this.$emit('update-group', {
          id: this.localGroup.id,
          alias: this.localGroup.alias,
          rules: JSON.stringify({
            type: this.localGroup.type,
            conditions: rules,
          }),
        });
      }
    },
    updateRuleAttribute(newRuleAttribute) {
      const id = newRuleAttribute.id;
      let rules = this.rulesParser();
      // updating already existing attribute
      if (id !== '') {
        const newRuleAttrIdx = rules.map((ra) => ra.id).indexOf(id);
        if (newRuleAttrIdx !== -1) {
          rules[newRuleAttrIdx] = newRuleAttribute;
        }
      } else {
        // this.addNewAttribute = false;
        rules = rules.concat([newRuleAttribute]);
      }
      this.$emit('update-group', {
        id: this.localGroup.id,
        alias: this.localGroup.alias,
        rules: JSON.stringify({
          type: this.localGroup.type,
          conditions: rules,
        }),
      });
    },
    deleteAttribute(attrId) {
      this.localGroup.ruleAttributes = this.localGroup.ruleAttributes.filter(
        (ra) => String(ra.id) !== String(attrId),
      );
      if (attrId !== '') {
        const rules = this.rulesParser();
        this.$emit('update-group', {
          id: this.localGroup.id,
          alias: this.localGroup.alias,
          rules: JSON.stringify({
            type: this.localGroup.type,
            conditions: rules,
          }),
        });
      }
    },
    rulesParser() {
      return this.localGroup.ruleAttributes.map((ra) => ({
        id: ra.id,
        companyAttributeId: ra.companyAttribute.id,
        companyAttributeValueId: ra.companyAttributeValue
          ? ra.companyAttributeValue.id
          : null,
        operator: ra.operator,
        value: ra.value,
      }));
    },
    ...mapActions(['getCompanyAttributes']),
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
    group() {
      this.addNewAttribute = false;
      this.localGroup = Object.assign({}, this.localGroup, this.group);
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  width: 24px;
  height: 24px;
}

.rule-step-option {
  border-right: 2px solid $grey-4-mayday;
}

.and-operator {
  color: $blue-mayday;
}

.add-condition-enable {
  cursor: pointer;
}

.add-condition-enable:hover {
  color: $blue-mayday;
  cursor: pointer;
}

.add-condition-disable {
  cursor: not-allowed;
  font-size: 14px;
  color: $grey-5-mayday;
}
a {
  color: $blue-mayday !important;
}
.rule-section {
  color: $grey-5-mayday;
  font-size: 14px;
}

.collapse-actions {
  color: $unknown-blue-1-mayday;
  font-weight: 400;
  .collapse-actions-special {
    font-weight: 700;
    color: $blue-mayday;
  }
}
.collapse-condition-content {
  padding: 10px;
  border: 1px solid #d5dae3;
  background: $grey-1-mayday;
  border-radius: 5px;
}
.and-operator {
  color: $blue-mayday;
}

.rule-section {
  color: $grey-8-mayday;
}

.add-condition-btn {
  border-color: $grey-5-mayday;
  color: $grey-5-mayday;
}

.add-condition-btn:hover {
  border-color: $blue-mayday;
  color: white;
}

.rule-modal {
  max-height: 50vh;
  overflow: auto;
}

.add-condition-enable {
  cursor: pointer;
  font-size: 13px;
  color: $grey-4-mayday;
}
.add-condition-disable {
  cursor: not-allowed;
}
.logic-operator {
  width: 60px;
  border-right: 1px solid $grey-4-mayday;
}

.logic-select {
  :deep() .el-input__inner {
    height: 20px;
    border: none;
    padding-left: 0px;
    font-size: 13px;
  }
}

.logic-select {
  :deep() .el-input__inner:focus {
    height: 20px;
    border: none;
    padding-left: 0px;
    font-size: 13px;
  }
}

.logic-select {
  :deep() .el-input__icon {
    line-height: 20px;
  }
}

.name-input {
  :deep() .form-control {
    height: 20px;
    font-size: 13px;
    border-radius: 3px;
    width: auto;
    padding: 0px;
    border-color: transparent;
    color: black;
  }
}

.empty-automation-icon {
  color: $grey-6-mayday;
  width: 40px;
  height: 40px;
}
</style>
