<template>
  <div class="key-step-option">
    <side-bar-item
      slot="reference"
      icon="key"
      :description="tooltipDescription"
      :type="type"
      :active="isKeyStep"
      @click="buttonClick"
    />
  </div>
</template>

<script>
import SideBarItem from './SideBarItem';

export default {
  name: 'key-step-option',
  components: {
    SideBarItem,
  },
  props: {
    type: {
      type: String,
      default: 'diagnostic',
    },
    isKeyStep: Boolean,
  },
  computed: {
    tooltipDescription() {
      return this.isKeyStep
        ? this.$t('knowledge.diagnostic-builder.side-bar.keyStep.cta-remove')
        : this.$t('knowledge.diagnostic-builder.side-bar.keyStep.cta-add');
    },
  },
  methods: {
    buttonClick() {
      this.$emit('click', { type: this.isKeyStep ? 'Step' : 'keyStep' });
    },
  },
};
</script>
