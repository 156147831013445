<template>
  <div class="d-flex flex-column gap-2 w-100">
    <span v-if="field.label" class="fw-bold">{{ $t(field.label) }}</span>
    <base-input
      v-if="field.type === 'input'"
      class="w-full"
      :model-value="field.model"
      @update:model-value="field.model = $event.detail[0]"
      :placeholder="$t(field.placeholder)"
      :type="field.inputType || 'text'"
      :rows="field.rows"
      :error="error"
    />
    <base-input
      class="w-full"
      v-if="field.type === 'textarea'"
      :model-value="field.model"
      @update:model-value="field.model = $event.detail[0]"
      :placeholder="$t(field.placeholder)"
      type="textarea"
      :rows="field.rows"
      :error="error"
    />
    <div
      v-show="field.type === 'color'"
      class="color-picker-input align-items-center px-1 w-100 h-10"
      :class="{ 'error-outline': error && !hasChanged }"
      @click="openColorPicker"
    >
      <ColorPicker
        ref="colorPickerRef"
        size="small"
        v-model="field.model"
      ></ColorPicker>
      <div
        class="text-sm ms-1"
        :class="field.model ? 'text-dark' : 'text-muted'"
      >
        {{ field.model || $t(field.placeholder) }}
      </div>
    </div>
    <base-select-wrapper
      class="w-full"
      v-if="field.type === 'select'"
      :options="field.options"
      :icon="true"
      :value="field.model"
      :placeholder="$t(field.placeholder)"
      :error="error"
      @select="field.model = $event"
    />

    <icon-picker v-if="field.type === 'icon'" :value="field.model" />

    <span v-if="!!error && !hasChanged" class="text-xs text-danger">{{
      $t(error)
    }}</span>
  </div>
</template>

<script>
import { ColorPicker } from 'element-ui';
import IconPicker from '../../../../components/IconPicker.vue';

export default {
  name: 'PluginAdvancedSettingsOption',
  components: {
    IconPicker,
    ColorPicker,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    error: {
      type: String,
    },
  },
  data() {
    return {
      hasChanged: false,
    };
  },
  watch: {
    field: {
      handler(newField) {
        this.hasChanged = true;
        this.$emit(
          'update-plugin',
          newField.prepend
            ? `${newField.prepend}${newField.model}`
            : newField.model,
        );
      },
      deep: true,
    },
    error: {
      handler() {
        this.hasChanged = false;
      },
    },
  },
  methods: {
    openColorPicker() {
      this.hasChanged = true;
      if (this.$refs.colorPickerRef) {
        this.$refs.colorPickerRef.handleTrigger();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.color-picker-input {
  display: flex;
  height: 40px;
  border-radius: 4px;
  gap: 4px;
  border: 1px solid $grey-4-mayday;
  transition: border 250ms ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid $grey-5-mayday;
  }
}

.error-outline {
  border: 1px solid $red-5-mayday !important;
  border-radius: 4px;
}

.el-input-error-outline {
  :deep() .el-input__inner {
    border: 1px solid $red-mayday;
  }
}

:deep() .el-color-picker__trigger {
  border: none !important;
  pointer-events: none;
}
</style>
