<template>
  <div>
    <card class="group-card">
      <div class="row">
        <!-- MAIN ICON -->
        <div
          class="col-1 px-0 d-flex align-items-center justify-content-center"
        >
          <div>
            <font-awesome-icon
              slot="reference"
              :icon="['fad', 'users-cog']"
              class="my-auto main-icon"
            />
          </div>
        </div>
        <!-- MAIN CONTENT -->
        <div class="col-10">
          <p class="mb-2">
            <strong>{{ group.name }}</strong>
          </p>
          <div class="row d-flex align-items-center">
            <div
              class="col-2 pr-1 group-user-count d cursor: pointer;-flex align-items-center"
            >
              <el-popover
                placement="top"
                trigger="hover"
                v-if="validatedUsers.length"
              >
                <div class="show-users-popover">
                  <small class="group-members-name"
                    >Membres de {{ group.name }}:</small
                  >

                  <ul class="p-0">
                    <li
                      v-for="user in validatedUsers"
                      :key="user.id"
                      class="pt-2"
                    >
                      <div class="d-flex" v-if="user.username">
                        <font-awesome-icon
                          :icon="['fad', 'user']"
                          class="mr-2 list-user-icon"
                        ></font-awesome-icon>
                        <small>{{ user.username }}</small>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="d-flex align-items-center" slot="reference">
                  <font-awesome-icon
                    :icon="['fad', 'users']"
                    class="mr-2 small-icon"
                  />
                  <p class="mb-0">
                    <small>{{ validatedUsers.length }}</small>
                  </p>
                </div>
              </el-popover>

              <div v-else class="d-flex align-items-center">
                <font-awesome-icon
                  :icon="['fad', 'users']"
                  class="mr-2 small-icon"
                />
                <p class="mb-0">
                  <small>{{ validatedUsers.length }}</small>
                </p>
              </div>
            </div>
            <el-divider direction="vertical"></el-divider>
            <el-tooltip
              :content="
                $t('settings.permissions.user-groups-card.restrictions-tooltip')
              "
              placement="top"
            >
              <div
                class="col-auto d-flex align-items-center cursor restriction-display"
                @click="triggerUpdate"
              >
                <font-awesome-icon
                  :icon="['fad', 'sliders-v']"
                  class="mr-2 settings-icon"
                />
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  name: 'user-groups-card',
  props: {
    group: Object,
  },
  components: {},
  computed: {
    validatedUsers() {
      return this.group.users.length
        ? this.group.users.filter((u) => !!u.username)
        : [];
    },
  },
  data() {
    return {
      focusGroupId: null,
    };
  },
  methods: {
    triggerUpdate() {
      this.$emit('update-user-group', { group: this.group, step: 2 });
    },
  },
};
</script>

<style lang="scss" scoped>
.show-users-popover {
  max-height: 150px;
  min-width: 150px;
  overflow: scroll;
  line-break: normal;
  ul {
    list-style: none;
  }
  .group-members-name {
    font-size: 12px;
  }
  .list-user-icon {
    color: $grey-4-mayday;
  }
}
</style>

<style lang="scss" scoped>
.group-user-count {
  max-width: 75px;
  cursor: pointer;
}
.flag-icon {
  max-width: 12px !important;
}

.group-card {
  :deep() .card-body {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.main-icon {
  border: 1px solid;
  border-color: $grey-6-mayday;
  color: $grey-6-mayday;
  background-color: #7d89a01a;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  padding: 5px;
}

.group-card:hover {
  :deep() .main-icon {
    border-color: $blue-mayday;
    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.1);
  }
}

.small-icon {
  height: 14px;
  color: $grey-5-mayday;
}

.settings-icon {
  height: 18px;
  width: 18px;
  color: $grey-5-mayday;
  padding: 2px;
  border: 1px solid transparent;
}

.settings-icon:hover {
  border-radius: 4px;
  border-color: $blue-mayday;
  color: $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
}

.restriction-display {
  margin-left: -8px;
}
</style>
