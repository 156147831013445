<template>
  <div v-if="displayedUsers && displayedUsers.length">
    <TransitionGroup class="connected-users-container" name="scale">
      <el-tooltip v-for="(user, i) in displayedUsers" :key="i">
        <div slot="content">
          <span>{{ getUserInfos(user).username || '?' }}</span>
          <span v-if="!user.isActive" class="ml-1"
            >({{ $t('knowledge.contributors.inactive') }})</span
          >
        </div>
        <user-avatar
          :color="user.isActive ? null : grey5Mayday"
          :shadow="false"
          :username="getUserInfos(user).username || '?'"
        />
      </el-tooltip>
      <el-tooltip v-if="extraDisplayedUsers.length > 0" key="additional">
        <div slot="content">
          <div v-for="(user, i) in extraDisplayedUsers" :key="i">
            <span>
              {{ getUserInfos(user).username || '?' }}
            </span>
            <span v-if="!user.isActive" class="ml-1">
              ({{ $t('knowledge.contributors.inactive') }})</span
            >
          </div>
        </div>
        <div class="extra-users">+{{ extraDisplayedUsers.length }}</div>
      </el-tooltip>
    </TransitionGroup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UserAvatar from 'components/UserAvatar.vue';

import { grey5Mayday } from '@/assets/scss/_colors.scss';

export default {
  name: 'connected-users',
  components: { UserAvatar },
  props: {
    contentId: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
      default: 'default',
    },
  },
  data() {
    return {
      grey5Mayday,
    };
  },
  computed: {
    displayedUsers() {
      if (!this.connectedUsers[`${this.contentId}_${this.lang}`]) return [];
      return this.connectedUsers[`${this.contentId}_${this.lang}`].slice(0, 5);
    },
    extraDisplayedUsers() {
      if (!this.connectedUsers[`${this.contentId}_${this.lang}`]) return [];
      return this.connectedUsers[`${this.contentId}_${this.lang}`].slice(5);
    },
    ...mapState('adminModule', ['connectedUsers', 'contributors']),
  },
  methods: {
    getUserInfos({ userId }) {
      return this.contributors.find((user) => user.id === userId) || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.3s;
}

.scale-enter,
.scale-leave-to {
  transform: scale(0);
}

.scale-leave-from,
.scale-enter-to {
  transform: scale(1);
}

.connected-users-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  :last-child {
    margin-right: 0;
  }
  * {
    transition: all 0.2s;
    margin-right: -16px;
  }
  &:hover > * {
    margin-right: 0;
  }
}

.extra-users {
  width: 40px;
  height: 40px;
  flex: none;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: $grey-7-mayday;
  border: 3px solid white;
  font-size: 12px;
  font-weight: 700;
}
</style>
