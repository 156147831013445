<template>
  <menu-collapse
    :collapse="collapse"
    :title="knowledge.label"
    @collapsing="updateFocusedKnowledge"
  >
    <form>
      <label v-for="currentCase in cases" :key="currentCase.id">
        <input
          v-model="focusedCaseId"
          :value="currentCase.id"
          type="radio"
          @change="updateFocusedCase(currentCase.id)"
        />
        <TextEllipsis :text="currentCase.label" font-size="14px"/>
      </label>
    </form>
  </menu-collapse>
</template>
<script>
import MenuCollapse from '@/components/Collapse/MenuCollapse';
import TextEllipsis from "components/TextEllipsis.vue";

export default {
  name: 'automationKnowledgeMenu',
  components: {
    TextEllipsis,
    MenuCollapse,
  },
  props: {
    knowledge: Object,
    collapse: Boolean,
  },
  data() {
    return {
      cases: [],
      focusedCaseId: '',
    };
  },
  created() {
    if (this.knowledge.isDefault) {
      this.updateFocusedKnowledge();
    }
  },
  methods: {
    async updateFocusedKnowledge() {
      this.cases = await this.$services.cases.getRoots(this.knowledge.value);
      if (this.cases.length) {
        this.focusedCaseId = this.cases[0].id;
      }

      this.$emit('updateFocusedKnowledge', {
        knowledgeId: this.knowledge.id,
        caseId: this.focusedCaseId,
        caseLabel: this.cases.find((currentCase) => {
          return currentCase.id === this.focusedCaseId;
        }).label,
      });
    },
    updateFocusedCase(id) {
      this.$emit('updateFocusedCase', {
        caseId: id,
        caseLabel: this.cases.find((currentCase) => {
          return currentCase.id === id;
        }).label,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  flex-wrap: nowrap;
}

label {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin-bottom: 4px;
  :first-child {
    margin-right: 6px;
  }
}
</style>
