<template>
  <div class="concepts-search-container">
    <h1>{{ $t('concepts.search.title') }}</h1>
    <div class="search-bar">
      <div class="search-input">
        <input v-model="queryText" :placeholder="$t('concepts.search.title')" />
      </div>
      <div class="d-flex align-items-center gap-2">
        <el-select v-model="selectedLanguage" class="select">
          <el-option
            v-for="lang in langOptions"
            :key="lang"
            :value="lang"
            :label="$t(`knowledge.languages.${lang}`)"
          >
          </el-option>
        </el-select>
        <div class="plus-icon-wrapper" @click="handleOpenNewConceptModal">
          <font-awesome-icon :icon="['fal', 'plus']" class="plus-icon" />
        </div>
      </div>
    </div>
    <div>
      <div v-if="concepts.length">
        <div class="total-text">
          {{
            $tc('concepts.search.results', total, {
              showCount: concepts.length,
              count: total,
            })
          }}
        </div>
        <div v-bind:key="concept.id" v-for="concept in concepts" class="card">
          <div class="card-number">
            <span>{{ assignedOnNumber(concept) }}</span>
          </div>
          <div class="card-body">
            <div class="card-title">{{ resolveField(concept, 'label') }}</div>
            <div class="card-description">
              {{ resolveField(concept, 'body') }}
            </div>
          </div>
          <div class="card-numbers-wrapper">
            <div class="validated-number">
              <font-awesome-icon
                slot="prefix"
                class="select-icon"
                :icon="['fa', 'thumbs-up']"
              />
              <span>{{ userAssignedOnNumber(concept) }}</span>
            </div>
            <div class="rejected-number">
              <font-awesome-icon
                slot="prefix"
                class="select-icon"
                :icon="['fa', 'thumbs-down']"
              />
              <span>{{ userRejectedOnNumber(concept) }}</span>
            </div>
          </div>
          <div>
            <div class="delete-icon-wrapper">
              <font-awesome-icon
                @click="handleDelete(concept.id)"
                :icon="['fas', 'trash']"
                class="delete-icon"
              />
            </div>
            <div class="delete-icon-wrapper">
              <font-awesome-icon
                @click="handleEdit(concept.id)"
                :icon="['fal', 'pen']"
                class="delete-icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div v-if="canLoadMore" v-intersect="loadMore">
          {{ $t('concepts.search.show-more') }}
        </div>
        <div v-else-if="loadingMore" class="loader">
          {{ $t('concepts.search.loading') }}
        </div>
        <div v-else>
          {{ $tc('concepts.search.done', total) }}
          <a
            @click.prevent="backToTop"
            class="back-to-top"
            href="#"
            v-if="isPageScrolled"
          >
            {{ $t('mayday-web.scroll-to-top') }}
          </a>
        </div>
      </div>
    </div>
    <ModalEvents modal-name="DeleteContentModal" :uid="editModalUniqueKey" />
    <ModalEvents modal-name="NewConceptModal" uid="new-concept" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import getTranslation from '@/utils/getTranslation';
import { langOptions } from '@/utils/langOptions.js';
import ModalEvents from '@/components/Modals/ModalEvents';

export default {
  name: 'ConceptSearch',
  data() {
    return {
      queryText: '',
      timeout: null,
      concepts: [],
      total: null,
      loadingMore: false,
      isPageScrolled: false,
      selectedLanguage: null,
      langOptions,
    };
  },
  components: { ModalEvents },
  mounted() {
    this.checkPreferences();
    this.debouncedSearch();
    window.addEventListener('scroll', this.checkScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkScroll);
  },
  computed: {
    hasMoreConcepts() {
      return this.concepts.length < this.total;
    },
    canLoadMore() {
      return (
        this.total &&
        this.concepts &&
        !this.loadingMore &&
        this.concepts.length < this.total
      );
    },
    editModalUniqueKey() {
      return `test-unique-key`;
    },
    ...mapGetters([
      'userAllowConceptsPreference',
      'companyAllowConceptsPreference',
    ]),
  },
  directives: {
    intersect: {
      inserted: (el, binding) => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              binding.value();
            }
          },
          {
            rootMargin: '20px',
            threshold: 0.1,
          },
        );

        observer.observe(el);

        el._observer = observer;
      },
      unbind: (el) => {
        el._observer.disconnect();
      },
    },
  },
  methods: {
    ...mapActions('knowledgeModule', ['searchConcepts', 'deleteConcept']),
    ...mapActions('modalsModule', ['openModal']),
    checkPreferences() {
      if (
        !this.userAllowConceptsPreference ||
        !this.companyAllowConceptsPreference
      )
        return this.$router.push({ name: 'not-found' });
    },
    backToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    assignedOnNumber(concept) {
      return concept.concepts ? concept.concepts.nAssignedOn || 0 : 0;
    },
    userAssignedOnNumber(concept) {
      return concept.concepts ? concept.concepts.nUserAssignedOn || 0 : 0;
    },
    userRejectedOnNumber(concept) {
      return concept.concepts ? concept.concepts.nUserRejectedOn || 0 : 0;
    },
    resolveField(concept, field) {
      const lang = this.selectedLanguage || this.$i18n.locale;
      return getTranslation(concept, [lang])[field];
    },
    debouncedSearch() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.runSearch, 150);
    },
    async runSearch() {
      clearTimeout(this.timeout);
      this.timeout = null;
      const result = await this.searchConcepts({
        queryText: this.queryText,
        language: this.selectedLanguage,
      });
      this.concepts = result.concepts;
      this.total = result.total.value;
      this.searchAfter = result.search_after;
    },
    async loadMore() {
      if (!this.canLoadMore) this.loadingMore = true;
      const result = await this.searchConcepts({
        queryText: this.queryText,
        searchAfter: this.searchAfter,
      });
      this.concepts = [...this.concepts, ...result.concepts];
      this.searchAfter = result.search_after;
      this.loadingMore = false;
    },
    checkScroll() {
      this.isPageScrolled = window.scrollY > 0;
    },

    handleDelete(conceptId) {
      this.deleteConcept(conceptId);
      this.concepts = this.concepts.filter((d) => d.id != conceptId);
      this.total -= 1;
    },

    handleEdit(conceptId) {
      this.openModal({
        component: 'EditConceptModal',
        uid: `edit-concept-${conceptId}`,
        props: { conceptId, display: true },
      });
    },
    handleOpenNewConceptModal() {
      this.openModal({
        component: 'NewConceptModal',
        uid: `new-concept`,
        props: { display: true },
      });
    },
  },
  watch: {
    async queryText() {
      this.debouncedSearch();
    },
  },
};
</script>

<style scoped lang="scss">
.concepts-search-container {
  padding: 16px;
}

.card {
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.loader {
  height: 50px;
  /* Add your loader styles here */
}
.load-more {
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}
.card-number {
  min-width: 30px;
}

.card-numbers-wrapper {
  display: flex;
  gap: 20px;
}

.card-body {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.card-title {
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0px;
}

.card-description {
  font-size: 0.8em;
  color: darkgray;
}

.validated-number {
  color: $green-5-mayday;
  display: flex;
  gap: 5px;
}

.rejected-number {
  color: $red-5-mayday;
  display: flex;
  gap: 5px;
}

.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 16px;
}

.search-input {
  flex: 1;
  max-width: 300px;
}

.search-input input {
  width: 100%;
  height: 36px;
  padding: 8px;
  border-radius: 4px;
  border: darkgray 1px solid;
}

.total-text {
  font-size: 0.8em;
  color: darkgray;
}

.footer {
  font-size: 0.8em;
  color: darkgray;
}

.el-select {
  height: 32px;
}

.delete-icon-wrapper:hover {
  cursor: pointer;
  .delete-icon {
    color: $blue-mayday;
  }
}

.plus-icon-wrapper {
  width: 43px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  .plus-icon {
    font-size: 30px;
  }
  &:hover {
    cursor: pointer;
    color: $blue-mayday;
  }
}
</style>
